import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPButton from '../../../../../components/IPButton'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import IPSelectBox from '../../../../../components/IPSelectBox'
import IPInput from '../../../../../components/IPInput'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import classes from './DealerInformation.module.scss'
import { ApplicationRequestService } from '../services/dealerInformationService'
import { IApplicationRequest, IApplicationRequestHistory } from '../models/response/getApplicationRequestList'
import DealerInformationUpdateModal from './DealerInformationUpdateModal'
import Swal from 'sweetalert2'
import IPModal from '../../../../../components/IPModal'
import IPCheckbox from '../../../../../components/IPCheckBox'
import { css } from '@emotion/css'
import { Spinner } from '../../../../../components/Spinner'
import Fancybox from '../../../../../components/Fancybox'
import InputCampaign from '../../../../../components/InputCampaign'
import { getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'
import Table from '../../../../../components/Table'
import { IPInputDataPattern } from '../../../../../components/IPInput/IPInput'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { SellerApplicationHistoryApi } from '../services/appHistoryApi'
import moment from 'moment'

interface IState {
  selectedStatus: ISelectOption
  selectedIslem?: ISelectOption
  selectedIsEnabled: ISelectOption
  showModal: boolean
  showDocumentModal: boolean
  selectedDocuments?: { id: number; documents: any }
  applicationRequestList: IApplicationRequest[]
  selectedApplicationId?: number
  trackingNumber: string
  authorizedName: string
  selectedRecordIDs: number[]
  previewDocumentLinkList: string[]
  isLoading: boolean
  pdfFrame: string
  historyData: IApplicationRequestHistory[]
  showHistory: boolean
}

function DealerInformation(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const getInitialState = (): IState => {
    return {
      showModal: false,
      showDocumentModal: false,
      applicationRequestList: [],
      selectedStatus: { label: 'Tümü', value: 0 },
      selectedIsEnabled: { label: 'Aktif', value: 1 },
      trackingNumber: '',
      authorizedName: '',
      selectedRecordIDs: [],
      previewDocumentLinkList: [],
      isLoading: false,
      pdfFrame: '',
      historyData: [],
      showHistory: false
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IState>(intialState)

  const StatusOptions: ISelectOption[] = [
    {
      label: 'Tümü',
      value: 0,
    },
    {
      label: 'Yeni Giriş',
      value: 1,
    },
    {
      label: 'Eksik Evrakı Olanlar',
      value: 2,
    },
    {
      label: t('pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.pdfGenerated'),
      value: 3,
    },
    {
      label: t('pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.confirmationEmailSent'),
      value: '4',
    },
    {
      label: t('pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.contractApproved'),
      value: 5,
    },
    {
      label: t('pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.completed'),
      value: 6,
    },
    {
      label: 'Başvurunuz Onaylanmadı',
      value: 7,
    },
  ]
  const IsEnabledOptions: ISelectOption[] = [
    {
      label: t('pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.active'),
      value: 1,
    },
    {
      label: t('pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.passive'),
      value: 0,
    },
  ]
  const onDurumChange = (value: ISelectOption) => {
    setState({ selectedStatus: value })
  }
  const onIsEnabledChange = (value: ISelectOption) => {
    setState({ selectedIsEnabled: value })
  }

  const getApplicationRequest = async () => {
    const applicationRequest = new ApplicationRequestService({})
    try {
      const applicationRequestList = await applicationRequest.getApplicationRequest()
      setState({ applicationRequestList: applicationRequestList })
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    filterApplicationRequest()
  }, [])

  const filterApplicationRequest = async () => {
    props.showLoading()
    interface Filter {
      StartDate?: Date
      EndDate?: Date
      Status?: number
      Enabled?: boolean
      Number?: string
      Yetkili?: string
    }

    let filter: Filter = {
      StartDate: getDateByTimezoneOffset(new Date(startDate)),
      EndDate: getDateByTimezoneOffset(new Date(endDate)),
      Enabled: state.selectedIsEnabled.value === 1 ? true : false,
    }

    if (state.trackingNumber.trim().length > 0) {
      filter.Number = state.trackingNumber
    }

    if (state.authorizedName.trim().length > 0) {
      filter.Yetkili = state.authorizedName
    }

    if (state.selectedStatus.value !== 0) {
      filter.Status = state.selectedStatus.value as number
    }

    const applicationRequest = new ApplicationRequestService({})
    try {
      const applicationRequestList = await applicationRequest.filterApplicationRequest(filter)
      // applicationRequestList.sort((a, b) => b.CreatedDate.getDate() - a.CreatedDate.getDate());
      setState({ applicationRequestList })
      props.hideLoading()
    } catch (err) {
      console.error(err)
      props.hideLoading()
    }
  }

  const putApplicationRequest = async updateState => {
    const request = {
      Id: state.selectedApplicationId,
      Status: updateState.status.value,
      StatusCode: Number(updateState.statusCode.value),
    }

    setState({ isLoading: true })

    const applicationRequest = new ApplicationRequestService({})
    try {
      const res = await applicationRequest.changeStatus(request)

      let updatedList

      if (res) {
        updatedList = state.applicationRequestList?.map(item => {
          if (item.Id === res.Id) {
            item = res
          }
          return item
        })
        setState({ applicationRequestList: updatedList, isLoading: false })
        Swal.fire('Başarılı', 'Bayi Başvurusu Değiştirildi', 'success')
      }
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
      setState({ isLoading: false })
    }
  }

  const closeModal = () => {
    setState({ showModal: false })
  }

  const closeDocumentModal = () => {
    setState({ showDocumentModal: false, previewDocumentLinkList: [] })
  }

  const handleDocumentModal = (pdf, documents) => {
    const files = documents ? JSON.parse(documents as any).map(item => item.FileUrl) : []
    if (pdf) {
      files.push(pdf)
    }
    setState({ showDocumentModal: true, previewDocumentLinkList: files })
  }

  const createPdf = async (Id: number[]) => {
    const request = [...Id]

    setState({ isLoading: true })

    const applicationRequest = new ApplicationRequestService({})
    try {
      const res = await applicationRequest.createPdf(request)
      let messages: any = ''

      if (res) {
        let updatedList
        res.forEach(pdfItem => {
          let currentTrackingNumber: string = ''

          updatedList = state.applicationRequestList?.map(item => {
            if (item.Id === pdfItem.Id) {
              currentTrackingNumber = item.TrackingNumber
              item.ContractAddress = pdfItem.PdfName
            }
            return item
          })

          const isPdfCreated = pdfItem.PdfName && pdfItem.PdfName.length > 0

          messages += `
						<div style='margin-bottom: 2rem;'>
							<b style='display: block; margin-bottom: 0.5rem;'>Takip Numarası: ${currentTrackingNumber} ${isPdfCreated ? '✅' : '❌'
            }</b>
							<br/>
							${isPdfCreated
              ? `<div style='display: block;'>'${pdfItem.PdfName}' sözleşme oluşturulmuştur.</div>`
              : 'Sözleşme oluşturulamadı.'
            }
						</div>
					`
        })
        setState({ applicationRequestList: updatedList, isLoading: false })
        Swal.fire('Sözleşme', messages, 'info')
      }
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
      setState({ isLoading: false })
    }
  }

  const sendContract = async (id: number[]) => {
    const req = [...id]

    setState({ isLoading: true })

    const appRequestService = new ApplicationRequestService({})
    try {
      const res = await appRequestService.sendContract(req)
      if (res) {
        let updatedList
        let messages: any = ''
        let currentTrackingNumber: string = ''
        let isMailSent: boolean = false

        res.forEach(mailItem => {
          updatedList = state.applicationRequestList?.map(item => {

            isMailSent = mailItem.MailSent

            if (item.Id === mailItem.Id) {
              currentTrackingNumber = item.TrackingNumber
              item.IsMailSent = mailItem.MailSent
            }
            return item
          })

          messages += `
						<div style='margin-bottom: 2rem;'>
							<b style='display: block; margin-bottom: 0.5rem;'>Takip Numarası: ${currentTrackingNumber} ${isMailSent ? '✅' : '❌'
            }</b>
							<br/>
							${isMailSent ? `<div style='display: block;'>Onay maili gönderilmiştir.</div>` : 'Mail gönderilemedi.'}
						</div>
					`
        })
        setState({ applicationRequestList: updatedList, isLoading: false })
        Swal.fire('Onay Maili', messages, 'info')
      }
    } catch (err: any) {
      setState({ isLoading: false })
      Swal.fire('Hata', err.description, 'error')
    }
  }

  const showHistory = async (id: number) => {
    props.showLoading();
    const appHistory: SellerApplicationHistoryApi = new SellerApplicationHistoryApi({})
    try {
      await appHistory
        .historyByID(id)
        .then(res => {
          setState({ showHistory: true, historyData: res })
        })
      props.hideLoading();
    } catch (err: any) {
      props.hideLoading();
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  }


  const createSapRecord = async (id: number[]) => {
    Swal.fire({
      icon: 'question',
      title: "SAP kaydı oluşturulacaktır. Devam etmek istediğinize emin misiniz?",
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        const req = [...id]
        setState({ isLoading: true })

        const service = new ApplicationRequestService({})
        try {
          const res = await service.createSapRecord(req)

          if (res) {
            let updatedList
            let messages: any = ''

            res.forEach(sapItem => {
              updatedList = state.applicationRequestList?.map(item => {
                if (item.TrackingNumber === sapItem.TrackingNumber && sapItem.Status) {
                  item.IsSapRecordCreated = true
                }
                return item
              })

              messages += `
					<div style='margin-bottom: 2rem;'>
						<b style='display: block; margin-bottom: 0.5rem;'>Takip Numarası: ${sapItem.TrackingNumber} ${sapItem.Status ? '✅' : '❌'
                }</b>
						<br/>
						${sapItem.Messages &&
                sapItem.Messages.length > 0 &&
                sapItem.Messages.map(msg => `<div style='display: block;'>${msg}</div>`)
                }
					</div>
			`
            })

            setState({ applicationRequestList: updatedList, isLoading: false })
            Swal.fire({
              title: 'SAP Kayıt Sonuçları',
              html: messages,
              width: '50%',
            })
          } else Swal.fire('Hata', 'Beklenmedik bir hata oluştu.', 'error')
        } catch (err: any) {
          setState({ isLoading: false })
          Swal.fire('Hata', err.description, 'error')
        }
      }
    })
  }

  const handleSelectedRecord = (checked: boolean, id: number) => {
    const sameId = state.selectedRecordIDs?.find(item => item === id)

    if (sameId) {
      const updatedState = state.selectedRecordIDs?.filter(item => item !== id)
      setState({ selectedRecordIDs: updatedState })
      return
    }

    if (checked) {
      let updatedState: number[]
      if (state.selectedRecordIDs!.length > 0) {
        updatedState = [...state.selectedRecordIDs, id]
      } else updatedState = [id]

      setState({ selectedRecordIDs: updatedState })
    }
  }

  const actionBtnCSS = css`
    width: 130px;
    min-width: auto;
    height: 35px;
    font-size: 13px;
    border-radius: 3px;
    margin-block: 0.5rem;
  `
  const actionButtons = (
    <>
      <IPButton onClick={() => createPdf(state.selectedRecordIDs)} text='PDF Oluştur' className={actionBtnCSS} />
      <IPButton onClick={() => sendContract(state.selectedRecordIDs)} text='Mail Gönder' className={actionBtnCSS} />
      <IPButton
        onClick={() => createSapRecord(state.selectedRecordIDs)}
        text='SAP Kaydı Oluştur'
        className={actionBtnCSS}
      />
    </>
  )

  const selectedApplication =
    state.selectedApplicationId && state.applicationRequestList.find(app => app.Id === state.selectedApplicationId)

  const statusOptionText = code => {
    const text = StatusOptions.find(option => Number(option.value) === Number(code))?.label

    let color = '#000'
    if (code === 2 || code === 3) color = '#FFC800'
    if (code === 4 || code === 5 || code === 6) color = 'green'
    if (code === 7) color = 'red'

    return <span style={{ color }}>{text}</span>
  }

  let pdfCol: JSX.Element[] = []
  let imageCol: JSX.Element[] = []

  const imageBoxCSS = css`
    position: relative;
    display: block;
    width: 100%;
    height: 200px;

    &:hover {
      .image--name {
        color: #ec4c4c;
      }
    }

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 180px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .image--name {
      position: absolute;
      padding: 10px;
      bottom: 0;
      left: 0;
      width: 100%;
      color: #333;
      background-color: #f0f0f0;
      text-decoration: none;
      transition: color, transform, 0.3s;
    }
  `

  const pdfBoxCSS = css`
    padding: 10px;
    width: 100%;
    color: #333;
    background-color: #f0f0f0;
    text-decoration: none;
    transition: color, transform, 0.3s;

    &:hover {
      color: #ec4c4c;
    }
  `

  state.previewDocumentLinkList.length > 0 &&
    state.previewDocumentLinkList.forEach(item => {
      if (!item) return

      const link = item.split('/')
      const name = link?.[link.length - 1]

      const _name = name.length > 23 ? name.slice(0, 23) + '...' : name

      if (
        item.indexOf('.jpg') > -1 ||
        item.indexOf('.jpeg') > -1 ||
        item.indexOf('.png') > -1 ||
        item.indexOf('.webp') > -1 ||
        item.indexOf('.svg') > -1 ||
        item.indexOf('.avif') > -1
      ) {
        const _name = name.length > 23 ? name.slice(0, 23) + '...' : name
        imageCol.push(
          <div className='col-12 col-md-4 col-lg-3 mb-4'>
            <a className={imageBoxCSS} data-fancybox='gallery' href={item} data-caption={_name}>
              <div className='image'>
                <img src={item} alt={_name} />
                <div className='image--name'>{_name}</div>
              </div>
            </a>
          </div>
        )
        return
      }

      if (item.indexOf('.pdf') > -1) {
        pdfCol.push(
          <div className='col-12 col-md-4 col-lg-3 mb-4'>
            <a data-fancybox='gallery' href={item} data-caption={_name}>
              <div className={pdfBoxCSS}>
                <i className='fa fa-file-pdf'></i> {_name}
              </div>
            </a>
          </div>
        )
      }
    })

  useEffect(() => {
    const pdfs: string[] = []
    state.previewDocumentLinkList.length > 0 &&
      state.previewDocumentLinkList.forEach(item => {
        if (item && item.indexOf('.pdf') > -1) {
          pdfs.push(item)
        }
      })
    if (pdfs.length > 0) {
      setState({ pdfFrame: pdfs[0] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.previewDocumentLinkList])

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }


  const setPassive = async (id: number) => {
    Swal.fire({
      icon: 'question',
      title: `Başvuru durumu ${state.selectedIsEnabled.value === 1 ? 'Pasif' : 'Aktif'} olarak değiştirilecektir. Devam etmek istiyor musunuz?`,
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        const deleteApi = new ApplicationRequestService({})
        try {
          const res = await deleteApi.setDelete(id)
          if (res) {
            Swal.fire('Başarılı', 'Bayi Başvurusu Güncellendi', 'success')
            filterApplicationRequest()
          }
        }
        catch (err: any) {
          Swal.fire('Hata', err.description, 'error')
        }
      }
    })
  }


  // Tablo başlıkları
  const columnDefinitions = [
    { Header: 'Tarihçe', accessor: 'col22' },
    { Header: 'Durum', accessor: 'col21' },
    { Header: 'Düzenle', accessor: 'col0' },
    { Header: 'Güncelle', accessor: 'col1' },
    { Header: 'Seç', accessor: 'col2' },
    { Header: 'Belge', accessor: 'col3' },
    { Header: 'PDF', accessor: 'col4' },
    { Header: 'MAİL', accessor: 'col5' },
    { Header: 'SAP', accessor: 'col6' },
    { Header: 'Takip No', accessor: 'col7' },
    { Header: 'Şirket Adı', accessor: 'col8' },
    { Header: 'Yetkili', accessor: 'col9' },
    { Header: 'Tel', accessor: 'col10' },
    { Header: 'Fax', accessor: 'col11' },
    { Header: 'Mobil', accessor: 'col12' },
    { Header: 'Mail', accessor: 'col13' },
    { Header: 'MERSİS No', accessor: 'col14' },
    { Header: 'Vergi Dairesi', accessor: 'col15' },
    { Header: 'Vergi No', accessor: 'col16' },
    { Header: 'Durum', accessor: 'col17' },
    { Header: 'Oluşturma Tarihi', accessor: 'col18' },
    { Header: 'IBAN', accessor: 'col19' },
    { Header: 'SAP Açılış', accessor: 'col20' },

  ];

  const navigate: any = useNavigate()

  const tableColumns = useMemo(() => columnDefinitions, []);
  // Tablo verileri
  const tableData = useMemo(
    () => state.applicationRequestList.sort((a, b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime()).map((item, index) => {
      const data = {
        col22: (<a style={{ cursor: 'pointer', color: 'blue' }} onClick={() => showHistory(item.Id)}><i className='icon-clock' style={{ color: 'blue' }}></i></a>),
        col21: (<a
          className='btn btn-transparent'
          style={{ display: 'contents' }}
          type='button'
          data-action-type='order'
          onClick={() => {
            setPassive(item.Id)
          }}
        >
          {
            state.selectedIsEnabled.value === 1 ? <i className='icon-unlock' style={{ color: 'green' }}></i> : <i className='icon-lock' style={{ color: 'red' }}></i>
          }

        </a>),
        col0: (<a
          className='btn btn-transparent'
          type='button'
          data-action-type='order'
          target='_blank'
          onClick={() => {
            const url = `../DealerInformationFormOperation/SellerApplication?trackingNumber=${item.TrackingNumber}`;
            window.open(url, '_blank');
          }}
        >
          <i className='icon-edit' style={{ color: '#333' }}></i>
        </a>),
        col1: (<button
          className='btn btn-transparent'
          type='button'
          style={{ display: 'contents' }}
          data-action-type='order'
          onClick={() =>
            setState({
              showModal: true,
              selectedApplicationId: item.Id,
            })
          }
        >
          <i className='icon-refresh-ccw' style={{ color: '#333' }}></i>
        </button>),

        col2: (<div
          className={css`
            display: block;
            width: 30px;

            .form-check {
              display: block;
              inset: 0;
              margin: auto;
              height: max-content;
            } `}
        >
          <IPCheckbox
            name={`cb${item.Id}`}
            className={css` width: 20px !important; `}
            onCheckedChange={(checked: boolean) => {
              handleSelectedRecord(checked, item.Id)
            }}
            checked={state.selectedRecordIDs && state.selectedRecordIDs.indexOf(item.Id) > -1}
          />
        </div>),

        col3: (<img
          style={{ cursor: 'pointer' }}
          onClick={() => handleDocumentModal(item.ContractAddress, item.Documents)}
          src='/images/document-icon.svg'
          alt=''
        />),

        col4: (<div
          style={{ position: "unset" }}
          className={classes.tableIcon}
          onClick={() => {
            if (item.Status != 6) {
              createPdf([item.Id])
            }
            else {
              Swal.fire('Hata', "Cari açıldığı için tekrar bir PDF oluşturamazsınız.", 'error')
            }
            // if (item.ContractAddress !== null)
            // 	return Swal.fire("PDF", "PDF dosyası daha önce oluşturulmuştur.", "error");

          }}
        >
          <i
            className={`fa fa-file-pdf ${item.ContractAddress !== null ? 'text-success' : 'text-danger'
              }`}
          ></i>
        </div>),

        col5: (<div
          className={classes.tableIcon}
          style={{ position: "unset" }}
          onClick={() => {
            if (item.Status != 6) {
              sendContract([item.Id])
            }
            else {
              Swal.fire('Hata', "Cari açıldığı için tekrar sözleşme iletemezsiniz.", 'error')
            }
          }}
        >
          <i className={`fa fa-envelope ${item.IsMailSent ? 'text-success' : 'text-danger'}`}></i>
        </div>),
        col6: (<div
          style={{ position: "unset" }}
          className={classes.tableIcon}
          onClick={() => {
            if (item.Status != 6) {
              createSapRecord([item.Id])
            }
            else {
              Swal.fire('Hata', "Cari zaten açılmıştır.", 'error')
            }

          }}
        >
          <i
            className={`icon-database ${item.IsSapRecordCreated ? 'text-success' : 'text-danger'
              }`}
          ></i>
        </div>),
        col7: item.TrackingNumber,
        col8: item.CompanyName,
        col9: item.AuthorizedNameSurname,
        col10: item.Phone,
        col11: item.Fax,
        col12: item.MobilePhone,
        col13: item.Email,
        col14: item.MersisNo,
        col15: item.TaxAdministration,
        col16: item.TaxOrTcNumber,
        col17: item.Status && statusOptionText(item.Status),
        col18: new Date(item.CreatedDate).toLocaleDateString('tr-TR'),
        col19: item.IbanNumber1,
        col20: item.SapData,
      }
      return data
    }), [state.applicationRequestList, state.selectedRecordIDs])

  return (
    <>
      <Spinner loading={state.isLoading} />

      <div className='card'>
        <div className='card-header px-4'>
          <span className='h4'>
            {t('pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.header')}
          </span>
        </div>
        <div className='card-body'>
          <form className='mb-4'>
            <div className='d-flex flex-wrap gap-4 align-items-end'>
              <div className='col-lg-1' style={{ minWidth: '220px' }}>
                {/* <label className='form-label'>Başlangıç Tarihi - Bitiş Tarihi</label>
                 <IPDatePicker
                  selectsRange={true as any}
                  startDate={dateRange[0]}
                  endDate={dateRange[1]}
                  onChange={setDateRange}
                  isClearable={true}
                  disabledKeyboardNavigation
                /> */}
                <InputCampaign
                  type='date'
                  id='StartDate'
                  name='StartDate'
                  label={'Başlangıç Tarihi'}
                  value={toDateTimeLocal(startDate)}
                  onChange={e => getDate(e.target.value, "Start")}
                  isActive
                />
              </div>
              <div className='col-lg-1' style={{ minWidth: '220px' }}>
                <InputCampaign
                  type='date'
                  id='EndDate'
                  name='EndDate'
                  label={'Bitiş Tarihi'}
                  value={toDateTimeLocal(endDate)}
                  onChange={e => getDate(e.target.value, "End")}
                  isActive
                />
              </div>
              <div className='col-lg-2'>
                <label htmlFor='bayikodu' className='form-label'>
                  {t('pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.status')}
                </label>
                <IPSelectBox
                  id='DealerContents'
                  name='DealerContents'
                  options={StatusOptions}
                  onChangeSingle={onDurumChange}
                  value={state.selectedStatus}
                />
              </div>
              <div className='col-lg-1'>
                <label htmlFor='bayikodu' className='form-label'>
                  {t('pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.activePassive')}
                </label>
                <IPSelectBox
                  id='DealerContents'
                  name='DealerContents'
                  options={IsEnabledOptions}
                  onChangeSingle={onIsEnabledChange}
                  value={state.selectedIsEnabled}
                />
              </div>
              <div className='col-lg-2'>
                <label htmlFor='bayikodu' className='form-label'>
                  {t('pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.trackingNumberTC')}
                </label>
                <IPInput
                  type='text'
                  className='form-control'
                  id='trackingNumber'
                  value={state.trackingNumber}
                  onChange={e => setState({ trackingNumber: e.target.value })}
                />
              </div>
              <div className='col-lg-2'>
                <label htmlFor='bayikodu' className='form-label'>
                  Yetkili Adı
                </label>
                <IPInput
                  type='text'
                  className='form-control'
                  id='authorizedName'
                  value={state.authorizedName}
                  onChange={e => setState({ authorizedName: e.target.value.toUpperCase() })}
                />
              </div>
              <div className='col-lg-1'>
                <IPButton
                  type='button'
                  className='btn btn-success'
                  text={t('pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.filtre')}
                  onClick={filterApplicationRequest}
                />
              </div>
            </div>
          </form>

          <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='d-flex gap-3'>{actionButtons}</div>
                <div
                // className={`table-responsive ${rotateCSS} ${css`
                //   ::-webkit-scrollbar {
                //     height: 6px;
                //   }
                // `}`}
                >
                  {/* UseMemo'dan gelen verilerin tablosu */}
                  <Table columns={tableColumns} data={tableData} currentPage={0} backgroundColor="red" />

                  {/* <table
                    id='datatable'
                    className={`${classes.dealerTable} ${rotateCSS} table table-bordered table-hover dataTable`}
                    role='grid'
                    aria-describedby='datatable_info'
                  >
                    <thead>
                      <tr role='row' className='odd'>
                        <th></th>
                        <th></th>
                        <th>
                          {t(
                            'pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.documents'
                          )}
                        </th>
                        <th>PDF</th>
                        <th>MAİL</th>
                        <th>SAP</th>
                        <th>
                          {t(
                            'pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.trackingNumber'
                          )}
                        </th>
                        <th>
                          {t(
                            'pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.appellation'
                          )}
                        </th>
                        <th>
                          {t('pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.official')}
                        </th>
                        <th>
                          {t('pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.phone')}
                        </th>
                        <th>Fax</th>
                        <th>
                          {t('pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.mobile')}
                        </th>
                        <th>Mail</th>
                        <th>MERSİS No</th>
                        <th>
                          {t(
                            'pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.taxAdministration'
                          )}
                        </th>
                        <th>
                          {t(
                            'pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.taxNumber'
                          )}
                        </th>
                        <th>
                          {t(
                            'pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.condition'
                          )}
                        </th>
                        <th>
                          {t('pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.date')}
                        </th>
                        <th>
                          {t('pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.notes')}
                        </th>
                        <th>IBAN</th>
                        <th>
                          {t(
                            'pages.admin.dealerInformationFormOperation.dealerInformation.dealerInformation.aperturaSap'
                          )}
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      {state.applicationRequestList && state.applicationRequestList.length === 0 && (
                        <tr role='row' className='odd'>
                          <td colSpan={20}>Kayıt Bulunmamaktadır.</td>
                        </tr>
                      )}
                      {state.applicationRequestList &&
                        state.applicationRequestList.map(item => (
                          <tr role='row' className='odd' key={uuidv4()}>
                            <td>
                              <button
                                className='btn btn-transparent'
                                type='button'
                                data-action-type='order'
                                onClick={() =>
                                  setState({
                                    showModal: true,
                                    selectedApplicationId: item.Id,
                                  })
                                }
                              >
                                <i className='icon-refresh-ccw' style={{ color: '#333' }}></i>
                              </button>
                              { <IPButton
                                onClick={() =>
                                  setState({
                                    showModal: true,
                                    selectedApplicationId: item.Id,
                                  })
                                }
                                text='Güncelle'
                                className={classes.updateButton}
                              /> }
                            </td>
                            <td style={{ position: 'relative' }}>
                              <div
                                className={css`
                                  display: block;
                                  width: 30px;

                                  .form-check {
                                    display: block;
                                    inset: 0;
                                    margin: auto;
                                    height: max-content;
                                  }
                                `}
                              >
                                <IPCheckbox
                                  name={`cb${item.Id}`}
                                  className={css`
                                    width: 20px !important;
                                  `}
                                  onCheckedChange={(checked: boolean) => {
                                    handleSelectedRecord(checked, item.Id)
                                  }}
                                  checked={state.selectedRecordIDs && state.selectedRecordIDs.indexOf(item.Id) > -1}
                                />
                              </div>
                            </td>
                            <td>
                              <img
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleDocumentModal(item.ContractAddress, item.Documents)}
                                src='/images/document-icon.svg'
                                alt=''
                              />
                            </td>
                            <td className='position-relative'>
                              <div
                                className={classes.tableIcon}
                                onClick={() => {
                                  createPdf([item.Id])
                                }}
                              >
                                <i
                                  className={`fa fa-file-pdf ${item.ContractAddress !== null ? 'text-success' : 'text-danger'
                                    }`}
                                ></i>
                              </div>
                            </td>
                            <td className='position-relative'>
                              <div
                                className={classes.tableIcon}
                                onClick={() => {
                                  // if (item.IsMailSent) return Swal.fire("Hata", "Mail daha önce gönderilmiştir.", "error");
                                  sendContract([item.Id])
                                }}
                              >
                                <i className={`fa fa-envelope ${item.IsMailSent ? 'text-success' : 'text-danger'}`}></i>
                              </div>
                            </td>

                            <td className='position-relative'>
                              <div
                                className={classes.tableIcon}
                                onClick={() => {
                                  createSapRecord([item.Id])
                                }}
                              >
                                <i
                                  className={`icon-database ${item.IsSapRecordCreated ? 'text-success' : 'text-danger'
                                    }`}
                                ></i>
                              </div>
                            </td>
                            <td>{item.TrackingNumber}</td>
                            <td>{item.CompanyName}</td>
                            <td style={{ minWidth: "200px" }}>{item.AuthorizedNameSurname}</td>
                            <td>{item.Phone}</td>
                            <td>{item.Fax}</td>
                            <td>{item.MobilePhone}</td>
                            <td>{item.Email}</td>
                            <td>{item.MersisNo}</td>
                            <td>{item.TaxAdministration}</td>
                            <td>{item.TaxOrTcNumber}</td>
                            <td>{item.Status && statusOptionText(item.Status)}</td>
                            <td>{new Date(item.CreatedDate).toLocaleDateString('tr-TR')}</td>
                            <td>Notlar</td>
                            <td>
                              {item.IbanNumber1}
                              {item.IbanNumberType1}
                            </td>
                            <td>{item.SapData}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                   */}
                </div>
                <div className='d-flex gap-3 mb-4'>{actionButtons}</div>
              </div>
            </div>
          </div>

          <DealerInformationUpdateModal
            putApplicationRequest={putApplicationRequest}
            showModal={state.showModal}
            closeModal={closeModal}
            selectedApplication={selectedApplication}
            statusOptionText={statusOptionText}
          />
          <IPModal
            width={1200}
            show={state.showDocumentModal}
            hideConfirm={true}
            onClose={() => {
              setState({ pdfFrame: '' })
              closeDocumentModal()
            }}
            closeOnEscapeKey={true}
          >
            <div className='container'>
              <div className='row'>
                <div className='col'>
                  <Fancybox
                    options={{
                      Carousel: {
                        infinite: false,
                      },
                      Thumbs: false,
                    }}
                  >
                    <h3>Resimler</h3>
                    <div className='row'>
                      {imageCol.length > 0 ? imageCol : <div className='col'>Resim bulunamadı.</div>}
                    </div>
                    <h3>Dokümanlar</h3>
                    <div className='row'>
                      {pdfCol.length > 0 ? pdfCol : <div className='col'>Doküman bulunamadı.</div>}
                    </div>
                  </Fancybox>
                </div>
              </div>
            </div>
          </IPModal>
          <IPModal
            show={state.showHistory}
            onClose={() => setState({ showHistory: false, historyData: [] })}
            hideConfirm={true}
            width={1200}
            title='Tarihçe'
          >
            <table className='table'>
              <thead>
                <tr role='row' className='odd'>
                  <th>#</th>
                  <th>Yetkili Adı</th>
                  <th>Yetkili TC</th>
                  <th>Şirket Adı</th>
                  <th>E-Posta</th>
                  <th>Cep No</th>
                  <th>Vergi / TC</th>
                  <th>Durum</th>
                  <th>Tarih</th>
                  <th>Bayi Kodu</th>
                  <th>Kullanıcı Adı</th>
                </tr>
              </thead>

              <tbody>
                {
                  state.historyData && state.historyData.length > 0 && state.historyData.map((his) => (
                    <tr role='row' className='odd' key={his.Id}>
                      <td>{<a
                        className='btn btn-transparent'
                        style={{ color: 'blue' }}
                        type='button'
                        data-action-type='order'
                        onClick={() => {
                          navigate(`../admin/DealerInformationFormOperation/SellerApplication`, { state: { userProp: his, history: true } })
                        }}
                      >
                        {his.Id}
                      </a>}</td>
                      <td>{his.AuthorizedNameSurname}</td>
                      <td>{his.AuthorizedTc}</td>
                      <td>{his.CompanyName}</td>
                      <td>{his.Email}</td>
                      <td>{his.MobilePhone}</td>
                      <td>{his.TaxOrTcNumber}</td>
                      <td>{his.Status && statusOptionText(his.Status)}</td>
                      <td>{his.UpdateTime && moment(his.UpdateTime).format('DD.MM.YYYY HH:mm:ss')}</td>
                      <td>{his.UserDealer && his.UserDealer.replace('0000', '')}</td>
                      <td>{his.UserName}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </IPModal>
        </div>
      </div>
    </>
  )
}
export default componentWithContext(DealerInformation)
