import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IProductIsActive } from '../models/models'

export class UpdateRedis extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/product/updateRedis',
      requesterInfo,
    })
  }

  public async updateRedis(): Promise<boolean> {
    return await this.getAsync<boolean>()
  }
}
