import React, { useLayoutEffect } from 'react'
import {
  Routes,
  Route,
  Navigate,
  // useNavigate,
  // useParams,
  useLocation,
} from 'react-router-dom'
import Home from '../pages/Admin/Home'
import Dashboard from '../pages/Admin/Dashboard/components'
import HomePage from '../pages/Homepage'
import Campaings from '../pages/Campaings'
import Register from '../pages/Register'
import LoginPage from '../pages/Loginpage'
import MyCard from '../pages/MyCard'
// import MyOrders from "../pages/MyOrders";
import NotFound from '../pages/NotFound'
// import Payment from "../pages/Payment";
import PaymentSpecial from '../pages/MySpecial/Payment'
import ProductDetail from '../pages/ProductDetail/index'
import ProductList from '../pages/ProductList'
import WithClientLayout from './withClientLayout'
import WithAdminLayout from './withAdminLayout'
import ListBanner from '../pages/Admin/HomeAndSiteOperation/BannersCrud/components/ListBanner'
import AddBanner from '../pages/Admin/HomeAndSiteOperation/BannersCrud/components/AddBanner'
// import ChartsPage from "../pages/Admin/Dashboard/IChartsBar/ChartBar1";
import AddPoll from '../pages/Admin/SiteReports/PollList/AddPoll/AddPoll'

// import MySpecial from '../pages/MySpecial/MySpecialLayout'
import MyReturns from '../pages/MySpecial/MyReturns/components'
import MyOffers from '../pages/MySpecial/MyOffers'
import LetterGuarantee from '../pages/MySpecial/CurrentAccount/LetterGuarantee/componnets'
import WebinarCalendar from '../pages/MySpecial/WebinarCalendar'
import DealerSpecificPromotional from '../pages/MySpecial/DealerSpecificPromotional'
import HeaderMySpecial from '../pages/MySpecial/Header/Contact'

import ListConsensus from '../pages/MySpecial/CurrentAccount/Consensus/components/ListConsensus'
import AddConsensus from '../pages/MySpecial/CurrentAccount/Consensus/components/AddConsensus'
// import Exchange from "../pages/MySpecial/Exchange";
// import OpportunitMarket from '../pages/OpportunitMarket/components/OpportunitMarket'
import CreditCardRates from '../pages/MySpecial/CreditCardRates'
import MyCreditCards from '../pages/MySpecial/MyCreditCards/components'
import AccountSummary from '../pages/MySpecial/Reports/AccountSummary'
import Provizyon from '../pages/MySpecial/Reports/Provizyon'
// import Shortcuts from "../pages/MySpecial/Shortcuts";
// import Support from "../pages/MySpecial/Support";
import WarrantyTracking from '../pages/MySpecial/SSH/WarrantyTracking'
import SSHProceduresBrandList from '../pages/MySpecial/SSH/SSHProcedures/components/SSHProceduresBrandList'
import SshProcedures from '../pages/MySpecial/SSH/SSHProcedures/components/SshProcedures'
import FaultTracking from '../pages/MySpecial/SSH/FaultTracking/components'
import SerialNumberCheck from '../pages/MySpecial/SSH/SerialNumberCheck'
import SSHContactForm from '../pages/MySpecial/SSHContactForm'
import AuthorizedServicePoints from '../pages/MySpecial/SSH/AuthorizedServicePoints'
import SSS from '../pages/MySpecial/SSS'
// import CariLogo from "../pages/MySpecial/Header/CariLogo";
// import MicrosoftESDOrders from "../pages/MySpecial/MicrosoftESDOrders";
// import Authorities from "../pages/MySpecial/Authorities";
import AddNewUser from '../pages/MySpecial/AddNewUser'
import Password from '../pages/MySpecial/Password'
import InformationUpdate from '../pages/MySpecial/InformationUpdate'
import UserUpdate from '../pages/MySpecial/UserUpdate'

import AddUser from '../pages/Admin/UserOperation/UserCrud/components/AddUser'
import DealerLoginAndOrderReport from '../pages/Admin/UserOperation/DealerLoginAndOrderReport/index'
// import AddCategory from "../pages/Admin/CategoryOperation/CategoryCrud/components/AddCategory";
// import ListCategory from "../pages/Admin/CategoryOperation/CategoryCrud/components/ListCategory";
import ListMenu from '../pages/Admin/CategoryOperation/MenuCrud/components/ListMenu'
// import AddMenu from "../pages/Admin/CategoryOperation/MenuCrud/components/AddMenu";

import ListCard from '../pages/Admin/CreditCardOperation/CreaditCardDefinedCrud/components/ListCard'
import AddCard from '../pages/Admin/CreditCardOperation/CreaditCardDefinedCrud/components/AddCard'

import BIN from '../pages/Admin/CreditCardOperation/BIN'
import AddCardBlack from '../pages/Admin/CreditCardOperation/CreditCardBlacklistCrud/components/AddCardBlack'
import ListCardBlack from '../pages/Admin/CreditCardOperation/CreditCardBlacklistCrud/components/ListCardBlack'
import SAP from '../pages/Admin/CreditCardOperation/SAP/components/index'
import NumberIssues from '../pages/Admin/CreditCardOperation/NumberIssues/components/index'
import POSIssues from '../pages/Admin/CreditCardOperation/POSIssues/components/index'
import Successful from '../pages/Admin/CreditCardOperation/Successful/components/index'
import AboutUs from '../pages/About/AboutUs'
import Dpp from '../pages/Dpp/components'
import NetChannel from '../pages/NetChannel/components'
import NetChannelEdu from '../pages/NetChannel/components/education'
import NetChannelApple from '../pages/NetChannelApple/components'
import ListUser from '../pages/Admin/UserOperation/UserCrud/components/ListUser'

import SessionClosed from '../pages/Admin/UserOperation/SessionClosed'

import CurrentLogo from '../pages/Admin/UserOperation/CurrentLogo/Index'
import PrepaidXML from '../pages/Admin/UserOperation/PrepaidXML/components/prepaidXML'

// import CurrentCardInquiry from "../pages/Admin/UserOperation/CurrentCardInquiry/Index";
import DefinedCompany from '../pages/Admin/UserOperation/DefinedCompany/Index'
import OrderOperation from '../pages/Admin/DealerAndOrderReport/OrderOperation/components/ListOrder'
import OrderListRapor from '../pages/Admin/DealerAndOrderReport/OrderRapor/components/ListOrderRapor'
import OrderReturnReport from '../pages/Admin/DealerAndOrderReport/OrderReturnReport/components'
import InformationOrderMail from '../pages/Admin/DealerAndOrderReport/İnformationOrderMail/components/İnformationOrderMailList'
// import DealerContentsReport from "../pages/Admin/DealerAndOrderReport/DealerContentsReport/components/DealerContentsReport";
import SiteNavigationStatistics from '../pages/Admin/DealerAndOrderReport/SiteNavigationStatistics'
import SiteNavigationStatistics2 from '../pages/Admin/DealerAndOrderReport/SiteNavigationStatistics2'
import DealerNavigationStatistics from '../pages/Admin/DealerAndOrderReport/DealerNavigationStatistics'
import OpportunityMarketReport from '../pages/Admin/DealerAndOrderReport/OpportunityMarketReport/components/OpportunityMarketReport'
import BasketQuotaReport from '../pages/Admin/DealerAndOrderReport/BasketQuotaReport/components/BasketQuotaReport'
import UsageReport from '../pages/Admin/DealerAndOrderReport/UsageReport/components/UsageReport'
import UntouchedOrdersList from '../pages/Admin/DealerAndOrderReport/UntouchedOrdersList/components/UntouchedOrdersList'
import HandcraftedOrdersList from '../pages/Admin/DealerAndOrderReport/HandcraftedOrdersList/components/HandcraftedOrdersList'
import NoneUntouchedOrdersList from '../pages/Admin/DealerAndOrderReport/NoneUntouchedOrdersList/components/NoneUntouchedOrdersList'
import CurrentEnteredOrders from '../pages/Admin/DealerAndOrderReport/CurrentEnteredOrders/components/CurrentEnteredOrders'
import SASReport from '../pages/Admin/DealerAndOrderReport/SASReport/components/SASReport'
// import PromotionalOrders from "../pages/Admin/DealerAndOrderReport/PromotionalOrders/components/PromotionalOrders";
//import DealerAccess from '../pages/Admin/DealerAndOrderReport/DealerAccess/components/DealerAccess'
import SapList from '../pages/Admin/HomeAndSiteOperation/SAP/components/Sap'
// import BulkAdding from "../pages/Admin/HomeAndSiteOperation/BulkAdding/components/BulkAdding";
// import CurrentProductCrud from "../pages/Admin/HomeAndSiteOperation/CurrentProductCrud/components/CurrentProductCrud";
import AddProduct from '../pages/Admin/HomeAndSiteOperation/CurrentProductCrud/components/AddProduct'
// import AdsAndCampingCrud from "../pages/Admin/HomeAndSiteOperation/AdsAndCampaingCrud/components/AdsAndCampaingCrud";
import AdsAndCampingAdd from '../pages/Admin/HomeAndSiteOperation/AdsAndCampaingCrud/components/AdsAndCampingAdd'
// import AdsAndCampingReset from "../pages/Admin/HomeAndSiteOperation/AdsAndCampingReset/components/AdsAndCampaingReset";
// import SpecifyingCategory from "../pages/Admin/HomeAndSiteOperation/SpecifyingCategory/components/SpecifyingCategory";
import CampaignQuery from '../pages/Admin/HomeAndSiteOperation/CampaignQuery/components/CampaignQuery'
import AnnouncementList from '../pages/Admin/HomeAndSiteOperation/AnnouncementList/components/AnnouncementList'
import BannerClickStatistics from '../pages/Admin/SiteReports/BannerClickStatistics/components'
import IceCatReport from '../pages/Admin/SiteReports/IceCatReport/components'
import Analytics from '../pages/Admin/SiteReports/Analytics/components'
import MailSend from '../pages/Admin/SiteReports/MailSend/components'
import PageAnalysis from '../pages/Admin/SiteReports/PageAnalysis'
import PollListChart from '../pages/Admin/SiteReports/PollList/PollListChart'
import PollList from '../pages/Admin/SiteReports/PollList'
import PollProductList from '../pages/Admin/SiteReports/PollProductList'
import ProductClickStatistics from '../pages/Admin/SiteReports/ProductClickStatistics'
// import ProductClickStatisticsExcel from "../pages/Admin/SiteReports/ProductClickStatisticsExcel";
//import ProductVideosClickStatistics from '../pages/Admin/SiteReports/ProductVideosClickStatistics'
import PromotionalProductLists from '../pages/Admin/SiteReports/PromotionalProductLists/components'
// import ThisTooList from "../pages/Admin/SiteReports/ThisTooList";
// import MailOrder from "../pages/Admin/MailOrderOperation/MailOrder/components/MailOrder";
// import SapFinancial from "../pages/Admin/MailOrderOperation/Financial/components/SAPFinancial";
import ManuelCredit from '../pages/Admin/MailOrderOperation/ManuelCredit/components/ManuelCredit'
import AddAndEdit from '../pages/Admin/CreditCardPaymentCampaigns/AddAndEdit/components/AddAndEdit'
import BankInstallmentReport from '../pages/Admin/CreditCardPaymentCampaigns/Bank/components/BankInstallmentReport'
import WhiteDealerList from '../pages/Admin/CreditCardPaymentCampaigns/Dealer/components/WhiteDealerList'
import TransactionList from '../pages/Admin/CreditCardPaymentCampaigns/Transaction/components/TransactionList'
// import BrandHelp from "../pages/Admin/BrandPageOperation/BrandHelp/components/BrandHelp";
import BrandInformation from '../pages/Admin/BrandPageOperation/BrandInformation/components/BrandInformation'
import BrandCrud from '../pages/Admin/BrandPageOperation/BrandCrud/components'
import BrandUpdate from '../pages/Admin/BrandPageOperation/BrandInformation/components/BrandUpdate'
import DealerInformation from '../pages/Admin/DealerInformationFormOperation/DealerInformation/components/DealerInformation'
// import DealerOnlineApplication from "../pages/Admin/DealerInformationFormOperation/DealerInformation/components/DealerOnlineApplication";
import BankAdd from '../pages/Admin/BankAndInstallementOperation/BankCrud/components/BankAdd'
// import BulkBankAdd from "../pages/Admin/BankAndInstallementOperation/BulkBankAdd/components/BulkBankAdd";
// import FinancialBankDetail from "../pages/Admin/BankAndInstallementOperation/FinancialBankDetail/components/FinancialBankDetail";
// import FinancialBankRate from "../pages/Admin/BankAndInstallementOperation/FinancialBankRate/components/FinancialBankRate";
import SubDealerAdd from '../pages/Admin/BankAndInstallementOperation/SubDealerAdd/components/SubDealerAdd'
import SubDealerControl from '../pages/Admin/BankAndInstallementOperation/SubDealerControl/components/SubDealerControl'
// import FinancialQuota from "../pages/Admin/BankAndInstallementOperation/FinancialQuota/components/FinancialQuota";
import BankUpdateDel from '../pages/Admin/BankAndInstallementOperation/BankCrud/components/BankUpdateDel'
import SurveyMain from '../pages/Survey'
import SSSPazar from '../pages/SSS/components/index'
import AddProductFeatures from '../pages/Admin/ProductOperation/AddProductFeatures/AddProductFeatures'
import AddPropertyModification from '../pages/Admin/ProductOperation/AddingProductAttributeChangeData/AddPropertyModification'
import BestSellerList from '../pages/Admin/ProductOperation/BestSellerList/components/BestSellerList'
import IsProductActive from '../pages/Admin/ProductOperation/IsProductActive/components/index'
import CampaignBundleAdd from '../pages/Admin/ProductOperation/CampaignBundleCrud/components/CampaignBundleAdd'
// import CampaignBundleAddV2 from "../pages/Admin/ProductOperation/CampaignBundleCrud/components/CampaignBundleAddV2";
import UndefinedProducts from '../pages/Admin/ProductOperation/FilterPropertiesUndefinedProducts/components/UndefinedProducts'
// import ListOfUnsoldProducts from "../pages/Admin/ProductOperation/ListOfUnsoldProducts/components/ListOfUnsoldProducts";
import OpportunityAisleProductList from '../pages/Admin/ProductOperation/OpportunityAisleProduct/components/OpportunityAisleProductList'
import AddOpportunityAisle from '../pages/Admin/ProductOperation/OpportunityAisleProduct/components/AddOpportunityAisle'
// import OrdersPendingApproval from "../pages/Admin/ProductOperation/OrdersPendingApproval/components/OrdersPendingApproval";
import PriceChecklist from '../pages/Admin/ProductOperation/PriceChecklist/components/PriceChecklist'
import ProductLinkList from '../pages/Admin/ProductOperation/ProductAttributeChangeData/components/ProductLinkList'
import ProductLinkAdd from '../pages/Admin/ProductOperation/ProductAttributeChangeData/components/ProductLinkAdd'
import ProductQuantityLimitList from '../pages/Admin/ProductOperation/ProductQuantityLimit/components/ProductQuantityLimitList'
import ProductSuggestList from '../pages/Admin/ProductOperation/ProductSuggest/components/ProductSuggestList'
import ProductSuggestAdd from '../pages/Admin/ProductOperation/ProductSuggest/components/ProductSuggestAdd'
import AddProductLimitation from '../pages/Admin/ProductOperation/ProductQuantityLimit/components/AddProductLimitation'
// import CampaignProducts from "../pages/Admin/ProductOperation/ProductsWithCampaignRequest/components/CampaignProducts";
import FilterProducts from '../pages/Admin/ProductOperation/ProductsWithNoFilterPropertiesEntered/components/FilterProducts'
import ProductsWithNoPrice from '../pages/Admin/ProductOperation/ProductsWithNoPrice/components/ProductsWithNoPrice'
import ProductsWithNoSpecialPrice from '../pages/Admin/ProductOperation/ProductsWithNoSpecialPrice/components/ProductsWithNoSpecialPrice'
import ProductsWithoutPictures from '../pages/Admin/ProductOperation/ProductsWithoutPictures/components/ProductsWithoutPictures'
import SendMailReports from '../pages/Admin/SiteReports/SendMails/components/index'
import UnspecifiedProductsList from '../pages/Admin/ProductOperation/ProductsWithUnspecifiedProperties/components/UnspecifiedProductsList'
// import StopwatchOperations from "../pages/Admin/ProductOperation/StopwatchOperations/components/StopwatchOperations";
import StopwatchOperationsAdd from '../pages/Admin/ProductOperation/StopwatchOperations/components/StopwatchOperationsAdd'
// import SurprisePriceIdentification from "../pages/Admin/ProductOperation/SurprisePriceIdentification/components/SurprisePriceIdentification";
import SurprisePriceIdentificationAdd from '../pages/Admin/ProductOperation/SurprisePriceIdentification/components/SurprisePriceIdentificationAdd'
import VisualDocumentationList from '../pages/Admin/ProductOperation/VisualDocumentationCrud/components/VisualDocumentationList'
import VisualDocumentationAdd from '../pages/Admin/ProductOperation/VisualDocumentationCrud/components/VisualDocumentationAdd'
// import VisualTransferProcesses from "../pages/Admin/ProductOperation/VisualTransferProcesses/components/VisualTransferProcesses";
import WithMySpecialLayout from './withMySpecialLayout'
// import WithCurrentAccountLayout from './withCurrentAccountLayout'
import Extract from '../pages/MySpecial/CurrentAccount/Extract/components/index'
import ExtractList from '../pages/MySpecial/CurrentAccount/Extract/components/listExtract'
import Openbox from '../pages/Openbox'
import MyFavorites from '../pages/MySpecial/MyFavorites'
import Address from '../pages/MySpecial/Address'
import MyspecialMyOrders from '../pages/MySpecial/MyOrders'
import Invoice from '../pages/MySpecial/MyOrders/components/EInvoice'
// import RepresentativesHover from "../pages/Admin/HomeAndSiteOperation/RepresentativesHover";
import ResetPassword from '../pages/ResetPassword'
// import PaymentOptions from "../pages/Admin/Settings/PaymentOptions";
import AddFrequentlyAskedQuestions from '../pages/Admin/Settings/FrequentlyAskedQuestions/components/AddFrequentlyAskedQuestions'
import ListFrequentlyAskedQuestions from '../pages/Admin/Settings/FrequentlyAskedQuestions/components/ListFrequentlyAskedQuestions'
import BankAccountNumbers from '../pages/FinancialInformation/components/BankAccountNumbers'
import WithFinancialInformationLayout from './withFinancialInformationLayout'
import CreditCardCommissionRates from '../pages/FinancialInformation/components/CreditCardComissionRates'
import FinancialExchange from '../pages/FinancialInformation/components/Exchange'
import FinancialDocuments from '../pages/FinancialInformation/components/FinancialDocuments'
import FrequentlyAskedQuestions from '../pages/FinancialInformation/components/FrequentlyAskedQuestions'
import OpenInvoices from '../pages/MySpecial/Reports/OpenInvoices/components/OpenInvoices'
import EInvoice from '../pages/MySpecial/Reports/EInvoice'
import PeriodicReport from '../pages/MySpecial/Reports/PeriodicReport'
import ImeiSerialNumber from '../pages/MySpecial/Reports/ImeiSerialNumber'
import CreditCardTransactions from '../pages/MySpecial/Reports/CreditCardTransactions'
import CreditStatement from '../pages/MySpecial/Reports/CreditStatement'
import CreditCardOperations from '../pages/MySpecial/CreditCardOperations'
import XmlSupport from '../pages/MySpecial/XmlSupport/components/xml'
import Microsoft from '../pages/MySpecial/LicenseTracking/components/microsoft'
import SubscriptionTracking from '../pages/MySpecial/LicenseTracking/components/subscriptionTracking'
import WebinarCalanderAdmin from '../pages/Admin/HomeAndSiteOperation/WebinarCalander/components/WebinarCalanderAdmin'
import BrandDetail from '../pages/BrandDetail/components/BrandDetail'
import AllBrands from '../pages/BrandDetail/components/AllBrands'
import NewsAnnouncements from '../pages/BrandDetail/components/NewsAnnouncements'
import ListAuthorizedServices from '../pages/Admin/BrandPageOperation/AuthorizedServices/components/ListAuthorizedServices'
import AddAuthorizedServices from '../pages/Admin/BrandPageOperation/AuthorizedServices/components/AddAuthorizedServices'
import BasketManagement from '../pages/MyCard/basketManagement'
import AddAuthorizedProcedure from '../pages/Admin/BrandPageOperation/AuthorizedProcedure/components/AddAuthorizedProcedure'
import ListAuthorizedProcedure from '../pages/Admin/BrandPageOperation/AuthorizedProcedure/components/ListAuthorizedProcedure'
import ListXml from '../pages/Admin/UserOperation/XMLUpdate/components/ListXml'
import AddXmlUpdate from '../pages/Admin/UserOperation/XMLUpdate/components/AddXmlUpdate'
import XMLDealer from '../pages/Admin/UserOperation/XMLDealer/components/XmlDealer'
import HomePageProducts from '../pages/Admin/HomeAndSiteOperation/HomePageProducts/components/HomePageProducts'
import Confirmation from '../pages/Register/Confirmation'
import ExchangeRateInformation from '../pages/Admin/Settings/ExchangeRateInformation'

import DealerChannel from '../pages/Admin/HomeAndSiteOperation/DealerChannel/components/DealerChannel'
import DealerChannelPrograms from '../pages/MySpecial/DealerChannelPrograms'
import UserRoleDefinitions from '../pages/Admin/Settings/Authorization/UserRoleDefinitions'
import RoleDefinitions from '../pages/Admin/Settings/Authorization/RoleDefinitions'
import AuthorizationDefinitionsToRole from '../pages/Admin/Settings/Authorization/AuthorizationDefinitionsToRole'
import AuthorizationDefinitionsToUser from '../pages/Admin/Settings/Authorization/AuthorizationDefinitionsToUser'
import CampaignBundleList from '../pages/Admin/ProductOperation/CampaignBundleCrud/components/CampaignBundleList'
import FcvCampaignAdd from '../pages/Admin/BankAndInstallementOperation/FCV/components/FcvCampaignAdd'
import ListDealer from '../pages/Admin/HomeAndSiteOperation/DealerChannel/components/ListDealer'
import DealerLimitDefinition from '../pages/Admin/BankAndInstallementOperation/DealerLimitDefinition/components/DealerLimitDefinition'
import AddDealerLimit from '../pages/Admin/BankAndInstallementOperation/DealerLimitDefinition/components/AddDealerLimit'
import FcvCampaignList from '../pages/Admin/BankAndInstallementOperation/FCV/components/FcvCampaignList'
import Marketing from '../pages/Dpp/components/marketing'
import Training from '../pages/Dpp/components/training'
import Mac from '../pages/Dpp/components/mac'
import MicrosoftWorld from '../pages/MicrosoftWorld/components'
import PriceLists from '../pages/PriceLists/components'
// import { Menu } from "../layouts/components/admin/Menu/Menu";
import HomeDealerChannel from '../pages/Admin/HomeAndSiteOperation/DealerChannel/components/HomeDealerChannel'
import DppAndMicrosoftPages from '../pages/Admin/HomeAndSiteOperation/DppAndMicrosoftPages/components/list'
import DppAndMicrosoftPagesAdd from '../pages/Admin/HomeAndSiteOperation/DppAndMicrosoftPages/components/Add'
import HomePageProductsList from '../pages/Admin/HomeAndSiteOperation/HomePageProducts/components/HomePageProductsList'
import IyzicoCampaign from '../pages/Admin/ProductOperation/IyzicoCampaign'
import IyzicoCampaignAdd from '../pages/Admin/ProductOperation/IyzicoCampaign/IyzicoCampaignAdd'
import { BaseComponentWithContextProps, componentWithContext } from '../base/customHooks/componentHOC'
import WithSSHLayout from './withSSHLayout'
import ReturnForm from '../pages/MySpecial/MyReturns/components/ReturnForm'
import XmlTokenFree from '../pages/MySpecial/XmlSupport/components/xmlTokenFree'
import XmlStateListing from '../pages/Admin/UserOperation/XmlStateListing/components/XmlStateListing'
import XmlCompanyParametersList from '../pages/Admin/UserOperation/XmlCompanyParameters/components/XmlCompanyParametersList'
import BankAdditionalInstallmentList from '../pages/Admin/BankAndInstallementOperation/AdditionalBankInstallment/components/BankAdditionalInstallmentList'
import AddBankAdditionalInstallment from '../pages/Admin/BankAndInstallementOperation/AdditionalBankInstallment/components/AddBankAdditionalInstallment'
import SellerApplication from '../pages/Admin/DealerInformationFormOperation/DealerInformation/components/SellerApplication'
import DealerCreditCardList from '../pages/Admin/DealerInformationFormOperation/DealerCreditCardList/components'
import AddBankNote from '../pages/Admin/BankAndInstallementOperation/BankNote/components/AddBankNote'
import BankNoteList from '../pages/Admin/BankAndInstallementOperation/BankNote/components/BankNoteList'
import ProductColorAdd from '../pages/Admin/ProductOperation/ProductColor/components/ProductColorAdd'
import ProductColorList from '../pages/Admin/ProductOperation/ProductColor/components/ProductColorList'
import HomepageNotice from '../pages/Admin/HomeAndSiteOperation/HomePageNotice/components/HomepageNotice'
import DocumentUpload from '../pages/Admin/ProductOperation/VisualDocumentationCrud/components/DocumentUpload'
import AddBIN from '../pages/Admin/CreditCardOperation/BIN/AddBIN'
import XMLAllDealerUpdate from '../pages/Admin/UserOperation/XmlAllDealerUpdate/components/xmlAllDealerUpdate'
import ParamposList from '../pages/Admin/BankAndInstallementOperation/ParamposCrud/components/ParamposList'
import Parampos from '../pages/Admin/BankAndInstallementOperation/ParamposCrud/components/Parampos'
import ParamposCompany from '../pages/Admin/BankAndInstallementOperation/ParamposCompany/components/ParamposCompany'
import AddWhiteDealerList from '../pages/Admin/CreditCardPaymentCampaigns/Dealer/components/AddWhiteDealerList'
import NetChannelContentList from '../pages/Admin/HomeAndSiteOperation/NetChannelContent/components/NetChannelContentList'
import NetChannelContentAdd from '../pages/Admin/HomeAndSiteOperation/NetChannelContent/components/NetChannelContentAdd'
import OneConfig from '../pages/OneConfig/components'

function AppRoutes(props: BaseComponentWithContextProps) {
  const location = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  const getClientRoutes = () => {
    return (
      <>
        <Route path='/' element={<Navigate replace to='/homepage' />} />
        <Route
          path={'/homepage'}
          element={
            <WithClientLayout>
              <HomePage />
            </WithClientLayout>
          }
        />
        <Route
          path={'/campaings'}
          element={
            <WithClientLayout>
              <Campaings />
            </WithClientLayout>
          }
        />
        <Route
          path={'/products'}
          element={
            <WithClientLayout>
              <ProductList />
            </WithClientLayout>
          }
        />
        <Route
          path={'/survey'}
          element={
            <WithClientLayout>
              <SurveyMain />
            </WithClientLayout>
          }
        />
        <Route
          path={'/sss'}
          element={
            <WithClientLayout>
              <SSSPazar />
            </WithClientLayout>
          }
        />

        {/* <Route
          path={"/opportunitMarket"}
          element={
            <WithClientLayout>
              <OpportunitMarket />
            </WithClientLayout>
          }
        /> */}
        <Route
          path={'/:type/detail/:id'}
          element={
            <WithClientLayout>
              <ProductDetail />
            </WithClientLayout>
          }
        />
        <Route
          path={'/brands'}
          element={
            <WithClientLayout>
              <AllBrands />
            </WithClientLayout>
          }
        />
        <Route
          path={'/brands/newsAnnouncements'}
          element={
            <WithClientLayout>
              <NewsAnnouncements />
            </WithClientLayout>
          }
        />
        <Route
          path={'/brandDetail'}
          element={
            <WithClientLayout>
              <BrandDetail />
            </WithClientLayout>
          }
        />
        {/* <Route
          path={"/myspecial"}
          element={
            <WithClientLayout>
              <MySpecial />
            </WithClientLayout>
          }
        /> */}

        <Route
          path={'/mycard'}
          element={
            <WithClientLayout>
              <MyCard />
            </WithClientLayout>
          }
        />

        <Route
          path={'/mycard/management'}
          element={
            <WithClientLayout>
              <BasketManagement />
            </WithClientLayout>
          }
        />

        <Route
          path={'/openbox'}
          element={
            <WithClientLayout>
              <Openbox />
            </WithClientLayout>
          }
        />
        {/* <Route
          path={"/payment"}
          element={
            <WithClientLayout>
              <Payment />
            </WithClientLayout>
          }
        /> */}
        <Route
          path={'/about'}
          element={
            <WithClientLayout>
              <AboutUs />
            </WithClientLayout>
          }
        />
        <Route
          path={'/microsoft'}
          element={
            <WithClientLayout>
              <MicrosoftWorld />
            </WithClientLayout>
          }
        />
        <Route
          path={'/oneConfig'}
          element={
            <WithClientLayout>
              <OneConfig />
            </WithClientLayout>
          }
        />
        <Route
          path={'/priceLists'}
          element={
            <WithClientLayout>
              <PriceLists />
            </WithClientLayout>
          }
        />
        {props.user.IsDPP && (
          <>
            <Route
              path={'/dpp'}
              element={
                <WithClientLayout>
                  <Dpp />
                </WithClientLayout>
              }
            />
            <Route
              path={'/dpp/marketing'}
              element={
                <WithClientLayout>
                  <Marketing />
                </WithClientLayout>
              }
            />
            <Route
              path={'/dpp/training'}
              element={
                <WithClientLayout>
                  <Training />
                </WithClientLayout>
              }
            />
            <Route
              path={'/dpp/marketing/mac'}
              element={
                <WithClientLayout>
                  <Mac />
                </WithClientLayout>
              }
            />
          </>
        )}
      </>
    )
  }

  const getNetChannelAppleRoutes = () => {
    return (
      <>
        <Route path={'/AppleNetChannel'} element={<NetChannelApple />} />
      </>
    )
  }

  const getNetChannelDealerRoutes = () => {
    return (
      <>
        <Route
          path={'/netChannel'}
          element={
            <WithClientLayout>
              <NetChannel />
            </WithClientLayout>
          }
        />
        <Route
          path={'/netChannelEducation'}
          element={
            <WithClientLayout>
              <NetChannelEdu />
            </WithClientLayout>
          }
        />
      </>
    )
  }
  const getMySpecialRoutes = () => {
    return (
      <>
        <Route
          path='/myspecial'
          element={
            <WithMySpecialLayout>
              <HeaderMySpecial />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/xmlSupport'
          element={
            <WithMySpecialLayout>
              <XmlSupport />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/InformationUpdate'
          element={
            <WithMySpecialLayout>
              <InformationUpdate />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/myfavorites'
          element={
            <WithMySpecialLayout>
              <MyFavorites />
            </WithMySpecialLayout>
          }
        />
        {/* <Route
          path="/myspecial/Exchange"
          element={
            <WithMySpecialLayout>
              <Exchange />
            </WithMySpecialLayout>
          }
        /> */}
        <Route
          path='/myspecial/MyCreditCards'
          element={
            <WithMySpecialLayout>
              <MyCreditCards />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/CreditCardRates'
          element={
            <WithMySpecialLayout>
              <CreditCardRates />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/CreditCardOperations'
          element={
            <WithMySpecialLayout>
              <CreditCardOperations />
            </WithMySpecialLayout>
          }
        />
        {/* <Route
          path="/myspecial/Support"
          element={
            <WithMySpecialLayout>
              <Support />
            </WithMySpecialLayout>
          }
        /> */}
        {/* <Route
          path='/myspecial/reports/account-summary'
          element={
            <WithMySpecialLayout>
              <AccountSummary />
            </WithMySpecialLayout>
          }
        /> */}
        <Route
          path='/myspecial/reports/provizyon'
          element={
            <WithMySpecialLayout>
              <Provizyon />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/reports/open-invoices'
          element={
            <WithMySpecialLayout>
              <OpenInvoices />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/DealerChannelPrograms'
          element={
            <WithMySpecialLayout>
              <DealerChannelPrograms />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/reports/e-invoice'
          element={
            <WithMySpecialLayout>
              <EInvoice />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/reports/periodic-report'
          element={
            <WithMySpecialLayout>
              <PeriodicReport />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/reports/credit-card-transactions'
          element={
            <WithMySpecialLayout>
              <CreditCardTransactions />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/reports/credit-statement'
          element={
            <WithMySpecialLayout>
              <CreditStatement />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/reports/imei-serial-number'
          element={
            <WithMySpecialLayout>
              <ImeiSerialNumber />
            </WithMySpecialLayout>
          }
        />
        {/* <Route
          path="/myspecial/Shortcuts"
          element={
            <WithMySpecialLayout>
              <Shortcuts />
            </WithMySpecialLayout>
          }
        /> */}
        {/* <Route
          path="/myspecial/MicrosoftESDOrders"
          element={
            <WithMySpecialLayout>
              <MicrosoftESDOrders />
            </WithMySpecialLayout>
          }
        /> */}
        <Route
          path='/myspecial/UserUpdate'
          element={
            <WithMySpecialLayout>
              <UserUpdate />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/Password'
          element={
            <WithMySpecialLayout>
              <Password />
            </WithMySpecialLayout>
          }
        />

        {(props.user.IsPermission || props.user.IsMonitorFinancialAndCurrentMovements) && (
          <>
            <Route
              path='/myspecial/currentAccount/extract'
              element={
                <WithMySpecialLayout>
                  <Extract />
                </WithMySpecialLayout>
              }
            />
            <Route path='/myspecial/currentAccount/extract/list' element={<ExtractList />} />
            {/* <Route
              path='/myspecial/currentAccount/consensus/list'
              element={
                <WithMySpecialLayout>
                  <ListConsensus />
                </WithMySpecialLayout>
              }
            />
            <Route
              path='/myspecial/currentAccount/consensus/add'
              element={
                <WithMySpecialLayout>
                  <AddConsensus />
                </WithMySpecialLayout>
              }
            /> */}
            <Route
              path='/myspecial/currentAccount/letterGuarantee'
              element={
                <WithMySpecialLayout>
                  <LetterGuarantee />
                </WithMySpecialLayout>
              }
            />
          </>
        )}

        <Route
          path='/myspecial/AddNewUser'
          element={
            <WithMySpecialLayout>
              <AddNewUser />
            </WithMySpecialLayout>
          }
        />
        {/* <Route
          path="/myspecial/Authorities"
          element={
            <WithMySpecialLayout>
              <Authorities />
            </WithMySpecialLayout>
          }
        /> */}
        {/* <Route
          path="/myspecial/CariLogo"
          element={
            <WithMySpecialLayout>
              <CariLogo />
            </WithMySpecialLayout>
          }
        /> */}
        <Route
          path='/myspecial/Address'
          element={
            <WithMySpecialLayout>
              <Address />
            </WithMySpecialLayout>
          }
        />
        {(props.user.IsPermission || props.user.IsMakeFinancialTransactions) && (
          <Route
            path='/myspecial/mypayments'
            element={
              <WithMySpecialLayout>
                <PaymentSpecial />
              </WithMySpecialLayout>
            }
          />
        )}
        {(props.user.IsPermission || props.user.IsMonitorSalesAndOrder) && (
          <Route
            path='/myspecial/myorders'
            element={
              <WithMySpecialLayout>
                <MyspecialMyOrders />
              </WithMySpecialLayout>
            }
          />
        )}
        <Route
          path='/myspecial/returnForm'
          element={
            <WithClientLayout>
              <ReturnForm />
            </WithClientLayout>
          }
        />
        <Route path='/myspecial/EInvoice' element={<Invoice />} />
        <Route path='/xml/xml-request' element={<XmlTokenFree />} />
        <Route
          path='/myspecial/myReturns'
          element={
            <WithMySpecialLayout>
              <MyReturns />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/ssh/WarrantyTracking'
          element={
            <WithMySpecialLayout>
              <WithSSHLayout></WithSSHLayout>
              <WarrantyTracking />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/ssh/SSHProceduresBrandList'
          element={
            <WithMySpecialLayout>
              <WithSSHLayout></WithSSHLayout>
              <SSHProceduresBrandList />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/ssh/SshProcedures'
          element={
            <WithMySpecialLayout>
              <WithSSHLayout></WithSSHLayout>
              <SshProcedures />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/ssh/FaultTracking'
          element={
            <WithMySpecialLayout>
              <WithSSHLayout></WithSSHLayout>
              <FaultTracking />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/ssh/SerialNumberCheck'
          element={
            <WithMySpecialLayout>
              <WithSSHLayout></WithSSHLayout>
              <SerialNumberCheck />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/ssh/SSHContactForm'
          element={
            <WithMySpecialLayout>
              <WithSSHLayout></WithSSHLayout>
              <SSHContactForm />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/ssh/AuthorizedServicePoints'
          element={
            <WithMySpecialLayout>
              <WithSSHLayout></WithSSHLayout>
              <AuthorizedServicePoints />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/ssh/SSS'
          element={
            <WithMySpecialLayout>
              <WithSSHLayout></WithSSHLayout>
              <SSS />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/myOffers'
          element={
            <WithMySpecialLayout>
              <MyOffers />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/webinarCalendar'
          element={
            <WithMySpecialLayout>
              <WebinarCalendar />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/licenseTracking/microsoft'
          element={
            <WithMySpecialLayout>
              <Microsoft />
            </WithMySpecialLayout>
          }
        />
        <Route
          path='/myspecial/licenseTracking/subscriptionTracking'
          element={
            <WithMySpecialLayout>
              <SubscriptionTracking />
            </WithMySpecialLayout>
          }
        />

        {/* <Route
          path={"/myspecial/dealerChannelPrograms"}
          element={
            <WithMySpecialLayout>
              <DealerChannelPrograms />
            </WithMySpecialLayout>
          }
        /> */}

        <Route
          path='/myspecial/dealerSpecificPromotional'
          element={
            <WithMySpecialLayout>
              <DealerSpecificPromotional />
            </WithMySpecialLayout>
          }
        />
      </>
    )
  }
  const getFinansalInformationRoutes = () => {
    return (
      <>
        <Route
          path='/financial-information/bank-account-numbers'
          element={
            <WithFinancialInformationLayout>
              <BankAccountNumbers />
            </WithFinancialInformationLayout>
          }
        />

        <Route
          path='/financial-information/credit-card-commission-rates'
          element={
            <WithFinancialInformationLayout>
              <CreditCardCommissionRates />
            </WithFinancialInformationLayout>
          }
        />
        <Route
          path='/financial-information/exchange'
          element={
            <WithFinancialInformationLayout>
              <FinancialExchange />
            </WithFinancialInformationLayout>
          }
        />
        <Route
          path='/financial-information/financial-documents'
          element={
            <WithFinancialInformationLayout>
              <FinancialDocuments />
            </WithFinancialInformationLayout>
          }
        />
        <Route
          path='/financial-information/frequently-asked-questions'
          element={
            <WithFinancialInformationLayout>
              <FrequentlyAskedQuestions />
            </WithFinancialInformationLayout>
          }
        />
      </>
    )
  }
  const getAdminRoutes = () => {
    return (
      <>
        <Route
          path='/admin/home'
          element={
            <WithAdminLayout>
              <Home />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/dashboard'
          element={
            <WithAdminLayout>
              <Dashboard />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/homeAndSiteOperation/banner/list'
          element={
            <WithAdminLayout>
              <ListBanner />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/UserOperation/ListUser'
          element={
            <WithAdminLayout>
              <ListUser />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/homeandsiteoperation/banner/add'
          element={
            <WithAdminLayout>
              <AddBanner />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/UserOperation/AddUser'
          element={
            <WithAdminLayout>
              <AddUser />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/UserOperation/DealerLoginAndOrderReport'
          element={
            <WithAdminLayout>
              <DealerLoginAndOrderReport />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path='/admin/CreditCardOperation/AddCard'
          element={
            <WithAdminLayout>
              <AddCard />
            </WithAdminLayout>
          }
        /> */}
        {/* <Route
          path='/admin/CreditCardOperation/ListCard'
          element={
            <WithAdminLayout>
              <ListCard />
            </WithAdminLayout>
          }
        /> */}
        {/* <Route
          path="/admin/CategoryOperation/AddCategory"
          element={
            <WithAdminLayout>
              <AddCategory />
            </WithAdminLayout>
          }
        /> */}
        {/* <Route
          path="/admin/CategoryOperation/ListCategory"
          element={
            <WithAdminLayout>
              <ListCategory />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/CategoryOperation/ListMenu'
          element={
            <WithAdminLayout>
              <ListMenu />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/CategoryOperation/AddMenu"
          element={
            <WithAdminLayout>
              <AddMenu />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/UserOperation/SessionClosed'
          element={
            <WithAdminLayout>
              <SessionClosed />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/UserOperation/CurrentCardInquiry"
          element={
            <WithAdminLayout>
              <CurrentCardInquiry />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/CreditCardOperation/BIN'
          element={
            <WithAdminLayout>
              <BIN />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/CreditCardOperation/AddBIN'
          element={
            <WithAdminLayout>
              <AddBIN />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/CreditCardOperation/CreaditCardDefinedCrud/ListCard"
          element={
            <WithAdminLayout>
              <ListCard />
            </WithAdminLayout>
          }
        /> */}
        {/* <Route
          path="/admin/CreditCardOperation/CreaditCardDefinedCrud/AddCard"
          element={
            <WithAdminLayout>
              <AddCard />
            </WithAdminLayout>
          }
        /> */}
        {/* <Route
          path='/admin/CreditCardOperation/CreditCardBlacklistCrud/ListCardBlack'
          element={
            <WithAdminLayout>
              <ListCardBlack />
            </WithAdminLayout>
          }
        /> */}
        {/* <Route
          path='/admin/CreditCardOperation/CreditCardBlacklistCrud/AddCardBlack'
          element={
            <WithAdminLayout>
              <AddCardBlack />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/CreditCardOperation/SAP'
          element={
            <WithAdminLayout>
              <SAP />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path='/admin/CreditCardOperation/NumberIssues'
          element={
            <WithAdminLayout>
              <NumberIssues />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/CreditCardOperation/POSIssues'
          element={
            <WithAdminLayout>
              <POSIssues />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/CreditCardOperation/Successful'
          element={
            <WithAdminLayout>
              <Successful />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/UserOperation/DefinedCompany'
          element={
            <WithAdminLayout>
              <DefinedCompany />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/UserOperation/XMLUpdate/listXML'
          element={
            <WithAdminLayout>
              <ListXml />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/UserOperation/XMLUpdate/addXMLUpdate'
          element={
            <WithAdminLayout>
              <AddXmlUpdate />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/UserOperation/XMLDealer'
          element={
            <WithAdminLayout>
              <XMLDealer />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/UserOperation/XMLAllDealerUpdate'
          element={
            <WithAdminLayout>
              <XMLAllDealerUpdate />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/UserOperation/xmlCompanyParametersList'
          element={
            <WithAdminLayout>
              <XmlCompanyParametersList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/UserOperation/xmlStateListing'
          element={
            <WithAdminLayout>
              <XmlStateListing />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/UserOperation/CurrentLogo'
          element={
            <WithAdminLayout>
              <CurrentLogo />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/UserOperation/PrepaidXML'
          element={
            <WithAdminLayout>
              <PrepaidXML />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/OrderOperation/ListOrder'
          element={
            <WithAdminLayout>
              <OrderOperation />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/OrderOperation/ListOrderRapor'
          element={
            <WithAdminLayout>
              <OrderListRapor />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/OrderOperation/orderReturnReport'
          element={
            <WithAdminLayout>
              <OrderReturnReport />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/OrderOperation/InformationOrderMail'
          element={
            <WithAdminLayout>
              <InformationOrderMail />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/OrderOperation/DealerContentsReport"
          element={
            <WithAdminLayout>
              <DealerContentsReport />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/OrderOperation/SiteNavigationStatistics'
          element={
            <WithAdminLayout>
              <SiteNavigationStatistics />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/OrderOperation/SiteNavigationStatistics2'
          element={
            <WithAdminLayout>
              <SiteNavigationStatistics2 />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/OrderOperation/DealerNavigationStatistics'
          element={
            <WithAdminLayout>
              <DealerNavigationStatistics />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/OrderOperation/DealerNavigationStatistics"
          element={
            <WithAdminLayout>
              <DealerNavigationStatistics />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/OrderOperation/OpportunityMarketReport'
          element={
            <WithAdminLayout>
              <OpportunityMarketReport />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/OrderOperation/BasketQuotaReport'
          element={
            <WithAdminLayout>
              <BasketQuotaReport />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/OrderOperation/UsageReport'
          element={
            <WithAdminLayout>
              <UsageReport />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/OrderOperation/HandcraftedOrdersList'
          element={
            <WithAdminLayout>
              <HandcraftedOrdersList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/OrderOperation/UntouchedOrdersList'
          element={
            <WithAdminLayout>
              <UntouchedOrdersList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/OrderOperation/NoneUntouchedOrdersList'
          element={
            <WithAdminLayout>
              <NoneUntouchedOrdersList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/OrderOperation/CurrentEnteredOrders'
          element={
            <WithAdminLayout>
              <CurrentEnteredOrders />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/OrderOperation/SASReport'
          element={
            <WithAdminLayout>
              <SASReport />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/OrderOperation/PromotionalOrders"
          element={
            <WithAdminLayout>
              <PromotionalOrders />
            </WithAdminLayout>
          }
        /> */}
        {/* <Route
          path='/admin/OrderOperation/DealerAccess'
          element={
            <WithAdminLayout>
              <DealerAccess />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/site-operation/MailSend'
          element={
            <WithAdminLayout>
              <MailSend />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/site-operation/add-poll'
          element={
            <WithAdminLayout>
              <AddPoll />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/site-operation/productClickStatistics'
          element={
            <WithAdminLayout>
              <ProductClickStatistics />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/site-operation/productClickStatisticsExcel"
          element={
            <WithAdminLayout>
              <ProductClickStatisticsExcel />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/site-operation/bannerClickStatistics'
          element={
            <WithAdminLayout>
              <BannerClickStatistics />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path='/admin/site-operation/productVideosClickStatistics'
          element={
            <WithAdminLayout>
              <ProductVideosClickStatistics />
            </WithAdminLayout>
          }
        /> */}
        {/* <Route
          path="/admin/site-operation/thisTooList"
          element={
            <WithAdminLayout>
              <ThisTooList />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/site-operation/pollList'
          element={
            <WithAdminLayout>
              <PollList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/site-operation/PollListChart'
          element={
            <WithAdminLayout>
              <PollListChart />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path='/admin/site-operation/infographicLists'
          element={
            <WithAdminLayout>
              <InfographicLists />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/site-operation/iceCatReport'
          element={
            <WithAdminLayout>
              <IceCatReport />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/site-operation/promotionalProductLists'
          element={
            <WithAdminLayout>
              <PromotionalProductLists />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/site-operation/pollProductList'
          element={
            <WithAdminLayout>
              <PollProductList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/site-operation/pageAnalysis'
          element={
            <WithAdminLayout>
              <PageAnalysis />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/site-operation/Analytics'
          element={
            <WithAdminLayout>
              <Analytics />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/homeAndSiteOperation/SapList'
          element={
            <WithAdminLayout>
              <SapList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/homeAndSiteOperation/DealerChannel'
          element={
            <WithAdminLayout>
              <DealerChannel />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/homeAndSiteOperation/ListDealer'
          element={
            <WithAdminLayout>
              <ListDealer />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/homeAndSiteOperation/HomeDealerChannel'
          element={
            <WithAdminLayout>
              <HomeDealerChannel />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/homeAndSiteOperation/dppAndMicrosoftPagesList'
          element={
            <WithAdminLayout>
              <DppAndMicrosoftPages />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/homeAndSiteOperation/dppAndMicrosoftPagesAdd'
          element={
            <WithAdminLayout>
              <DppAndMicrosoftPagesAdd />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/homeAndSiteOperation/NetChannelContentList'
          element={
            <WithAdminLayout>
              <NetChannelContentList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/homeAndSiteOperation/NetChannelContentAdd'
          element={
            <WithAdminLayout>
              <NetChannelContentAdd />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/homeAndSiteOperation/homepageNotice'
          element={
            <WithAdminLayout>
              <HomepageNotice />
            </WithAdminLayout>
          }
        />

        {/* <Route
          path="/admin/OrderOperation/BulkAdding"
          element={
            <WithAdminLayout>
              <BulkAdding />
            </WithAdminLayout>
          }
        /> */}
        {/* <Route
          path="/admin/homeAndSiteOperation/CurrentProductCrud"
          element={
            <WithAdminLayout>
              <CurrentProductCrud />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/homeAndSiteOperation/CurrentProductCrud/AddProduct'
          element={
            <WithAdminLayout>
              <AddProduct />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/homeAndSiteOperation/AdsAndCampingCrud"
          element={
            <WithAdminLayout>
              <AdsAndCampingCrud />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/homeAndSiteOperation/AdsAndCampingCrud/AdsAndCampingAdd'
          element={
            <WithAdminLayout>
              <AdsAndCampingAdd />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/homeAndSiteOperation/AdsAndCampingCrudReset"
          element={
            <WithAdminLayout>
              <AdsAndCampingReset />
            </WithAdminLayout>
          }
        /> */}
        {/* <Route
          path="/admin/homeAndSiteOperation/SpecifyingCategory"
          element={
            <WithAdminLayout>
              <SpecifyingCategory />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/homeAndSiteOperation/CampaignQuery'
          element={
            <WithAdminLayout>
              <CampaignQuery />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/homeAndSiteOperation/WebinarCalander'
          element={
            <WithAdminLayout>
              <WebinarCalanderAdmin />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/homeAndSiteOperation/HomePageProducts'
          element={
            <WithAdminLayout>
              <HomePageProducts />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/homeAndSiteOperation/HomePageProductsList'
          element={
            <WithAdminLayout>
              <HomePageProductsList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/homeAndSiteOperation/AnnouncementList'
          element={
            <WithAdminLayout>
              <AnnouncementList />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/homeAndSiteOperation/representativesHover"
          element={
            <WithAdminLayout>
              <RepresentativesHover />
            </WithAdminLayout>
          }
        /> */}
        {/* <Route
          path="/admin/MailOrderOperation/MailOrder"
          element={
            <WithAdminLayout>
              <MailOrder />
            </WithAdminLayout>
          }
        /> */}
        {/* <Route
          path="/admin/MailOrderOperation/SapFinancial"
          element={
            <WithAdminLayout>
              <SapFinancial />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/MailOrderOperation/ManuelCredit'
          element={
            <WithAdminLayout>
              <ManuelCredit />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path='/admin/CreditCardPaymentCampaigns/AddAndEdit'
          element={
            <WithAdminLayout>
              <AddAndEdit />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/CreditCardPaymentCampaigns/BankInstallmentReport'
          element={
            <WithAdminLayout>
              <BankInstallmentReport />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/CreditCardPaymentCampaigns/WhiteDealerList'
          element={
            <WithAdminLayout>
              <WhiteDealerList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/CreditCardPaymentCampaigns/AddWhiteDealerList'
          element={
            <WithAdminLayout>
              <AddWhiteDealerList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/CreditCardPaymentCampaigns/TransactionList'
          element={
            <WithAdminLayout>
              <TransactionList />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/BrandPageOperation/BrandHelp"
          element={
            <WithAdminLayout>
              <BrandHelp />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/BrandPageOperation/BrandInformation'
          element={
            <WithAdminLayout>
              <BrandInformation />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/BrandPageOperation/BrandCrud'
          element={
            <WithAdminLayout>
              <BrandCrud />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/BrandPageOperation/BrandUpdate'
          element={
            <WithAdminLayout>
              <BrandUpdate />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/BrandPageOperation/authorizedServices/listAuthorizedServices'
          element={
            <WithAdminLayout>
              <ListAuthorizedServices />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/BrandPageOperation/authorizedProcedure/addAuthorizedProcedure'
          element={
            <WithAdminLayout>
              <AddAuthorizedProcedure />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/BrandPageOperation/authorizedProcedure/listAuthorizedProcedure'
          element={
            <WithAdminLayout>
              <ListAuthorizedProcedure />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/BrandPageOperation/authorizedServices/addAuthorizedServices'
          element={
            <WithAdminLayout>
              <AddAuthorizedServices />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/DealerInformationFormOperation/DealerInformation'
          element={
            <WithAdminLayout>
              <DealerInformation />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/DealerInformationFormOperation/SellerApplication'
          element={
            <WithAdminLayout>
              <SellerApplication />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/DealerInformationFormOperation/DealerCreditCardList'
          element={
            <WithAdminLayout>
              <DealerCreditCardList />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/DealerInformationFormOperation/DealerOnlineApplication"
          element={
            <WithAdminLayout>
              <DealerOnlineApplication />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/BankAndInstallementOperation/BankCrud'
          element={
            <WithAdminLayout>
              <BankUpdateDel />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/BankAndInstallementOperation/BankCrud/add'
          element={
            <WithAdminLayout>
              <BankAdd />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/BankAndInstallementOperation/AdditionalBankInstallment/AdditionalBankInstallmentList'
          element={
            <WithAdminLayout>
              <BankAdditionalInstallmentList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/BankAndInstallementOperation/AdditionalBankInstallment/AddBankAdditionalInstallment'
          element={
            <WithAdminLayout>
              <AddBankAdditionalInstallment />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/BankAndInstallementOperation/BankNote/AddBankNote'
          element={
            <WithAdminLayout>
              <AddBankNote />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/BankAndInstallementOperation/BankNote/BankNoteList'
          element={
            <WithAdminLayout>
              <BankNoteList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/BankAndInstallementOperation/Parampos/ParamposList'
          element={
            <WithAdminLayout>
              <ParamposList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/BankAndInstallementOperation/Parampos/Parampos'
          element={
            <WithAdminLayout>
              <Parampos />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/BankAndInstallementOperation/ParamposCompany'
          element={
            <WithAdminLayout>
              <ParamposCompany />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/BankAndInstallementOperation/BulkBankAdd"
          element={
            <WithAdminLayout>
              <BulkBankAdd />
            </WithAdminLayout>
          }
        /> */}
        {/* <Route
          path="/admin/BankAndInstallementOperation/FinancialBankDetail"
          element={
            <WithAdminLayout>
              <FinancialBankDetail />
            </WithAdminLayout>
          }
        /> */}
        {/* <Route
          path="/admin/BankAndInstallementOperation/FinancialBankRate"
          element={
            <WithAdminLayout>
              <FinancialBankRate />
            </WithAdminLayout>
          }
        /> */}
        {/* <Route
          path="/admin/BankAndInstallementOperation/FinancialQuota"
          element={
            <WithAdminLayout>
              <FinancialQuota />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/BankAndInstallementOperation/SubDealerAdd'
          element={
            <WithAdminLayout>
              <SubDealerAdd />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path='/admin/BankAndInstallementOperation/DealerLimitDefinition'
          element={
            <WithAdminLayout>
              <DealerLimitDefinition />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/BankAndInstallementOperation/AddDealerLimit'
          element={
            <WithAdminLayout>
              <AddDealerLimit />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/BankAndInstallementOperation/SubDealerControl'
          element={
            <WithAdminLayout>
              <SubDealerControl />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/BankAndInstallementOperation/PaymentOptions"
          element={
            <WithAdminLayout>
              <PaymentOptions />
            </WithAdminLayout>
          }
        /> */}
        {/* <Route
          path="/admin/ProductOperation/AddProductFeatures"
          element={
            <WithAdminLayout>
              <AddProductFeatures />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/ProductOperation/AddPropertyModification'
          element={
            <WithAdminLayout>
              <AddPropertyModification />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/BestSellerList'
          element={
            <WithAdminLayout>
              <BestSellerList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/IsProductActive'
          element={
            <WithAdminLayout>
              <IsProductActive />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/CampaignBundleList'
          element={
            <WithAdminLayout>
              <CampaignBundleList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/CampaignBundleAdd'
          element={
            <WithAdminLayout>
              <CampaignBundleAdd />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/bankAndInstallementOperation/fcv/fcvCampaignAdd'
          element={
            <WithAdminLayout>
              <FcvCampaignAdd />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/bankAndInstallementOperation/fcv/fcvCampaignList'
          element={
            <WithAdminLayout>
              <FcvCampaignList />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/ProductOperation/CampaignBundleAddV2"
          element={
            <WithAdminLayout>
              <CampaignBundleAddV2 />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/product-operation/iyzico-campaign'
          element={
            <WithAdminLayout>
              <IyzicoCampaign />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/product-operation/iyzico-campaign/add'
          element={
            <WithAdminLayout>
              <IyzicoCampaignAdd />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/UndefinedProducts'
          element={
            <WithAdminLayout>
              <UndefinedProducts />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/AddProductFeatures'
          element={
            <WithAdminLayout>
              <AddProductFeatures />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/ProductOperation/ListOfUnsoldProducts"
          element={
            <WithAdminLayout>
              <ListOfUnsoldProducts />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/ProductOperation/OpportunityAisleProductList'
          element={
            <WithAdminLayout>
              <OpportunityAisleProductList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/AddOpportunityAisle'
          element={
            <WithAdminLayout>
              <AddOpportunityAisle />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/ProductOperation/OrdersPendingApproval"
          element={
            <WithAdminLayout>
              <OrdersPendingApproval />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/ProductOperation/PriceChecklist'
          element={
            <WithAdminLayout>
              <PriceChecklist />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/ProductLinkList'
          element={
            <WithAdminLayout>
              <ProductLinkList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/ProductLinkAdd'
          element={
            <WithAdminLayout>
              <ProductLinkAdd />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/ProductQuantityLimitList'
          element={
            <WithAdminLayout>
              <ProductQuantityLimitList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/ProductSuggestList'
          element={
            <WithAdminLayout>
              <ProductSuggestList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/ProductSuggestAdd'
          element={
            <WithAdminLayout>
              <ProductSuggestAdd />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/ProductColorAdd'
          element={
            <WithAdminLayout>
              <ProductColorAdd />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/ProductColorList'
          element={
            <WithAdminLayout>
              <ProductColorList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/AddProductLimitation'
          element={
            <WithAdminLayout>
              <AddProductLimitation />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/ProductOperation/CampaignProducts"
          element={
            <WithAdminLayout>
              <CampaignProducts />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/ProductOperation/FilterProducts'
          element={
            <WithAdminLayout>
              <FilterProducts />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/ProductsWithNoPrice'
          element={
            <WithAdminLayout>
              <ProductsWithNoPrice />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/ProductsWithNoSpecialPrice'
          element={
            <WithAdminLayout>
              <ProductsWithNoSpecialPrice />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/ProductsWithoutPictures'
          element={
            <WithAdminLayout>
              <ProductsWithoutPictures />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/UnspecifiedProductsList'
          element={
            <WithAdminLayout>
              <UnspecifiedProductsList />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/ProductOperation/StopwatchOperations"
          element={
            <WithAdminLayout>
              <StopwatchOperations />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/ProductOperation/StopwatchOperationsAdd'
          element={
            <WithAdminLayout>
              <StopwatchOperationsAdd />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/ProductOperation/SurprisePriceIdentification"
          element={
            <WithAdminLayout>
              <SurprisePriceIdentification />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/ProductOperation/SurprisePriceIdentificationAdd'
          element={
            <WithAdminLayout>
              <SurprisePriceIdentificationAdd />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/VisualDocumentationList'
          element={
            <WithAdminLayout>
              <VisualDocumentationList />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/DocumentUpload'
          element={
            <WithAdminLayout>
              <DocumentUpload />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/ProductOperation/VisualDocumentationAdd'
          element={
            <WithAdminLayout>
              <VisualDocumentationAdd />
            </WithAdminLayout>
          }
        />
        {/* <Route
          path="/admin/ProductOperation/VisualTransferProcesses"
          element={
            <WithAdminLayout>
              <VisualTransferProcesses />
            </WithAdminLayout>
          }
        /> */}
        {/* <Route
          path="/admin/chartbar"
          element={
            <WithAdminLayout>
              <ChartsPage />
            </WithAdminLayout>
          }
        /> */}
        {/* <Route
          path="/admin/settings/paymentOptions"
          element={
            <WithAdminLayout>
              <PaymentOptions />
            </WithAdminLayout>
          }
        /> */}
        <Route
          path='/admin/settings/frequentlyAskedQuestions/add'
          element={
            <WithAdminLayout>
              <AddFrequentlyAskedQuestions />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/settings/frequentlyAskedQuestions'
          element={
            <WithAdminLayout>
              <ListFrequentlyAskedQuestions />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/settings/exchangeRateInformation'
          element={
            <WithAdminLayout>
              <ExchangeRateInformation />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/authorization/addOrUpdateRole'
          element={
            <WithAdminLayout>
              <RoleDefinitions />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/authorization/addUserToRole'
          element={
            <WithAdminLayout>
              <UserRoleDefinitions />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/authorization/addAuthorizationToRole'
          element={
            <WithAdminLayout>
              <AuthorizationDefinitionsToRole />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/authorization/addAuthorizationToUser'
          element={
            <WithAdminLayout>
              <AuthorizationDefinitionsToUser />
            </WithAdminLayout>
          }
        />
        <Route
          path='/admin/reportMails/sendReportMails'
          element={
            <WithAdminLayout>
              <SendMailReports />
            </WithAdminLayout>
          }
        />
      </>
    )
  }

  return (
    <div>
      <Routes>
        <Route path={'/login'} element={<LoginPage />} />
        <Route path={'/register'} element={<Register />} />
        <Route path={'/resetPassword/:number'} element={<ResetPassword />} />
        <Route path='/confirmation' element={<Confirmation />} />
        {!props.user.IsApple && getClientRoutes()}
        {!props.user.IsApple && props.user.IsAdmin && !props.user.IsRemote && getAdminRoutes()}
        {!props.user.IsApple && getMySpecialRoutes()}
        {!props.user.IsApple && getFinansalInformationRoutes()}
        {props.user.IsApple && getNetChannelAppleRoutes()}
        {props.user.IsNetChannelDealer && getNetChannelDealerRoutes()}
        <Route path={'*'} element={<NotFound />} />
      </Routes>
    </div>
  )
}

export default componentWithContext(AppRoutes)
