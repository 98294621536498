import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { INews } from '../models/models'

export class GetByIdApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'openBox/getById',
      requesterInfo,
    })
  }

  public async getById(id: number): Promise<INews> {
    this.props.url = `${this.props.url}/${id}`
    return await this.getAsync<INews>()
  }
}
