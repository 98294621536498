import React, { useState } from 'react'
import product from '../../api/mostpopularproducts.json'
import Image from '../../components/IPImage'
import { Link, useNavigate } from 'react-router-dom'
import Translations from '../../translations'
import { translate } from '../../base/localization'
import { DATAGATE_THEME, DEFAULT_THEME, DESPEC_THEME, INDEX_THEME, NETEX_THEME } from '../../base/theme/themeConfig'
import { useAppSelector } from '../../redux/app/hooks'
import { selectedTheme } from '../../redux/slices/theme/themeSlice'
import IPButton from '../../components/IPButton'
import { ProductListSpecialFilterType } from '../ProductList/models/commonModels'
import AddToBasket from '../../components/AddToBasket'
import IPInput from '../../components/IPInput'
import { css } from '@emotion/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Navigation, Mousewheel, Keyboard, Pagination, Autoplay } from 'swiper'
import './css/styles.scss'
import IPPriceDisplay from '../../components/IPPriceDisplay'

function HomePagePromotionalProducts(props) {
  const t = translate(Translations)
  const theme = useAppSelector(selectedTheme)
  const navigate: any = useNavigate()
  const [quantity, setQuantity] = useState(1)
  const handleQuantity = (e: any) => {
    setQuantity(e.target.value)
  }

  const handleQuantityOnBlur = (e: any) => {
    if (e.target.value?.length < 1 || isNaN(Number(e.target.value))) {
      setQuantity(1)
    } else {
      setQuantity(e.target.value)
    }
  }
  return (
    <section className='home-page-slider section-back-grey mobile-resize py-5 p-l-40 p-r-40 m-t-40 text-left '>
      <div className='row'>
        <div className='col d-flex align-items-center'>
          <Image src='/images/KampanyaliUrunler.png' alt='' style={{ width: '36px', marginTop: '-5px' }} />
          <h4 className='section-back-grey-title'>{t('pages.homePage.campaignProducts')}</h4>
        </div>
        {props.count > 4 && (
          <div className='col-6'>
            <IPButton
              className={`${theme.name === NETEX_THEME ? 'netex_buttonColor' : ''}${
                theme.name === INDEX_THEME ? 'index_buttonColor' : ''
              }${theme.name === DEFAULT_THEME ? 'default_buttonColor' : ''}${
                theme.name === DESPEC_THEME ? 'despec_buttonColor' : ''
              }${theme.name === DATAGATE_THEME ? 'datagate_buttonColor' : ''}`}
              onClick={() => navigate(`/products?specialFilter=${ProductListSpecialFilterType.PromotionalProducts}`)}
              style={{
                width: 'auto',
                float: 'right',
                backgroundColor: '#fff',
              }}
              text='Tümünü Göster'
            />
          </div>
        )}
      </div>
      <div
        className={`seperator m-0 ${theme.name === NETEX_THEME ? 'netex_borderBottom' : ''}${
          theme.name === INDEX_THEME ? 'index_borderBottom' : ''
        }${theme.name === DEFAULT_THEME ? 'default_borderBottom' : ''}${
          theme.name === DESPEC_THEME ? 'despec_borderBottom' : ''
        }${theme.name === DATAGATE_THEME ? 'datagate_borderBottom' : ''}`}
      ></div>
      <div className='row m-t-10'>
        {props.data.length > 4 ? (
          <Swiper
            navigation={true}
            keyboard={true}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
            observer={true}
            observeParents={true}
            modules={[Navigation, Mousewheel, Keyboard, Pagination, Autoplay]}
            className='mySwiper'
          >
            {[...Array(Math.ceil(props.data.length / 4))].map((_, slideIndex) => (
              <SwiperSlide key={slideIndex}>
                <div className='row'>
                  {props.data.slice(slideIndex * 4, (slideIndex + 1) * 4).map((item: any) => (
                    <div
                      className={`col-12 col-sm-6 col-md-4 col-lg-3 card-mobile-resize${
                        slideIndex === Math.ceil(props.data.length / 4) - 1 ? ' last-column' : ''
                      }`}
                      key={item.Id}
                      style={{ backgroundColor: '#f5f5f5' }}
                    >
                      <div
                        className={`d-flex flex-column justify-content-start most-popular-products ${css`
                          padding: 20px;
                        `}`}
                      >
                        <div className='room'>
                          <div className='room-image text-center'>
                            <Link to={`/product/detail/${item.Id}?from=promotional`}>
                              {item.Image ? (
                                <Image
                                  src={item.Image}
                                  alt=''
                                  className='popular_product'
                                  style={{ objectFit: 'contain' }}
                                />
                              ) : (
                                <Image
                                  src='./images/products/Resim_yok-01.jpg'
                                  alt=''
                                  style={{ objectFit: 'contain' }}
                                />
                              )}
                            </Link>
                          </div>
                        </div>
                        <div className='product-description'>
                          <Link to={`/product/detail/${item.Id}?from=promotional`}>
                            <div className='product-title  text-left'>{item.Name}</div>
                          </Link>
                          <div className='product-title text-left'>Ürün Kodu: {item.ProductCode}</div>
                        </div>
                        <hr />
                        <div className='row align-items-center product-bottom-fixed justify-content-between '>
                          <div className='col-5 p-0 '>
                            {item.BrandDetail?.ImageUrl && (
                              <Link to={`/products?brandId=${item.BrandId}`}>
                                <Image
                                  src={item.BrandDetail?.ImageUrl}
                                  alt=''
                                  className='popular_product'
                                  style={{ width: '50px' }}
                                />
                              </Link>
                            )}
                          </div>
                          <div className='col-7 text-right mt-2 mb-1'>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                              <span style={{ fontSize: '16px', whiteSpace: 'nowrap' }}>Size Özel:</span>
                              <span
                                className={css`
                                  color: #b3b3b3;
                                  margin-left: 5px;
                                `}
                              >
                                <IPPriceDisplay strikeThrough={true} price={item.Prices?.Special.Price ?? ''} />
                              </span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                              <IPPriceDisplay price={item.CampaignPrice?.CampaignPriceDisplay} />
                            </div>
                          </div>
                        </div>
                        <div className='d-flex align-items-end justify-content-between'>
                          <div>
                            {/* Adet:{' '} */}
                            <IPInput
                              type='number'
                              className={`stockNumber myCardStockNumber ${css`
                                height: 44px;
                              `}`}
                              min={1}
                              controlledField
                              value={quantity}
                              onChange={e => handleQuantity(e)}
                              onBlur={e => handleQuantityOnBlur(e)}
                            />
                          </div>
                          <div className='productButton'>
                            <AddToBasket
                              product={item}
                              quantity={quantity}
                              className={css`
                                font-size: 14px;
                                height: 44px;
                              `}
                            />
                          </div>
                        </div>
                        {/* <div className='text-right'>
                          <del
                            className={css`
                              color: #b3b3b3;
                            `}
                          >
                            {item.Prices?.Special.Price ?? ''}
                          </del>
                          <div
                            className={css`
                              font-size: 19px;
                            `}
                          >
                            {item.CampaignPrice?.CampaignPriceDisplay}
                          </div>
                        </div> */}
                      </div>
                    </div>
                  ))}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          props.data.map(
            (item: any, index) =>
              index < 4 && (
                <div className={`col-12 col-sm-6 col-md-4 col-lg-3 card-mobile-resize`} key={item.Id}>
                  <div
                    className={`d-flex flex-column justify-content-start most-popular-products ${css`
                      padding: 20px;
                    `}`}
                  >
                    <div className='room'>
                      <div className='room-image text-center'>
                        <Link to={`/product/detail/${item.Id}?from=promotional`}>
                          {item.Image ? (
                            <Image
                              src={item.Image}
                              alt=''
                              className='popular_product'
                              style={{ objectFit: 'contain' }}
                            />
                          ) : (
                            <Image src='./images/products/Resim_yok-01.jpg' alt='' style={{ objectFit: 'contain' }} />
                          )}
                        </Link>
                      </div>
                    </div>
                    <div className='product-description'>
                      <Link to={`/product/detail/${item.Id}?from=promotional`}>
                        <div className='product-title  text-left'>{item.Name}</div>
                      </Link>
                      <div className='product-title text-left'>Ürün Kodu: {item.ProductCode}</div>
                    </div>
                    <hr />
                    {/* <div className='text-right'>
                      <del
                        className={css`
                          color: #b3b3b3;
                        `}
                      >
                        {item.Prices?.Special.Price ?? ''}
                      </del>
                      <div
                        className={css`
                          font-size: 19px;
                        `}
                      >
                        {item.CampaignPrice?.CampaignPriceDisplay}
                      </div>
                    </div> */}

                    <div className='row align-items-center product-bottom-fixed justify-content-between '>
                      <div className='col-5 p-0 '>
                        {item.BrandDetail?.ImageUrl && (
                          <Link to={`/products?brandId=${item.BrandId}`}>
                            <Image
                              src={item.BrandDetail?.ImageUrl}
                              alt=''
                              className='popular_product'
                              style={{ width: '50px' }}
                            />
                          </Link>
                        )}
                      </div>
                      <div className='col-7 text-right mt-2 mb-1'>
                        <div style={{ display: 'grid' }}>
                          {<span style={{ fontSize: '16px' }}>Size Özel</span>}
                          <del
                            className={css`
                              color: #b3b3b3;
                              font-size: 14px;
                            `}
                          >
                            {item.Prices?.Special.Price ?? '0,00 $'}
                          </del>
                          {/* <div
                            className={css`
                              font-size: 19px;
                            `}
                          >
                            {item.CampaignPrice?.CampaignPriceDisplay}
                          </div> */}
                          <IPPriceDisplay
                            price={item.CampaignPrice?.CampaignPriceDisplay ?? '0,00 $'}
                            integerFontSize='16px'
                          />
                        </div>
                      </div>
                    </div>

                    <div className='d-flex align-items-end justify-content-between'>
                      <div>
                        {/* Adet:{' '} */}
                        <IPInput
                          type='number'
                          className={`stockNumber myCardStockNumber ${css`
                            height: 44px;
                          `}`}
                          min={1}
                          controlledField
                          value={quantity}
                          onChange={e => handleQuantity(e)}
                          onBlur={e => handleQuantityOnBlur(e)}
                        />
                      </div>
                      <div className='productButton'>
                        <AddToBasket
                          product={item}
                          quantity={quantity}
                          className={css`
                            font-size: 14px;
                            height: 44px;
                          `}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
          )
        )}
      </div>
    </section>
  )
}

export default HomePagePromotionalProducts
