import React, { useEffect, useMemo, useState } from 'react'
import IPButton from '../../../../../components/IPButton'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import './BankInstallmentReport.scss'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import IPCheckbox from '../../../../../components/IPCheckBox'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { GetWhiteDealerListApi } from '../../Dealer/services/getWhiteDealerListApi'
import Swal from 'sweetalert2'
import { BankInstallmentReportsRequest, BankInstallmentReportsResponse } from '../../Dealer/models/models'
import { excelToJSON, exportToXLSX, getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'
import IPFile from '../../../../../components/IPFile'
import CampaignSelect from '../../../ProductOperation/IyzicoCampaign/CampaignSelect'
import { IyzicoCampaignService } from '../../../ProductOperation/IyzicoCampaign/IyzicoCampaignService'
import { DealerType } from '../../../ProductOperation/IyzicoCampaign/IyzicoCampaignModel'
import { Spinner } from '../../../../../components/Spinner'
import InputCampaign from '../../../ProductOperation/IyzicoCampaign/InputCampaign'
import Table from '../../../../../components/Table'

function Index(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  const [isLoading, setIsLoading] = useState(false)
  const [bankInstallmentReports, setBankInstallmentReports] = useState<BankInstallmentReportsResponse[]>([])

  const paymentTypeOptions: ISelectOption[] = [
    { label: 'Hepsi', value: 0 },
    { label: 'Avantaj', value: 1 },
    { label: 'Fırsat', value: 2 },
  ]
  const companyListOptions: ISelectOption[] = [
    { label: 'Index Grup', value: 0 },
    { label: 'Index', value: 1000 },
    { label: 'Datagate', value: 1100 },
    { label: 'Despec', value: 1200 },
    { label: 'Netex', value: 2300 },
  ]
  const [paymentTypeValue, setPaymentTypeValue] = useState<ISelectOption>(paymentTypeOptions[0])
  const [companyListValue, setCompanyListValue] = useState<ISelectOption>(companyListOptions[0])

  const filterState: BankInstallmentReportsRequest = {
    StartDate: inititalStartDate,
    EndDate: new Date(),
    BankName: '',
    PaymentType: 0,
    CompanyType: 0,
    DealerCodes: [],
    DealerSubTypes: [],
    IsDealerGroup: false,
    IsCompanyGroup: false,
  }
  const [filters, setFilters] = useState<BankInstallmentReportsRequest>(filterState)

  function updateFilters(prop, val) {
    setFilters(prev => ({ ...prev, [prop]: val }))
  }

  //#region DealerCodes
  const [dealerCodeValue, setDealerCodeValue] = useState<ISelectOption[] | null>(null)
  const [dealerSubTypeValue, setDealerSubTypeValue] = useState<ISelectOption[] | null>(null)
  const [IsDealerListInclude, setIsDealerListInclude] = useState<boolean>(true)
  const [IsDealerSubTypeInclude, setIsDealerSubTypeInclude] = useState<boolean>(true)

  // const getDealerCodes = async () => {
  //   const res = await new IyzicoCampaignService({}).getDealerCodes()
  //   if (res) {
  //     const dealerCodeList: any = []

  //     res.forEach((item: any) => {
  //       item.SapCode && dealerCodeList.push({ value: item.SapCode, label: item.SapCode.toString().replace('0000', '') })
  //     })

  //     return dealerCodeList
  //   }
  // }
  const getDealerTypes = async () => {
    const res = await new IyzicoCampaignService({}).getDealerTypes()
    if (res) {
      const dealerTypeList: ISelectOption[] = []

      res.forEach((item: DealerType) => {
        item && dealerTypeList.push({ value: item.MusteriGrubu, label: item.MusteriGrubuAciklama })
      })

      return dealerTypeList
    }
  }
  const getDealerSubTypes = async () => {
    const res = await new IyzicoCampaignService({}).getSubTypes()
    if (!res) return []
    return res.map(item => ({ value: item, label: item }))
  }
  const [optionList, setOptionList] = useState<any>({
    //dealerCodeOptions: [],
    dealerTypeOptions: [],
    dealerSubTypeOptions: [],
    bankOptions: [],
  })
  enum CariListType {
    CariCode,
    CariType,
    CariAltType,
  }
  const [selectedDealerList, setSelectedDealerList] = useState(CariListType.CariCode)
  const selectDealer = (takenState, takenEnum) => {
    return takenState === takenEnum ? 'active' : ''
  }

  const setDealerCodes = (dealerCodes, optionArr, updateFunc, type) => {
    if (type === 'DealerCode') {
      const codeData = dealerCodes.map(item => ({
        label: String(item.DealerCode),
        value: String(item.DealerCode),
      }))

      let list: { label: string; value: string }[] = []

      codeData.forEach(codeItem => {
        if (!dealerCodeValue?.some((item: any) => item.label === String(codeItem.label))) {
          list.push(codeItem)
        }
      })
      const newData = dealerCodeValue ? [...dealerCodeValue, ...list] : list
      const uniqueList = newData.filter((item, index, self) => {
        return index === self.findIndex(i => i.label === item.label)
      })
      setDealerCodeValue(uniqueList)
      updateFilters(
        'DealerCodes',
        uniqueList.map(item => item.value)
      )
    } else {
      const list: any = []
      const codeData: any = []

      dealerCodes.forEach(item => {
        const codes: any = optionArr.find((code: any) => {
          if (item.DealerCode.length === 6) {
            return code.label === item.DealerCode
          } else {
            return code.label === '0000' + item.DealerCode
          }
        })
        if (codes === undefined) codeData.push(item.DealerCode)
        else list.push(codes)
      })

      if (type === 'DealerSubType') {
        updateFilters(
          'DealerSubTypes',
          list.map(item => item.value)
        )
      }

      if (codeData.length === 0) {
        return updateFunc(list)
      }

      Swal.fire({
        icon: 'question',
        title: `${codeData.map(dt => dt)} bu cariler bulunamadı. Yeniden yüklemek ister misiniz?`,
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(async result => {
        if (result.isConfirmed) return
        updateFunc(list)
      })
    }
  }

  const handleFiles = async (files: File[], type: 'DealerCode' | 'DealerType' | 'DealerSubType') => {
    const dealerCodes: any = await excelToJSON(files[0])

    if (type === 'DealerCode') {
      setDealerCodes(dealerCodes[0], optionList.dealerCodeOptions, setDealerCodeValue, type)
      return
    }
    if (type === 'DealerSubType') {
      setDealerCodes(dealerCodes[0], optionList.dealerSubTypeOptions, setDealerSubTypeValue, type)
    }
  }
  //#endregion

  const [bankValue, setBankValue] = useState<ISelectOption | null>(null)
  async function GetBanks() {
    try {
      const res = await new GetWhiteDealerListApi({}).getBanks()
      if (res) {
        const banks = res.map(x => ({ label: x.BankName, value: x.BankID }))
        if (!banks) return []
        return banks
      }
    } catch (err: any) {
      console.error(err)
    }
  }

  useEffect(() => {
    props.showLoading()
    Promise.all([getDealerTypes(), getDealerSubTypes(), GetBanks()])
      .then((data: any) => {
        const [dealerTypeOptions, dealerSubTypeOptions, bankOptions] = data

        setOptionList({
          dealerTypeOptions,
          dealerSubTypeOptions,
          bankOptions,
        })
        props.hideLoading()
      })
      .catch(() => {
        props.hideLoading()
      })
  }, [])

  const dateValid = () => {
    const result = filters.EndDate.getTime() - filters.StartDate.getTime()
    if (result < 60000) {
      Swal.fire('Bitiş tarihi başlangıç tarihinden önce olamaz.')
      updateFilters('EndDate', new Date())
    }
  }

  async function getTransactionList() {
    const req = {
      ...filters,
      StartDate: getDateByTimezoneOffset(startDate),
      EndDate: getDateByTimezoneOffset(endDate),
      IsDealerListInclude: IsDealerListInclude,
      IsDealerSubTypeInclude: IsDealerSubTypeInclude,
    }
    req.DealerCodes = req.DealerCodes.map(a => '0000' + a)
    props.showLoading()
    try {
      const res = await new GetWhiteDealerListApi({}).bankInstallmentReports(req)
      if (res) {
        setBankInstallmentReports(res)
      }
      props.hideLoading()
    } catch (err: any) {
      props.hideLoading()
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
      })
      setBankInstallmentReports([])
    }
  }

  const formatCurrency = value => {
    return (
      value
        .toFixed(2)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ₺'
    )
  }

  const formatCurrencyExcel = value => {
    return value.toFixed(2).replace('.', ',')
  }

  const tableColumns = useMemo(() => {
    const withoutDealer = [
      {
        Header: 'Şirket',
        accessor: 'Company',
      },
      {
        Header: 'Banka',
        accessor: 'BankName',
      },
      {
        Header: 'Peşin',
        accessor: 'OdemePesin',
      },
      {
        Header: '2 Taksit',
        accessor: 'OdemeIkiTaksit',
      },
      {
        Header: '3 Taksit',
        accessor: 'OdemeUcTaksit',
      },
      {
        Header: '4 Taksit',
        accessor: 'OdemeDortTaksit',
      },
      {
        Header: '5 Taksit',
        accessor: 'OdemeBesTaksit',
      },
      {
        Header: '6 Taksit',
        accessor: 'OdemeAltiTaksit',
      },
      {
        Header: '9 Taksit',
        accessor: 'OdemeDokuzTaksit',
      },
      {
        Header: '12 Taksit',
        accessor: 'OdemeOnIkiTaksit',
      },
      {
        Header: '15 Taksit',
        accessor: 'OdemeOnBesTaksit',
      },
      {
        Header: '18 Taksit',
        accessor: 'OdemeOnSekizTaksit',
      },
      {
        Header: '24 Taksit',
        accessor: 'OdemeYirmiDortTaksit',
      },
    ]
    if (filters.IsDealerGroup) {
      return [
        {
          Header: 'Cari',
          accessor: 'SapCode',
        },
        {
          Header: 'Cari Ünvanı',
          accessor: 'DealerName',
        },
        ...withoutDealer,
      ]
    }
    return withoutDealer
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankInstallmentReports])

  const tableData = useMemo(
    () =>
      bankInstallmentReports.length > 0 &&
      bankInstallmentReports.map(p => ({
        Company:
          p.Company && p.Company === 0
            ? 'Index Grup'
            : p.Company === 1000
              ? 'Index'
              : p.Company === 1100
                ? 'Datagate'
                : p.Company === 1200
                  ? 'Despec'
                  : p.Company === 2300
                    ? 'Netex'
                    : 'Index Grup',
        SapCode: p.SapCode && p.SapCode.replace('0000', ''),
        DealerName: p.DealerName,
        BankName: p.BankName,
        OdemeAltiTaksit: formatCurrency(p.OdemeAltiTaksit),
        OdemeBesTaksit: formatCurrency(p.OdemeBesTaksit),
        OdemeDokuzTaksit: formatCurrency(p.OdemeDokuzTaksit),
        OdemeDortTaksit: formatCurrency(p.OdemeDortTaksit),
        OdemeIkiTaksit: formatCurrency(p.OdemeIkiTaksit),
        OdemeOnBesTaksit: formatCurrency(p.OdemeOnBesTaksit),
        OdemeOnIkiTaksit: formatCurrency(p.OdemeOnIkiTaksit),
        OdemeOnSekizTaksit: formatCurrency(p.OdemeOnSekizTaksit),
        OdemePesin: formatCurrency(p.OdemePesin),
        OdemeUcTaksit: formatCurrency(p.OdemeUcTaksit),
        OdemeYirmiDortTaksit: formatCurrency(p.OdemeYirmiDortTaksit),
      })),
    [bankInstallmentReports]
  )

  const excelButton = async () => {
    try {
      if (bankInstallmentReports) {
        const data: any[] = []
        if (bankInstallmentReports.length > 0) {
          bankInstallmentReports.map(p => {
            const rowData = {}

            if (filters.IsDealerGroup) {
              rowData['SAP Bayi Kodu'] = p.SapCode ? p.SapCode.replace('0000', '') : ''
              rowData['Cari Ünvanı'] = p.DealerName
            }

            let companyValue = 'Index Grup' // Default value
            if (p.Company === 0) {
              companyValue = 'Index Grup'
            } else if (p.Company === 1000) {
              companyValue = 'Index'
            } else if (p.Company === 1100) {
              companyValue = 'Datagate'
            } else if (p.Company === 1200) {
              companyValue = 'Despec'
            } else if (p.Company === 2300) {
              companyValue = 'Netex'
            }

            rowData['Şirket'] = companyValue
            rowData['Banka Adı'] = p.BankName
            rowData['Pesin'] = p.OdemePesin
            rowData['2 Taksit'] = p.OdemeIkiTaksit
            rowData['3 Taksit'] = p.OdemeUcTaksit
            rowData['4 Taksit'] = p.OdemeDortTaksit
            rowData['5 Taksit'] = p.OdemeBesTaksit
            rowData['6 Taksit'] = p.OdemeAltiTaksit
            rowData['9 Taksit'] = p.OdemeDokuzTaksit
            rowData['12 Taksit'] = p.OdemeOnIkiTaksit
            rowData['15 Taksit'] = p.OdemeOnBesTaksit
            rowData['18 Taksit'] = p.OdemeOnSekizTaksit
            rowData['24 Taksit'] = p.OdemeYirmiDortTaksit

            data.push(rowData)
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Banka Taksit Raporu')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const [inputValue, setInputValue] = useState('')

  const handleInputChange = event => {
    setInputValue(event.target.value)
  }

  const handleInputKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAddValue()
    }
  }

  const handleAddValue = () => {
    if (inputValue.trim() !== '') {
      const words = inputValue.split(' ')
      let dealerList: { label: string; value: string }[] = []
      words.forEach((word: any) => {
        if (!dealerCodeValue?.some((item: any) => item.label === word)) {
          const newItem = { label: word, value: word }
          dealerList.push(newItem)
        }
      })

      const newData = dealerCodeValue ? [...dealerCodeValue, ...dealerList] : dealerList
      const uniqueList = newData.filter((item, index, self) => {
        return index === self.findIndex(i => i.label === item.label)
      })
      setDealerCodeValue(uniqueList)
      updateFilters(
        'DealerCodes',
        uniqueList.map(item => item.value)
      )
      setInputValue('')
    }
  }

  return (
    <>
      <div className='container whiteDealer'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <span className='h4'>Banka Taksit Raporu</span>
            <div className='d-flex align-items-center'>
              <span>Excel: </span>
              <button
                type='button'
                style={{
                  border: '1px solid #2e7d32',
                  borderRadius: 0,
                  width: '29px',
                  height: '29px',
                  backgroundColor: 'white',
                  margin: '0 10px',
                }}
                onClick={excelButton}
                className='btn text-capitalize background-white-important d-flex align-items-center'
              >
                <i
                  className='fas fa-file-excel'
                  style={{
                    marginLeft: '-6px',
                    fontSize: '18px',
                    color: '#2e7d32',
                  }}
                ></i>
              </button>
            </div>
          </div>
          <div className='card-body'>
            <form>
              <div className='row'>
                <div className='col-12 col-lg-3 mb-4'>
                  <label htmlFor='bank' className='form-label'></label>
                  <InputCampaign
                    type='datetime-local'
                    id='StartDate'
                    name='StartDate'
                    label={'Yayın Başlangıç Tarihi'}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, 'Start')}
                    isActive
                  />
                </div>
                <div className='col-12 col-lg-3 mb-4'>
                  <label htmlFor='bank' className='form-label'></label>
                  <InputCampaign
                    type='datetime-local'
                    id='EndDate'
                    name='EndDate'
                    label={'Yayın Bitiş Tarihi'}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, 'End')}
                    onBlur={dateValid}
                    isActive
                  />
                </div>
                <div className='col-12 col-lg-3 mb-4'>
                  <label htmlFor='bank' className='form-label'>
                    Banka
                  </label>
                  <IPSelectBox
                    isClearable
                    id='Bank'
                    options={optionList.bankOptions}
                    value={bankValue}
                    onChangeSingle={option => {
                      setBankValue(option)
                      if (option) updateFilters('BankName', option.label)
                      else updateFilters('BankName', '')
                    }}
                  />
                </div>
                <div className='col-12 col-lg-3 mb-4'>
                  <label htmlFor='sirket' className='form-label'>
                    Şirket
                  </label>
                  <IPSelectBox
                    id='Companies'
                    name='Companies'
                    options={companyListOptions}
                    value={companyListValue}
                    onChangeSingle={option => {
                      setCompanyListValue(option)
                      updateFilters('CompanyType', option.value)
                    }}
                  />
                </div>
                <div className='col-12 col-lg-6'>
                  <div className='row'>
                    <label htmlFor='tip' className='form-label'>
                      Avantaj/Fırsat POS
                    </label>
                    <IPSelectBox
                      id='Types'
                      name='Types'
                      options={paymentTypeOptions}
                      value={paymentTypeValue}
                      onChangeSingle={option => {
                        setPaymentTypeValue(option)
                        updateFilters('PaymentType', option.value)
                      }}
                    />
                  </div>
                  <div className='row mt-5'>
                    <div className='col-lg-5'>
                      <IPCheckbox
                        name='Active'
                        label='Cariye Göre Grupla'
                        checked={filters.IsDealerGroup}
                        onCheckedChange={checked => updateFilters('IsDealerGroup', checked)}
                      />
                      {companyListValue.label === 'Index Grup' && (
                        <IPCheckbox
                          name='Active'
                          label='Şirkete Göre Grupla'
                          checked={filters.IsCompanyGroup}
                          onCheckedChange={checked => updateFilters('IsCompanyGroup', checked)}
                        />
                      )}
                    </div>
                    <div className='col-lg-3'>
                      <IPButton type='button' className='btn btn-success' text='Excele Aktar' onClick={excelButton} />
                    </div>
                    <div className='col-lg-3'>
                      <IPButton
                        type='button'
                        className='btn btn-danger'
                        text='Göster'
                        onClick={getTransactionList}
                        style={{ width: 80 }}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-12 col-lg-6'>
                  <div className='leftContainer mb-5'>
                    <div>
                      <a className='campaignExcelBtn' href='/assetWeb/docs/dealer-codes-example.xlsx' target='_blank'>
                        Örnek Excel
                      </a>
                      <div className='listBtnGroup justify-content-between'>
                        <IPButton
                          className={`listCariBtn ${selectDealer(selectedDealerList, CariListType.CariCode)}`}
                          text={`Cari Kodu Listesi ${dealerCodeValue && dealerCodeValue.length > 0 ? '✅' : ''}`}
                          onClick={() => setSelectedDealerList(CariListType.CariCode)}
                        />
                        {/* <IPButton
                      className={`listCariBtn ${selectDealer(selectedDealerList, CariListType.CariType)}`}
                      text={`Cari Tipi Listesi ${dealerTypeValue && dealerTypeValue.length > 0 ? '✅' : ''}`}
                      onClick={() => setSelectedDealerList(CariListType.CariType)}
                    /> */}
                        <IPButton
                          className={`listCariBtn ${selectDealer(selectedDealerList, CariListType.CariAltType)}`}
                          text={`Cari Alt Tipi Listesi ${dealerSubTypeValue && dealerSubTypeValue.length > 0 ? '✅' : ''
                            }`}
                          onClick={() => setSelectedDealerList(CariListType.CariAltType)}
                        />
                      </div>
                    </div>

                    <div className='p-0 m-0'>
                      <div
                        className={`cariFilterTextAreaWrap ${selectDealer(selectedDealerList, CariListType.CariCode)}`}
                      >
                        <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                          <IPFile
                            id='FileInputDealerCode'
                            multiple={false}
                            handleFiles={e => handleFiles(e, 'DealerCode')}
                            removeAfterSelect={true}
                            className='campaignFileInput'
                            label="Excel'den Yükle"
                          />
                          <IPButton
                            text={'Dahil'}
                            className={`btnIncluded ${IsDealerListInclude ? 'active' : ''}`}
                            onClick={() => setIsDealerListInclude(true)}
                          />
                          <IPButton
                            text={'Hariç'}
                            className={`btnExcept ${!IsDealerListInclude ? 'active' : ''}`}
                            onClick={() => setIsDealerListInclude(false)}
                          />
                        </div>
                        <CampaignSelect
                          placeholder={'Cari Kod Listesi'}
                          height={180}
                          //options={optionList.dealerCodeOptions}
                          onChangeMulti={options => {
                            setDealerCodeValue(options)
                            updateFilters(
                              'DealerCodes',
                              options.map(item => item.value)
                            )
                          }}
                          value={dealerCodeValue}
                        />
                        <input
                          type='text'
                          placeholder='Cari kodu yazın'
                          value={inputValue}
                          onChange={handleInputChange}
                          onKeyPress={handleInputKeyPress}
                        />
                      </div>

                      <div
                        className={`cariFilterTextAreaWrap ${selectDealer(
                          selectedDealerList,
                          CariListType.CariAltType
                        )}`}
                      >
                        <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                          <div className='btns d-flex'>
                            <IPFile
                              id='FileInputDealerSubType'
                              multiple={false}
                              handleFiles={e => handleFiles(e, 'DealerSubType')}
                              removeAfterSelect={true}
                              className='campaignFileInput'
                              label="Excel'den Yükle"
                            />
                            <IPButton
                              text={'Dahil'}
                              className={`btnIncluded ${IsDealerSubTypeInclude ? 'active' : ''}`}
                              onClick={() => setIsDealerSubTypeInclude(true)}
                            />
                            <IPButton
                              text={'Hariç'}
                              className={`btnExcept ${!IsDealerSubTypeInclude ? 'active' : ''}`}
                              onClick={() => setIsDealerSubTypeInclude(false)}
                            />
                          </div>
                        </div>
                        <CampaignSelect
                          placeholder={'Cari Alt Tipi Listesi'}
                          height={180}
                          options={optionList.dealerSubTypeOptions}
                          onChangeMulti={options => {
                            setDealerSubTypeValue(options)
                            updateFilters(
                              'DealerSubTypes',
                              options.map(item => item.value)
                            )
                          }}
                          value={dealerSubTypeValue}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className='row mt-4'>
              <div className='col-12 col-lg-6'>
                <div className='row'>
                  <div className='col-6'>
                    <IPButton
                      type='button'
                      className='btn btn-success'
                      text={t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.exportToExcel')}
                    />
                  </div>
                  <div className='col-6'>
                    <IPButton
                      type='button'
                      className='btn btn-success'
                      text={t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.show')}
                      onClick={getWhiteDealerList}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            </form>

            {bankInstallmentReports && bankInstallmentReports.length > 0 && (
              <div className='row'>
                <div className='col-lg-12'>
                  <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                    <div className='row'>
                      <div className='col-sm-12'>
                        <Table columns={tableColumns} data={tableData} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Spinner loading={isLoading} />
    </>
  )
}

export default componentWithContext(Index)
