import React, { useEffect, useMemo, useState } from 'react'
import { useTable, usePagination, useSortBy } from 'react-table'
import IPButton from '../../../../../components/IPButton'
import IPInput from '../../../../../components/IPInput'
import IPRadio from '../../../../../components/IPRadio'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { useCustomReducer } from '../../../../../base/customHooks'
import Swal from 'sweetalert2'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { getCompanyApi } from '../../../components/api'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { css } from '@emotion/css'
import { exportToXLSX } from '../../../../../base/utils/dataHelper'
import { PaymentTransactionList, PaymentTransactionListRequest, PaymentTransactionListResponse } from '../models/model'
import { PaymentTransactionApi } from '../services/getBankInstallmentReportListApi'
import InputCampaign from '../../../../../components/InputCampaign'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import IPCheckbox from '../../../../../components/IPCheckBox'

interface BankInstallmentReportListState {
  reportList: PaymentTransactionListResponse
  form: PaymentTransactionListRequest
  Company: ISelectOption | null
  companyListOptions: ISelectOption[]
  companyListOption: any
}

function BankInstallmentReport(props: BaseComponentWithContextProps) {
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  const getInitialState = (): BankInstallmentReportListState => {
    return {
      reportList: {
        Id: 0,
        PaymentTransactionLists: [],
        TotalKalanLimit: 0.0,
        TotalKullanilanBakiye: 0.0,
        TotalOdemeAltiTaksit: 0.0,
        TotalOdemeBesTaksit: 0.0,
        TotalOdemeDokuzTaksit: 0.0,
        TotalOdemeDortTaksit: 0.0,
        TotalOdemeIkiTaksit: 0.0,
        TotalOdemeOnBesTaksit: 0.0,
        TotalOdemeOnIkiTaksit: 0.0,
        TotalOdemeOnSekizTaksit: 0.0,
        TotalOdemePesin: 0.0,
        TotalOdemeUcTaksit: 0.0,
        TotalOdemeYirmiDortTaksit: 0.0,
        TotalPosLimit: 0.0,
      },
      form: {
        StartDate: inititalStartDate,
        EndDate: new Date(),
        Durumu: 2,
        IslemTipi: 1,
        AitOlduguDonem: '',
        DealerCode: '',
        KanalSorumlusu: '',
        FirmaTipi: '',
        Company: 0,
        IsBankGroup: false,
      },
      Company: null,
      companyListOption: null,
      companyListOptions: [
        { label: 'Index', value: 1000 },
        { label: 'Netex', value: 2300 },
        { label: 'Despec', value: 1200 },
        { label: 'Datagate', value: 1100 },
      ],
    }
  }

  const initialState = getInitialState()
  const [state, setState] = useCustomReducer<BankInstallmentReportListState>(initialState)
  const t = translate(Translations)
  const [companyOptions, setCompanyOptions] = useState<ISelectOption[]>([])
  const [filteredList, setFilteredList] = useState<PaymentTransactionList[]>([])
  const [totalCol, setTotalCol] = useState<PaymentTransactionListResponse>()
  const [showFilterRow, setShowFilterRow] = useState(false)
  const [campaignSelectOptions, setCampaignSelectOptions] = useState<ISelectOption[]>([])
  const [campaignSelectOption, setCampaignSelectOption] = useState<ISelectOption[]>([])
  const [bankSelectOptions, setBankSelectOptions] = useState<ISelectOption[]>([])
  const [bankSelectOption, setBankSelectOption] = useState<any>()
  const [dealerFilter, setDealerFilter] = useState('')
  const [isBankGroup, setIsBankGroup] = useState<boolean>(false)
  const [showBankNameColumn, setShowBankNameColumn] = useState<boolean>(isBankGroup)

  const toggleBankNameColumn = () => {
    setShowBankNameColumn(!showBankNameColumn)
  }

  const getCompanies = async () => {
    const res: any[] = await getCompanyApi()
    const companies = res.map(x => ({ label: x.Name, value: x.Code }))
    setCompanyOptions(companies)
  }

  useEffect(() => {
    getCompanies()
  }, [])

  const dateToISO = date => new Date(new Date(date).getTime() + 3 * 3600 * 1000)

  const dateToEndOfDayISO = date => {
    const endOfDay = new Date(date)
    endOfDay.setHours(26, 59, 59, 999) // Set hours, minutes, seconds, and milliseconds to the end of the day
    return endOfDay
  }

  const dateValid = () => {
    const result = state.form.EndDate.getTime() - state.form.StartDate.getTime()
    if (result < 60000) {
      Swal.fire('Bitiş tarihi başlangıç tarihinden önce olamaz.')
      setState({ form: { ...state.form, EndDate: new Date() } })
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    // Reset state values
    setCampaignSelectOption([])
    setBankSelectOption(null)
    setState({ companyListOption: null })
    setDealerFilter('')

    const req = {
      ...state.form,
      StartDate: dateToISO(startDate),
      EndDate: dateToEndOfDayISO(endDate),
      Company: state.Company === null ? 0 : (state.Company.value as number),
      IsBankGroup: isBankGroup,
    }

    try {
      props.showLoading()
      const res = await new PaymentTransactionApi({}).PaymentTransaction(req)
      props.hideLoading()

      if (res) {
        const uniqueBankLabels = {}
        const uniqueTermsLabels = {}

        // Extract options for CampaignSelect and BankSelect if isBankGroup is true
        const campaignOptions = res.PaymentTransactionLists.map(x => ({
          value: x.LimitTermsName,
          label: x.LimitTermsName.toUpperCase(),
        }))
        setCampaignSelectOptions(
          campaignOptions.filter(option => {
            if (!uniqueTermsLabels[option.label]) {
              uniqueTermsLabels[option.label] = true
              return true
            }
            return false
          })
        )

        if (isBankGroup) {
          const bankOptions = res.PaymentTransactionLists.map(x => ({
            value: x.BankName,
            label: x.BankName.toUpperCase(),
          }))
          setBankSelectOptions(
            bankOptions.filter(option => {
              if (!uniqueBankLabels[option.label]) {
                uniqueBankLabels[option.label] = true
                return true
              }
              return false
            })
          )
        }

        setState({ reportList: res })
        setTotalCol(res)
        setFilteredList(res.PaymentTransactionLists)
        setShowFilterRow(true)
        return
      }

      setState({ reportList: res })
    } catch (err: any) {
      props.hideLoading()
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const excelButton = async () => {
    try {
      if (!filteredList || filteredList.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Excele atılacak veri bulunmamaktadır.',
          allowOutsideClick: true,
          backdrop: true,
        })
        return
      }

      const data = filteredList.map(p => {
        const rowData = {
          Şirket: companyOptions.length > 0 ? companyOptions.find(x => +x.value === p.Company)?.label || '' : '',
          'Hesap (SAP) Kodu': p.SapCode.replace('0000', ''),
          'Kampanya Adı': p.LimitTermsName,
          'Bayi Adı': p.DealerName,
          Şehir: p.City,
          MT: p.MusteriTemsilcisi,
          KS: p.KanalTemsilcisi,
          'Firma Tipi': p.FirmaTipi,
          Dönemi: p.Donemi,
          Durumu: p.Durumu ? 'Aktif' : 'Pasif',
          'Pos Limit': +p.PosLimit,
          'Kullanılan Bakiye': +p.KullanilanBakiye,
          'Kalan Limit': +p.KalanLimit,
          Peşin: p.OdemePesin,
          '2 Taksit': p.OdemeIkiTaksit,
          '3 Taksit': p.OdemeUcTaksit,
          '4 Taksit': p.OdemeDortTaksit,
          '5 Taksit': p.OdemeBesTaksit,
          '6 Taksit': p.OdemeAltiTaksit,
          '9 Taksit': p.OdemeDokuzTaksit,
          '12 Taksit': p.OdemeOnIkiTaksit,
          '15 Taksit': p.OdemeOnBesTaksit,
          '18 Taksit': p.OdemeOnSekizTaksit,
          '24 Taksit': p.OdemeYirmiDortTaksit,
        }

        if (isBankGroup) {
          rowData['Banka Adı'] = p.BankName
        }

        return rowData
      })

      exportToXLSX(data, 'İşlem Listesi')
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const handleChange = (e, val: string) => {
    setState({ form: { ...state.form, [val]: e.target.value } })
  }

  const handleSelectChange = (option: ISelectOption, val: string) => {
    setState({ form: { ...state.form, [val]: option ? option.value : null }, [val]: option })
  }

  // Detaylı Süzme fonksiyonu
  const filterList = () => {
    const filterConditions: ((item: PaymentTransactionList) => boolean)[] = []

    if (campaignSelectOption.length > 0) {
      filterConditions.push(b => {
        const selectedLabels = campaignSelectOption.map(option => option.label.toUpperCase())
        return selectedLabels.includes(b.LimitTermsName.toUpperCase())
      })
    }

    if (bankSelectOption) {
      filterConditions.push(b => b.BankName.toUpperCase() === bankSelectOption.label)
    }

    if (dealerFilter) {
      filterConditions.push(b => b.SapCode.includes(dealerFilter))
    }
    if (state.companyListOption) {
      filterConditions.push(c => c.Company === state.companyListOption.value)
    }

    let filteredData: PaymentTransactionList[] = state.reportList.PaymentTransactionLists

    if (filterConditions.length > 0) {
      filteredData = filteredData.filter(item => {
        return filterConditions.every(condition => condition(item))
      })
    }
    setFilteredList(filteredData)
  }

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: 'Şirket',
        accessor: 'Company',
      },
      {
        Header: 'SAP Kodu',
        accessor: 'SapCode',
      },
      {
        Header: 'Kampanya Adı',
        accessor: 'LimitTermsName',
      },
      {
        Header: 'Hesap Adı',
        accessor: 'DealerName',
      },
      {
        Header: 'Şehir',
        accessor: 'City',
      },
      {
        Header: 'MT',
        accessor: 'MT',
      },
      {
        Header: 'KS',
        accessor: 'KS',
      },
      {
        Header: 'Firma Tipi',
        accessor: 'FirmaTipi',
      },
      {
        Header: 'Dönem',
        accessor: 'Donemi',
      },
      {
        Header: 'Durum',
        accessor: 'Durumu',
      },
      {
        Header: 'POS Limiti',
        accessor: 'PosLimit',
      },
      {
        Header: 'Kullanılan',
        accessor: 'KullanilanBakiye',
      },
      {
        Header: 'Kalan Limiti',
        accessor: 'KalanLimit',
      },
      {
        Header: 'Peşin',
        accessor: 'OdemePesin',
      },
      {
        Header: '2 Taksit',
        accessor: 'OdemeIkiTaksit',
      },
      {
        Header: '3 Taksit',
        accessor: 'OdemeUcTaksit',
      },
      {
        Header: '4 Taksit',
        accessor: 'OdemeDortTaksit',
      },
      {
        Header: '5 Taksit',
        accessor: 'OdemeBesTaksit',
      },
      {
        Header: '6 Taksit',
        accessor: 'OdemeAltiTaksit',
      },
      {
        Header: '9 Taksit',
        accessor: 'OdemeDokuzTaksit',
      },
      {
        Header: '12 Taksit',
        accessor: 'OdemeOnIkiTaksit',
      },
      {
        Header: '15 Taksit',
        accessor: 'OdemeOnBesTaksit',
      },
      {
        Header: '18 Taksit',
        accessor: 'OdemeOnSekizTaksit',
      },
      {
        Header: '24 Taksit',
        accessor: 'OdemeYirmiDortTaksit',
      },
    ]

    if (isBankGroup) {
      baseColumns.splice(2, 0, {
        Header: 'Banka Adı',
        accessor: 'BankName',
      })
    }

    return baseColumns
  }, [showBankNameColumn])

  const data = useMemo(
    () =>
      filteredList.map(p => ({
        Company: companyOptions.length > 0 ? (companyOptions.find(x => +x.value === p.Company)?.label as string) : '',
        SapCode: p.SapCode.slice(4),
        BankName: p.BankName,
        LimitTermsName: p.LimitTermsName,
        DealerName: p.DealerName,
        City: p.City,
        MT: p.MusteriTemsilcisi,
        KS: p.KanalTemsilcisi,
        FirmaTipi: p.FirmaTipi,
        Donemi: p.Donemi,
        Durumu: p.Durumu ? 'Aktif' : 'Pasif',
        PosLimit:
          (p.PosLimit
            ? (+p.PosLimit)
                .toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            : '0,00') + '₺',
        KullanilanBakiye:
          (p.KullanilanBakiye
            ? (+p.KullanilanBakiye)
                .toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            : '0,00') + '₺',
        KalanLimit:
          (p.KalanLimit
            ? (+p.KalanLimit)
                .toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            : '0,00') + '₺',
        OdemeAltiTaksit:
          (p.OdemeAltiTaksit
            ? p.OdemeAltiTaksit.toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            : '0,00') + '₺',
        OdemeBesTaksit:
          (p.OdemeBesTaksit
            ? p.OdemeBesTaksit.toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            : '0,00') + '₺',
        OdemeDokuzTaksit:
          (p.OdemeDokuzTaksit
            ? p.OdemeDokuzTaksit.toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            : '0,00') + '₺',
        OdemeDortTaksit:
          (p.OdemeDortTaksit
            ? p.OdemeDortTaksit.toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            : '0,00') + '₺',
        OdemeIkiTaksit:
          (p.OdemeIkiTaksit
            ? p.OdemeIkiTaksit.toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            : '0,00') + '₺',
        OdemeOnBesTaksit:
          (p.OdemeOnBesTaksit
            ? p.OdemeOnBesTaksit.toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            : '0,00') + '₺',
        OdemeOnIkiTaksit:
          (p.OdemeOnIkiTaksit
            ? p.OdemeOnIkiTaksit.toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            : '0,00') + '₺',
        OdemeOnSekizTaksit:
          (p.OdemeOnSekizTaksit
            ? p.OdemeOnSekizTaksit.toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            : '0,00') + '₺',
        OdemePesin:
          (p.OdemePesin
            ? p.OdemePesin.toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            : '0,00') + '₺',
        OdemeUcTaksit:
          (p.OdemeUcTaksit
            ? p.OdemeUcTaksit.toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            : '0,00') + '₺',
        OdemeYirmiDortTaksit:
          (p.OdemeYirmiDortTaksit
            ? p.OdemeYirmiDortTaksit.toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            : '0,00') + '₺',
      })),
    [filteredList]
  )

  const formattedData = filteredList.map(p => ({
    PosLimit: p.PosLimit ? +p.PosLimit : 0,
    KullanilanBakiye: p.KullanilanBakiye ? +p.KullanilanBakiye : 0,
    KalanLimit: p.KalanLimit ? +p.KalanLimit : 0,
    OdemeAltiTaksit: p.OdemeAltiTaksit ? +p.OdemeAltiTaksit : 0,
    OdemeBesTaksit: p.OdemeBesTaksit ? +p.OdemeBesTaksit : 0,
    OdemeDokuzTaksit: p.OdemeDokuzTaksit ? +p.OdemeDokuzTaksit : 0,
    OdemeDortTaksit: p.OdemeDortTaksit ? +p.OdemeDortTaksit : 0,
    OdemeIkiTaksit: p.OdemeIkiTaksit ? +p.OdemeIkiTaksit : 0,
    OdemeOnBesTaksit: p.OdemeOnBesTaksit ? +p.OdemeOnBesTaksit : 0,
    OdemeOnIkiTaksit: p.OdemeOnIkiTaksit ? +p.OdemeOnIkiTaksit : 0,
    OdemeOnSekizTaksit: p.OdemeOnSekizTaksit ? +p.OdemeOnSekizTaksit : 0,
    OdemePesin: p.OdemePesin ? +p.OdemePesin : 0,
    OdemeUcTaksit: p.OdemeUcTaksit ? +p.OdemeUcTaksit : 0,
    OdemeYirmiDortTaksit: p.OdemeYirmiDortTaksit ? +p.OdemeYirmiDortTaksit : 0,
  }))

  const totalFields = [
    'PosLimit',
    'KullanilanBakiye',
    'KalanLimit',
    'OdemePesin',
    'OdemeIkiTaksit',
    'OdemeUcTaksit',
    'OdemeDortTaksit',
    'OdemeBesTaksit',
    'OdemeAltiTaksit',
    'OdemeDokuzTaksit',
    'OdemeOnIkiTaksit',
    'OdemeOnBesTaksit',
    'OdemeOnSekizTaksit',
    'OdemeYirmiDortTaksit',
  ]

  const totalValues = totalFields.map(field => formattedData.reduce((acc, p) => acc + +p[field], 0))

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // @ts-ignore
    page,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    pageCount,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    state: { pageIndex, pageSize },
  } = useTable(
    {
      // @ts-ignore
      columns,
      data,
      // defaultColumn,
      // @ts-ignore
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination
    // useBlockLayout
  )

  useEffect(() => {
    const pageSize = sessionStorage.getItem('PageSize')
    if (!pageSize) return
    setPageSize(pageSize)
  }, [])

  const tableStyles = css`
    tbody {
      border-top: none !important;
    }
    td {
      padding: 0.3rem 9px !important;
    }
  `
  return (
    <div className='container-fluid'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <span className='h4 mb-0'>
              {t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.header')}
            </span>
            <div className='d-flex align-items-center'>
              <span>Excel: </span>
              <button
                type='button'
                style={{
                  border: '1px solid #2e7d32',
                  borderRadius: 0,
                  width: '29px',
                  height: '29px',
                  backgroundColor: 'white',
                  margin: '0 10px',
                }}
                onClick={excelButton}
                className='btn text-capitalize background-white-important d-flex align-items-center'
              >
                <i
                  className='fas fa-file-excel'
                  style={{
                    marginLeft: '-6px',
                    fontSize: '18px',
                    color: '#2e7d32',
                  }}
                ></i>
              </button>
            </div>
          </div>
          <div className='card-body'>
            <form id='id1' className='formform' onSubmit={handleSubmit}>
              <label htmlFor='location' className='form-label'>
                {t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.subHeader')}
              </label>
              <div className='row'>
                <div className='col-6'>
                  <div className='posislemleri'>
                    {/* <div className='form-group col-md-12'>
                      <label htmlFor='islemdurumu' className='form-label'>
                        {t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.transactionStatus')}
                      </label>
                      <div className='row'>
                        <div className='islemdurumu col-4'>
                          <IPRadio
                            text={t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.traders')}
                            name='islemdurumu'
                          />
                        </div>
                        <div className='islemdurumu col-4'>
                          <IPRadio
                            text={t(
                              'pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.nonTransactions'
                            )}
                            name='islemdurumu'
                          />
                        </div>
                        <div className='islemdurumu col-4'>
                          <IPRadio
                            text={t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.all')}
                            name='islemdurumu'
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className='row'>
                      <div className='form-group col-md-12'>
                        <label htmlFor='durumu' className='form-label'>
                          {t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.status')}
                        </label>
                        <div className='row'>
                          <div className='durumu col-4'>
                            <IPRadio
                              text={t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.active')}
                              name='durumu'
                              onChange={() => setState({ form: { ...state.form, Durumu: 1 } })}
                              checked={state.form.Durumu === 1}
                            />
                          </div>
                          <div className='durumu col-4'>
                            <IPRadio
                              text={t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.passive')}
                              name='durumu'
                              onChange={() => setState({ form: { ...state.form, Durumu: 0 } })}
                              checked={state.form.Durumu === 0}
                            />
                          </div>
                          <div className='durumu col-4'>
                            <IPRadio
                              text={t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.all')}
                              name='durumu'
                              onChange={() => setState({ form: { ...state.form, Durumu: 2 } })}
                              checked={state.form.Durumu === 2}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='form-group col-md-12'>
                        <label htmlFor='islemtipi' className='form-label'>
                          {t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.processType')}
                        </label>
                        <div className='row'>
                          <div className='islemtipi col-4'>
                            <IPRadio
                              text={'Avantaj POS'}
                              name='islemtipi'
                              onChange={() => setState({ form: { ...state.form, IslemTipi: 1 } })}
                              checked={state.form.IslemTipi === 1}
                            />
                          </div>
                          <div className='islemtipi col-4'>
                            <IPRadio
                              text={t(
                                'pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.opportunity'
                              )}
                              name='islemtipi'
                              onChange={() => setState({ form: { ...state.form, IslemTipi: 2 } })}
                              checked={state.form.IslemTipi === 2}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className='form-group col-md-12'>
                      <label htmlFor='listeTipi' className='form-label'>
                        {t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.listType')}
                      </label>
                      <div className='row'>
                        <div className='listeTipi col-4'>
                          <IPRadio
                            text={t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.total')}
                            name='listeTipi'
                          />
                        </div>
                        <div className='listeTipi col-4'>
                          <IPRadio
                            text={t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.detailed')}
                            name='listeTipi'
                          />
                        </div>
                      </div>
                    </div> */}
                      <div className='form-group col-md-12'>
                        <label htmlFor='location' className='form-label'>
                          {t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.period')}
                        </label>
                        <div>
                          <IPInput
                            type='text'
                            className='form-control'
                            id='AitOlduguDonem'
                            placeholder={t(
                              'pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.null'
                            )}
                            value={state.form.AitOlduguDonem}
                            onChange={e => setState({ form: { ...state.form, AitOlduguDonem: e.target.value } })}
                          />
                        </div>
                      </div>
                      <div className='form-group col-md-6'>
                        <InputCampaign
                          type='date'
                          id='StartDate'
                          name='StartDate'
                          label={'Başlangıç Tarihi'}
                          value={toDateTimeLocal(startDate)}
                          onChange={e => getDate(e.target.value, 'Start')}
                          isActive
                        />
                      </div>
                      <div className='form-group col-md-6'>
                        <InputCampaign
                          type='date'
                          id='EndDate'
                          name='EndDate'
                          label={'Bitiş Tarihi'}
                          value={toDateTimeLocal(endDate)}
                          onChange={e => getDate(e.target.value, 'End')}
                          isActive
                        />
                      </div>
                    </div>
                    {/* <div className='form-group col-md-12'>
                      <label htmlFor='location' className='form-label'>
                        {t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.date')}
                      </label>
                      <div className='row'>
                        <div className='form-group col-md-6'>
                          <IPDatePicker
                            selectsRange={true as any}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={update => {
                              setDateRange(update)
                            }}
                          />
                        </div>

                        <div className='col-6' style={{ fontSize: 18 }}>
                          {t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.wholePeriod')}
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {showFilterRow && (
                    <>
                      <div
                        className='detailFilter'
                        style={{
                          marginTop: '10px',
                          border: '2px solid #9c9cf1',
                          padding: '10px 5px 0px 5px',
                        }}
                      >
                        <div className='row justify-content-center'>
                          <div className='form-group col-md-6'>
                            <IPSelectBox
                              isClearable
                              isMulti
                              isCreatableSelect
                              isSearchable
                              placeholder='Kampanya Seçiniz...'
                              options={campaignSelectOptions}
                              value={campaignSelectOption}
                              onChangeMulti={option => {
                                setCampaignSelectOption(option)
                              }}
                            />
                          </div>
                          {isBankGroup && (
                            <div className='form-group col-md-3'>
                              <IPSelectBox
                                isClearable
                                placeholder='Banka...'
                                options={bankSelectOptions}
                                value={bankSelectOption}
                                onChangeSingle={option => {
                                  setBankSelectOption(option)
                                }}
                              />
                            </div>
                          )}
                          <div className='form-group col-md-3'>
                            <IPSelectBox
                              isClearable
                              placeholder='Şirket...'
                              options={state.companyListOptions}
                              value={state.companyListOption}
                              onChangeSingle={option => {
                                setState({ companyListOption: option })
                              }}
                            />
                          </div>
                          <div className='form-group col-md-3'>
                            <IPInput
                              type='text'
                              id='Title'
                              className='form-control'
                              name='Title'
                              placeholder='Cari Kodu...'
                              onChange={e => setDealerFilter(e.target.value)}
                              value={dealerFilter}
                              onKeyDown={e => {
                                if (e.key === 'Enter') {
                                  e.preventDefault()
                                  filterList()
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className='row justify-content-center'>
                          <div className='form-group col-md-3'>
                            <IPButton
                              type='button'
                              className='btn btn-primary'
                              text='Detaylı Süz'
                              onClick={filterList}
                              style={{ height: '40px' }}
                            />
                          </div>
                          <div className='form-group col-md-3'>
                            <IPButton
                              type='button'
                              className='btn btn-success'
                              text={t(
                                'pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.exportToExcel'
                              )}
                              onClick={excelButton}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className='col-6'>
                  <div className='form-group col-md-12'>
                    <span className='h6' style={{ color: 'red' }}>
                      *Birden çok filtre için boşluk bırakınız. Kullandığınız bütün filtreler için toplam sonucu
                      getirir.
                    </span>
                    <label htmlFor='location' className='form-label'>
                      {t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.code')}
                    </label>
                    <div>
                      <IPInput
                        type='text'
                        className='form-control'
                        id='SiparisKodu'
                        placeholder={t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.null')}
                        value={state.form.DealerCode}
                        onChange={e => handleChange(e, 'DealerCode')}
                      />
                    </div>
                  </div>
                  <div className='form-group col-md-12'>
                    <label htmlFor='location' className='form-label'>
                      {t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.channelManager')}
                    </label>
                    <div>
                      <IPInput
                        type='text'
                        className='form-control'
                        placeholder={t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.null')}
                        value={state.form.KanalSorumlusu}
                        onChange={e => handleChange(e, 'KanalSorumlusu')}
                      />
                    </div>
                  </div>
                  <div className='form-group col-md-12'>
                    <label htmlFor='location' className='form-label'>
                      {t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.type')}
                    </label>
                    <div>
                      <IPInput
                        type='text'
                        className='form-control'
                        placeholder={t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.null')}
                        value={state.form.FirmaTipi}
                        onChange={e => handleChange(e, 'FirmaTipi')}
                      />
                    </div>
                  </div>
                  {/* <div className='form-group col-md-12'>
                    <label htmlFor='location' className='form-label'>
                      {t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.subType')}
                    </label>
                    <div>
                      <IPInput
                        type='text'
                        className='form-control'
                        placeholder={t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.null')}
                      />
                    </div>
                  </div> */}
                  <div className='form-group col-md-12'>
                    <label className='form-label'>Şirket</label>
                    <IPSelectBox
                      isClearable
                      options={companyOptions}
                      value={state.Company}
                      onChangeSingle={option => handleSelectChange(option, 'Company')}
                    />
                  </div>
                  <div className='col-lg-12'>
                    <br />
                    <div className='row  justify-content-center'>
                      <div className='col-lg-5'>
                        <IPCheckbox
                          name='Active'
                          label='Bankaya Göre Grupla'
                          checked={isBankGroup}
                          onCheckedChange={checked => setIsBankGroup(checked)}
                        />
                      </div>
                      <div className='col-lg-4'>
                        <IPButton
                          type='submit'
                          className='btn btn-danger'
                          text={t('pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.show')}
                          onClick={toggleBankNameColumn}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <br />
            {state.reportList.PaymentTransactionLists &&
              state.reportList.PaymentTransactionLists.length === 0 &&
              'Veri bulunamadı.'}
            {state.reportList.PaymentTransactionLists && state.reportList.PaymentTransactionLists.length > 0 && (
              <div className='row'>
                <div className='col-12'>
                  <div
                    className={`table-responsive ${css`
                      ::-webkit-scrollbar:horizontal {
                        height: 6px;
                      }
                    `}`}
                  >
                    <table className={`table ${tableStyles}`} {...getTableProps()}>
                      <thead>
                        {headerGroups.map(headerGroup => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                              // @ts-ignore
                              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                          prepareRow(row)
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                              })}
                            </tr>
                          )
                        })}
                        {!isBankGroup && (
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <b>Toplam</b>
                            </td>
                            {totalFields.map((field, index) => (
                              <td key={index}>
                                <b>
                                  {totalValues[index]
                                    .toFixed(2)
                                    .replace('.', ',')
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '₺'}
                                </b>
                              </td>
                            ))}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className='mt-3'>
                    <button
                      className='btn btn-sm btn-secondary'
                      onClick={() => {
                        gotoPage(0)
                        sessionStorage.setItem('Page', '0')
                      }}
                      disabled={!canPreviousPage}
                    >
                      {'<<'}
                    </button>{' '}
                    <button
                      className='btn btn-sm btn-secondary'
                      onClick={() => {
                        previousPage()
                        sessionStorage.setItem('Page', String(pageIndex - 1))
                      }}
                      disabled={!canPreviousPage}
                    >
                      {'<'}
                    </button>{' '}
                    <button
                      className='btn btn-sm btn-secondary'
                      onClick={() => {
                        nextPage()
                        sessionStorage.setItem('Page', String(pageIndex + 1))
                      }}
                      disabled={!canNextPage}
                    >
                      {'>'}
                    </button>{' '}
                    <button
                      className='btn btn-sm btn-secondary'
                      onClick={() => {
                        gotoPage(pageCount - 1)
                        sessionStorage.setItem('Page', String(pageCount - 1))
                      }}
                      disabled={!canNextPage}
                    >
                      {'>>'}
                    </button>{' '}
                    <span>
                      Sayfa{' '}
                      <strong>
                        {pageIndex + 1} / {pageOptions.length}
                      </strong>{' '}
                    </span>
                    <span>
                      | Sayfaya git:{' '}
                      <input
                        type='number'
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                          if (Number(e.target.value) < 0) return
                          const page = e.target.value ? Number(e.target.value) - 1 : 0
                          gotoPage(page)
                          sessionStorage.setItem('Page', String(page))
                        }}
                        style={{ width: 80 }}
                      />
                    </span>{' '}
                    <select
                      value={pageSize}
                      onChange={e => {
                        setPageSize(Number(e.target.value))
                        sessionStorage.setItem('PageSize', String(e.target.value))
                      }}
                      style={{ width: 120 }}
                    >
                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize} Satır Göster
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(BankInstallmentReport)
