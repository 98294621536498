import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { useFormik } from 'formik'
import _ from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { excelToJSON, getUploadFileRequest, handleImageUpload } from '../../../../../base/utils/dataHelper'
import IPButton from '../../../../../components/IPButton'
import IPCheckbox from '../../../../../components/IPCheckBox'
import IPFile from '../../../../../components/IPFile'
import IPImage from '../../../../../components/IPImage'
import IPInput from '../../../../../components/IPInput'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import InputCampaign from '../../../../../components/InputCampaign'
import Table from '../../../../../components/Table'
import CampaignSelect from '../../../ProductOperation/CampaignBundleCrud/components/CampaignSelect'
import { DealerType } from '../../../ProductOperation/IyzicoCampaign/IyzicoCampaignModel'
import { IyzicoCampaignService } from '../../../ProductOperation/IyzicoCampaign/IyzicoCampaignService'
import { getBrandApi, getDealerApi } from '../../../components/api'
import { IBrand, IDealer } from '../../../components/models/models'
import '../components/TextEditor.scss'
import '../index'
import { IAnnouncementFilteredRequest, IAnnouncementList, IPostAnnouncementListRequest } from '../models/requestModels'
import { DeleteAnnouncementApi } from '../services/deleteAnnouncementApi'
import { GetAnnouncementApi } from '../services/getAnnouncementApi'
import { PostAnnouncementApi } from '../services/postAnnouncementApi'
import { UpdateAnnouncementApi } from '../services/updateAnnouncementApi'
import './AnnouncementList.scss'
import validationSchema from './Validation'
import moment from 'moment'
interface IListAnnouncementState {
  announcementLists: IAnnouncementList[]
  dealerLists?: IDealer[]
  brandList?: IBrand[]
  xIsDealerListInclude: boolean
  xIsDealerSubTypeInclude: boolean
  statusOption?: ISelectOption
  status: number
}

function AnnouncementList(props: BaseComponentWithContextProps) {
  const inputEl: any = useRef(null)
  type formType = 'create' | 'update'
  const [flag, setFlag] = useState<formType>('create')
  const [tmpImage, setTmpImage] = useState('')

  const [announcementLists, setAnnouncementLists] = useState<IAnnouncementList[]>([])

  const initialValues = Object.assign({
    Id: 0,
    DealersID: null,
    OpenBoxDealers: null,
    ProductCode: 0,
    Topic: '',
    Description: '',
    Image: '',
    ImageUrl: '',
    File: {
      Content: '',
      Extension: '',
      FileType: '',
    },
    Link: null,
    ShortDescription: null,
    BrandId: null,
    BrandName: '',
    Enable: false,
    IsMobile: false,
    DealerSubtype: '',
    IsDealerListInclude: true,
    IsDealerSubTypeInclude: true,
  })

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(' ', 'T')
  }
  //#region Duyuru Filtresi tarihleri
  const filterInititalStartDate = new Date()
  filterInititalStartDate.setDate(1)
  filterInititalStartDate.setHours(0)
  filterInititalStartDate.setMinutes(0)
  filterInititalStartDate.setSeconds(0)
  const [filterStartDate, setFilterStartDate] = useState(filterInititalStartDate)
  const [filterEndDate, setFilterEndDate] = useState(new Date())
  const toDateTimeLocalFilter = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDateFilter = async (data: any, title: string) => {
    if (title === 'Start') setFilterStartDate(data)
    else setFilterEndDate(data)
  }
  //#endregion

  const getInitialState = (): IListAnnouncementState => {
    return {
      announcementLists: [],
      dealerLists: [],
      // brandBool: true
      xIsDealerSubTypeInclude: true,
      xIsDealerListInclude: true,
      status: 1,
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListAnnouncementState>(intialState)

  useEffect(() => {
    getAnnouncement()
    getData()
  }, [])

  const filterState: IPostAnnouncementListRequest = {
    StartDate: inititalStartDate,
    EndDate: new Date(),
    DealersID: [],
    Id: 0,
    OpenBoxDealers: [],
    ProductCode: 0,
    Topic: '',
    Description: '',
    Image: '',
    File: {
      Label: '',
      Content: '',
      Extension: '',
      FileType: '',
    },
    BrandId: 0,
    Enable: false,
    IsMobile: false,
    DealerSubtype: '',
    IsDealerListInclude: true,
    IsDealerSubTypeInclude: true,
  }
  const [filters, setFilters] = useState<IPostAnnouncementListRequest>(filterState)

  function updateFilters(prop, val) {
    setFilters(prev => ({ ...prev, [prop]: val }))
  }

  //#region DealerCodes
  const getDealerTypes = async () => {
    const res = await new IyzicoCampaignService({}).getDealerTypes()
    if (res) {
      const dealerTypeList: ISelectOption[] = []

      res.forEach((item: DealerType) => {
        item && dealerTypeList.push({ value: item.MusteriGrubu, label: item.MusteriGrubuAciklama })
      })

      return dealerTypeList
    }
  }

  const getDealerSubTypes = async () => {
    const res = await new IyzicoCampaignService({}).getSubTypes()
    if (!res) return []
    return res.map(item => ({ value: item, label: item }))
  }
  const [optionList, setOptionList] = useState<any>({
    //dealerCodeOptions: [],
    dealerTypeOptions: [],
    dealerSubTypeOptions: [],
  })
  enum CariListType {
    CariCode,
    CariType,
    CariAltType,
  }
  const [selectedDealerList, setSelectedDealerList] = useState(CariListType.CariCode)
  const selectDealer = (takenState, takenEnum) => {
    return takenState === takenEnum ? 'active' : ''
  }
  const [dealerSubTypeValue, setDealerSubTypeValue] = useState<ISelectOption[] | null>(null)
  const [updateDealerSubTypeValue, setUpdateDealerSubTypeValue] = useState<ISelectOption[] | null>(null)
  const [dealerCodeValue, setDealerCodeValue] = useState<ISelectOption[] | null>()

  const setDealerCodes = (dealerCodes, optionArr, updateFunc, type) => {
    if (type === 'DealerCode') {
      const codeData = dealerCodes.map(item => ({
        label: String(item.DealerCode),
        value: String(item.DealerCode),
      }))

      let list: { label: string; value: string }[] = []

      codeData.forEach(codeItem => {
        if (!dealerCodeValue?.some((item: any) => item.label === String(codeItem.label))) {
          list.push(codeItem)
        }
      })
      const newData = dealerCodeValue ? [...dealerCodeValue, ...list] : list
      const uniqueList = newData.filter((item, index, self) => {
        return index === self.findIndex(i => i.label === item.label)
      })
      setDealerCodeValue(uniqueList)
      updateFilters(
        'DealerCodes',
        uniqueList.map(item => item.value)
      )
    } else {
      const list: any = []
      const codeData: any = []

      dealerCodes.forEach(item => {
        const codes: any = optionArr.find((code: any) => {
          if (item.DealerCode.length === 6) {
            return code.label === item.DealerCode
          } else {
            return code.label === '0000' + item.DealerCode
          }
        })
        if (codes === undefined) codeData.push(item.DealerCode)
        else list.push(codes)
      })

      if (type === 'DealerSubType') {
        updateFilters(
          'DealerSubTypes',
          list.map(item => item.value)
        )
      }

      if (codeData.length === 0) {
        return updateFunc(list)
      }
      Swal.fire({
        icon: 'question',
        title: `${codeData.map(dt => dt)} bu cariler bulunamadı. Yeniden yüklemek ister misiniz?`,
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(async result => {
        if (result.isConfirmed) return
        updateFunc(list)
      })
    }
  }

  const handleFiles = async (files: File[], type: 'DealerCode' | 'DealerSubType') => {
    const dealerCodes: any = await excelToJSON(files[0])

    if (type === 'DealerCode') {
      setDealerCodes(dealerCodes[0], optionList.dealerCodeOptions, setDealerCodeValue, type)
      return
    }
    if (type === 'DealerSubType') {
      setDealerCodes(dealerCodes[0], optionList.dealerSubTypeOptions, setDealerSubTypeValue, type)
    }
  }
  //#endregion

  useEffect(() => {
    props.showLoading()
    Promise.all([getDealerTypes(), getDealerSubTypes()])
      .then((data: any) => {
        const [dealerTypeOptions, dealerSubTypeOptions] = data

        setOptionList({
          dealerTypeOptions,
          dealerSubTypeOptions,
        })
        props.hideLoading()
      })
      .catch(() => {
        props.hideLoading()
      })
  }, [])

  const dateValid = () => {
    const result = filters.EndDate.getTime() - filters.StartDate.getTime()
    if (result < 60000) {
      Swal.fire('Bitiş tarihi başlangıç tarihinden önce olamaz.')
      updateFilters('EndDate', new Date())
    }
  }

  const getData = async () => {
    props.showLoading()
    getDealerApi().then(res => {
      getBrandApi().then(result => {
        props.hideLoading()
        setState({ brandList: result, dealerLists: res })
      })
    })
  }

  const getAnnouncement = async () => {
    if (!filterStartDate || !filterEndDate) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen tarih seçiniz...',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const req: IAnnouncementFilteredRequest = {
      FilterStartDate: filterStartDate,
      FilterEndDate: filterEndDate,
      State: state.status === 1 ? true : false,
    }
    props.showLoading()
    const getAnnouncementApi: GetAnnouncementApi = new GetAnnouncementApi({})
    getAnnouncementApi
      .getAnnouncement(req)
      .then(res => {
        if (res) {
          setAnnouncementLists(res)
          props.hideLoading()
        }
      })
      .catch(e => {
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const handleImage = async (e: any) => {
    const fileList: any[] = await getUploadFileRequest(e)
    if (e && e.target && e.target.files && e.target.files[0]) {
      const base64Str = await handleImageUpload(e.target.files[0])
      setTmpImage(base64Str)
    }

    if (fileList && fileList.length > 0) {
      setFieldValue('File', fileList[0])
    }
  }

  const deleteImage = async () => {
    setTmpImage('')
    setFieldValue('Image', '')
    setFieldValue('ImageUrl', '')
  }

  const { handleSubmit, handleChange, setValues, setFieldValue, resetForm, values, errors, touched } = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async values => {
      props.showLoading()
      const clnValues = _.clone(values)
      if (flag === 'update') {
        clnValues.DealersID = [
          ...(clnValues.DealersID || []), // Ensure clnValues.DealersID is an array
          ...(dealerCodeValue || []).map((item: ISelectOption) => Number(item.value)),
        ].filter(Boolean)
      } else {
        clnValues.DealersID = dealerCodeValue
          ? (dealerCodeValue.map((item: ISelectOption) => Number(item.value)) as any)
          : []
      }

      var ops =
        state.dealerLists &&
        state.dealerLists.map((com: IDealer) => {
          return {
            value: `${com.Id}`,
            label: com.SapCode.replace('0000', ''),
          }
        })

      if (ops) {
        const filteredOps = ops.filter(item => {
          const dealerID = Number(item.label)
          return state.xIsDealerListInclude
            ? clnValues.DealersID.includes(dealerID)
            : !clnValues.DealersID.includes(dealerID)
        })

        clnValues.DealersID = filteredOps.map(item => Number(item.value))
      }

      if (flag === 'update') {
        clnValues.DealerSubtype = updateDealerSubTypeValue
          ? updateDealerSubTypeValue.map((item: ISelectOption) => String(item.label)).join(',')
          : ''
      } else {
        clnValues.DealerSubtype = dealerSubTypeValue
          ? dealerSubTypeValue.map((item: ISelectOption) => String(item.label)).join(',')
          : ''
      }

      clnValues.DealerList = dealerCodeValue
        ? dealerCodeValue.map((item: ISelectOption) => String(item.label)).join(',')
        : ''

      clnValues.IsDealerListInclude = state.xIsDealerListInclude

      clnValues.IsDealerSubTypeInclude = state.xIsDealerSubTypeInclude

      clnValues.BrandId = clnValues.BrandId ? Number(clnValues.BrandId.value) : null
      if (!startDate || !endDate) {
        Swal.fire({
          icon: 'error',
          title: 'Lütfen tarih giriniz.',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }
      const dateStart = startDate
      const dateEnd = endDate
      clnValues.StartDate = dateStart
      clnValues.EndDate = dateEnd
      if (clnValues) {
        if (flag === 'update') {
          clnValues.UpdatedDate = moment().format()
          clnValues.Thumb = clnValues.Image
          props.showLoading()
          const updateAnnouncement: UpdateAnnouncementApi = new UpdateAnnouncementApi({})
          updateAnnouncement
            .putAnnouncement(clnValues)
            .then(async result => {
              if (result) {
                props.hideLoading()
                Swal.fire({
                  icon: 'success',
                  title: 'Duyuru Başarıyla Güncellendi.',
                  showConfirmButton: true,
                  allowOutsideClick: false,
                }).then(async result => {
                  window.location.reload()
                })
              }
            })
            .catch((err: any) => {
              props.hideLoading()
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            })
        } else {
          props.showLoading()
          const postAnnouncement: PostAnnouncementApi = new PostAnnouncementApi({})
          postAnnouncement
            .postAnnouncement(clnValues)
            .then(async result => {
              if (result) {
                props.hideLoading()
                Swal.fire({
                  icon: 'success',
                  title: 'Duyuru Başarıyla Eklendi',
                  showConfirmButton: true,
                  allowOutsideClick: false,
                }).then(async result => {
                  window.location.reload()
                })
              }
            })
            .catch((err: any) => {
              props.hideLoading()
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            })
        }
      }
    },
  })

  const [cariKodTik, setCariKodTik] = useState<boolean>()

  const update = async (data: any) => {
    if (data) {
      // let dealerList =
      //   data.OpenBoxDealers &&
      //   data.OpenBoxDealers.length > 0 &&
      //   data.OpenBoxDealers[0].DealerId &&
      //   data.OpenBoxDealers.map(com => {
      //     if (com) {
      //       return {
      //         value: `${com.DealerId}`,
      //         label: com.TheCode.replace('0000', ''),
      //       }
      //     }
      //     return true
      //   })

      let BrandId = data.BrandId ? { label: data.BrandName, value: data.BrandId } : null
      setValues(data)
      //if (data.OpenBoxDealers && data.OpenBoxDealers.length > 0) setFieldValue('DealersID', dealerList)
      if (data.BrandId) setFieldValue('BrandId', BrandId)

      setStartDate(data.StartDate)
      setEndDate(data.EndDate)
      //#region Edit sırasındaki Cari Tipi state ve options
      setCariKodTik(true)
      setUpdateDealerSubTypeValue(null)
      setSelectedDealerList(CariListType.CariCode)
      setState({ xIsDealerListInclude: data.IsDealerListInclude })
      setState({ xIsDealerSubTypeInclude: data.IsDealerSubTypeInclude })

      if (data.DealerSubtype) {
        const dealerSubtypeString = data.DealerSubtype
        const dealerSubtypes = dealerSubtypeString.split(',')

        const selectOptions = dealerSubtypes.map(subtype => ({
          label: subtype,
          value: subtype,
        }))
        setCariKodTik(false)
        setUpdateDealerSubTypeValue(selectOptions)
        setSelectedDealerList(CariListType.CariAltType)
      } else if (data.DealerList) {
        const dealerCodesOptions: ISelectOption[] = data.DealerList.split(',').map((code: string) => ({
          label: code?.replace(/^0+/, ''), // Başındaki sıfırları kaldırır
          value: code?.replace(/^0+/, ''),
        }))
        setDealerCodeValue(dealerCodesOptions)
      }
    } else {
      setDealerCodeValue(null)
      setUpdateDealerSubTypeValue(null)
    }
    //#endregion
  }

  const deleteAnnouncement = async id => {
    Swal.fire({
      icon: 'question',
      title: 'Duyuruyu silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        props.showLoading()
        const deleteAnnouncementApi = new DeleteAnnouncementApi({})
        deleteAnnouncementApi
          .deleteAnnouncement(id)
          .then(async res => {
            if (res) {
              setAnnouncementLists((prevState: any) => prevState.filter(item => item.Id !== id))
            }
            props.hideLoading()
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          })
      }
    })
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }
  //#region Tablo Verileri
  const tableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
      },
      {
        Header: 'Aktif',
        accessor: 'col2',
      },
      {
        Header: 'Konu',
        accessor: 'col3',
        width: 250,
      },
      {
        Header: 'Başlangıç-Bitiş',
        accessor: 'col4',
        width: 200,
      },
      {
        Header: 'İşlemler',
        accessor: 'col5',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      announcementLists.map((dt, index) => {
        const data = {
          col1: index + 1,
          col2: (
            <label className='p-switch p-switch-sm switch-color-success'>
              <input
                type='checkbox'
                name='switch'
                className='switch'
                checked={dt.Enable}
                data-action-type='switch'
                onClick={() => {
                  Swal.fire({
                    icon: 'error',
                    title: 'Lütfen duyuruyu aktif pasif yapabilmek için güncelleme butonunu kullanınız',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                  })
                }}
              />
              <span className='p-switch-style'></span>
            </label>
          ),
          col3: <div title={dt.Topic}>{dt.Topic.slice(0, 40) + (dt.Topic.length > 40 ? ' ...' : '')}</div>,
          col4:
            new Date(dt.StartDate).toLocaleDateString('tr-TR') + '-' + new Date(dt.EndDate).toLocaleDateString('tr-TR'),
          col5: (
            <div>
              <a
                data-action-type='edit'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                onClick={() => {
                  update(dt)
                  setFlag('update')
                }}
              >
                <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
              </a>
              <a
                data-action-type='delete'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                onClick={() => deleteAnnouncement(dt.Id)}
              >
                <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
              </a>
            </div>
          ),
        }

        return data
      }),
    [announcementLists]
  )
  //#endregion

  const [inputValue, setInputValue] = useState('')

  const handleInputChange = event => {
    setInputValue(event.target.value)
  }

  const handleInputKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAddValue()
    }
  }

  const handleAddValue = () => {
    if (inputValue.trim() !== '') {
      const words = inputValue.split(' ')
      let dealerList: { label: string; value: string }[] = []
      words.forEach((word: any) => {
        if (!dealerCodeValue?.some((item: any) => item.label === word)) {
          const newItem = { label: word, value: word }
          dealerList.push(newItem)
        }
      })

      const newData = dealerCodeValue ? [...dealerCodeValue, ...dealerList] : dealerList
      const uniqueList = newData.filter((item, index, self) => {
        return index === self.findIndex(i => i.label === item.label)
      })
      setDealerCodeValue(uniqueList)
      updateFilters(
        'DealerCodes',
        uniqueList.map(item => item.value)
      )
      setInputValue('')
    }
  }

  return (
    <div className='content col-lg-12 m-auto'>
      <div className='card'>
        <div className='card-header d-flex justify-content-between'>
          <span className='h4'>Duyuru Listesi</span>
        </div>
        <div className='container card-body'>
          <div className='row'>
            <div className='col-md-6 col-sm-12'>
              <div className='row'>
                <div className='col-md-3'>
                  <IPButton
                    type='button'
                    className='btn btn-primary'
                    text=' Formu Sıfırla'
                    onClick={() => {
                      setFlag('create')
                      setStartDate(inititalStartDate)
                      setEndDate(new Date())
                      setDealerSubTypeValue(null)
                      setUpdateDealerSubTypeValue(null)
                      setDealerCodeValue(null)
                      setState({ xIsDealerListInclude: true, xIsDealerSubTypeInclude: true })
                      setCariKodTik(false)
                      resetForm()
                    }}
                  />
                </div>
                <div className='col-md-7'></div>
                <div className='col-md-2'>
                  <IPCheckbox name='Enable' onChange={handleChange} checked={values.Enable} label='Etkin' />
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-md-12'>
                  <IPInput
                    type='text'
                    id='Topic'
                    className='form-control'
                    name='Topic'
                    value={values.Topic}
                    placeholder='Konu Başlığı'
                    onChange={handleChange}
                  />
                  <p className='validation-error text-danger'>
                    {' '}
                    {errors.Topic && touched.Topic ? '* ' + errors.Topic : null}
                  </p>
                </div>
                <div className='col-md-12'>
                  <IPInput
                    type='text'
                    id='ShortDescription'
                    // disabled={state.brandBool}
                    className='form-control'
                    name='ShortDescription'
                    value={values.ShortDescription}
                    placeholder='Kısa Açıklama'
                    onChange={handleChange}
                  />
                  <p className='validation-error text-danger'>
                    {' '}
                    {errors.Topic && touched.ShortDescription ? '* ' + errors.ShortDescription : null}
                  </p>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-12 col-lg-6'>
                  <div className='leftContainer mb-5'>
                    <div>
                      <a className='campaignExcelBtn' href='/assetWeb/docs/dealer-codes-example.xlsx' target='_blank'>
                        Örnek Excel
                      </a>
                      <div className='listBtnGroup justify-content-between'>
                        <IPButton
                          className={`listCariBtn ${selectDealer(selectedDealerList, CariListType.CariCode)}`}
                          text={`Cari Kodu Listesi ${!dealerSubTypeValue && cariKodTik ? '✅' : ''}`}
                          onClick={() => setSelectedDealerList(CariListType.CariCode)}
                        />
                        <IPButton
                          className={`listCariBtn ${selectDealer(selectedDealerList, CariListType.CariAltType)}`}
                          text={`Cari Alt Tipi Listesi ${updateDealerSubTypeValue && updateDealerSubTypeValue.length > 0 ? '✅' : ''
                            }`}
                          onClick={() => setSelectedDealerList(CariListType.CariAltType)}
                        />
                      </div>
                    </div>

                    <div className='p-0 m-0'>
                      <div
                        className={`cariFilterTextAreaWrap ${selectDealer(selectedDealerList, CariListType.CariCode)}`}
                      >
                        <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                          <IPFile
                            id='FileInputDealerCode'
                            multiple={false}
                            handleFiles={e => handleFiles(e, 'DealerCode')}
                            removeAfterSelect={true}
                            className='campaignFileInput mr-1'
                            label="Excel'den Yükle"
                          />
                          <IPButton
                            text={'Dahil'}
                            className={`btnIncluded ${state.xIsDealerListInclude ? 'active' : ''}`}
                            onClick={() => {
                              setState({ xIsDealerListInclude: true })
                              setFieldValue('IsDealerListInclude', true)
                            }}
                          />
                          <IPButton
                            text={'Hariç'}
                            className={`btnExcept ${!state.xIsDealerListInclude ? 'active' : ''}`}
                            onClick={() => {
                              setState({ xIsDealerListInclude: false })
                              setFieldValue('IsDealerListInclude', false)
                            }}
                          />
                        </div>
                        <CampaignSelect
                          isClearable
                          isMulti
                          placeholder={'Cari Kod Listesi'}
                          height={180}
                          onChangeMulti={options => {
                            setDealerCodeValue(options)
                            updateFilters(
                              'DealerCodes',
                              options.map(item => item.value)
                            )
                            //setFieldValue('DealerList', options)
                          }}
                          //value={dealerCodeValue ? dealerCodeValue : updateDealerValue}
                          value={dealerCodeValue}
                        />
                        <input
                          type='text'
                          placeholder='Cari kodu yazın'
                          value={inputValue}
                          onChange={handleInputChange}
                          onKeyPress={handleInputKeyPress}
                        />
                      </div>

                      <div
                        className={`cariFilterTextAreaWrap ${selectDealer(
                          selectedDealerList,
                          CariListType.CariAltType
                        )}`}
                      >
                        <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                          <div className='btns d-flex w-100'>
                            <IPFile
                              id='FileInputDealerSubType'
                              multiple={false}
                              handleFiles={e => handleFiles(e, 'DealerSubType')}
                              removeAfterSelect={true}
                              className='campaignFileInput mr-1'
                              label="Excel'den Yükle"
                            />
                            <IPButton
                              text={'Dahil'}
                              className={`btnIncluded ${state.xIsDealerSubTypeInclude ? 'active' : ''}`}
                              onClick={() => {
                                setState({ xIsDealerSubTypeInclude: true })
                                setFieldValue('IsDealerSubTypeInclude', true)
                              }}
                            />
                            <IPButton
                              text={'Hariç'}
                              className={`btnExcept ${!state.xIsDealerSubTypeInclude ? 'active' : ''}`}
                              onClick={() => {
                                setState({ xIsDealerSubTypeInclude: false })
                                setFieldValue('IsDealerSubTypeInclude', false)
                              }}
                            />
                          </div>
                        </div>
                        <CampaignSelect
                          isClearable
                          isMulti
                          placeholder={'Cari Alt Tipi Listesi'}
                          height={180}
                          options={optionList.dealerSubTypeOptions}
                          onChangeMulti={options => {
                            setDealerSubTypeValue(options)
                            updateFilters(
                              'DealerSubTypes',
                              options.map(item => item.value)
                            )
                          }}
                          value={dealerSubTypeValue ? dealerSubTypeValue : updateDealerSubTypeValue}
                        />
                      </div>
                    </div>
                  </div>
                  <p style={{ color: 'red' }}>* Bayi seçilmezse tüm bayilerde geçerli olacak</p>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-md-6'>
                  <InputCampaign
                    type='datetime-local'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={startDate && toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, 'Start')}
                    isActive
                  />
                  <div className='col-md-12 mt-3'></div>
                  <InputCampaign
                    type='datetime-local'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={endDate && toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, 'End')}
                    onBlur={dateValid}
                    isActive
                  />
                  <div className='col-md-12 mt-3'></div>
                  <IPSelectBox
                    isClearable
                    id='BrandId'
                    name='BrandId'
                    options={
                      state.brandList &&
                      state.brandList.map(brand => {
                        return { label: brand.Name, value: brand.Id }
                      })
                    }
                    onChangeSingle={option => {
                      setFieldValue('BrandId', option)
                      setFieldValue('BrandName', null)
                    }}
                    value={values.BrandId}
                    placeholder='Marka Seçiniz...'
                  />
                  <p style={{ color: 'red' }}>*Marka seçilirse o markanın haberler kısmına gider açbox ta görünmez.</p>
                  <div className='col-md-12 mt-3'></div>
                  <IPInput
                    type='text'
                    id='Link'
                    className='form-control'
                    name='Link'
                    value={values.Link}
                    placeholder='Link'
                    onChange={handleChange}
                  />
                  <div className='col-md-12 mt-3'></div>
                  <IPCheckbox
                    name='IsMobile'
                    onChange={handleChange}
                    checked={values.IsMobile}
                    label='Mobil de Göster'
                  />
                </div>
                <div className='col-lg-6 mt-sm-4 mt-lg-0 card-mobile-resize'>
                  {tmpImage ? (
                    <div className='your-recent-visits text-center' style={{ minHeight: '270px', height: '270px' }}>
                      <div className='room '>
                        <div className='room-image text-center '>
                          <IPImage src={tmpImage} alt='' className='RecentVisits' />
                        </div>
                      </div>
                      <a
                        className='m-2'
                        data-toggle='tooltip'
                        onClick={() => deleteImage()}
                        style={{
                          cursor: 'pointer',
                          color: 'red',
                          fontSize: '35px',
                        }}
                      >
                        <i className='icon-trash-2'></i>
                      </a>
                    </div>
                  ) : values.Image ? (
                    <div className='your-recent-visits text-center' style={{ minHeight: '270px', height: '270px' }}>
                      <div className='room '>
                        <div className='room-image text-center '>
                          <IPImage src={values.ImageUrl} alt='' className='RecentVisits' />
                        </div>
                      </div>
                      <a
                        className='m-2'
                        data-toggle='tooltip'
                        onClick={() => deleteImage()}
                        style={{
                          cursor: 'pointer',
                          color: 'red',
                          fontSize: '35px',
                        }}
                      >
                        <i className='icon-trash-2'></i>
                      </a>
                    </div>
                  ) : (
                    <div className='your-recent-visits text-center' style={{ minHeight: '270px', height: '270px' }}>
                      <i
                        className='icon-plus'
                        style={{ fontSize: '150px', color: '#adb5bd' }}
                        onClick={() => inputEl.current.click()}
                      ></i>
                      <input
                        ref={inputEl}
                        type='file'
                        name='bannerImage'
                        className='form-control'
                        id='bannerImage'
                        style={{ display: 'none' }}
                        onChange={event => {
                          handleImage(event)
                        }}
                      />
                      <br />
                      <h5 style={{ color: '#adb5bd' }}>Dosya Ekle</h5>
                    </div>
                  )}
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <label className='labeltext'>Açıklama</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={values.Description}
                    onReady={editor => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setFieldValue('Description', data)
                    }}
                  />
                </div>
              </div>
              <div className='row mt-4' style={{ float: 'right' }}>
                <div className='col-md-1'>
                  <IPButton
                    type='button'
                    className='btn btn-success'
                    text={flag === 'create' ? 'Ekle' : 'Güncelle'}
                    onClick={() => handleSubmit()}
                  />
                </div>
              </div>
            </div>
            <div className='col-md-6 col-sm-12'>
              <form className='formbanner'>
                <div className='row mb-1'>
                  <div className='col-12 col-md-3'>
                    <InputCampaign
                      type='datetime-local'
                      id='StartDate'
                      name='StartDate'
                      label={'Başlangıç Tarihi'}
                      value={filterStartDate && toDateTimeLocalFilter(filterStartDate)}
                      onChange={e => getDateFilter(e.target.value, 'Start')}
                      isActive
                    />
                  </div>
                  <div className='col-12 col-md-3'>
                    <InputCampaign
                      type='datetime-local'
                      id='EndDate'
                      name='EndDate'
                      label={'Bitiş Tarihi'}
                      value={filterEndDate && toDateTimeLocalFilter(filterEndDate)}
                      onChange={e => getDateFilter(e.target.value, 'End')}
                      isActive
                    />
                  </div>

                  <div className='col-12 col-md-3'>
                    <IPSelectBox
                      options={[
                        { value: 1, label: 'Aktif' },
                        { value: 0, label: 'Pasif' },
                        // { value: 3, label: 'Silinmiş' },
                      ]}
                      // value={state.statusOption}
                      value={state.statusOption || { value: 1, label: 'Aktif' }} // Varsayılan değer burada ayarlanıyor
                      placeholder='Durum Seçiniz'
                      onChangeSingle={option => {
                        if (option) setState({ statusOption: option, status: +option.value })
                        else setState({ statusOption: option, status: 0 })
                      }}
                    />
                  </div>

                  <div className='col-12 col-md-1'>
                    <IPButton text='Süz' className='btn btn-danger' onClick={getAnnouncement} />
                  </div>
                </div>
              </form>
              <Table columns={tableColumns} data={tableData} currentPage={0} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(AnnouncementList)
