import classes from './IPButton.module.scss'

export interface IPButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  type?: 'button' | 'submit' | 'reset' | undefined
  text: string
  className?: string
  isRounded?: undefined | boolean
  icon?: string
}

export default function Button(props: IPButtonProps) {
  let { type, text, className, isRounded, ...others } = props
  return (
    <button
      type={props.type ?? 'button'}
      className={`${classes.Button} ${isRounded ? classes.Rounded : ''} ${props.className ?? ''}`}
      {...others}
    >
      {props.icon && <img src={`/images/${props.icon}`} alt='' />}
      {props.text}
    </button>
  )
}
