import React, { useEffect, useRef, useState } from 'react'
import IPInput from '../../../components/IPInput'
import { formatCVC, formatExpirationDate } from '../../MyCard/creditCardUtils'
import Cards, { Focused } from 'react-credit-cards'
import { useCustomReducer } from '../../../base/customHooks'
import IPButton from '../../../components/IPButton'
import Swal from 'sweetalert2'
import { IPInputDataPattern } from '../../../components/IPInput/IPInput'
import InputMask from '../../../components/InputMask'
import { IException } from '../../../base/models/proxyModels'
import { PaymentService } from './services/PaymentService'
import Image from '../../../components/IPImage'
import styles from './../../MyCard/MyCard.module.scss'
import {
  IAllPaymentData,
  IDealerLimit,
  IDealerReminder,
  IGetInstallmentSpecial,
  IInvoice,
  ILimit,
  IPaymentDetail,
  IPaymentInvoice,
  PaymentRequestMySpecial,
  PaymentTypeEnum,
  IBanner,
  PaymentControl,
  IManualCreditCardPaymentControl,
  IManualCreditCardPayment,
  IInstallmentInquiry,
} from './models/PaymentModel'
import { downloadFileFromUrl, formatPrice, printElementInvoiceRecord } from '../../../base/utils/dataHelper'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'
import IPCheckbox from '../../../components/IPCheckBox'
import numeral from 'numeral'
import IPSelectBox from '../../../components/IPSelectBox'
import './style.scss'
import './tableSection.css'
import IPModal from '../../../components/IPModal'
import { PostReceiptExcel } from './services/receiptExcel'
import { GetBannerDetailsApi } from './services/getBannerDetailsApi'
import { ReceiptExcelResponse } from './models/receiptExcel'
import { GetExchangeRatesApi } from '../../../layouts/components/client/services/getExchangeRatesApi'
import { IExchangeRate } from '../../../layouts/components/client/models'
import { Link, useSearchParams } from 'react-router-dom'
import IPImage from '../../../components/IPImage'
import { GetIsAuthorizedToCompanyApi } from './services/getIsAuthorizedToCompanyApi'
import { IsNot3DPayment } from '../../../services/isNot3DPayment'
import { GetManualCreditCardPaymentControlApi } from './services/getManualCreditCardPaymentControlApi'
import { GetManualCreditCardPaymentApi } from './services/getManualCreditCardPaymentApi'
import { InstallmentInquiryApi } from './services/installmentInquiryApi'
import IPRadio from '../../../components/IPRadio'
import { IBankNoteList } from '../../Admin/BankAndInstallementOperation/BankNote/models/models'
import IPTextarea from '../../../components/IPTextarea'
import { OrderMailApi } from './services/orderMailApi'
import { IBankInfos, binRequest } from '../../MyCard/models/models'
import { FindBankInfosApi } from './services/findBankInfosApi'
import IPPriceDisplay from '../../../components/IPPriceDisplay'

interface IInvoiceCollectionAmount {
  faturaID: number
  collectionAmount: number
}

interface IFcv {
  id: number
  name: string
}

interface IAdvantage {
  id: number
  name: string
  PaymentTermsId?: number
}

interface IOpportunity {
  id: number
  name: string
  PaymentTermsId?: number
}

interface ISelectOption {
  value: string | number
  label: string
  PaymentTermsId?: number
}

interface MyCardState {
  sentBinNumber: boolean
  bankCode: number
  bankLogo: string

  ccCVC: string | number
  ccExpiry: string | number
  ccFocus: Focused | undefined
  ccName: string
  ccNumber: string
  orderNote?: string
  paymentWarn: boolean
  amount: string | number
  dealerReminders: IDealerReminder[]
  installmentNumber: number
  commission: number
  installmentSpecials?: IGetInstallmentSpecial[]
  selectedInstallment?: IGetInstallmentSpecial
  selectedPaymentType: PaymentTypeEnum
  binNumber: string
  invoices?: IInvoice[]
  selectedInvoicesCollectionAmounts: IInvoiceCollectionAmount[]
  allPaymentData?: IAllPaymentData
  firsatLimit?: IDealerLimit
  avantajLimit?: IDealerLimit
  fcvLimit?: IDealerLimit
  selectedInvoices: IInvoice[]
  cariSelectedCompany?: number
  avantajSelectedCompany?: number
  firsatSelectedCompany?: number
  fcvs: IFcv[]
  advantages: IAdvantage[]
  opportunities: IOpportunity[]
  currentMethodLimit?: number
  invoicesTotalAmount?: number
  selectedFcv?: ISelectOption
  selectedAdvantage?: ISelectOption
  selectedOpportunity?: ISelectOption
  isIncluded: number
  selectedCurrency: ISelectOption | undefined
  showPaymentDetails: boolean
  paymentDetail?: IPaymentDetail
  exchangeRate?: IExchangeRate
  securePayment: boolean
  paymentTermsId?: number
  banner: IBanner[]
  modalBanner?: IBanner
  showModalPopup: boolean
  modalPopup: boolean
  isMarked: boolean
  // companyData: any[]
  threedPaymentCompanyCode: string[]
  threedPaymentDisabled: boolean
  notCommission: boolean
  mtBool: boolean
  bankNotes: IBankNoteList[]
  showMail: boolean
  mailName: string
  mailEmailText: string
  mailSubject: string
  mailNote: string
  paramposBool: boolean
}

function Payment(props: BaseComponentWithContextProps) {
  const getInitialState = (): MyCardState => {
    return {
      bankCode: 0,
      bankLogo: '',
      sentBinNumber: false,
      bankNotes: [],
      ccCVC: '',
      ccExpiry: '',
      ccFocus: 'number',
      ccName: '',
      ccNumber: '',
      paymentWarn: false,
      amount: 0,
      dealerReminders: [],
      installmentNumber: 0,
      commission: 0,
      selectedPaymentType: PaymentTypeEnum.CariOdeme,
      binNumber: '',
      selectedInvoicesCollectionAmounts: [],
      selectedInvoices: [],
      fcvs: [],
      advantages: [],
      opportunities: [],
      isIncluded: 0,
      selectedCurrency: undefined,
      showPaymentDetails: false,
      securePayment: false,
      paymentTermsId: 0,
      banner: [],
      showModalPopup: false,
      modalPopup: true,
      isMarked: false,
      threedPaymentCompanyCode: [],
      threedPaymentDisabled: false,
      notCommission: false,
      mtBool: false,
      showMail: false,
      mailName: '',
      mailEmailText: '',
      mailSubject: '',
      mailNote: '',
      paramposBool: false,
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<MyCardState>(intialState)

  const orderSendMail = () => {
    var mailList = state.mailEmailText.split(',')

    if (
      !state.mailEmailText ||
      state.mailEmailText.length === 0 ||
      !state.mailName ||
      state.mailName.length === 0 ||
      !state.mailSubject ||
      state.mailSubject.length === 0 ||
      !state.mailNote ||
      state.mailNote.length === 0 ||
      !mailList ||
      mailList.length === 0
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen tüm alanları doldurunuz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    props.showLoading()
    const orderMailApi: OrderMailApi = new OrderMailApi({})
    orderMailApi
      .orderSendMail({
        Name: state.mailName,
        Emails: mailList,
        Subject: state.mailSubject,
        Note: state.mailNote,
      })
      .then(res => {
        props.hideLoading()
        if (res) {
          Swal.fire({
            icon: 'success',
            title: `${mailList} adreslerine mail gönderildi`,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          setState({ showMail: false })
          props.hideLoading()
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Mail Gönderiminde Hata!',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        }
      })
      .catch((err: IException) => {
        props.hideLoading()
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const postReceiptExcel = () => {
    const receipt = Object.assign({
      ReceiptNumber: state.paymentDetail?.ReceiptNumber,
      DocumentNo: state.paymentDetail?.DocumentNo,
      Amount: state.paymentDetail?.Amount,
      Bank: state.paymentDetail?.Bank,
      CardNumber: state.paymentDetail?.CrediCard,
      Date: state.paymentDetail?.Date,
      Installment: state.paymentDetail?.Installment,
      DealerCode: state.paymentDetail?.DealerCode,
      DealerName: state.paymentDetail?.DealerName,
      NameSurname: state.paymentDetail?.NameSurname,
    })
    const postReceiptExcelApi: PostReceiptExcel = new PostReceiptExcel({})
    postReceiptExcelApi.postReceiptExcel(receipt).then((res: ReceiptExcelResponse) => {
      if (res) {
        downloadFileFromUrl('Dekont', res.FileUrl)
      }
    })
  }
  // const exportToExcel = () => {
  //   exportToXLSX(state.paymentDetail, "Receipt")
  // }
  const getAllPaymentData = () => {
    props.showLoading()
    const service = new PaymentService({})
    service
      .getAllPaymentData()
      .then((res: IAllPaymentData) => {
        if (res) {
          const avantajLimit = res.DealerLimits
            ? res.DealerLimits.find((item: IDealerLimit) => item.Type === PaymentTypeEnum.Avantaj)
            : undefined
          const firsatLimit = res.DealerLimits
            ? res.DealerLimits.find((item: IDealerLimit) => item.Type === PaymentTypeEnum.Firsat)
            : undefined
          const fcvLimit = res.DealerLimits
            ? res.DealerLimits.find((item: IDealerLimit) => item.Type === PaymentTypeEnum.Fcv)
            : undefined
          const data: number[] = [12]
          if (res.DealerLimits) {
            if (res.DealerLimits.find((item: IDealerLimit) => item.Type === PaymentTypeEnum.Avantaj)) data.push(13)
            if (res.DealerLimits.find((item: IDealerLimit) => item.Type === PaymentTypeEnum.Firsat)) data.push(14)
            if (res.DealerLimits.find((item: IDealerLimit) => item.Type === PaymentTypeEnum.Fcv)) data.push(15)
            const getBannerDetails: GetBannerDetailsApi = new GetBannerDetailsApi({})
            getBannerDetails.getBannerDetails(data).then((res: IBanner[]) => {
              if (res) {
                setState({ banner: res })
              }
            })
          }
          const distinctInvoices: IFcv[] = []

          if (res.Invoices) {
            for (const invoice of res.Invoices) {
              const idx = distinctInvoices.findIndex((invElem: IFcv) => invElem.id === invoice.FCVID)

              if (idx === -1) {
                distinctInvoices.push({ id: invoice.FCVID, name: invoice.FCVName })
              }
            }
          }

          const advantages: IAdvantage[] = []

          if (avantajLimit?.List) {
            for (const adv of avantajLimit.List) {
              if (advantages.findIndex((val: IAdvantage) => val.id === adv.Id) === -1) {
                advantages.push({ id: adv.Id, name: adv.PaymentTermsName, PaymentTermsId: adv.PaymentTermsId })
              }
            }
          }

          const opportunities: IOpportunity[] = []

          if (firsatLimit?.List) {
            for (const opp of firsatLimit.List) {
              if (opportunities.findIndex((val: IOpportunity) => val.id === opp.Id) === -1) {
                opportunities.push({ id: opp.Id, name: opp.PaymentTermsName, PaymentTermsId: opp.PaymentTermsId })
              }
            }
          }

          setState({
            allPaymentData: res,
            avantajLimit,
            firsatLimit,
            fcvLimit,
            fcvs: distinctInvoices,
            advantages,
            opportunities,
          })
        }
      })
      .finally(() => {
        props.hideLoading()
      })
  }

  const getIsAuthorizedToCompany = async (
    title: string,
    companyId?: string,
    paymentType?: PaymentTypeEnum,
    binNumber?: string,
    amount?: number
  ) => {
    sessionStorage.setItem('selectedPaymentType', String(state.selectedPaymentType))

    let company: string = ''

    if (state.selectedPaymentType === PaymentTypeEnum.CariOdeme) {
      company = state.cariSelectedCompany ? `${state.cariSelectedCompany}` : ''
    } else if (state.selectedPaymentType === PaymentTypeEnum.Avantaj) {
      company = state.avantajSelectedCompany ? `${state.avantajSelectedCompany}` : ''
    } else if (state.selectedPaymentType === PaymentTypeEnum.Firsat) {
      company = state.firsatSelectedCompany ? `${state.firsatSelectedCompany}` : ''
    } else if (state.selectedPaymentType === PaymentTypeEnum.Fcv) {
      company =
        state.selectedInvoices && state.selectedInvoices[0] && state.selectedInvoices[0].Company
          ? `${state.selectedInvoices[0].Company}`
          : ''
    }
    if (company.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Şirket seçiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return false
    }
    sessionStorage.setItem('selectedCompany', String(company))

    if (state.ccNumber.replaceAll('_', '').replaceAll(' ', '').length < 15) {
      Swal.fire({
        icon: 'error',
        title: 'Kart numaranızı eksiksiz giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return false
    }
    props.showLoading()
    const api: GetIsAuthorizedToCompanyApi = new GetIsAuthorizedToCompanyApi({})
    await api
      .getIsAuthorizedToCompany(company)
      .then((res: any) => {
        props.hideLoading()
        if (res) {
          if (title === 'Onay') paymentControl()
          if (title === 'Ödeme') completePayment()
          if (title === 'Taksit') {
            if (companyId && paymentType && binNumber && amount)
              getInstallmentSpecial(companyId, paymentType, binNumber, amount)
          }
        }
        return res
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
        return false
      })
  }

  useEffect(() => {
    if (isNaN(Number(state.amount)) || Number(state.amount) < 1) {
      setState({ installmentSpecials: undefined, selectedInstallment: undefined })
      return
    }

    if (!(state.binNumber && state.binNumber.length >= 8)) {
      setState({ installmentSpecials: undefined, selectedInstallment: undefined })
      return
    }

    let company: string = ''

    if (state.selectedPaymentType === PaymentTypeEnum.CariOdeme) {
      company = state.cariSelectedCompany ? `${state.cariSelectedCompany}` : ''
    } else if (state.selectedPaymentType === PaymentTypeEnum.Avantaj) {
      company = state.avantajSelectedCompany ? `${state.avantajSelectedCompany}` : ''
    } else if (state.selectedPaymentType === PaymentTypeEnum.Firsat) {
      company = state.firsatSelectedCompany ? `${state.firsatSelectedCompany}` : ''
    } else if (state.selectedPaymentType === PaymentTypeEnum.Fcv) {
      company =
        state.selectedInvoices && state.selectedInvoices[0] && state.selectedInvoices[0].Company
          ? `${state.selectedInvoices[0].Company}`
          : ''
    } else {
      setState({ installmentSpecials: undefined, selectedInstallment: undefined })
      return
    }

    if (!company) {
      setState({ installmentSpecials: undefined, selectedInstallment: undefined })
      return
    }
    getInstallmentSpecial(company, state.selectedPaymentType, state.binNumber.slice(0, 8), Number(state.amount))
  }, [
    state.amount,
    state.binNumber,
    state.selectedPaymentType,
    state.cariSelectedCompany,
    state.avantajSelectedCompany,
    state.firsatSelectedCompany,
    state.isIncluded,
  ])

  useEffect(() => {
    getAllPaymentData()
    getExchangeRates()
    // getUserCompany()
  }, [])

  //#region Modal'i session boyunca kapatan kod
  // // Function to set session storage item
  // const setSessionStorage = (key, value) => {
  //   sessionStorage.setItem(key, JSON.stringify(value))
  // }

  // // Function to get session storage item
  // const getSessionStorage = key => {
  //   const item = sessionStorage.getItem(key)
  //   return item ? JSON.parse(item) : null
  // }

  // // Check if modal has been closed in this session
  // const isModalClosed = getSessionStorage('modalClosed')

  // const handleCloseModal = () => {
  //   setState({ showModalPopup: false })
  //   setSessionStorage('modalClosed', true)
  // }

  // useEffect(() => {
  //   if (state.allPaymentData && state.allPaymentData.IsIncludedList && !isModalClosed) {
  //     const showModalForBanner = location => {
  //       if (state.modalPopup && state.banner.find(ban => ban.Location === location)) {
  //         const banner = state.banner.filter(ban => ban.Location === location)
  //         setState({ showModalPopup: true, modalBanner: banner[banner.length - 1], modalPopup: false })
  //       }
  //     }

  //     switch (state.selectedPaymentType) {
  //       case PaymentTypeEnum.CariOdeme:
  //         if (state.allPaymentData.IsIncludedList.indexOf(`${state.cariSelectedCompany}`) === -1) {
  //           setState({ isIncluded: 2 })
  //         }
  //         showModalForBanner(12)
  //         break
  //       case PaymentTypeEnum.Avantaj:
  //         if (state.allPaymentData.IsIncludedList.indexOf(`${state.avantajSelectedCompany}`) === -1) {
  //           setState({ isIncluded: 2 })
  //         }
  //         showModalForBanner(13)
  //         break
  //       case PaymentTypeEnum.Firsat:
  //         if (state.allPaymentData.IsIncludedList.indexOf(`${state.firsatSelectedCompany}`) === -1) {
  //           setState({ isIncluded: 2 })
  //         }
  //         showModalForBanner(14)
  //         break
  //       case PaymentTypeEnum.Fcv:
  //         showModalForBanner(15)
  //         break
  //       default:
  //         break
  //     }
  //   }
  // }, [
  //   state.selectedPaymentType,
  //   state.cariSelectedCompany,
  //   state.avantajSelectedCompany,
  //   state.firsatSelectedCompany,
  //   state.banner,
  //   isModalClosed,
  //   state.modalPopup,
  //   state.allPaymentData,
  // ])
  //#endregion

  useEffect(() => {
    if (state.allPaymentData && state.allPaymentData.IsIncludedList) {
      switch (state.selectedPaymentType) {
        case PaymentTypeEnum.CariOdeme:
          if (state.allPaymentData.IsIncludedList.indexOf(`${state.cariSelectedCompany}`) === -1) {
            setState({ isIncluded: 2 })
          }
          if (state.modalPopup) {
            if (
              state.banner.find(ban => {
                return ban.Location === 12
              })
            ) {
              const banner = state.banner.filter(ban => {
                return ban.Location === 12
              })
              setState({ showModalPopup: true, modalBanner: banner[banner.length - 1], modalPopup: false })
            }
          }
          break
        case PaymentTypeEnum.Avantaj:
          if (state.allPaymentData.IsIncludedList.indexOf(`${state.avantajSelectedCompany}`) === -1) {
            setState({ isIncluded: 2 })
          }
          if (state.modalPopup) {
            if (
              state.banner.find(ban => {
                return ban.Location === 13
              })
            ) {
              const banner = state.banner.filter(ban => {
                return ban.Location === 13
              })
              setState({ showModalPopup: true, modalBanner: banner[banner.length - 1], modalPopup: false })
            }
          }
          break
        case PaymentTypeEnum.Firsat:
          if (state.allPaymentData.IsIncludedList.indexOf(`${state.firsatSelectedCompany}`) === -1) {
            setState({ isIncluded: 2 })
          }
          if (state.modalPopup) {
            if (
              state.banner.find(ban => {
                return ban.Location === 14
              })
            ) {
              const banner = state.banner.filter(ban => {
                return ban.Location === 14
              })
              setState({ showModalPopup: true, modalBanner: banner[banner.length - 1], modalPopup: false })
            }
          }
          break
        case PaymentTypeEnum.Fcv:
          if (state.modalPopup) {
            if (
              state.banner.find(ban => {
                return ban.Location === 15
              })
            ) {
              const banner = state.banner.filter(ban => {
                return ban.Location === 15
              })
              setState({ showModalPopup: true, modalBanner: banner[banner.length - 1], modalPopup: false })
            }
          }
          break
        default:
          break
      }
    }
  }, [
    state.selectedPaymentType,
    state.cariSelectedCompany,
    state.avantajSelectedCompany,
    state.firsatSelectedCompany,
    state.banner,
  ])

  useEffect(() => {
    let limit: IDealerLimit | undefined = undefined

    setState({ currentMethodLimit: undefined, amount: undefined })

    switch (state.selectedPaymentType) {
      case PaymentTypeEnum.CariOdeme:
        break
      case PaymentTypeEnum.Avantaj:
        limit =
          state.allPaymentData && state.allPaymentData.DealerLimits
            ? state.allPaymentData.DealerLimits.find((limit: IDealerLimit) => limit.Type === PaymentTypeEnum.Avantaj)
            : undefined

        if (limit && limit.List && limit.List.length > 0) {
          const currentLimit = limit.List.find(
            (limitItem: ILimit) =>
              Number(limitItem.CompanyCode) === Number(state.avantajSelectedCompany) &&
              limitItem.PaymentTermsName === state.selectedAdvantage?.label
          )

          if (currentLimit) {
            setState({ currentMethodLimit: currentLimit.RemainingTotal })
          }
        }

        break
      case PaymentTypeEnum.Firsat:
        limit =
          state.allPaymentData && state.allPaymentData.DealerLimits
            ? state.allPaymentData.DealerLimits.find((limit: IDealerLimit) => limit.Type === PaymentTypeEnum.Firsat)
            : undefined

        if (limit && limit.List && limit.List.length > 0) {
          const currentLimit = limit.List.find(
            (limitItem: ILimit) =>
              Number(limitItem.CompanyCode) === Number(state.firsatSelectedCompany) &&
              limitItem.PaymentTermsName === state.selectedOpportunity?.label
          )

          if (currentLimit) {
            setState({ currentMethodLimit: currentLimit.RemainingTotal })
          }
        }

        break
      case PaymentTypeEnum.Fcv:
        setState({ amount: state.invoicesTotalAmount })

        limit =
          state.allPaymentData && state.allPaymentData.DealerLimits
            ? state.allPaymentData.DealerLimits.find((limit: IDealerLimit) => limit.Type === PaymentTypeEnum.Fcv)
            : undefined

        if (limit && limit.List && limit.List.length > 0) {
          const currentLimit = limit.List.find(
            (limitItem: ILimit) =>
              state.selectedInvoices[0] &&
              Number(limitItem.CompanyCode) === Number(state.selectedInvoices[0].Company) &&
              limitItem.PaymentTermsName === state.selectedFcv?.label
          )

          if (currentLimit) {
            setState({ currentMethodLimit: currentLimit.RemainingTotal })
          }

          if (!currentLimit) {
            setState({
              currentMethodLimit: limit.List.find(x => x.PaymentTermsId === state.paymentTermsId)?.RemainingTotal,
            })
          }
        }

        break
      default:
        break
    }
  }, [
    state.selectedPaymentType,
    state.avantajSelectedCompany,
    state.firsatSelectedCompany,
    state.selectedInvoices,
    state.selectedOpportunity,
    state.selectedAdvantage,
    state.selectedFcv,
  ])

  useEffect(() => {
    const total = numeral(
      state.selectedInvoicesCollectionAmounts.reduce(
        (previousValue: number, currentValue: IInvoiceCollectionAmount) =>
          numeral(previousValue ?? 0)
            .add(currentValue.collectionAmount ?? 0)
            .value()!,
        0
      )
    )
      .add(
        state.selectedInvoices
          .filter(
            (invItem: IInvoice) =>
              state.selectedInvoicesCollectionAmounts
                .map((elem: IInvoiceCollectionAmount) => elem.faturaID)
                .indexOf(invItem.FaturaID) === -1
          )
          .reduce(
            (previousValue2: number, currentValue2: IInvoice) =>
              numeral(previousValue2 ?? 0)
                .add(
                  numeral(currentValue2.ToplamTry ?? 0)
                    .subtract(currentValue2.OdenmisMiktar ?? 0)
                    .value()!
                )
                .value()!,
            0
          )
      )
      .value()!

    const companyLimit =
      state.fcvLimit && state.fcvLimit.List
        ? state.fcvLimit.List.find(
          (limitElem: ILimit) =>
            state.selectedInvoices[0] &&
            Number(limitElem.CompanyCode) === Number(state.selectedInvoices[0].Company) &&
            limitElem.PaymentTermsId === state.selectedInvoices[0].FCVID
        )
        : undefined

    if (companyLimit) {
      if (total < companyLimit.RemainingTotal) {
        setState({ invoicesTotalAmount: total, amount: total })
      } else {
        setState({ invoicesTotalAmount: companyLimit.RemainingTotal, amount: companyLimit.RemainingTotal })
      }
    } else {
      setState({ invoicesTotalAmount: undefined, amount: undefined })
    }
  }, [state.selectedInvoicesCollectionAmounts, state.selectedInvoices])

  const paymentRef = useRef<any>()

  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedCompany, setSelectedCompany] = useState<string>()

  const transId = searchParams.get('transId')
  const select = sessionStorage.getItem('selectedPaymentType')

  useEffect(() => {
    const threeDCompany = sessionStorage.getItem('selectedCompany')
    if (threeDCompany) {
      setSelectedCompany(threeDCompany)
    }
  }, [getIsAuthorizedToCompany])

  const clearSelectedCompany = () => {
    sessionStorage.removeItem('selectedCompany')
  }

  const paymentThreedsResponse = async () => {
    if (transId) {
      const service: PaymentService = new PaymentService({})
      try {
        if (props.user.IsRemote && props.user.IsAdmin && select && +select === PaymentTypeEnum.CariOdeme) {
          const res: any = await service.manuelCreditCardPaymentThreedsResponse({ transId })
          if (res) {
            setState({ showPaymentDetails: true, paymentDetail: res, isMarked: false })
            searchParams.delete('transId')
            setSearchParams(searchParams)
          }
          return
        }
        const res: any = await service.paymentThreedsResponse({ transId })

        if (res) {
          setState({ showPaymentDetails: true, paymentDetail: res, isMarked: false })
          searchParams.delete('transId')
          setSearchParams(searchParams)
        }
      } catch (err: any) {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then(() => {
          searchParams.delete('status')
          searchParams.delete('info')
          searchParams.delete('transId')
          setSearchParams(searchParams)
        })
      }
    }
  }

  useEffect(() => {
    paymentThreedsResponse()
  }, [])

  function reverseString(str) {
    if (str === null) return null
    if (str.length === 1) return str
    const splitString = str.split('')
    const reverseArray = splitString.reverse()
    const joinArray = reverseArray.join('')
    return joinArray
  }

  const completePayment = async () => {
    //#region warnings
    if (!state.isMarked) {
      Swal.fire('Hata', 'Önce ödemenizi onaylamalısınız!', 'error')
      return
    }

    if (!state.amount) {
      Swal.fire({
        // position: 'top-end',
        icon: 'warning',
        title: 'Lütfen Tutar Giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }

    if (!state.ccNumber) {
      Swal.fire({
        // position: 'top-end',
        icon: 'warning',
        title: 'Lütfen Kredi Kartı Numarası Giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }

    if (state.ccNumber.length > 19) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Geçerli kart numarasını giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
        //timer: 1500
      })

      return
    }

    if (!state.ccName) {
      Swal.fire({
        // position: 'top-end',
        icon: 'warning',
        title: 'Lütfen Kredi Kartı Sahibini Giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }

    if (!state.ccName.includes(' ')) {
      Swal.fire({
        // position: 'top-end',
        icon: 'warning',
        title: 'Lütfen Kredi Kartı Sahibi İçin Soyad Bilgisini Giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }

    if (!state.ccExpiry) {
      Swal.fire({
        // position: 'top-end',
        icon: 'warning',
        title: 'Lütfen Kredi Kartı Geçerlilik Tarihini Giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }

    if (!state.ccCVC) {
      Swal.fire({
        // position: 'top-end',
        icon: 'warning',
        title: 'Lütfen Kredi Kartı CVC No Giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }

    if (!state.selectedInstallment) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Taksit bilgisi bulunamadı!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }

    if (!state.selectedCurrency) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Hesabınıza geçecek para birimini seçiniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }
    //#endregion

    let company: string = ''

    if (state.selectedPaymentType === PaymentTypeEnum.CariOdeme) {
      company = state.cariSelectedCompany ? `${state.cariSelectedCompany}` : ''
    } else if (state.selectedPaymentType === PaymentTypeEnum.Avantaj) {
      company = state.avantajSelectedCompany ? `${state.avantajSelectedCompany}` : ''
    } else if (state.selectedPaymentType === PaymentTypeEnum.Firsat) {
      company = state.firsatSelectedCompany ? `${state.firsatSelectedCompany}` : ''
    } else if (state.selectedPaymentType === PaymentTypeEnum.Fcv) {
      company =
        state.selectedInvoices && state.selectedInvoices[0] && state.selectedInvoices[0].Company
          ? `${state.selectedInvoices[0].Company}`
          : ''
    } else {
      setState({ installmentSpecials: undefined, selectedInstallment: undefined })
      return
    }

    if (!company) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Seçili şirket bulunamadı!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }
    if (
      !state.securePayment &&
      (state.selectedInstallment.BankId === '2901' || state.selectedInstallment.BankId === '22901')
    ) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Ziraat bankası ile ödeme yapabilmek için 3D seçmelisiniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }

    const service: PaymentService = new PaymentService({})

    const req: PaymentRequestMySpecial = {
      CardNumber: `${state.ccNumber}`.replaceAll(' ', ''),
      HolderName: state.ccName,
      ExpireDate: `${state.ccExpiry}`,
      Cvc: `${state.ccCVC}`,
      Installment:
        state.selectedPaymentType === PaymentTypeEnum.CariOdeme &&
          !props.user.IsRemote &&
          state.selectedInstallment.ParamPosDetail
          ? state.selectedInstallment.ParamPosDetail.Installment
          : state.selectedInstallment.Installment,
      Amount:
        state.selectedPaymentType === PaymentTypeEnum.CariOdeme &&
          !props.user.IsRemote &&
          state.selectedInstallment.ParamPosDetail
          ? state.selectedInstallment.ParamPosDetail.TotalMain
          : state.selectedInstallment.TotalMain,
      ComissionAmount:
        state.selectedPaymentType === PaymentTypeEnum.CariOdeme &&
          !props.user.IsRemote &&
          state.selectedInstallment.ParamPosDetail
          ? state.selectedInstallment.ParamPosDetail.TotalAmount
          : state.selectedInstallment.TotalAmount,
      Company: Number(company),
      InterestRate:
        state.selectedPaymentType === PaymentTypeEnum.CariOdeme &&
          !props.user.IsRemote &&
          state.selectedInstallment.ParamPosDetail
          ? state.selectedInstallment.ParamPosDetail.InterestRate
          : state.selectedInstallment.InterestRate,
      BankId: state.selectedInstallment.BankId,
      Currency: `${state.selectedCurrency.value}`,
      IsIncluding: state.isIncluded === 1 ? true : false,
      isMT: props.user.IsRemote,
      DealerLimitId:
        state.selectedPaymentType === PaymentTypeEnum.Avantaj
          ? Number(state.selectedAdvantage?.value)
          : state.selectedPaymentType === PaymentTypeEnum.Firsat
            ? Number(state.selectedOpportunity?.value)
            : 0,
    }

    if (!checkPaymentRequirements(state.selectedPaymentType)) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
    }

    Swal.fire({
      // position: 'top-end',
      icon: 'question',
      title: `${state.selectedCurrency.value === 'TL'
        ? state.selectedPaymentType === PaymentTypeEnum.CariOdeme &&
          !props.user.IsRemote &&
          state.selectedInstallment.ParamPosDetail
          ? state.selectedInstallment.ParamPosDetail.InfoValueTL
          : state.selectedInstallment.InfoValueTL
        : state.selectedCurrency.value === 'USD'
          ? state.selectedPaymentType === PaymentTypeEnum.CariOdeme &&
            !props.user.IsRemote &&
            state.selectedInstallment.ParamPosDetail
            ? state.selectedInstallment.ParamPosDetail.InfoValueDolar
            : state.selectedInstallment.InfoValueDolar
          : state.selectedPaymentType === PaymentTypeEnum.CariOdeme &&
            !props.user.IsRemote &&
            state.selectedInstallment.ParamPosDetail
            ? state.selectedInstallment.ParamPosDetail.InfoValueEuro
            : state.selectedInstallment.InfoValueEuro
        } tutarında ödeme yapılacaktır. Devam etmek istiyor musunuz?`,
      showDenyButton: true,
      confirmButtonText: 'Devam',
      denyButtonText: `Vazgeç`,
      allowOutsideClick: false,
    }).then(async result => {
      if (result.isConfirmed) {
        if (state.selectedPaymentType === PaymentTypeEnum.CariOdeme) {
          props.showLoading()

          const securePayment = async () => {
            try {
              if (props.user.IsRemote) {
                const request: IManualCreditCardPayment = {
                  HolderName: req.HolderName,
                  CardNumber: req.CardNumber,
                  ExpireDate: req.ExpireDate,
                  Cvc: req.Cvc,
                  Installment: req.Installment,
                  Amount: req.Amount,
                  Company: req.Company,
                  DealerCode: props.user.AccountCodeRemote,
                  InterestRate: req.InterestRate,
                  BankID: req.BankId,
                  Currency: req.Currency,
                  ComissionType: state.isIncluded,
                  ComissionAmount: req.ComissionAmount,
                }
                const res: any = await service.paymentThreeds(request)
                if (res && res.statu === 0) {
                  Swal.fire({
                    icon: 'error',
                    title: res.message,
                    showConfirmButton: true,
                    allowOutsideClick: false,
                  })
                  return
                }
                if (res && res.statu === 3) {
                  // if (req.BankId === '20501') res.message = res.message.split('</html>')[1].replaceAll('"') + '</html>'
                  const tmpHtml = document.createElement('html')
                  tmpHtml.innerHTML = res.message
                  let form = tmpHtml.querySelector('form')!.outerHTML
                  if (
                    (req.BankId === '101' || req.BankId === '20101') &&
                    !form.includes('https://goguvenliodeme.bkm.com.tr/troy/approve') &&
                    tmpHtml.querySelector('#webform0') != null
                  ) {
                    form = tmpHtml.querySelector('#webform0')!.outerHTML
                  }
                  if (paymentRef.current) {
                    paymentRef.current.innerHTML = form
                    if (form.includes('https://goguvenliodeme.bkm.com.tr/troy/approve')) {
                      paymentRef.current.querySelector('form').action = 'https://goguvenliodeme.bkm.com.tr/troy/approve'
                    }

                    paymentRef.current.querySelector('form')!.submit()
                  }
                }
                return
              }

              const res: any = await service.cariOdemeThreeds(req)
              if (res && res.statu === 0) {
                Swal.fire({
                  icon: 'error',
                  title: res.message,
                  showConfirmButton: true,
                  allowOutsideClick: false,
                })
                return
              }
              if (res && res.statu === 3) {
                // if (req.BankId === '20501') res.message = res.message.split('</html>')[1].replaceAll('"') + '</html>'
                const tmpHtml = document.createElement('html')
                tmpHtml.innerHTML = res.message
                let form = tmpHtml.querySelector('form')!.outerHTML
                if ((req.BankId === '101' || req.BankId === '20101') &&
                  !form.includes('https://goguvenliodeme.bkm.com.tr/troy/approve') &&
                  tmpHtml.querySelector('#webform0') != null
                ) {
                  form = tmpHtml.querySelector('#webform0')!.outerHTML
                }
                if (paymentRef.current) {
                  paymentRef.current.innerHTML = form
                  if (form.includes('https://goguvenliodeme.bkm.com.tr/troy/approve')) {
                    paymentRef.current.querySelector('form').action = 'https://goguvenliodeme.bkm.com.tr/troy/approve'
                  }
                  paymentRef.current.querySelector('form')!.submit()
                }
              }
            } catch (err: any) {
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              props.hideLoading()
            }
          }

          if (state.securePayment) {
            securePayment()
            return
          }
          if (props.user.IsRemote && props.user.IsAdmin) {
            const reqManuel: IManualCreditCardPayment = {
              HolderName: req.HolderName,
              CardNumber: req.CardNumber,
              ExpireDate: req.ExpireDate,
              Cvc: req.Cvc,
              Installment: req.Installment,
              Amount: req.Amount,
              Company: req.Company,
              DealerCode: props.user.AccountCodeRemote,
              InterestRate: req.InterestRate,
              BankID: req.BankId,
              Currency: req.Currency,
              ComissionType: state.isIncluded,
              ComissionAmount: req.ComissionAmount,
            }
            const getManualCreditCardPaymentApi: GetManualCreditCardPaymentApi = new GetManualCreditCardPaymentApi({})
            await getManualCreditCardPaymentApi
              .getManualCreditCardPayment(reqManuel)
              .then(async result => {
                if (result) {
                  setState({ showPaymentDetails: true, paymentDetail: result, isMarked: false })
                } else return Swal.fire('Hata', 'Başarısız', 'error')
                props.hideLoading()
              })
              .catch((err: any) => {
                Swal.fire({
                  icon: 'error',
                  title: err.description,
                  showConfirmButton: true,
                  allowOutsideClick: false,
                })
                props.hideLoading()
              })
          } else {
            service
              .cariOdeme(req)
              .then((res: any) => {
                if (res) setState({ showPaymentDetails: true, paymentDetail: res, isMarked: false })
              })
              .catch((err: IException) => {
                Swal.fire({
                  // position: 'top-end',
                  icon: 'error',
                  title: err.description,
                  showConfirmButton: true,
                  allowOutsideClick: false,
                  // timer: 1500
                })
              })
              .finally(() => {
                props.hideLoading()
              })
          }
        } else if (state.selectedPaymentType === PaymentTypeEnum.Avantaj) {
          props.showLoading()

          const securePayment = async () => {
            try {
              const res: any = await service.avantajOdemeThreeds(req)
              if (res && res.statu === 0) {
                Swal.fire({
                  icon: 'error',
                  title: res.message,
                  showConfirmButton: true,
                  allowOutsideClick: false,
                })
                return
              }
              if (res && res.statu === 3) {
                // if (req.BankId === '20501') res.message = res.message.split('</html>')[1].replaceAll('"') + '</html>'
                const tmpHtml = document.createElement('html')
                tmpHtml.innerHTML = res.message
                let form = tmpHtml.querySelector('form')!.outerHTML
                if ((req.BankId === '101' || req.BankId === '20101') &&
                  !form.includes('https://goguvenliodeme.bkm.com.tr/troy/approve') &&
                  tmpHtml.querySelector('#webform0') != null
                ) {
                  form = tmpHtml.querySelector('#webform0')!.outerHTML
                }
                if (paymentRef.current) {
                  paymentRef.current.innerHTML = form
                  if (form.includes('https://goguvenliodeme.bkm.com.tr/troy/approve')) {
                    paymentRef.current.querySelector('form').action = 'https://goguvenliodeme.bkm.com.tr/troy/approve'
                  }
                  paymentRef.current.querySelector('form')!.submit()
                }
              }
            } catch (err: any) {
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              props.hideLoading()
            }
          }

          if (state.securePayment) {
            securePayment()
            return
          }

          service
            .avantajOdeme(req)
            .then((res: any) => {
              if (res) setState({ showPaymentDetails: true, paymentDetail: res, isMarked: false })
            })
            .catch((err: IException) => {
              Swal.fire({
                // position: 'top-end',
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
                // timer: 1500
              })
            })
            .finally(() => {
              props.hideLoading()
            })
        } else if (state.selectedPaymentType === PaymentTypeEnum.Firsat) {
          props.showLoading()

          const securePayment = async () => {
            try {
              const res: any = await service.firsatOdemeThreeds(req)
              if (res && res.statu === 0) {
                Swal.fire({
                  icon: 'error',
                  title: res.message,
                  showConfirmButton: true,
                  allowOutsideClick: false,
                })
                return
              }
              if (res && res.statu === 3) {
                // if (req.BankId === '20501') res.message = res.message.split('</html>')[1].replaceAll('"') + '</html>'
                const tmpHtml = document.createElement('html')
                tmpHtml.innerHTML = res.message
                let form = tmpHtml.querySelector('form')!.outerHTML
                if ((req.BankId === '101' || req.BankId === '20101') &&
                  !form.includes('https://goguvenliodeme.bkm.com.tr/troy/approve') &&
                  tmpHtml.querySelector('#webform0') != null
                ) {
                  form = tmpHtml.querySelector('#webform0')!.outerHTML
                }
                if (paymentRef.current) {
                  paymentRef.current.innerHTML = form
                  if (form.includes('https://goguvenliodeme.bkm.com.tr/troy/approve')) {
                    paymentRef.current.querySelector('form').action = 'https://goguvenliodeme.bkm.com.tr/troy/approve'
                  }
                  paymentRef.current.querySelector('form')!.submit()
                }
              }
            } catch (err: any) {
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              props.hideLoading()
            }
          }

          if (state.securePayment) {
            securePayment()
            return
          }
          service
            .firsatOdeme(req)
            .then((res: any) => {
              if (res) setState({ showPaymentDetails: true, paymentDetail: res, isMarked: false })
            })
            .catch((err: IException) => {
              Swal.fire({
                // position: 'top-end',
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
                // timer: 1500
              })
            })
            .finally(() => {
              props.hideLoading()
            })
        } else if (state.selectedPaymentType === PaymentTypeEnum.Fcv) {
          props.showLoading()

          const securePayment = async () => {
            try {
              const res: any = await service.fcvOdemeThreeds({
                ...req,
                FcvId: state.selectedInvoices[0].FCVID,
                Faturalar: getInvoicesForPayment(),
              })
              if (res && res.statu === 0) {
                Swal.fire({
                  icon: 'error',
                  title: res.message,
                  showConfirmButton: true,
                  allowOutsideClick: false,
                })
                return
              }
              if (res && res.statu === 3) {
                // if (req.BankId === '20501') res.message = res.message.split('</html>')[1].replaceAll('"') + '</html>'
                const tmpHtml = document.createElement('html')
                tmpHtml.innerHTML = res.message
                let form = tmpHtml.querySelector('form')!.outerHTML
                if ((req.BankId === '101' || req.BankId === '20101') &&
                  !form.includes('https://goguvenliodeme.bkm.com.tr/troy/approve') &&
                  tmpHtml.querySelector('#webform0') != null
                ) {
                  form = tmpHtml.querySelector('#webform0')!.outerHTML
                }
                if (paymentRef.current) {
                  paymentRef.current.innerHTML = form
                  if (form.includes('https://goguvenliodeme.bkm.com.tr/troy/approve')) {
                    paymentRef.current.querySelector('form').action = 'https://goguvenliodeme.bkm.com.tr/troy/approve'
                  }
                  paymentRef.current.querySelector('form')!.submit()
                }
              }
            } catch (err: any) {
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            }
          }

          if (state.securePayment) {
            securePayment()
            return
          }

          service
            .fcvOdeme({
              ...req,
              FcvId: state.selectedInvoices[0].FCVID,
              Faturalar: getInvoicesForPayment(),
            })
            .then((res: any) => {
              if (res) setState({ showPaymentDetails: true, paymentDetail: res, isMarked: false })
            })
            .catch((err: IException) => {
              Swal.fire({
                // position: 'top-end',
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
                // timer: 1500
              })
              props.hideLoading()
            })
            .finally(() => {
              props.hideLoading()
            })
        }
      }
    })
  }

  const getExchangeRates = () => {
    const api: GetExchangeRatesApi = new GetExchangeRatesApi({})
    api.getExhangeRates().then((res: IExchangeRate) => {
      setState({ exchangeRate: res })
    })
  }
  // const getUserCompany = () => {
  //   const user = props.user.RemotableCompanies.split(",")
  //   setState({ companyData: user })
  // }

  const handleQuantity = (e: any, invoice: any) => {
    onCollectionAmountChange(invoice, Number(e.target.value.replace(',', '.')))
  }

  const handleQuantityOnBlur = (e: any, invoice) => {
    if (e.target.value?.length < 1 || isNaN(Number(e.target.value))) {
      onCollectionAmountChange(invoice, Number(1))
    } else {
      onCollectionAmountChange(invoice, Number(e.target.value.replace(',', '.')))
    }
  }

  const checkPaymentRequirements = (paymentType: PaymentTypeEnum): boolean => {
    let companyLimit: ILimit | undefined = undefined

    switch (paymentType) {
      case PaymentTypeEnum.CariOdeme:
        return true
      case PaymentTypeEnum.Avantaj:
        companyLimit =
          state.avantajLimit && state.avantajLimit.List
            ? state.avantajLimit.List.find(
              (limitElem: ILimit) => Number(limitElem.CompanyCode) === Number(state.avantajSelectedCompany)
            )
            : undefined

        if (companyLimit) {
          if (+state.amount <= numeral(companyLimit.Limit).subtract(companyLimit.RemainingTotal).value()!) {
            return true
          }
        }

        break
      case PaymentTypeEnum.Firsat:
        companyLimit =
          state.firsatLimit && state.firsatLimit.List
            ? state.firsatLimit.List.find(
              (limitElem: ILimit) => Number(limitElem.CompanyCode) === Number(state.firsatSelectedCompany)
            )
            : undefined

        if (companyLimit) {
          if (+state.amount <= numeral(companyLimit.Limit).subtract(companyLimit.RemainingTotal).value()!) {
            return true
          }
        }

        break
      case PaymentTypeEnum.Fcv:
        companyLimit =
          state.fcvLimit && state.fcvLimit.List
            ? state.fcvLimit.List.find(
              (limitElem: ILimit) =>
                state.selectedInvoices[0] &&
                Number(limitElem.CompanyCode) === Number(state.selectedInvoices[0].Company)
            )
            : undefined

        if (companyLimit) {
          if (+state.amount <= numeral(companyLimit.Limit).subtract(companyLimit.RemainingTotal).value()!) {
            return true
          }
        }

        break
      default:
        break
    }

    return false
  }

  const getInvoicesForPayment = (): IPaymentInvoice[] => {
    const result: IPaymentInvoice[] = []

    for (const invoiceItem of state.selectedInvoices) {
      const tmpAmountChangedInvoice = state.selectedInvoicesCollectionAmounts.find(
        (elem: IInvoiceCollectionAmount) => elem.faturaID === invoiceItem.FaturaID
      )

      result.push({
        FatuaNo: invoiceItem.FaturaNo,
        Tutar: tmpAmountChangedInvoice
          ? tmpAmountChangedInvoice.collectionAmount
          : invoiceItem.ToplamTry - invoiceItem.OdenmisMiktar,
        IsClosed: tmpAmountChangedInvoice ? false : true,
      })
    }

    return result
  }

  const getInstallmentSpecial = (
    companyId: string,
    paymentType: PaymentTypeEnum,
    binNumber: string,
    amount: number
  ) => {
    if (props.user.IsRemote && state.selectedPaymentType === PaymentTypeEnum.CariOdeme) {
      const req: IInstallmentInquiry = {
        Company: companyId,
        DealerCode: props.user.AccountCodeRemote,
        Amount: amount,
        BinNumber: binNumber,
        ComissionType: state.isIncluded,
      }
      const api: InstallmentInquiryApi = new InstallmentInquiryApi({})

      api
        .installmentInquiry(req)
        .then((res: IGetInstallmentSpecial[]) => {
          if (res) {
            setState({ installmentSpecials: undefined, selectedInstallment: undefined })
            setState({ installmentSpecials: res.sort((a, b) => Number(a.Installment) - Number(b.Installment)) })
          }
        })
        .catch(e => {
          var description = e.description
          Swal.fire({
            icon: 'warning',
            title: description,
            showConfirmButton: true,
            allowOutsideClick: false,
            // timer: 1500
          })
          setState({ installmentSpecials: undefined })
        })
      return
    }
    const api: PaymentService = new PaymentService({})
    const req = {
      Company: companyId,
      Type: paymentType,
      BinNumber: binNumber.trim(),
      Amount: amount,
      IsIncluding: state.isIncluded === 1 ? true : false,
      PaymentTermsId: state.paymentTermsId,
    }

    api
      .getInstallmentSpecial(req)
      .then((res: IGetInstallmentSpecial[]) => {
        if (res) {
          setState({
            installmentSpecials: res.sort((a, b) => Number(a.Installment) - Number(b.Installment)),
            paramposBool:
              res.findIndex(r => {
                return r.ParamPosDetail
              }) > -1,
          })
        }
      })
      .catch(e => {
        var description = e.description
        Swal.fire({
          icon: 'warning',
          title: description,
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
        setState({ installmentSpecials: undefined })
      })
  }

  const onChangeBIN = (binNumber: string) => {
    setState({ binNumber })
  }

  const findBankInfos = async (req: binRequest) => {
    try {
      const findBankInfosService = new FindBankInfosApi({})

      const res: IBankInfos = await findBankInfosService.findBankInfos(req)
      if (res) {
        state.bankCode = res.BankCode
      }
      bankImageUrl()
    } catch (error) {
      setState({ bankCode: undefined, bankLogo: '' })
    }
  }

  enum bankNames {
    akbank = '46',
    halkbank = '12',
    denizbank = '134',
    Akbank = '46',
    AlbarakaTurk = '203',
    AlternatifBank = '124',
    Anadolubank = '135',
    Denizbank = '134',
    Fibabanka = '103',
    QNBFinansbank = '111',
    GarantiBBVA = '62',
    Halkbank = '12',
    HSBC = '123',
    INGBank = '99',
    IsBankasi = '64',
    KuveytTurk = '205',
    Odeabank = '146',
    TurkEkonomiBankasi = '32',
    TurkiyeFinans = '206',
    Vakifbank = '15',
    YapiKrediBankasi = '67',
    Sekerbank = '59',
    ZiraatBankasi = '10',
    AktifYatirimBankasi = '143',
  }

  const bankImageUrl = () => {
    const bankCodeString = state.bankCode.toString()
    switch (bankCodeString) {
      case bankNames.akbank:
        setState({ bankLogo: '/images/akbank.png' })
        break
      case bankNames.halkbank:
        setState({ bankLogo: '/images/halkbank.png' })
        break
      case bankNames.denizbank:
        setState({ bankLogo: '/images/denizBank.png' })
        break
      case bankNames.Akbank:
        setState({ bankLogo: '/images/akbank.png' })
        break
      case bankNames.AktifYatirimBankasi:
        setState({ bankLogo: '/images/product-detail/aktifBank.svg' })
        break
      case bankNames.AlbarakaTurk:
        setState({ bankLogo: '/images/product-detail/alBaraka.svg' })
        break
      case bankNames.AlternatifBank:
        setState({
          bankLogo: '/images/product-detail/alternatifBank.svg',
        })
        break
      case bankNames.Anadolubank:
        setState({ bankLogo: '/images/product-detail/anadoluBank.svg' })
        break
      case bankNames.Denizbank:
        setState({ bankLogo: '/images/denizBank.png' })
        break
      case bankNames.Fibabanka:
        setState({ bankLogo: '/images/product-detail/fibabanka.svg' })
        break
      case bankNames.GarantiBBVA:
        setState({ bankLogo: '/images/garantiBankasi.png' })
        break
      case bankNames.HSBC:
        setState({ bankLogo: '/images/product-detail/hsbcBank.svg' })
        break
      case bankNames.Halkbank:
        setState({ bankLogo: '/images/halkbank.png' })
        break
      case bankNames.INGBank:
        setState({ bankLogo: '/images/product-detail/ingBank.svg' })
        break
      case bankNames.IsBankasi:
        setState({ bankLogo: '/images/isBank.png' })
        break
      case bankNames.KuveytTurk:
        setState({ bankLogo: '/images/product-detail/kuveytTurk.svg' })
        break
      case bankNames.Odeabank:
        setState({ bankLogo: '/images/product-detail/odeabank.svg' })
        break
      case bankNames.QNBFinansbank:
        setState({ bankLogo: '/images/finansbank.png' })
        break
      case bankNames.Sekerbank:
        setState({ bankLogo: '/images/product-detail/sekerBank.svg' })
        break
      case bankNames.TurkEkonomiBankasi:
        setState({ bankLogo: '/images/product-detail/teb.svg' })
        break
      case bankNames.TurkiyeFinans:
        setState({
          bankLogo: '/images/product-detail/turkiyeFinansBank.svg',
        })
        break
      case bankNames.Vakifbank:
        setState({ bankLogo: '/images/product-detail/vakifBank.svg' })
        break
      case bankNames.YapiKrediBankasi:
        setState({ bankLogo: '/images/yapiKredi.png' })
        break
      case bankNames.ZiraatBankasi:
        setState({ bankLogo: '/images/ZiraatBankasi.png' })
        break
    }
  }

  const getCompanyName = (companyCode: string | number) => {
    return Number(companyCode) === 1000
      ? 'Index'
      : Number(companyCode) === 1100
        ? 'Datagate'
        : Number(companyCode) === 1200
          ? 'Despec'
          : 'Netex'
  }

  const getCompanyLongName = selectedCompany => {
    switch (selectedCompany) {
      case '1000':
        return 'Index Bilgisayar Sistemleri Tic. A.Ş.'
      case '1100':
        return 'DATAGATE BİLGİSAYAR MALZEMELERİ TİCARET A.Ş'
      case '1200':
        return 'Despec Bilgisayar Pazarlama Tic. A.Ş.'
      case '2300':
        return 'NETEKS TEKNOLOJİ ÜRÜNLERİ A.Ş.'
      default:
        return ''
    }
  }

  const isPaymentTabVisible = (tab: PaymentTypeEnum) => {
    switch (tab) {
      case PaymentTypeEnum.CariOdeme:
        if (
          state.allPaymentData &&
          state.allPaymentData.DealerReminders &&
          state.allPaymentData.DealerReminders.length > 0
        ) {
          return true
        }

        break
      case PaymentTypeEnum.Avantaj:
        if (state.allPaymentData && state.allPaymentData.DealerLimits) {
          const limit = state.allPaymentData.DealerLimits.find((limit: IDealerLimit) => limit.Type === tab)

          if (limit && limit.List && limit.List.length > 0) {
            return true
          }
        }

        break
      case PaymentTypeEnum.Firsat:
        if (state.allPaymentData && state.allPaymentData.DealerLimits) {
          const limit = state.allPaymentData.DealerLimits.find((limit: IDealerLimit) => limit.Type === tab)

          if (limit && limit.List && limit.List.length > 0) {
            return true
          }
        }

        break
      case PaymentTypeEnum.Fcv:
        if (
          state.allPaymentData &&
          state.allPaymentData.DealerLimits &&
          state.allPaymentData.Invoices &&
          state.allPaymentData.Invoices.length > 0
        ) {
          const limit = state.allPaymentData.DealerLimits.find((limit: IDealerLimit) => limit.Type === tab)

          if (limit && limit.List && limit.List.length > 0) {
            return true
          }
        }

        break
      default:
        break
    }

    return false
  }

  const handleInvoiceSelected = (invoice: IInvoice) => {
    const tmpInvoice = state.selectedInvoices.findIndex((val: IInvoice) => val.FaturaID === invoice.FaturaID)

    if (tmpInvoice === -1) {
      const companyLimit =
        state.fcvLimit && state.fcvLimit.List
          ? state.fcvLimit.List.find(
            (limitElem: ILimit) =>
              state.selectedInvoices[0] &&
              Number(limitElem.CompanyCode) === Number(state.selectedInvoices[0].Company) &&
              limitElem.PaymentTermsId === state.selectedInvoices[0].FCVID
          )
          : undefined

      // Checking the limit
      let exactAmount: number = 0

      if (companyLimit) {
        if (!(state.invoicesTotalAmount !== undefined && state.invoicesTotalAmount < companyLimit.RemainingTotal)) {
          return
        }

        if (
          !(
            state.invoicesTotalAmount !== undefined &&
            numeral(state.invoicesTotalAmount)
              .add(numeral(invoice.ToplamTry).subtract(invoice.OdenmisMiktar).value()!)
              .value()! < companyLimit.RemainingTotal
          )
        ) {
          exactAmount = numeral(companyLimit.RemainingTotal).subtract(state.invoicesTotalAmount).value()!
        }
      }

      setState({ selectedInvoices: [invoice, ...state.selectedInvoices] })

      const collectionAmountItem = state.selectedInvoicesCollectionAmounts.find(
        (elem: IInvoiceCollectionAmount) => elem.faturaID === invoice.FaturaID
      )

      if (!collectionAmountItem) {
        const amnt = exactAmount > 0 ? exactAmount : numeral(invoice.ToplamTry).subtract(invoice.OdenmisMiktar).value()!
        if (state.currentMethodLimit! > amnt) {
          exactAmount = amnt
        } else {
          exactAmount = state.currentMethodLimit!
        }

        setState({
          selectedInvoicesCollectionAmounts: [
            {
              faturaID: invoice.FaturaID,
              collectionAmount: exactAmount,
            },
            ...state.selectedInvoicesCollectionAmounts,
          ],
        })
      }
    } else {
      const collectionItem = state.selectedInvoicesCollectionAmounts.find(
        (elem: IInvoiceCollectionAmount) => elem.faturaID === invoice.FaturaID
      )

      if (collectionItem) {
        setState({
          selectedInvoicesCollectionAmounts: [
            ...state.selectedInvoicesCollectionAmounts.filter(
              (elem: IInvoiceCollectionAmount) => elem.faturaID !== invoice.FaturaID
            ),
          ],
        })
      }

      setState({
        selectedInvoices: state.selectedInvoices.filter((inv: IInvoice) => inv.FaturaID !== invoice.FaturaID),
      })
    }
  }

  const onCollectionAmountChange = (invoice: IInvoice, amount: number) => {
    let collectionItem = state.selectedInvoicesCollectionAmounts.find(
      (elem: IInvoiceCollectionAmount) => elem.faturaID === invoice.FaturaID
    )

    if (collectionItem) {
      if (amount > numeral(invoice.ToplamTry).subtract(invoice.OdenmisMiktar).value()!) {
        collectionItem.collectionAmount = numeral(invoice.ToplamTry).subtract(invoice.OdenmisMiktar).value()!
      } else {
        collectionItem.collectionAmount = amount
      }
    } else {
      if (amount > numeral(invoice.ToplamTry).subtract(invoice.OdenmisMiktar).value()!) {
        collectionItem = {
          faturaID: invoice.FaturaID,
          collectionAmount: numeral(invoice.ToplamTry).subtract(invoice.OdenmisMiktar).value()!,
        }
      } else {
        collectionItem = { faturaID: invoice.FaturaID, collectionAmount: amount }
      }
    }

    setState({
      selectedInvoicesCollectionAmounts: [
        collectionItem,
        ...state.selectedInvoicesCollectionAmounts.filter(
          (elem: IInvoiceCollectionAmount) => elem.faturaID !== invoice.FaturaID
        ),
      ],
    })
  }

  const onChangeAmount = (e: any) => {
    let target = e.target.value
    if (isNaN(Number(e.target.value)) && e.target.value) {
      target = 1
    }
    if (Number(e.target.value) < 0) {
      target = 1
    }
    switch (state.selectedPaymentType) {
      case PaymentTypeEnum.CariOdeme:
        setState({ amount: Number(target) })

        break
      case PaymentTypeEnum.Avantaj:
        if (state.avantajLimit && state.avantajLimit.List) {
          const companyLimit = state.avantajLimit.List.find(
            (limitElem: ILimit) =>
              Number(limitElem.CompanyCode) === Number(state.avantajSelectedCompany) &&
              limitElem.PaymentTermsName === state.selectedAdvantage?.label
          )

          if (companyLimit) {
            if (Number(target) < companyLimit.RemainingTotal) {
              setState({ amount: Number(target) })
            } else {
              setState({ amount: companyLimit.RemainingTotal })
            }
          } else {
            setState({ amount: undefined })
          }
        }

        break
      case PaymentTypeEnum.Firsat:
        if (state.firsatLimit && state.firsatLimit.List) {
          const companyLimit = state.firsatLimit.List.find(
            (limitElem: ILimit) =>
              Number(limitElem.CompanyCode) === Number(state.firsatSelectedCompany) &&
              limitElem.PaymentTermsName === state.selectedOpportunity?.label
          )

          if (companyLimit) {
            if (Number(target) < companyLimit.RemainingTotal) {
              setState({ amount: Number(target) })
            } else {
              setState({ amount: companyLimit.RemainingTotal })
            }
          } else {
            setState({ amount: undefined })
          }
        }

        break
      default:
        break
    }
  }

  const [selectedFirsatLimit, setSelectedFirsatLimit] = useState()

  useEffect(() => {
    state.firsatLimit &&
      state.selectedOpportunity &&
      setSelectedFirsatLimit(
        (state as any).firsatLimit.List.find(
          firsat => firsat.PaymentTermsName == (state.selectedOpportunity as any).label
        )
      )
  }, [state.selectedOpportunity, state.firsatLimit])

  // useEffect(() => {
  //   console.log(selectedFirsatLimit);
  // }, [selectedFirsatLimit]);

  const paymentControl = async () => {
    if (!state.amount) {
      Swal.fire({
        // position: 'top-end',
        icon: 'warning',
        title: 'Lütfen Tutar Giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }

    if (!state.ccNumber) {
      Swal.fire({
        // position: 'top-end',
        icon: 'warning',
        title: 'Lütfen Kredi Kartı Numarası Giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }

    if (state.ccNumber.length > 19) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Geçerli kart numarasını giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
        //timer: 1500
      })

      return
    }

    if (!state.ccName) {
      Swal.fire({
        // position: 'top-end',
        icon: 'warning',
        title: 'Lütfen Kredi Kartı Sahibini Giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }

    if (!state.ccName.includes(' ')) {
      Swal.fire({
        // position: 'top-end',
        icon: 'warning',
        title: 'Lütfen Kredi Kartı Sahibi İçin Soyad Bilgisini Giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }

    if (!state.ccExpiry) {
      Swal.fire({
        // position: 'top-end',
        icon: 'warning',
        title: 'Lütfen Kredi Kartı Geçerlilik Tarihini Giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }

    if (!state.ccCVC) {
      Swal.fire({
        // position: 'top-end',
        icon: 'warning',
        title: 'Lütfen Kredi Kartı CVC No Giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }

    if (!state.selectedInstallment) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Taksit bilgisi bulunamadı!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }

    if (!state.selectedCurrency) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Seçili döviz bilgisi bulunamadı!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }

    let company: string = ''

    if (state.selectedPaymentType === PaymentTypeEnum.CariOdeme) {
      company = state.cariSelectedCompany ? `${state.cariSelectedCompany}` : ''
    } else if (state.selectedPaymentType === PaymentTypeEnum.Avantaj) {
      company = state.avantajSelectedCompany ? `${state.avantajSelectedCompany}` : ''
    } else if (state.selectedPaymentType === PaymentTypeEnum.Firsat) {
      company = state.firsatSelectedCompany ? `${state.firsatSelectedCompany}` : ''
    } else if (state.selectedPaymentType === PaymentTypeEnum.Fcv) {
      company =
        state.selectedInvoices && state.selectedInvoices[0] && state.selectedInvoices[0].Company
          ? `${state.selectedInvoices[0].Company}`
          : ''
    } else {
      setState({ installmentSpecials: undefined, selectedInstallment: undefined })
      return
    }

    if (!company) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Seçili şirket bulunamadı!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }
    if (
      !state.securePayment &&
      (state.selectedInstallment.BankId === '2901' || state.selectedInstallment.BankId === '22901')
    ) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Ziraat bankası ile ödeme yapabilmek için 3D seçmelisiniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })

      return
    }
    if (state.isMarked) return
    if (props.user.IsRemote && props.user.IsAdmin && state.selectedPaymentType === PaymentTypeEnum.CariOdeme) {
      const req: IManualCreditCardPaymentControl = {
        Amount: state.amount as number,
        BinNumber: state.ccNumber,
        Company: company,
        DealerCode: props.user.AccountCodeRemote,
        ComissionType: state.isIncluded,
        Installment: state.selectedInstallment.Installment,
      }
      const getManualCreditCardPaymentControlApi: GetManualCreditCardPaymentControlApi =
        new GetManualCreditCardPaymentControlApi({})
      await getManualCreditCardPaymentControlApi
        .getManualCreditCardPaymentControl(req)
        .then(async result => {
          if (result) {
            setState({ isMarked: true })
            return
          }
          Swal.fire('Hata', 'Başarısız', 'error')
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
      return
    }
    const req: PaymentControl = {
      Amount: state.amount as number,
      BinNumber: state.ccNumber,
      Company: company,
      IsIncluding: state.isIncluded === 1 ? true : false,
      PaymentTermsId: state.paymentTermsId ? state.paymentTermsId : 0,
      Type: state.selectedPaymentType,
      Installment: state.selectedInstallment.Installment,
      UserId: props.user.UserId,
    }

    try {
      const res = await new PaymentService({}).paymentControl(req)
      if (res) {
        setState({ isMarked: true })
        return
      }
      Swal.fire('Hata', 'Başarısız', 'error')
    } catch (err: any) {
      Swal.fire('Hata', err.description ? err.description : 'Başarısız', 'error')
      console.error(err)
    }
  }

  async function IsNot3D() {
    try {
      const res = await new IsNot3DPayment({}).post({ Companies: state.threedPaymentCompanyCode })
      setState({ threedPaymentDisabled: !res, securePayment: !res })
    } catch (err: any) {
      console.error(err)
    }
  }

  useEffect(() => {
    IsNot3D()
    //state.threedPaymentCompanyCode && setSelectedCompany(state.threedPaymentCompanyCode[0])
  }, [state.threedPaymentCompanyCode])

  return (
    <React.Fragment>
      <div id='mainMenu'>
        <nav style={{ float: 'left' }}>
          <ul className='align-items-center' style={{ height: '43px' }}>
            {isPaymentTabVisible(PaymentTypeEnum.CariOdeme) && (
              <li
                onClick={() => {
                  if (state.isMarked) {
                    Swal.fire({
                      icon: 'question',
                      title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                      showConfirmButton: true,
                      allowOutsideClick: false,
                      showDenyButton: true,
                      confirmButtonText: 'Evet',
                      denyButtonText: 'Hayır',
                    }).then(async result => {
                      if (result.isConfirmed) {
                        window.location.reload()
                      }
                    })
                  } else
                    setState({
                      selectedPaymentType: PaymentTypeEnum.CariOdeme,
                      paymentTermsId: 0,
                      modalPopup: true,
                      cariSelectedCompany: undefined,
                      avantajSelectedCompany: undefined,
                      firsatSelectedCompany: undefined,
                      selectedOpportunity: undefined,
                      selectedAdvantage: undefined,
                      amount: '',
                      ccNumber: '',
                      binNumber: '',
                    })
                }}
                className={`dropdown-submenu payment-tab${state.selectedPaymentType === PaymentTypeEnum.CariOdeme ? ' active-payment-tab' : ''
                  }`}
              >
                <span>Cari Ödeme</span>
              </li>
            )}
            {isPaymentTabVisible(PaymentTypeEnum.Avantaj) && (
              <li
                onClick={() => {
                  if (state.isMarked) {
                    Swal.fire({
                      icon: 'question',
                      title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                      showConfirmButton: true,
                      allowOutsideClick: false,
                      showDenyButton: true,
                      confirmButtonText: 'Evet',
                      denyButtonText: 'Hayır',
                    }).then(async result => {
                      if (result.isConfirmed) {
                        window.location.reload()
                      }
                    })
                  } else
                    setState({
                      selectedPaymentType: PaymentTypeEnum.Avantaj,
                      modalPopup: true,
                      paymentTermsId: 0,
                      cariSelectedCompany: undefined,
                      avantajSelectedCompany: undefined,
                      firsatSelectedCompany: undefined,
                      selectedOpportunity: undefined,
                      selectedAdvantage: undefined,
                      amount: '',
                      ccNumber: '',
                      binNumber: '',
                    })
                }}
                className={`dropdown-submenu payment-tab${state.selectedPaymentType === PaymentTypeEnum.Avantaj ? ' active-payment-tab' : ''
                  }`}
              >
                <span>Avantaj POS Ödeme</span>
              </li>
            )}
            {isPaymentTabVisible(PaymentTypeEnum.Firsat) && (
              <li
                onClick={() => {
                  if (state.isMarked) {
                    Swal.fire({
                      icon: 'question',
                      title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                      showConfirmButton: true,
                      allowOutsideClick: false,
                      showDenyButton: true,
                      confirmButtonText: 'Evet',
                      denyButtonText: 'Hayır',
                    }).then(async result => {
                      if (result.isConfirmed) {
                        window.location.reload()
                      }
                    })
                  } else
                    setState({
                      selectedPaymentType: PaymentTypeEnum.Firsat,
                      modalPopup: true,
                      paymentTermsId: 0,
                      cariSelectedCompany: undefined,
                      avantajSelectedCompany: undefined,
                      firsatSelectedCompany: undefined,
                      selectedOpportunity: undefined,
                      selectedAdvantage: undefined,
                      amount: '',
                      ccNumber: '',
                      binNumber: '',
                    })
                }}
                className={`dropdown-submenu payment-tab${state.selectedPaymentType === PaymentTypeEnum.Firsat ? ' active-payment-tab' : ''
                  }`}
              >
                <span>Fırsat POS Ödeme</span>
              </li>
            )}
            {isPaymentTabVisible(PaymentTypeEnum.Fcv) && (
              <li
                onClick={() => {
                  if (state.isMarked) {
                    Swal.fire({
                      icon: 'question',
                      title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                      showConfirmButton: true,
                      allowOutsideClick: false,
                      showDenyButton: true,
                      confirmButtonText: 'Evet',
                      denyButtonText: 'Hayır',
                    }).then(async result => {
                      if (result.isConfirmed) {
                        setState({ isMarked: false })
                        window.location.reload()
                      }
                    })
                  } else
                    setState({
                      selectedPaymentType: PaymentTypeEnum.Fcv,
                      modalPopup: true,
                      paymentTermsId: 0,
                      cariSelectedCompany: undefined,
                      avantajSelectedCompany: undefined,
                      firsatSelectedCompany: undefined,
                      selectedOpportunity: undefined,
                      selectedAdvantage: undefined,
                      amount: '',
                      ccNumber: '',
                      binNumber: '',
                    })
                }}
                className={`dropdown-submenu payment-tab${state.selectedPaymentType === PaymentTypeEnum.Fcv ? ' active-payment-tab' : ''
                  }`}
              >
                <span>FÇV Ödeme</span>
              </li>
            )}
          </ul>
        </nav>
      </div>
      <div className='seperator headerSeperator m-0 p-0'></div>
      {
        <div>
          {state.currentMethodLimit !== undefined && (
            <div style={{ float: 'right', margin: '10px 15px' }}>
              <button
                type='button'
                className='IPButton_Button__GWRch background-white px-3 pt-2 pb-1'
                style={{ border: '1px solid #E0E0E2', height: '46px' }}
              >
                <span style={{ color: '#EE3439', fontWeight: '500' }}>Kredi Limiti:&nbsp;&nbsp;</span>
                <span style={{ color: '#616161', fontWeight: '500' }}>
                  {/* 10.000,00 USD */}
                  {state.currentMethodLimit}
                </span>
              </button>
            </div>
          )}
          {state.selectedPaymentType === PaymentTypeEnum.Fcv && (
            <div style={{ padding: '10px' }}>
              <IPSelectBox
                value={state.selectedFcv}
                menuPortalTarget={document.body}
                options={state.fcvs.map((item: IFcv) => {
                  return {
                    value: item.id,
                    label: item.name,
                  }
                })}
                onChangeSingle={(option: ISelectOption) => {
                  if (state.isMarked) {
                    Swal.fire({
                      icon: 'question',
                      title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                      showConfirmButton: true,
                      allowOutsideClick: false,
                      showDenyButton: true,
                      confirmButtonText: 'Evet',
                      denyButtonText: 'Hayır',
                    }).then(async result => {
                      if (result.isConfirmed) {
                        window.location.reload()
                      }
                    })
                  } else {
                    if (option.value !== state.selectedFcv?.value) {
                      setState({
                        selectedFcv: option,
                        paymentTermsId: option.value as number,
                        selectedInvoices: [],
                        selectedInvoicesCollectionAmounts: [],
                      })
                    }
                  }
                }}
              />
            </div>
          )}
          {state.selectedPaymentType === PaymentTypeEnum.Avantaj && (
            <div style={{ padding: '10px' }}>
              <IPSelectBox
                value={state.selectedAdvantage}
                menuPortalTarget={document.body}
                options={state.advantages.map((item: IAdvantage) => {
                  return {
                    value: item.id,
                    label: item.name,
                    PaymentTermsId: item.PaymentTermsId,
                  }
                })}
                onChangeSingle={(option: ISelectOption) => {
                  if (state.isMarked) {
                    Swal.fire({
                      icon: 'question',
                      title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                      showConfirmButton: true,
                      allowOutsideClick: false,
                      showDenyButton: true,
                      confirmButtonText: 'Evet',
                      denyButtonText: 'Hayır',
                    }).then(async result => {
                      if (result.isConfirmed) {
                        window.location.reload()
                      }
                    })
                  } else {
                    if (option.value !== state.selectedAdvantage?.value) {
                      setState({ selectedAdvantage: option, paymentTermsId: option.PaymentTermsId })
                    }
                  }
                }}
              />
            </div>
          )}
          {state.selectedPaymentType === PaymentTypeEnum.Firsat && (
            <div style={{ padding: '10px' }}>
              <IPSelectBox
                value={state.selectedOpportunity}
                menuPortalTarget={document.body}
                options={state.opportunities.map((item: IOpportunity) => {
                  return {
                    value: item.id,
                    label: item.name,
                    PaymentTermsId: item.PaymentTermsId,
                  }
                })}
                onChangeSingle={(option: ISelectOption) => {
                  if (state.isMarked) {
                    Swal.fire({
                      icon: 'question',
                      title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                      showConfirmButton: true,
                      allowOutsideClick: false,
                      showDenyButton: true,
                      confirmButtonText: 'Evet',
                      denyButtonText: 'Hayır',
                    }).then(async result => {
                      if (result.isConfirmed) {
                        window.location.reload()
                      }
                    })
                  } else {
                    if (option.value !== state.selectedOpportunity?.value) {
                      setState({ selectedOpportunity: option, paymentTermsId: option.PaymentTermsId })
                    }
                  }
                }}
              />
            </div>
          )}
        </div>
      }
      <div className='container mb-5 mt-5'>
        {state.allPaymentData && state.selectedPaymentType === PaymentTypeEnum.CariOdeme && (
          <>
            <section className='fixed'>
              <div className='wrap'>
                <table className='bordered-table'>
                  <thead>
                    <tr>
                      <th>
                        Seçim
                        <div>Seçim</div>
                      </th>
                      <th>
                        Şirket
                        <div>Şirket</div>
                      </th>
                      <th>
                        TL Bakiye
                        <div>TL Bakiye</div>
                      </th>
                      <th>
                        TL Vadesi Geçmiş
                        <div>TL Vadesi Geçmiş</div>
                      </th>
                      <th>
                        USD Bakiye
                        <div>USD Bakiye</div>
                      </th>
                      <th>
                        USD Vadesi Geçmiş
                        <div>USD Vadesi Geçmiş</div>
                      </th>
                      <th>
                        EUR Bakiye
                        <div>EUR Bakiye</div>
                      </th>
                      <th>
                        EUR Vadesi Geçmiş
                        <div>EUR Vadesi Geçmiş</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.allPaymentData &&
                      state.allPaymentData.DealerReminders &&
                      state.allPaymentData.DealerReminders.map((dealer: IDealerReminder) => (
                        <tr key={dealer.Company}>
                          <td>
                            <IPRadio
                              text=''
                              name='rowSelection'
                              checked={state.cariSelectedCompany === dealer.Company}
                              onChange={() => {
                                if (state.isMarked) {
                                  Swal.fire({
                                    icon: 'question',
                                    title:
                                      'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                                    showConfirmButton: true,
                                    allowOutsideClick: false,
                                    showDenyButton: true,
                                    confirmButtonText: 'Evet',
                                    denyButtonText: 'Hayır',
                                  }).then(async result => {
                                    if (result.isConfirmed) {
                                      window.location.reload()
                                    }
                                  })
                                } else {
                                  setState({
                                    cariSelectedCompany: dealer.Company,
                                    threedPaymentCompanyCode: [dealer.Company.toString()],
                                  })
                                }
                              }}
                            />
                          </td>
                          <td>
                            {dealer.Company === 1000
                              ? 'Index'
                              : dealer.Company === 1100
                                ? 'Datagate'
                                : dealer.Company === 1200
                                  ? 'Despec'
                                  : 'Netex'}
                          </td>
                          <td
                            style={{ textAlign: 'right' }}
                          // className={dealer.TL_BAK > 0
                          //   ? 'positive-number'
                          //   : dealer.TL_BAK < 0
                          //     ? 'negative-number'
                          //     : 'zero-number'}
                          >
                            {formatPrice(dealer.TL_BAK, 'TRY', 'symbol')}
                          </td>
                          <td
                            style={{ textAlign: 'right' }}
                          // className={dealer.VGA_TL > 0
                          //   ? 'positive-number'
                          //   : dealer.VGA_TL < 0
                          //     ? 'negative-number'
                          //     : 'zero-number'}
                          >
                            {formatPrice(dealer.VGA_TL, 'TRY', 'symbol')}
                          </td>
                          <td
                            style={{ textAlign: 'right' }}
                          // className={dealer.USD_BAK > 0
                          //   ? 'positive-number'
                          //   : dealer.USD_BAK < 0
                          //     ? 'negative-number'
                          //     : 'zero-number'}
                          >
                            {formatPrice(dealer.USD_BAK, 'USD', 'symbol')}
                          </td>
                          <td
                            style={{ textAlign: 'right' }}
                          // className={dealer.VGA_USD > 0
                          //   ? 'positive-number'
                          //   : dealer.VGA_USD < 0
                          //     ? 'negative-number'
                          //     : 'zero-number'}
                          >
                            {formatPrice(dealer.VGA_USD, 'USD', 'symbol')}
                          </td>
                          <td
                            style={{ textAlign: 'right' }}
                          // className={dealer.EUR_BAK > 0
                          //   ? 'positive-number'
                          //   : dealer.EUR_BAK < 0
                          //     ? 'negative-number'
                          //     : 'zero-number'}
                          >
                            {formatPrice(dealer.EUR_BAK, 'EUR', 'symbol')}
                          </td>
                          <td
                            style={{ textAlign: 'right' }}
                          // className={dealer.VGA_EUR > 0
                          //   ? 'positive-number'
                          //   : dealer.VGA_EUR < 0
                          //     ? 'negative-number'
                          //     : 'zero-number'}
                          >
                            {formatPrice(dealer.VGA_EUR, 'EUR', 'symbol')}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </section>
            <label className='negative-number mt-2'> *Lütfen önce ödeme yapmak istediğiniz şirketi seçiniz. </label>
          </>
        )}
        {state.allPaymentData && state.selectedPaymentType === PaymentTypeEnum.Avantaj && (
          <>
            {state.selectedAdvantage && (
              <section className='fixed'>
                <div className='wrap'>
                  <table className='bordered-table'>
                    <thead>
                      <tr>
                        <th>
                          Seçim
                          <div>Seçim</div>
                        </th>
                        <th>
                          Firma
                          <div>Firma</div>
                        </th>
                        <th>
                          Limit
                          <div>Limit</div>
                        </th>
                        <th>
                          Harcanan Limit
                          <div>Harcanan Limit</div>
                        </th>
                        <th>
                          Kalan Limit
                          <div>Kalan Limit</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.avantajLimit &&
                        state.avantajLimit.List &&
                        state.avantajLimit.List.filter(
                          (advItem: ILimit) => advItem.Id === state.selectedAdvantage?.value
                        ).map((avantaj: ILimit) => {
                          return (
                            <tr key={avantaj.Id}>
                              <td>
                                <IPRadio
                                  text=''
                                  name='rowSelection'
                                  checked={Number(state.avantajSelectedCompany) === Number(avantaj.CompanyCode)}
                                  onChange={() => {
                                    if (state.isMarked) {
                                      Swal.fire({
                                        icon: 'question',
                                        title:
                                          'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                                        showConfirmButton: true,
                                        allowOutsideClick: false,
                                        showDenyButton: true,
                                        confirmButtonText: 'Evet',
                                        denyButtonText: 'Hayır',
                                      }).then(async result => {
                                        if (result.isConfirmed) {
                                          window.location.reload()
                                        }
                                      })
                                    } else
                                      setState({
                                        avantajSelectedCompany: Number(avantaj.CompanyCode),
                                        threedPaymentCompanyCode: [avantaj.CompanyCode.toString()],
                                      })
                                  }}
                                />
                              </td>
                              <td>{getCompanyName(avantaj.CompanyCode)}</td>
                              <td style={{ textAlign: 'right' }}>{formatPrice(avantaj.Limit, 'TRY', 'symbol')}</td>
                              <td style={{ textAlign: 'right' }}>{formatPrice(avantaj.UsedTotal, 'TRY', 'symbol')}</td>
                              <td style={{ textAlign: 'right' }}>
                                {formatPrice(avantaj.RemainingTotal, 'TRY', 'symbol')}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              </section>
            )}
          </>
        )}
        {state.allPaymentData && state.selectedPaymentType === PaymentTypeEnum.Firsat && (
          <>
            {state.selectedOpportunity && (
              <section className='fixed'>
                <div className='wrap'>
                  <table className='bordered-table'>
                    <thead>
                      <tr>
                        <th>
                          Seçim
                          <div>Seçim</div>
                        </th>
                        <th>
                          Firma
                          <div>Firma</div>
                        </th>
                        <th>
                          Limit
                          <div>Limit</div>
                        </th>
                        <th>
                          Harcanan Limit
                          <div>Harcanan Limit</div>
                        </th>
                        <th>
                          Kalan Limit
                          <div>Kalan Limit</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(selectedFirsatLimit as any) && (
                        <tr key={(selectedFirsatLimit as any).Id}>
                          <td>
                            <IPRadio
                              text=''
                              name='rowSelection'
                              checked={
                                Number(state.firsatSelectedCompany) ===
                                Number(
                                  (selectedFirsatLimit as any).CompanyCode && (selectedFirsatLimit as any).CompanyCode
                                )
                              }
                              onChange={() => {
                                if (state.isMarked) {
                                  Swal.fire({
                                    icon: 'question',
                                    title:
                                      'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                                    showConfirmButton: true,
                                    allowOutsideClick: false,
                                    showDenyButton: true,
                                    confirmButtonText: 'Evet',
                                    denyButtonText: 'Hayır',
                                  }).then(async result => {
                                    if (result.isConfirmed) {
                                      window.location.reload()
                                    }
                                  })
                                } else {
                                  setState({
                                    firsatSelectedCompany: Number(
                                      (selectedFirsatLimit as any).CompanyCode &&
                                      (selectedFirsatLimit as any).CompanyCode
                                    ),
                                    threedPaymentCompanyCode: (selectedFirsatLimit as any).CompanyCode && [
                                      (selectedFirsatLimit as any).CompanyCode.toString(),
                                    ],
                                  })
                                }
                              }}
                            />
                          </td>
                          <td>
                            {getCompanyName(
                              (selectedFirsatLimit as any).CompanyCode && (selectedFirsatLimit as any).CompanyCode
                            )}
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            {formatPrice((selectedFirsatLimit as any).Limit, 'TRY', 'symbol')}
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            {formatPrice((selectedFirsatLimit as any).UsedTotal, 'TRY', 'symbol')}
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            {formatPrice((selectedFirsatLimit as any).RemainingTotal, 'TRY', 'symbol')}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </section>
            )}
            <label className='negative-number'> *Lütfen önce ödeme yapmak istediğiniz şirketi seçiniz. </label>
          </>
        )}
        {state.allPaymentData && state.selectedPaymentType === PaymentTypeEnum.Fcv && (
          <>
            {state.selectedFcv && (
              <section className='fixed'>
                <div className='wrap'>
                  <table className='bordered-table'>
                    <thead>
                      <tr>
                        <th>
                          Seçim
                          <div>Seçim</div>
                        </th>
                        <th>
                          Firma
                          <div>Firma</div>
                        </th>
                        <th>
                          Fatura No
                          <div>Fatura No</div>
                        </th>
                        <th>
                          Fatura Tarihi
                          <div>Fatura Tarihi</div>
                        </th>
                        <th>
                          Tutar
                          <div>Tutar</div>
                        </th>
                        <th>
                          Açık Tutar
                          <div>Açık Tutar</div>
                        </th>
                        <th>
                          Tahsil Tutar
                          <div>Tahsil Tutar</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.allPaymentData &&
                        state.allPaymentData.Invoices &&
                        state.allPaymentData.Invoices.filter((fcvElem: IInvoice) => {
                          return state.selectedFcv && fcvElem.FCVID === state.selectedFcv.value
                        }).map((invoice: IInvoice) => (
                          <tr key={invoice.FaturaID}>
                            <td style={{ paddingLeft: '20px' }}>
                              <IPCheckbox
                                label=''
                                removeMarginBottom
                                name='rowSelection'
                                disabled={invoice.ToplamTry === invoice.OdenmisMiktar}
                                checked={
                                  state.selectedInvoices.findIndex(
                                    (val: IInvoice) => val.FaturaID === invoice.FaturaID
                                  ) > -1
                                }
                                onChange={() => {
                                  if (state.isMarked) {
                                    Swal.fire({
                                      icon: 'question',
                                      title:
                                        'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                                      showConfirmButton: true,
                                      allowOutsideClick: false,
                                      showDenyButton: true,
                                      confirmButtonText: 'Evet',
                                      denyButtonText: 'Hayır',
                                    }).then(async result => {
                                      if (result.isConfirmed) {
                                        window.location.reload()
                                      }
                                    })
                                  } else {
                                    setState({ threedPaymentCompanyCode: [invoice.Company] })
                                    handleInvoiceSelected(invoice)
                                  }
                                }}
                              />
                            </td>
                            <td>
                              {Number(invoice.Company) === 1000
                                ? 'Index'
                                : Number(invoice.Company) === 1100
                                  ? 'Datagate'
                                  : Number(invoice.Company) === 1200
                                    ? 'Despec'
                                    : 'Netex'}
                            </td>
                            <td>{invoice.FaturaNo}</td>
                            <td>{new Date(invoice.FaturaTarihi)?.toLocaleDateString('tr-TR')}</td>
                            <td style={{ textAlign: 'right' }}>{formatPrice(invoice.ToplamTry, 'TRY', 'symbol')}</td>
                            <td style={{ textAlign: 'right' }}>
                              {formatPrice(
                                numeral(invoice.ToplamTry).subtract(invoice.OdenmisMiktar).value()!,
                                'TRY',
                                'symbol'
                              )}
                            </td>
                            <td>
                              <IPInput
                                type='text'
                                style={{ height: '22px', padding: '2px 4px' }}
                                disabled={
                                  !(
                                    state.selectedInvoices.findIndex(
                                      (val: IInvoice) => val.FaturaID === invoice.FaturaID
                                    ) > -1
                                  )
                                }
                                value={
                                  state.selectedInvoicesCollectionAmounts?.find(
                                    (elem: IInvoiceCollectionAmount) => elem.faturaID === invoice.FaturaID
                                  )?.collectionAmount
                                }
                                onChange={e => handleQuantity(e, invoice)}
                                onBlur={e => handleQuantityOnBlur(e, invoice)}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <span style={{ float: 'right', marginRight: '10px', fontWeight: '500', color: '#fff' }}>
                  Toplam Ödenecek Tutar:
                  <span style={{ fontWeight: '700', marginLeft: '10px', color: '#fff' }}>
                    {state.invoicesTotalAmount}
                  </span>
                </span>
              </section>
            )}
          </>
        )}

        <br></br>
        {/* <Link to='../myspecial/creditCardRates' target='_blank'><p className='h5' style={{ color: "red" }}>* Kredi Kartı Komisyon Oranlarını Görmek İçin <strong>Tıklayınız</strong>.</p></Link> */}
        <Link to='../myspecial/creditCardRates' target='_blank' className='button-link'>
          <p className='h5' style={{ color: 'black' }}>
            <strong>
              <i className='icon-credit-card'></i> Kredi Kartı Komisyon Oranlarını Görmek İçin Tıklayınız
            </strong>
          </p>
        </Link>

        <div style={{ marginTop: '25px', marginLeft: '15px' }}>
          <div className='row mt-3'>
            <div
              className={`${props.user.IsRemote && state.selectedPaymentType === PaymentTypeEnum.CariOdeme
                ? 'col-md-8 p-0'
                : 'col-md-6 p-0'
                }`}
            >
              <div className='row mt-3'>
                <div
                  className={`${props.user.IsRemote && state.selectedPaymentType === PaymentTypeEnum.CariOdeme
                    ? 'col-md-3 input-group mb-3'
                    : 'col-md-6 input-group mb-3'
                    }`}
                >
                  <IPInput
                    type='number'
                    name='number'
                    className='form-control'
                    placeholder='Tutar'
                    min={0}
                    skipCustomValidation
                    controlledField={true}
                    disabled={state.selectedPaymentType === PaymentTypeEnum.Fcv}
                    dataPattern={IPInputDataPattern.Numeric}
                    value={state.amount}
                    onChange={e => {
                      if (state.isMarked) {
                        Swal.fire({
                          icon: 'question',
                          title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                          showConfirmButton: true,
                          allowOutsideClick: false,
                          showDenyButton: true,
                          confirmButtonText: 'Evet',
                          denyButtonText: 'Hayır',
                        }).then(async result => {
                          if (result.isConfirmed) {
                            window.location.reload()
                          }
                        })
                      } else onChangeAmount(e)
                    }}
                  />
                  <div className='input-group-prepend'>
                    <span
                      className='input-group-text'
                      style={{ height: '47px', fontWeight: 'bolder', fontFamily: 'cursive', fontSize: '25px' }}
                    >
                      ₺
                    </span>
                  </div>
                </div>
                {state.allPaymentData &&
                  state.allPaymentData.IsIncludedList &&
                  ((state.selectedPaymentType === PaymentTypeEnum.CariOdeme &&
                    state.allPaymentData.IsIncludedList.indexOf(`${state.cariSelectedCompany}`) > -1) ||
                    (state.selectedPaymentType === PaymentTypeEnum.Avantaj &&
                      state.allPaymentData.IsIncludedList.indexOf(`${state.avantajSelectedCompany}`) > -1) ||
                    (state.selectedPaymentType === PaymentTypeEnum.Firsat &&
                      state.allPaymentData.IsIncludedList.indexOf(`${state.firsatSelectedCompany}`) > -1)) && (
                    <div className='col-md-3'>
                      <IPRadio
                        name='includingRadio'
                        text='Komisyon Dahil'
                        checked={state.isIncluded === 1 ? true : false}
                        onChange={() => {
                          if (state.isMarked) {
                            Swal.fire({
                              icon: 'question',
                              title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                              showConfirmButton: true,
                              allowOutsideClick: false,
                              showDenyButton: true,
                              confirmButtonText: 'Evet',
                              denyButtonText: 'Hayır',
                            }).then(async result => {
                              if (result.isConfirmed) {
                                window.location.reload()
                              }
                            })
                          } else setState({ isIncluded: 1 })
                        }}
                      />
                    </div>
                  )}
                {state.allPaymentData && state.allPaymentData.IsIncludedList && (
                  <div className='col-md-3'>
                    <IPRadio
                      name='includingRadio'
                      text='Komisyon Hariç'
                      checked={state.isIncluded === 2 ? true : false}
                      onChange={() => {
                        if (state.isMarked) {
                          Swal.fire({
                            icon: 'question',
                            title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            showDenyButton: true,
                            confirmButtonText: 'Evet',
                            denyButtonText: 'Hayır',
                          }).then(async result => {
                            if (result.isConfirmed) {
                              window.location.reload()
                            }
                          })
                        } else setState({ isIncluded: 2 })
                      }}
                    />
                  </div>
                )}
                {props.user.IsRemote &&
                  state.selectedPaymentType === PaymentTypeEnum.CariOdeme &&
                  props.user.IsAdmin && (
                    <div className='col-md-3'>
                      <IPRadio
                        name='includingRadio'
                        text='Komisyonsuz'
                        checked={state.isIncluded === 3 ? true : false}
                        onChange={() => {
                          if (state.isMarked) {
                            Swal.fire({
                              icon: 'question',
                              title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                              showConfirmButton: true,
                              allowOutsideClick: false,
                              showDenyButton: true,
                              confirmButtonText: 'Evet',
                              denyButtonText: 'Hayır',
                            }).then(async result => {
                              if (result.isConfirmed) {
                                window.location.reload()
                              }
                            })
                          } else setState({ isIncluded: 3 })
                        }}
                      />
                    </div>
                  )}
              </div>
              <div className='row mt-3'>
                <div className='col-md-12'>
                  <InputMask
                    placeholder='Kart Numarası'
                    mask='9999 9999 9999 9999'
                    className='form-control'
                    value={state.ccNumber}
                    onPaste={async e => {
                      const ccNumber = e.clipboardData.getData('text').replaceAll(' ', '')

                      setState({
                        ccNumber,
                        binNumber: undefined,
                        installmentSpecials: undefined,
                        selectedInstallment: undefined,
                        sentBinNumber: false,
                      })

                      if (ccNumber.length >= 6) {
                        const BinNumber = ccNumber.slice(0, 6)
                        const req = { BinNumber }
                        await findBankInfos(req)
                        setState({ sentBinNumber: true }) // Set flag to true indicating sendBinNumber has been called
                      }

                      if (state.isMarked) {
                        const result = await Swal.fire({
                          icon: 'question',
                          title: 'Onayladığınız için sayfa yenilenecektir. İşlem yapmak istediğinize emin misiniz?',
                          showConfirmButton: true,
                          allowOutsideClick: false,
                          showDenyButton: true,
                          confirmButtonText: 'Evet',
                          denyButtonText: 'Hayır',
                        })
                        if (result.isConfirmed) {
                          window.location.reload()
                        }
                      } else {
                        onChangeBIN(ccNumber)
                      }
                    }}
                    onChange={async e => {
                      const ccNumber = e.target.value.replaceAll(' ', '').replaceAll('_', '')
                      setState({ ccNumber })

                      if (ccNumber.length < 8) {
                        setState({
                          binNumber: undefined,
                          installmentSpecials: undefined,
                          selectedInstallment: undefined,
                        })
                      } else {
                        onChangeBIN(ccNumber)
                      }

                      if (ccNumber.length < 6) {
                        setState({ sentBinNumber: false })
                      } else if (ccNumber.length >= 6 && !state.sentBinNumber) {
                        // Only proceed if sendBinNumber hasn't been called yet
                        const BinNumber = ccNumber.slice(0, 6)
                        const req = { BinNumber }
                        await findBankInfos(req)
                        setState({ sentBinNumber: true }) // Set flag to true indicating sendBinNumber has been called
                      }

                      if (state.isMarked) {
                        const result = await Swal.fire({
                          icon: 'question',
                          title: 'Onayladığınız için sayfa yenilenecektir. İşlem yapmak istediğinize emin misiniz?',
                          showConfirmButton: true,
                          allowOutsideClick: false,
                          showDenyButton: true,
                          confirmButtonText: 'Evet',
                          denyButtonText: 'Hayır',
                        })
                        if (result.isConfirmed) {
                          window.location.reload()
                        }
                      }
                    }}
                    onFocus={() => {
                      setState({ ccFocus: 'number' })
                    }}
                  />
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-md-12'>
                  <IPInput
                    type='text'
                    name='name'
                    className='form-control'
                    placeholder='Ad Soyad'
                    dataPattern={IPInputDataPattern.AlphabeticWithSpaces}
                    required
                    onChange={e => {
                      if (state.isMarked) {
                        Swal.fire({
                          icon: 'question',
                          title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                          showConfirmButton: true,
                          allowOutsideClick: false,
                          showDenyButton: true,
                          confirmButtonText: 'Evet',
                          denyButtonText: 'Hayır',
                        }).then(async result => {
                          if (result.isConfirmed) {
                            window.location.reload()
                          }
                        })
                      } else {
                        const { target } = e

                        setState({ ccName: target.value })
                      }
                    }}
                    onFocus={() => {
                      if (state.isMarked) {
                        Swal.fire({
                          icon: 'question',
                          title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                          showConfirmButton: true,
                          allowOutsideClick: false,
                          showDenyButton: true,
                          confirmButtonText: 'Evet',
                          denyButtonText: 'Hayır',
                        }).then(async result => {
                          if (result.isConfirmed) {
                            window.location.reload()
                          }
                        })
                      } else setState({ ccFocus: 'name' })
                    }}
                  />
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-md-6'>
                  <InputMask
                    mask={'99/99'}
                    type='tel'
                    name='expiry'
                    placeholder='Geçerlilik Tarihi'
                    //pattern="\d\d/\d\d"
                    //dataPattern={IPInputDataPattern.Numeric}
                    required
                    onChange={e => {
                      if (state.isMarked) {
                        Swal.fire({
                          icon: 'question',
                          title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                          showConfirmButton: true,
                          allowOutsideClick: false,
                          showDenyButton: true,
                          confirmButtonText: 'Evet',
                          denyButtonText: 'Hayır',
                        }).then(async result => {
                          if (result.isConfirmed) {
                            window.location.reload()
                          }
                        })
                      } else {
                        const { target } = e

                        target.value = formatExpirationDate(target.value)

                        setState({ ccExpiry: target.value })
                      }
                    }}
                    onFocus={() => {
                      if (state.isMarked) {
                        Swal.fire({
                          icon: 'question',
                          title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                          showConfirmButton: true,
                          allowOutsideClick: false,
                          showDenyButton: true,
                          confirmButtonText: 'Evet',
                          denyButtonText: 'Hayır',
                        }).then(async result => {
                          if (result.isConfirmed) {
                            window.location.reload()
                          }
                        })
                      } else setState({ ccFocus: 'expiry' })
                    }}
                  />
                </div>
                <div className='col-md-6'>
                  <IPInput
                    type='tel'
                    name='cvc'
                    className='form-control'
                    placeholder='CVC'
                    //pattern="\d{3,4}"
                    dataPattern={IPInputDataPattern.Numeric}
                    maxLength={4}
                    required
                    onChange={e => {
                      if (state.isMarked) {
                        Swal.fire({
                          icon: 'question',
                          title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                          showConfirmButton: true,
                          allowOutsideClick: false,
                          showDenyButton: true,
                          confirmButtonText: 'Evet',
                          denyButtonText: 'Hayır',
                        }).then(async result => {
                          if (result.isConfirmed) {
                            window.location.reload()
                          }
                        })
                      } else {
                        const { target } = e

                        target.value = formatCVC(target.value)

                        setState({ ccCVC: target.value })
                      }
                    }}
                    onFocus={() => {
                      if (state.isMarked) {
                        Swal.fire({
                          icon: 'question',
                          title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                          showConfirmButton: true,
                          allowOutsideClick: false,
                          showDenyButton: true,
                          confirmButtonText: 'Evet',
                          denyButtonText: 'Hayır',
                        }).then(async result => {
                          if (result.isConfirmed) {
                            window.location.reload()
                          }
                        })
                      } else setState({ ccFocus: 'cvc' })
                    }}
                  />
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-md-4'>
                  <IPSelectBox
                    placeholder={'Seçiniz...'}
                    value={state.selectedCurrency}
                    options={[
                      { value: 'TL', label: 'TL Hesabı' },
                      { value: 'USD', label: 'USD  Hesabı' },
                      { value: 'EUR', label: 'EUR  Hesabı' },
                    ]}
                    onChangeSingle={(option: ISelectOption) => {
                      if (state.isMarked) {
                        Swal.fire({
                          icon: 'question',
                          title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                          showConfirmButton: true,
                          allowOutsideClick: false,
                          showDenyButton: true,
                          confirmButtonText: 'Evet',
                          denyButtonText: 'Hayır',
                        }).then(async result => {
                          if (result.isConfirmed) {
                            window.location.reload()
                          }
                        })
                      } else setState({ selectedCurrency: option })
                    }}
                  />
                </div>
                <div className='col-8 p-0 mt-3'>
                  <IPCheckbox
                    disabled={state.threedPaymentDisabled}
                    checked={state.securePayment}
                    label='3D Secure ile ödeme yapmak istiyorum.'
                    onCheckedChange={checked => {
                      if (state.isMarked) {
                        Swal.fire({
                          icon: 'question',
                          title: 'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                          showConfirmButton: true,
                          allowOutsideClick: false,
                          showDenyButton: true,
                          confirmButtonText: 'Evet',
                          denyButtonText: 'Hayır',
                        }).then(async result => {
                          if (result.isConfirmed) {
                            window.location.reload()
                          }
                        })
                      } else setState({ securePayment: checked })
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className={`${props.user.IsRemote && state.selectedPaymentType === PaymentTypeEnum.CariOdeme ? 'col-md-4' : 'col-md-6'
                }`}
              style={{ position: 'relative' }}
            >
              {state.ccNumber.length > 5 && ( //state.bankCode > 0 &&
                <Image
                  alt=''
                  src={state.bankLogo}
                  className={styles.banksImage}
                  style={{
                    position: 'absolute',
                    top: '10%',
                    left: '45%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: '999',
                    maxWidth: '90px',
                  }}
                />
              )}
              <Cards
                cvc={state.ccCVC}
                expiry={state.ccExpiry}
                focused={state.ccFocus}
                name={state.ccName}
                placeholders={{ name: 'AD SOYAD' }}
                number={state.ccNumber}
              />
            </div>
            <div className='row mt-5'>
              <div className='col'>
                {state.installmentSpecials &&
                  state.installmentSpecials.map(x =>
                    x.BankName && x.BankNote !== null ? (
                      <p key={x.BankName} style={{ fontSize: '18px' }}>
                        <strong>* Taksit Notu:</strong> {x.BankNote}
                      </p>
                    ) : null
                  )}
              </div>
            </div>

            {state.installmentSpecials && (
              <>
                <div className='bankLogoRow row'>
                  <div className='bankLogoCol col-md-12'>
                    <h5 style={{ textAlign: 'center' }}>
                      {state.installmentSpecials[0].BankId === '101' ? (
                        <>
                          <img src='/images/akbank.png' />
                        </>
                      ) : state.installmentSpecials[0].BankId === '501' ? (
                        <>
                          <img src='/images/denizBank.png' />
                        </>
                      ) : state.installmentSpecials[0].BankId === '701' ? (
                        <>
                          <img src='/images/finansbank.png' />
                        </>
                      ) : state.installmentSpecials[0].BankId === '801' ? (
                        <>
                          <img src='/images/garantiBankasi.png' />
                        </>
                      ) : state.installmentSpecials[0].BankId === '1001' ? (
                        <>
                          <img src='/images/halkbank.png' />
                        </>
                      ) : state.installmentSpecials[0].BankId === '1201' ? (
                        <>
                          <img src='/images/isBank.png' />
                        </>
                      ) : state.installmentSpecials[0].BankId === '2801' ? (
                        <>
                          <img src='/images/yapiKredi.png' />
                        </>
                      ) : state.installmentSpecials[0].BankId === '2901' ? (
                        <>
                          <img src='/images/ZiraatBankasi.png' />
                        </>
                      ) : state.installmentSpecials[0].BankId === '20101' ? (
                        <>
                          <img src='/images/akbankTicari.png' />
                        </>
                      ) : state.installmentSpecials[0].BankId === '20701' ? (
                        <>
                          <img src='/images/finansbankTicari.png' />
                        </>
                      ) : state.installmentSpecials[0].BankId === '20801' ? (
                        <>
                          <img src='/images/garantiBankasiTicari.png' />
                        </>
                      ) : state.installmentSpecials[0].BankId === '21001' ? (
                        <>
                          <img src='/images/halkbankTicari.png' />
                        </>
                      ) : state.installmentSpecials[0].BankId === '21201' ? (
                        <>
                          <img src='/images/isBankTicari.png' />
                        </>
                      ) : state.installmentSpecials[0].BankId === '20501' ? (
                        <>
                          <img src='/images/Denizbank-Ticari.png' />
                        </>
                      ) : state.installmentSpecials[0].BankId === '22801' ? (
                        <>
                          <img src='/images/yapiKrediTicari.png' />
                        </>
                      ) : state.installmentSpecials[0].BankId === '22901' ? (
                        <>
                          <img src='/images/ZiraatBankası-Ticari.png' />
                        </>
                      ) : (
                        ''
                      )}
                    </h5>

                    {/* Note: Yeni Taksit Tablosu */}
                    <div className='custom-table-responsive'>
                      <div className='custom-table-container'>
                        <div className={`custom-table-header ${state.paramposBool ? 'with-parampos' : ''}`}>
                          {/* {state.paramposBool && <div></div>} */}
                          <div></div>
                          <div>Seç</div>
                          <div>Ana Para</div>
                          <div>Taksit Sayısı</div>
                          <div>Oran %</div>
                          <div>Kur</div>
                          <div>Aylık Taksit</div>
                          <div>Toplam %</div>
                          <div>Toplam Tutar</div>
                          <div className='custom-highlight nowrap'>Hesaba Geç. Tut.</div>
                        </div>
                        {state.installmentSpecials &&
                          state.installmentSpecials.map((item: IGetInstallmentSpecial) => (
                            <div
                              key={item.Id}
                              className={`custom-table-row ${state.paramposBool ? 'with-parampos' : ''}`}
                            >
                              {console.log(item)}
                              {state.paramposBool ? (
                                item.ParamPosDetail ? (
                                  <div
                                    className='hasTooltip d-flex align-items-center justify-content-center p-1'
                                    style={{ marginLeft: '25px' }}
                                  >
                                    <img src='/images/star.png' alt='' style={{ width: '20px' }} />
                                    <div className='otooltip'>ParamPOS ile Avantajlı Oran Fırsatı !</div>
                                  </div>
                                ) : (
                                  <div></div>
                                )
                              ) : (
                                <div></div>
                              )}
                              <div
                                className='custom-radio-input'
                                style={{
                                  //marginLeft: item.ParamPosDetail ? '25px' : '',
                                  width: '8%',
                                }}
                              >
                                <input
                                  type='radio'
                                  name='InstallmentRadio'
                                  value={item.Id}
                                  onChange={() => {
                                    if (state.isMarked) {
                                      Swal.fire({
                                        icon: 'question',
                                        title:
                                          'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                                        showConfirmButton: true,
                                        allowOutsideClick: false,
                                        showDenyButton: true,
                                        confirmButtonText: 'Evet',
                                        denyButtonText: 'Hayır',
                                      }).then(async result => {
                                        if (result.isConfirmed) {
                                          window.location.reload()
                                        }
                                      })
                                    } else {
                                      setState({ selectedInstallment: item })
                                    }
                                  }}
                                />
                              </div>
                              {/* Ana Para */}
                              <div className='custom-price-display' style={{ position: 'relative' }}>
                                <IPPriceDisplay
                                  useDiv={true}
                                  price={formatPrice(
                                    item.ParamPosDetail ? item.ParamPosDetail.TotalMain : item.TotalMain,
                                    'TRY',
                                    'symbol'
                                  )}
                                />
                                {item.ParamPosDetail && item.TotalAmount > 0 && (
                                  <span
                                    style={{
                                      whiteSpace: 'nowrap',
                                      position: 'absolute',
                                      top: '16px',
                                      left: '-69px',
                                      fontSize: '12px',
                                      color: 'red',
                                      display: 'inline-block',
                                    }}
                                  >
                                    İndex Oran:{' '}
                                    <span style={{ display: 'inline-flex', marginLeft: '3px' }}>
                                      <IPPriceDisplay
                                        useDiv={true}
                                        price={formatPrice(item.TotalMain, 'TRY', 'symbol')}
                                      />
                                    </span>
                                  </span>
                                )}
                              </div>
                              {/* Taksit Sayısı */}
                              <div style={{ position: 'relative' }}>
                                {item.ParamPosDetail ? item.ParamPosDetail.Installment : item.Installment}
                                {item.AdditionalInstallment && '  ' + item.AdditionalInstallment}
                              </div>
                              {/* Oran */}
                              <div style={{ position: 'relative' }}>
                                {(item.ParamPosDetail ? item.ParamPosDetail.InterestRate : item.InterestRate).toFixed(
                                  2
                                )}{' '}
                                %
                                {item.ParamPosDetail && item.TotalAmount > 0 && (
                                  <span
                                    style={{
                                      whiteSpace: 'nowrap',
                                      position: 'absolute',
                                      top: '16px',
                                      left: '1px',
                                      fontSize: '12px',
                                      color: 'red',
                                      display: 'inline-block',
                                    }}
                                  >
                                    {item.InterestRate.toFixed(2) + ' %'}
                                  </span>
                                )}
                              </div>
                              {/* Kur */}
                              <div>
                                {state.selectedCurrency && state.selectedCurrency.value === 'USD'
                                  ? `${state.exchangeRate?.Usd} ₺`
                                  : state.selectedCurrency && state.selectedCurrency.value === 'EUR'
                                    ? `${state.exchangeRate?.Eur} ₺`
                                    : '1 ₺'}
                              </div>
                              {/* Aylık Taksit */}
                              <div className='custom-price-display' style={{ position: 'relative' }}>
                                <IPPriceDisplay
                                  useDiv={false}
                                  price={
                                    item.ParamPosDetail
                                      ? item.ParamPosDetail.ComissionAmountValue
                                      : item.ComissionAmountValue
                                  }
                                />
                                {item.ParamPosDetail && item.TotalAmount > 0 && (
                                  <span
                                    style={{
                                      whiteSpace: 'nowrap',
                                      position: 'absolute',
                                      top: '16px',
                                      left: '1px',
                                      fontSize: '12px',
                                      color: 'red',
                                      display: 'inline-block',
                                      fontFamily: 'sans-serif',
                                    }}
                                  >
                                    {item.ComissionAmountValue}
                                  </span>
                                )}
                              </div>
                              {/* Toplam % */}
                              <div className='custom-price-display' style={{ position: 'relative' }}>
                                <IPPriceDisplay
                                  useDiv={false}
                                  price={formatPrice(
                                    (item.ParamPosDetail ? item.ParamPosDetail.TotalAmount : item.TotalAmount) -
                                    (item.ParamPosDetail ? item.ParamPosDetail.TotalMain : item.TotalMain),
                                    'TRY',
                                    'symbol'
                                  )}
                                />
                                {item.ParamPosDetail && item.TotalAmount > 0 && (
                                  <span
                                    style={{
                                      whiteSpace: 'nowrap',
                                      position: 'absolute',
                                      top: '16px',
                                      left: '1px',
                                      fontSize: '12px',
                                      color: 'red',
                                      display: 'inline-block',
                                    }}
                                  >
                                    <span style={{ display: 'inline-flex', marginLeft: '3px' }}>
                                      <IPPriceDisplay
                                        useDiv={true}
                                        price={formatPrice(item.TotalAmount - item.TotalMain, 'TRY', 'symbol')}
                                      />
                                    </span>
                                  </span>
                                )}
                              </div>
                              {/* Toplam Tutar */}
                              <div className='custom-price-display' style={{ position: 'relative' }}>
                                <IPPriceDisplay
                                  useDiv={false}
                                  price={
                                    item.ParamPosDetail
                                      ? item.ParamPosDetail.TotalAmountTLValue
                                      : item.TotalAmountTLValue
                                  }
                                />
                                {item.ParamPosDetail && item.TotalAmount > 0 && (
                                  <span
                                    style={{
                                      whiteSpace: 'nowrap',
                                      position: 'absolute',
                                      top: '16px',
                                      left: '1px',
                                      fontSize: '12px',
                                      color: 'red',
                                      display: 'inline-block',
                                      fontFamily: 'sans-serif',
                                    }}
                                  >
                                    {item.TotalAmountTLValue}
                                  </span>
                                )}
                              </div>
                              {/* Hesaba Geç. Tut. */}
                              <div className='custom-highlight' style={{ position: 'relative' }}>
                                <IPPriceDisplay
                                  useDiv={false}
                                  price={
                                    state.selectedCurrency && state.selectedCurrency.value === 'TL'
                                      ? item.ParamPosDetail
                                        ? item.ParamPosDetail.InfoValueTL
                                        : item.InfoValueTL
                                      : state.selectedCurrency && state.selectedCurrency.value === 'USD'
                                        ? item.ParamPosDetail
                                          ? item.ParamPosDetail.InfoValueDolar
                                          : item.InfoValueDolar
                                        : state.selectedCurrency && state.selectedCurrency.value === 'EUR'
                                          ? item.ParamPosDetail
                                            ? item.ParamPosDetail.InfoValueEuro
                                            : item.InfoValueEuro
                                          : item.ParamPosDetail
                                            ? item.ParamPosDetail.InfoValueTL
                                            : item.InfoValueTL
                                  }
                                />
                                {item.ParamPosDetail && item.TotalAmount > 0 && (
                                  <span
                                    style={{
                                      whiteSpace: 'nowrap',
                                      position: 'absolute',
                                      top: '16px',
                                      left: '1px',
                                      fontSize: '12px',
                                      color: 'red',
                                      display: 'inline-block',
                                    }}
                                  >
                                    {state.selectedCurrency && state.selectedCurrency.value === 'TL'
                                      ? item.InfoValueTL
                                      : state.selectedCurrency && state.selectedCurrency.value === 'USD'
                                        ? item.InfoValueDolar
                                        : state.selectedCurrency && state.selectedCurrency.value === 'EUR'
                                          ? item.InfoValueEuro
                                          : item.InfoValueTL}
                                  </span>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>

                    {/* Note: Old Table
                     <div className='table-responsive'>
                      <div style={{ minWidth: 600 }}>
                        <div className='subtitleInstallment'></div>
                        <div className='descriptionInstallment'>
                          {state.selectedPaymentType === PaymentTypeEnum.CariOdeme && state.paramposBool && <div></div>}
                          <div>Seç</div>
                          <div>Ana Para</div>
                          <div>Taksit Sayısı</div>
                          <div>Oran %</div>
                          <div>
                            Kur (
                            {state.selectedCurrency && state.selectedCurrency.value === 'USD'
                              ? ' $ '
                              : state.selectedCurrency && state.selectedCurrency.value === 'EUR'
                              ? ' € '
                              : ' ₺ '}
                            )
                          </div>
                          <div>Aylık Taksit</div>
                          <div>Toplam %</div>
                          <div>Toplam Tutar</div>
                          <div style={{ color: 'blue' }}>Hesaba Geç. Tut.</div>
                        </div>
                        <div className='subtitleInstallment'></div>
                        {state.installmentSpecials &&
                          state.installmentSpecials.map((item: IGetInstallmentSpecial) => {
                            return (
                              <div key={item.Id}>
                                <div className='installmentContainer'>
                                  {state.selectedPaymentType === PaymentTypeEnum.CariOdeme && state.paramposBool ? (
                                    item.ParamPosDetail ? (
                                      <div
                                        className='hasTooltip d-flex align-items-center justify-content-center p-1'
                                        style={{ marginLeft: '25px' }}
                                      >
                                        <img src='/images/star.png' alt='' style={{ width: '20px' }} />
                                        <div className='otooltip'>ParamPOS ile Avantajlı Oran Fırsatı !</div>
                                      </div>
                                    ) : (
                                      <div></div>
                                    )
                                  ) : (
                                    ''
                                  )}
                                  <div style={{ marginLeft: item.ParamPosDetail ? '25px' : '13px', width: '8%' }}>
                                    <input
                                      type='radio'
                                      name='InstallmentRadio'
                                      value={item.Id}
                                      onChange={() => {
                                        if (state.isMarked) {
                                          Swal.fire({
                                            icon: 'question',
                                            title:
                                              'Onayıladığınız için sayfa yenilenecektir işlem yapmak istediğinize emin misiniz?',
                                            showConfirmButton: true,
                                            allowOutsideClick: false,
                                            showDenyButton: true,
                                            confirmButtonText: 'Evet',
                                            denyButtonText: 'Hayır',
                                          }).then(async result => {
                                            if (result.isConfirmed) {
                                              window.location.reload()
                                            }
                                          })
                                        } else setState({ selectedInstallment: item })
                                      }}
                                    />
                                  </div>
                                  <p style={{ whiteSpace: 'nowrap' }}>
                                    <IPPriceDisplay
                                      useDiv={false}
                                      price={formatPrice(
                                        item.ParamPosDetail ? item.ParamPosDetail.TotalMain : item.TotalMain,
                                        'TRY',
                                        'symbol'
                                      )}
                                    />
                                  </p>
                                  <p style={{ justifyContent: 'center', whiteSpace: 'nowrap' }}>
                                    {item.ParamPosDetail ? item.ParamPosDetail.Installment : item.Installment}
                                    {!item.ParamPosDetail &&
                                      item.AdditionalInstallment &&
                                      '  ' + item.AdditionalInstallment}
                                  </p>
                                  <p style={{ paddingRight: '20px', whiteSpace: 'nowrap' }}>
                                    {(item.ParamPosDetail
                                      ? item.ParamPosDetail.InterestRate.toFixed(2)
                                      : item.InterestRate.toFixed(2)) + ' %'}
                                  </p>
                                  <p style={{ paddingRight: '20px', whiteSpace: 'nowrap' }}>
                                    {state.selectedCurrency && state.selectedCurrency.value === 'USD'
                                      ? `${state.exchangeRate?.Usd}${' ₺'}`
                                      : state.selectedCurrency && state.selectedCurrency.value === 'EUR'
                                      ? `${state.exchangeRate?.Eur}${' ₺'}`
                                      : '1 ₺'}
                                  </p>
                                  <p style={{ paddingRight: '8px', whiteSpace: 'nowrap' }}>
                                    <IPPriceDisplay
                                      useDiv={false}
                                      price={
                                        item.ParamPosDetail
                                          ? item.ParamPosDetail.ComissionAmountValue
                                          : item.ComissionAmountValue
                                      }
                                    />
                                  </p>
                                  <p style={{ paddingRight: '8px', whiteSpace: 'nowrap' }}>
                                    <IPPriceDisplay
                                      useDiv={false}
                                      price={formatPrice(
                                        (item.ParamPosDetail ? item.ParamPosDetail.TotalAmount : item.TotalAmount) -
                                          (item.ParamPosDetail ? item.ParamPosDetail.TotalMain : item.TotalMain),
                                        'TRY',
                                        'symbol'
                                      )}
                                    />
                                  </p>
                                  <p style={{ paddingRight: '5px', whiteSpace: 'nowrap' }}>
                                    <IPPriceDisplay
                                      useDiv={false}
                                      price={
                                        item.ParamPosDetail
                                          ? item.ParamPosDetail.TotalAmountTLValue
                                          : item.TotalAmountTLValue
                                      }
                                    />
                                  </p>
                                  <p style={{ color: 'blue', whiteSpace: 'nowrap' }}>
                                    <IPPriceDisplay
                                      useDiv={false}
                                      price={
                                        state.selectedCurrency && state.selectedCurrency.value === 'TL'
                                          ? item.ParamPosDetail
                                            ? item.ParamPosDetail.InfoValueTL
                                            : item.InfoValueTL
                                          : state.selectedCurrency && state.selectedCurrency.value === 'USD'
                                          ? item.ParamPosDetail
                                            ? item.ParamPosDetail.InfoValueDolar
                                            : item.InfoValueDolar
                                          : state.selectedCurrency && state.selectedCurrency.value === 'EUR'
                                          ? item.ParamPosDetail
                                            ? item.ParamPosDetail.InfoValueEuro
                                            : item.InfoValueEuro
                                          : item.ParamPosDetail
                                          ? item.ParamPosDetail.InfoValueTL
                                          : item.InfoValueTL
                                      }
                                    />
                                  </p>
                                </div>
                                {item.ParamPosDetail && item.TotalAmount > 0 && (
                                  <div className='installmentContainer2'>
                                    <p style={{ whiteSpace: 'nowrap', width: '467px' }}>
                                      İndex Oran: {formatPrice(item.TotalMain, 'TRY', 'symbol')}
                                    </p>
                                    <p style={{ justifyContent: 'center', whiteSpace: 'nowrap' }}>
                                      {item.Installment}
                                      {item.AdditionalInstallment && '  ' + item.AdditionalInstallment}
                                    </p>
                                    <p style={{ paddingRight: '20px', whiteSpace: 'nowrap' }}>
                                      {item.InterestRate.toFixed(2) + ' %'}
                                    </p>
                                    <p style={{ paddingRight: '20px', whiteSpace: 'nowrap' }}>
                                      {state.selectedCurrency && state.selectedCurrency.value === 'USD'
                                        ? `${state.exchangeRate?.Usd}${' ₺'}`
                                        : state.selectedCurrency && state.selectedCurrency.value === 'EUR'
                                        ? `${state.exchangeRate?.Eur}${' ₺'}`
                                        : '1 ₺'}
                                    </p>
                                    <p style={{ paddingRight: '8px', whiteSpace: 'nowrap' }}>
                                      {item.ComissionAmountValue}
                                    </p>
                                    <p style={{ paddingRight: '8px', whiteSpace: 'nowrap' }}>
                                      {formatPrice(item.TotalAmount - item.TotalMain, 'TRY', 'symbol')}
                                    </p>
                                    <p style={{ paddingRight: '5px', whiteSpace: 'nowrap' }}>
                                      {item.TotalAmountTLValue}
                                    </p>
                                    <p style={{ whiteSpace: 'nowrap' }}>
                                      {state.selectedCurrency && state.selectedCurrency.value === 'TL'
                                        ? item.InfoValueTL
                                        : state.selectedCurrency && state.selectedCurrency.value === 'USD'
                                        ? item.InfoValueDolar
                                        : state.selectedCurrency && state.selectedCurrency.value === 'EUR'
                                        ? item.InfoValueEuro
                                        : item.InfoValueTL}
                                    </p>
                                  </div>
                                )}
                              </div>
                            )
                          })}
                      </div>
                    </div> */}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className='d-flex flex-wrap justify-content-end gap-4 mr-2 mt-3'>
          <IPButton
            text={state.isMarked ? 'Onaylandı' : 'Onayla'}
            type='button'
            style={{ width: 150, backgroundColor: state.isMarked ? 'green' : 'orange' }}
            onClick={() => getIsAuthorizedToCompany('Onay')}
          />
          <IPButton text='Öde' type='button' style={{ width: 150 }} onClick={() => getIsAuthorizedToCompany('Ödeme')} />
        </div>
        {state.installmentSpecials && state.selectedPaymentType === PaymentTypeEnum.CariOdeme && state.paramposBool && (
          <div className='row' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div className='img-star'>
              <img src='/images/star.png' alt='' style={{ width: '20px', height: '20px' }} />
            </div>
            <div className='img-p'>
              <p style={{ color: 'red', wordBreak: 'break-word', marginTop: '20px' }}>
                Param POS, Cari Hesap Ödemelerinizde sizin için en iyi oranı otomatik olarak uygular.
              </p>
            </div>
          </div>
        )}
      </div>
      <IPModal
        show={state.showPaymentDetails}
        onClose={() => {
          setState({ showPaymentDetails: false })
          window.location.reload()
          clearSelectedCompany()
        }}
        hideConfirm={true}
        width={1000}
      >
        <>
          <div>
            {/* Note: Print, Pdf ,Excel Bar */}
            <div className='form-row' style={{ justifyContent: 'center', position: 'relative' }}>
              <div className='col-md-3 text-right mt-3 mr-2' style={{ position: 'absolute', top: 5, right: 5 }}>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setState({ showMail: true })
                  }}
                  className='p-6'
                >
                  <img
                    style={{ marginTop: '-5px' }}
                    src='/images/icons/epostadetay.svg'
                    alt=''
                    width={40}
                    height={40}
                    title='Mail Gönder'
                  />
                </a>
                <a
                  onClick={async () => {
                    await printElementInvoiceRecord(document.getElementById('order'))
                  }}
                  className='p-3'
                >
                  <img
                    src='/images/icons/yazicidetay.svg'
                    alt=''
                    width={40}
                    height={40}
                    style={{ cursor: 'pointer' }}
                    title='Yazdır'
                  />
                </a>

                <a onClick={postReceiptExcel} className='p-3'>
                  <img
                    src='/images/icons/excel.png'
                    alt=''
                    width={30}
                    height={30}
                    style={{ cursor: 'pointer' }}
                    title="Excel'e Aktar"
                  />
                </a>
              </div>
              <div className='form-group'>
                <i
                  className='icon-check'
                  style={{
                    color: 'green',
                    fontSize: '50px',
                    marginLeft: '160px',
                  }}
                ></i>
                <h4>İşleminiz Başarıyla Gerçekleşmiştir!</h4>
              </div>
            </div>

            <hr />
            {/* Note: Yeni Makbuz Formatı */}
            <div id='order' className='container' style={{ padding: '10px' }}>
              <div className='inv-title'>
                <h2>Makbuz No # {state.paymentDetail?.ReceiptNumber}</h2>
              </div>
              {selectedCompany === '1000' ? (
                <img src='/images/ındex_logo.png' className='inv-logo' alt='Logo' />
              ) : selectedCompany === '1100' ? (
                <img src='/images/datagate_logo.png' className='inv-logo' alt='Logo' />
              ) : selectedCompany === '1200' ? (
                <img src='/images/despec_logo.png' className='inv-logo' alt='Logo' />
              ) : selectedCompany === '2300' ? (
                <img src='/images/netex_logo.png' className='inv-logo' alt='Logo' />
              ) : (
                ''
              )}
              <div className='inv-header'>
                <div>
                  <h2>Bayi Bilgileri</h2>
                  <ul>
                    <li>{state.paymentDetail?.DealerCode}</li>
                    <li>{state.paymentDetail?.DealerName}</li>
                  </ul>
                  <h2>Kredi Kartı Makbuzu</h2>
                  <ul>
                    {/* <li>
                      {selectedCompany === '1000'
                        ? 'Index Bilgisayar Sistemleri Tic. A.Ş.'
                        : selectedCompany === '1100'
                        ? 'DATAGATE BİLGİSAYAR MALZEMELERİ TİCARET A.Ş'
                        : selectedCompany === '1200'
                        ? 'Despec Bilgisayar Pazarlama Tic. A.Ş.'
                        : selectedCompany === '2300'
                        ? 'NETEKS TEKNOLOJİ ÜRÜNLERİ A.Ş.'
                        : ''}
                    </li> */}
                    <li>{getCompanyLongName(selectedCompany)}</li>
                    <li>
                      İstanbul Merkez Ofis - Ayazağa Mahallesi, Mimar Sinan Sokak No:21 Seba Office Boulevard, D Blok
                      Kat: 1 Bölüm No: 11 Sarıyer, 34485 İstanbul
                    </li>
                    <li>0 (212) 331 21 21</li>
                  </ul>
                </div>
                <div>
                  <table>
                    <tr>
                      <th>Tarih</th>
                      <td>
                        {state.paymentDetail?.Date
                          ? new Date(state.paymentDetail?.Date).toLocaleDateString('tr-TR')
                          : '-'}
                      </td>
                    </tr>
                    <tr>
                      <th>Belge No</th>
                      <td>{state.paymentDetail?.DocumentNo}</td>
                    </tr>
                    <tr>
                      <th>Banka</th>
                      <td>{state.paymentDetail?.Bank}</td>
                    </tr>
                    <tr>
                      <th>Taksit Sayısı</th>
                      <td>{state.paymentDetail?.Installment}</td>
                    </tr>
                    <tr>
                      <th>Tutar (TL)</th>
                      <td>
                        {' '}
                        {state.paymentDetail
                          ? formatPrice(state.paymentDetail?.Amount, 'TRY', 'symbol')
                          : formatPrice(0, 'TRY', 'symbol')}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className='inv-body'>
                <table>
                  <thead>
                    <tr>
                      <th>Ad Soyad</th>
                      <th>Kredi Kartı Numarası</th>
                      <th>Son Kullanma Tarihi(CVC)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <h4> {state.paymentDetail?.NameSurname}</h4>
                      </td>
                      <td>{state.paymentDetail?.CrediCard}</td>
                      <td>{state.paymentDetail?.ExpireDate}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div className='inv-footer'>
                <div></div>
                <div>
                  <table>
                    <tr>
                      <th>Sub total</th>
                      <td>200</td>
                    </tr>
                    <tr>
                      <th>Sales tax</th>
                      <td>200</td>
                    </tr>
                    <tr>
                      <th>Grand total</th>
                      <td>1200</td>
                    </tr>
                  </table>
                </div>
              </div> */}
            </div>
            {/* Note: Eski Makbuz
            <div id='order2'>
              <div className='form-row' style={{ justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
                <div className='form-group'>
                  <h4 style={{ marginTop: '10px' }}>Kredi Kartı Makbuzu</h4>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col-md-12 d-flex align-items-center justify-content-center'>
                  {selectedCompany === '1000' ? (
                    <Image src='/images/ındex_logo.png' alt='' style={{ width: '150px' }} />
                  ) : selectedCompany === '1100' ? (
                    <Image src='/images/datagate_logo.png' alt='' style={{ width: '150px' }} />
                  ) : selectedCompany === '1200' ? (
                    <Image src='/images/despec_logo.png' alt='' style={{ width: '150px' }} />
                  ) : selectedCompany === '2300' ? (
                    <Image src='/images/netex_logo.png' alt='' style={{ width: '150px' }} />
                  ) : (
                    ''
                  )}
                </div>
                <div className='col'>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Makbuz No</b> <span className='mr-2'>:</span>
                    {state.paymentDetail?.ReceiptNumber}
                  </div>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Tutar (TL) </b>
                    <span className='mr-2'>:</span>
                    {state.paymentDetail
                      ? formatPrice(state.paymentDetail?.Amount, 'TRY', 'symbol')
                      : formatPrice(0, 'TRY', 'symbol')}
                  </div>
                </div>

                <div className='col'>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Belge No</b> <span className='mr-2'>:</span>
                    {state.paymentDetail?.DocumentNo}
                  </div>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Banka </b>
                    <span className='mr-2'>:</span>
                    {state.paymentDetail?.Bank}
                  </div>
                </div>
                <div className='col'>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Tarih</b> <span className='mr-2'>:</span>
                    {state.paymentDetail?.Date ? new Date(state.paymentDetail?.Date).toLocaleDateString('tr-TR') : '-'}
                  </div>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Taksit Sayısı </b>
                    <span className='mr-2'>:</span>
                    {state.paymentDetail?.Installment}
                  </div>
                </div>
              </div>
              <hr />

              <div className='d-flex align-items-center justify-content-start'>{state.paymentDetail?.DealerCode}</div>
              <div className='d-flex align-items-center justify-content-start'>{state.paymentDetail?.DealerName}</div>
              <h5 style={{ marginTop: '10px' }}>
                <b>Kredi Kartı Makbuzu</b>
              </h5>
            </div>
            <hr />
            <div className='d-flex align-items-center justify-content-start'>
              <b style={{ width: '200px' }}>Ad Soyad </b>
              <span className='mr-2'>:</span>
              {state.paymentDetail?.NameSurname}
            </div>
            <div className='d-flex align-items-center justify-content-start'>
              <b style={{ width: '200px' }}>Kredi Kartı Numarası</b>
              <span className='mr-2'>:</span>
              {state.paymentDetail?.CrediCard}
            </div>
            <div className='d-flex align-items-center justify-content-start'>
              <b style={{ width: '200px' }}>Son kullanma Tarihi</b>
              <span className='mr-2'>:</span>
              {state.paymentDetail?.ExpireDate}
            </div> */}
          </div>

          {/* <div className='d-flex justify-content-end'>
            <IPButton
              text='Yazdır'
              className='button w-25'
              isRounded={false}
              onClick={() => {
                printElementInvoiceRecord(document.getElementById('order')) //Makbuz için özelleştirilmiş print func.
              }}
            />
            <IPButton
              name='createOfferInModal'
              className='button ml-5 w-25'
              onClick={() => {
                setState({ showMail: true })
              }}
              text='E-Posta Gönder'
              icon='bi_chat-left-text-fill.png'
            />
            <IPButton text="Excel'e Gönder" className='button ml-5 w-25' isRounded={false} onClick={postReceiptExcel} />
          </div> */}
        </>
      </IPModal>
      <IPModal
        show={state.showMail}
        onClose={() => {
          setState({ showMail: false })
        }}
        width={500}
        onConfirm={orderSendMail}
        confirmText={'Mail Gönder'}
      >
        <div className='form-group col-md-12 align-items-center '>
          <label className='text-sm-left'>Ad Soyad</label>
          <IPInput
            type='text'
            className='form-control'
            name='sendMail'
            required
            value={state.mailName}
            onChange={e => {
              setState({ mailName: e.target.value })
            }}
          />
          <label className='text-sm-left'>Mail Adresi</label>
          <IPInput
            type='text'
            className='form-control'
            name='sendMail'
            required
            value={state.mailEmailText}
            onChange={e => {
              setState({ mailEmailText: e.target.value })
            }}
          />
          <label className='text-sm-left'>Konu</label>
          <IPInput
            type='text'
            className='form-control'
            name='sendMail'
            required
            value={state.mailSubject}
            onChange={e => {
              setState({ mailSubject: e.target.value })
            }}
          />
          <label className='text-sm-left'>Kişisel Notunuz</label>
          <IPTextarea
            className='form-control'
            name='sendMail'
            required
            value={state.mailNote}
            onChange={e => {
              setState({ mailNote: e.target.value })
            }}
          />
          <label className='text-sm-left'>*Tüm alanların doldurulması zorunludur.</label>
          <label className='text-sm-left'>
            ** Birden fazla kişiye e-posta atmak için, adreslerin arasına virgül (",") koyunuz.
          </label>
        </div>
      </IPModal>
      <IPModal
        title={state.modalBanner?.Title}
        width='auto'
        maxWidth={1440}
        show={state.showModalPopup}
        onClose={() => setState({ showModalPopup: false })}
        //onClose={handleCloseModal}
        hideConfirm={true}
      >
        <div className='p-3'>
          <div className='row'>
            {state.modalBanner && state.modalBanner.BannerType === 7 ? (
              state.modalBanner.VideoId && (
                <iframe
                  width='100%'
                  style={{ height: '400px' }}
                  src={'https://www.youtube.com/embed/' + state.modalBanner.VideoId}
                  title='YouTube video player'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                ></iframe>
              )
            ) : state.modalBanner && state.modalBanner.BannerType === 8 ? (
              state.modalBanner.VideoId && (
                <iframe
                  src={'https://player.vimeo.com/video/' + state.modalBanner.VideoId + '?h=ff2b296e05&color=ffffff'}
                  width='640'
                  height='360'
                  allow='autoplay; fullscreen; picture-in-picture'
                ></iframe>
              )
            ) : state.modalBanner && state.modalBanner.BannerType !== 1 ? (
              state.modalBanner.Image && state.modalBanner.Image.toLocaleLowerCase('tr-TR').indexOf('.mp4') > -1 ? (
                <video width='100%' preload='auto' controls style={{ height: '100%', padding: '5px 0 0 0' }}>
                  <source src={state.modalBanner.ImageUrl} type='video/mp4'></source>
                  Sorry, your browser doesn't support videos.
                </video>
              ) : state.modalBanner.Image.toLocaleLowerCase('tr-TR').indexOf('.MP4') > -1 ? (
                <video width='100%' preload='auto' controls style={{ height: '100%', padding: '5px 0 0 0' }}>
                  <source src={state.modalBanner.ImageUrl} type='video/MP4'></source>
                  Sorry, your browser doesn't support videos.
                </video>
              ) : state.modalBanner.ProductAddress ? (
                <a href={state.modalBanner.ProductAddress} target={state.modalBanner.BannerType === 6 ? '_blank' : ''}>
                  {
                    <IPImage
                      className='banner'
                      style={{ height: '100%', padding: '5px 0 0 0' }}
                      src={state.modalBanner.ImageUrl}
                      alt={state.modalBanner.Title}
                    />
                  }
                </a>
              ) : (
                <Link
                  to={`/products?bannerId=${state.modalBanner.Id}`}
                  target={state.modalBanner.BannerType === 6 ? '_blank' : ''}
                >
                  {
                    <IPImage
                      className='banner'
                      style={{ height: '100%', padding: '5px 0 0 0' }}
                      src={state.modalBanner.ImageUrl}
                      alt={state.modalBanner.Title}
                    />
                  }
                </Link>
              )
            ) : (
              state.modalBanner && (
                <IPImage
                  className='banner'
                  style={{ height: '100%', padding: '5px 0 0 0' }}
                  src={state.modalBanner.ImageUrl}
                  alt={state.modalBanner.Title}
                />
              )
            )}
          </div>
        </div>
      </IPModal>
      <div ref={paymentRef} style={{ display: 'none' }}></div>
    </React.Fragment>
  )
}

export default componentWithContext(Payment)
