import { useFormik } from 'formik'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useCustomReducer } from '../../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { uuidv4 } from '../../../../../base/utils'
import { excelToJSON, exportToXLSX } from '../../../../../base/utils/dataHelper'
import IPButton from '../../../../../components/IPButton'
import IPFile from '../../../../../components/IPFile'
import IPInput from '../../../../../components/IPInput'
import { IPInputDataPattern } from '../../../../../components/IPInput/IPInput'
import IPModal from '../../../../../components/IPModal'
import IPSelectBox, { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { getCompanyApi } from '../../../components/api'
import { ICompany } from '../../../components/models/models'
import InputCampaign from '../../../ProductOperation/CampaignBundleCrud/components/InputCampaign'
import { FCVRule } from '../../../ProductOperation/CampaignBundleCrud/services/FCVRuleData'
import { GetPoductSubTypeListApi } from '../../../ProductOperation/CampaignBundleCrud/services/getPoductSubTypeListApi'
import {
  CheckUsedRequestModel,
  ClusterAndLimit,
  FCVObject,
  IFcvTable,
  IHistory,
  InsertFcvService,
} from '../../../ProductOperation/CampaignBundleCrud/services/InsertFcvService'
import { IyzicoCampaignService } from '../../../ProductOperation/IyzicoCampaign/IyzicoCampaignService'
import '../Fcv.scss'
import { DealerControl, DealerControlReq } from '../services/dealerControl'
import { CheckUsedTotal } from '../services/dealerLimitCheck'
import { PaymentTermHistoryByIdApi } from '../services/paymentTermHistoryByIdApi'
import { UpdatePaymentTerms } from '../services/updatePaymentTerms'

enum CariListType {
  CariCode,
  CariAltType,
  CariType,
}
export interface ICampaignState {
  showModal: boolean
  selectedDealerList?: CariListType
  isProductCodesInclude?: boolean
  isQuotaCodesInclude?: boolean
  isGroupCodesInclude?: boolean
  isBrandsInclude?: boolean
  isSubTypesInclude?: boolean
  productCodeList?: ISelectOption[]
  QuotaCodeList?: ISelectOption[]
  GroupList?: ISelectOption[]
  BrandList?: ISelectOption[]
  ProductSubTypeList?: ISelectOption[]
  minMaxType: boolean
  minQuantity: number
  maxQuantity: number
  totalCondition: ISelectOption
  minPrice: number
  maxPrice: number
  currencyType: ISelectOption
  dealerCodeOptions?: []
  dealerTypeOptions?: []
  basketOptions: ISelectOption
  isNewRecord: boolean
  selectedBasketConditionId: string
  resultOperation?: number
  productConditionQuantity?: number
  productConditionMaxQuantity?: number
  productContionAmountOrRatio?: number
  productConditionCurrencyType?: number
  productCodeOptions?: ISelectOption[]
  quotaCodeOptions?: string[]
  brandListOptions?: string[]
  wareHouseList?: ISelectOption[]
  giftCode: string
  selectedMaterials: ISelectOption[]
  dagitimKanaliList: ISelectOption[]
  type?: ISelectOption
  cluesterType?: ISelectOption
  limit?: number
  cluster: string
  clusterOld: string
  tableName: string
  dealerId: number
  expiryDay: string
  productSubTypeOptions: ISelectOption[]
  showHistory: boolean
  historyData: IHistory[]
  artiLimit?: number
  eksiLimit?: number
  displayNewLimit?: number
}

function FcvCampaignAdd(props: BaseComponentWithContextProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const [fcvTable, setFcvTable] = useState<IFcvTable[]>([])
  const [searchFcvTable, setSearchFcvTable] = useState<IFcvTable[]>([])
  const [searchDealerText, setSearchDealerText] = useState('')
  const [searchDealerBool, setSearchDealerBool] = useState(false)

  const getInitialState = (): ICampaignState => {
    return {
      showModal: false,
      isNewRecord: false,
      selectedDealerList: CariListType.CariCode,
      isProductCodesInclude: true,
      isQuotaCodesInclude: true,
      isGroupCodesInclude: true,
      isBrandsInclude: true,
      isSubTypesInclude: true,
      productCodeList: [],
      minMaxType: true,
      minQuantity: 0,
      maxQuantity: 0,
      minPrice: 0,
      maxPrice: 0,
      QuotaCodeList: [],
      totalCondition: { label: 'Hayır', value: 'H' },
      currencyType: { label: 'TRY', value: 'TRY' },
      basketOptions: { label: 'Sepet', value: 1 },
      selectedBasketConditionId: '',
      wareHouseList: [],
      giftCode: '',
      selectedMaterials: [],
      dagitimKanaliList: [],
      // dealerCodeOptions: [],
      cluster: '',
      tableName: 'Tabloya Ekle',
      dealerId: 0,
      expiryDay: '',
      productSubTypeOptions: [],
      clusterOld: '',
      showHistory: false,
      historyData: [],
      artiLimit: 0,
      eksiLimit: 0,
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ICampaignState>(intialState)
  const [fvcRuleData, setFcvRuleData] = useState<FCVRule[]>([])
  // const [dealerBool, setDealerBool] = useState(false)
  const [companies, setCompanies] = useState<ISelectOption[]>([])

  // useEffect(() => {
  //   dealerService()
  //   const api: FCVRuleDataService = new FCVRuleDataService({});

  //   props.showLoading();
  //   api.listAllFCVRules().then(res => {
  //     setFcvRuleData(res)
  //   }).finally(() => {
  //     props.hideLoading();
  //   });
  // }, []);

  const validationSchema = yup.object().shape({
    //BundleCode: yup.string().required("Lütfen kampanya kodunu girin!"),
    //SapCampaignCode: yup.string().max(50, "Karakter sınırı 50 yi geçmemeli"),
    ProductDetailDescription: yup.string().max(50, 'Karakter sınırı 50 yi geçmemeli'),
    //CampaignDescription: yup.string().required("Lütfen açıklamayı girin!"),
  })

  const sweetAlert = (icon: 'error' | 'success', title: string, message: string) => {
    return Swal.fire({
      icon,
      title,
      html: `<div style='font-size: 17px'>${message}</div>`,
      showConfirmButton: true,
      allowOutsideClick: false,
    })
  }

  const { handleSubmit, handleChange, handleBlur, setFieldValue, setValues, values, errors, touched } = useFormik({
    initialValues: {
      Id: 0,
      PaymentTermsName: '',
      Company: '',
      InvoiceNumbers: [],
      DistributionChannel: [],
      StartDate: '',
      EndDate: '',
      Enabled: true,
      Type: 0,
      CluesterType: 0,
      ClusterAndLimit: [],
    },
    validationSchema,
    onSubmit: async () => {
      if (!values.Company || values.Company.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Lütfen şirket giriniz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }

      if (!values.PaymentTermsName || values.PaymentTermsName.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Lütfen ad giriniz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }

      if (fcvTable.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Lütfen cari giriniz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }

      if (values.EndDate.length === 0 || values.StartDate.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Lütfen tarihleri giriniz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }
      if (location.state) {
        var distributionChannel: any[] = []
        var invoiceNumber: any[] = []

        for (const disChannel of values.DistributionChannel) {
          distributionChannel.push((disChannel as any).value)
        }

        for (const invoiceNo of values.InvoiceNumbers) {
          invoiceNumber.push((invoiceNo as any).value)
        }

        const data: ClusterAndLimit[] = []
        let i = 0
        fcvTable &&
          fcvTable.map(fcv => {
            if (fcv.Bool) {
              data.push({
                Id: fcv.Id,
                Cluster: fcv.DealerCode,
                Limit: fcv.Limit,
                ClusterType: state.cluesterType ? Number(state.cluesterType?.value) : 0,
                ClusterName: '',
                UsedLimit: 0,
                RemainderLimit: 0,
              })
              return null
            } else {
              i++
            }
          })

        const fcv: FCVObject = {
          Company: String(values.Company),
          ProductCodes: productCodeValue?.map(x => x.value) as string[],
          ProductSubTypes: productSubTypeValue?.map(x => x.value) as string[],
          Quota: quotaCodesValue?.map(x => x.value) as string[],
          Brands: brandIdValue?.map(x => x.label) as string[],
          ExpiryDay: +state.expiryDay,
          DistributionChannel: distributionChannel as any,
          StartDate: values.StartDate,
          EndDate: values.EndDate,
          Enabled: true,
          Type: state.type ? Number(state.type?.value) : 0,
          ClusterType: state.cluesterType ? Number(state.cluesterType?.value) : 0,
          PaymentTermsName: values.PaymentTermsName,
          ClusterAndLimit: data,
          InvoiceNumbers: invoiceNumber as any,
          Id: values.Id,
        }

        if (i > 0) {
          Swal.fire({
            icon: 'question',
            title: 'Hatalı Cariler var yinede yüklemek ister misiniz? Hatalı cariler silinecektir.',
            showConfirmButton: true,
            allowOutsideClick: false,
            showDenyButton: true,
            confirmButtonText: 'Evet',
            denyButtonText: 'Hayır',
          }).then(async result => {
            if (result.isConfirmed) {
              props.showLoading()
              const paymentTerms = new UpdatePaymentTerms({})

              try {
                const res = await paymentTerms.updatePaymentTerms(fcv)

                if (!res) {
                  props.hideLoading()
                  return sweetAlert('error', 'Hata', 'Beklenmedik bir hata oluştu.')
                } else {
                  props.hideLoading()
                  sweetAlert('success', 'Başarılı', 'Kampanya Güncellendi').then(() =>
                    navigate(
                      '/admin/bankAndInstallementOperation/fcv/fcvCampaignList' +
                        (location.state?.url ? '?' + location.state?.url : '')
                    )
                  )
                }
              } catch (err: any) {
                props.hideLoading()
                sweetAlert('error', 'Hata', err.description)
              }
            }
          })
        } else {
          const paymentTerms = new UpdatePaymentTerms({})

          try {
            props.showLoading()
            const res = await paymentTerms.updatePaymentTerms(fcv)

            if (!res) {
              props.hideLoading()
              sweetAlert('error', 'Hata', 'Beklenmedik bir hata oluştu.')
            } else {
              props.hideLoading()
              Swal.fire({
                icon: 'success',
                title: 'Kampanya Güncellendi',
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then(async () => {
                navigate(
                  '/admin/bankAndInstallementOperation/fcv/fcvCampaignList' +
                    (location.state?.url ? '?' + location.state?.url : '')
                )
              })
            }
          } catch (err: any) {
            props.hideLoading()
            sweetAlert('error', 'Hata', err.description)
          }
        }
        // const data: ClusterAndLimit[] = [];
        // fcvTable && fcvTable.map((fcv) => {
        //   data.push({ Id: fcv.Id, Cluster: fcv.DealerCode, Limit: fcv.Limit, ClusterType: state.cluesterType ? Number(state.cluesterType?.value) : 0, ClusterName: "", UsedLimit: Number(fcv.UsedLimit) })
        // })

        // var fcv: FCVObject = {
        //   Company: values.Company,
        //   // DealerCodes: dealerCodes as any,
        //   // SubTypes: (subTypes as any),
        //   // Quota: (quota as any),
        //   MaterialNumbers: (materials as any),
        //   DistributionChannel: (distributionChannel as any),
        //   StartDate: values.StartDate,
        //   EndDate: values.EndDate,
        //   Enabled: true,
        //   Type: state.type ? Number(state.type?.value) : 0,
        //   ClusterType: state.cluesterType ? Number(state.cluesterType?.value) : 0,
        //   PaymentTermsName: values.PaymentTermsName,
        //   ClusterAndLimit: data,
        //   InvoiceNumbers: (invoiceNumber as any),
        //   Id: values.Id

        // }
        // const paymentTerms: UpdatePaymentTerms = new UpdatePaymentTerms({});
        // paymentTerms.updatePaymentTerms(fcv).then(async (result) => {
        //   if (result) {
        //     Swal.fire({
        //       icon: "success",
        //       title: "Kampanya Güncellendi",
        //       showConfirmButton: true,
        //       allowOutsideClick: false,
        //     }).then(async (result) => {
        //       navigate("/admin/bankAndInstallementOperation/fcv/fcvCampaignList");
        //     });
        //   }
        // }).catch((err: any) => {
        //   Swal.fire({
        //     icon: "error",
        //     title: err.description,
        //     showConfirmButton: true,
        //     allowOutsideClick: false,
        //   });
        // });
      } else {
        const insertFcvAPI = new InsertFcvService({})

        var distributionChannel: any[] = []
        var invoiceNumber: any[] = []

        // for (const dealer of values.DealerCodes) {
        //   dealerCodes.push((dealer as any).value);
        // }

        for (const disChannel of values.DistributionChannel) {
          distributionChannel.push((disChannel as any).value)
        }

        // for (const subType of values.SubTypes) {
        //   subTypes.push((subType as any).value);
        // }

        // for (const quotaItem of values.Quota) {
        //   quota.push((quotaItem as any).value);
        // }

        for (const invoiceNo of values.InvoiceNumbers) {
          invoiceNumber.push((invoiceNo as any).value)
        }

        const data: ClusterAndLimit[] = []
        let i = 0
        fcvTable &&
          fcvTable.map(fcv => {
            // if (errorMessage) {
            //   if (fcv.Bool)
            //     data.push({ Id: fcv.Id, Cluster: fcv.DealerCode, Limit: fcv.Limit })
            //   return;
            // }
            if (fcv.Bool) {
              data.push({
                Id: fcv.Id,
                Cluster: fcv.DealerCode,
                Limit: fcv.Limit,
                ClusterType: state.cluesterType ? Number(state.cluesterType?.value) : 0,
                ClusterName: '',
                UsedLimit: 0,
                RemainderLimit: 0,
              })
              return null
            } else {
              i++
            }
          })

        const fcv: FCVObject = {
          Company: String(values.Company),
          ProductCodes: productCodeValue?.map(x => x.value) as string[],
          ProductSubTypes: productSubTypeValue?.map(x => x.value) as string[],
          Quota: quotaCodesValue?.map(x => x.value) as string[],
          Brands: brandIdValue?.map(x => x.label) as string[],
          ExpiryDay: +state.expiryDay,
          DistributionChannel: distributionChannel as any,
          StartDate: values.StartDate,
          EndDate: values.EndDate,
          Enabled: true,
          Type: state.type ? Number(state.type?.value) : 0,
          ClusterType: state.cluesterType ? Number(state.cluesterType?.value) : 0,
          PaymentTermsName: getCompany(values.Company) + ' ' + values.PaymentTermsName,
          ClusterAndLimit: data,
          InvoiceNumbers: invoiceNumber as any,
        }

        if (i > 0) {
          Swal.fire({
            icon: 'question',
            title: 'Hatalı Cariler var yinede yüklemek ister misiniz? Hatalı cariler silinecektir.',
            showConfirmButton: true,
            allowOutsideClick: false,
            showDenyButton: true,
            confirmButtonText: 'Evet',
            denyButtonText: 'Hayır',
          }).then(async result => {
            if (result.isConfirmed) {
              props.showLoading()

              try {
                const res = await insertFcvAPI.insertFCVRule(fcv)
                if (!res) {
                  props.hideLoading()
                  sweetAlert('error', 'Hata', 'Beklenmedik bir hata oluştu.')
                } else {
                  props.hideLoading()
                  Swal.fire({
                    icon: 'success',
                    title: 'Ödeme Kampanyası Eklendi.',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                  }).then(async () => {
                    navigate(
                      '/admin/bankAndInstallementOperation/fcv/fcvCampaignList' +
                        (location.state?.url ? '?' + location.state?.url : '')
                    )
                  })
                }
              } catch (err: any) {
                props.hideLoading()
                sweetAlert('error', 'Hata', err.description)
              }
            }
            return
          })
        } else {
          try {
            props.showLoading()
            const res = await insertFcvAPI.insertFCVRule(fcv)
            if (!res) {
              props.hideLoading()
              sweetAlert('error', 'Hata', 'Beklenmedik bir hata oluştu.')
            } else {
              props.hideLoading()
              Swal.fire({
                icon: 'success',
                title: 'Ödeme Kampanyası Eklendi.',
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then(async () => {
                navigate(
                  '/admin/bankAndInstallementOperation/fcv/fcvCampaignList' +
                    (location.state?.url ? '?' + location.state?.url : '')
                )
              })
            }
          } catch (err: any) {
            props.hideLoading()
            sweetAlert('error', 'Hata', err.description)
          }
        }
      }
    },
  })

  const getCompany = (company: string) => {
    const companyMap = {
      '1000': 'INDEX',
      '1100': 'DATAGATE',
      '1200': 'DESPEC',
      '2300': 'NETEX',
    }
    return companyMap[company] || ''
  }

  const onCreateOption = (option, property, updateFunc?) => {
    if (updateFunc === undefined || updateFunc === null) {
      const newData = {
        value: option[option.length - 1].value,
        label: option[option.length - 1].label,
      }

      if (values[property] && values[property].length > 0) {
        for (const state of values[property]) {
          if (state.value === newData.value) return [...values[property]]
          const prevData = values[property]
          setFieldValue(property, [...prevData, newData])
          return [...values[property], newData]
        }
      }
      setFieldValue(property, [newData])
      return [newData]
    }

    updateFunc(prevState => {
      const newData = {
        value: option[option.length - 1].value,
        label: option[option.length - 1].label,
      }
      if (prevState && prevState.length > 0) {
        for (const state of prevState) {
          if (state.value === newData.value) return [...prevState]
          return [...prevState, newData]
        }
      }
      return [newData]
    })
  }

  const placeholder = (value: string) => (
    <div
      style={{
        color: '#000',
        fontSize: '12px',
        fontWeight: '600',
      }}
    >
      {value}
    </div>
  )

  const [optionList, setOptionList] = useState<any>({
    brandIdOptions: [],
    quotaCodeOptions: [],
    productSubTypeOptions: [],
    productCodeOptions: [],
  })

  const getProductSubTypes = async () => {
    const getPoductSubTypeList: GetPoductSubTypeListApi = new GetPoductSubTypeListApi({})
    const res = await getPoductSubTypeList.getPoductSubTypeList()
    if (res) {
      const productSubTypeOptions: ISelectOption[] = []
      res.forEach((item: any) => {
        item && productSubTypeOptions.push({ value: item, label: item })
      })
      setState({ productSubTypeOptions })
    }
  }

  const [brandIdValue, setBrandIdValue] = useState<ISelectOption[] | null>(null)
  const getBrandList = async () => {
    const res = await new IyzicoCampaignService({}).getBrand()
    if (res) {
      const brands: any = []
      res.forEach(item => {
        if (item.Name.length === 0) return
        brands.push({ value: item.Id, label: item.Name })
      })
      return brands
    }
  }

  const checkUsedTotal = async (fcvId: number) => {
    const req: CheckUsedRequestModel = {
      FcvId: fcvId,
      PaymentTermsId: values.Id,
    }
    try {
      const result = await new CheckUsedTotal({}).checkUsedTotal(req)
      if (result.UsedTotal > 0) {
        Swal.fire({
          icon: 'error',
          title: `Bayinin güncel harcama değeri ${result.UsedTotal}. Harcama yapan bayi silinemez!`,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return false // Return false to indicate the dealer cannot be deleted
      } else {
        return true // Return true to indicate the dealer can be deleted
      }
    } catch (error) {
      // Handle any potential errors that might occur during the async operation
      console.error('Error fetching limit details:', error)
      Swal.fire({
        icon: 'error',
        title: 'Limit bilgileri alınırken bir hata oluştu.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return false // Return false to indicate a problem occurred
    }
  }

  const [quotaCodesValue, setQuotaCodesValue] = useState<ISelectOption[] | null>(null)
  const [productCodeValue, setProductCodeValue] = useState<ISelectOption[] | null>(null)
  const getProductAndQuotaCodes = async () => {
    const res = await new IyzicoCampaignService({}).getProductAndQuotaCodes()
    if (!res) return []
    const data = {
      quotaCodes: res.Entity.QuotaCode.map(item => ({ label: item, value: item })),
      productCodes: res.Entity.ProductsInfo.map(item => ({ label: item.ProductCode, value: item.ProductCode })),
    }
    return data
  }

  const [productSubTypeValue, setProductSubTypeValue] = useState<ISelectOption[] | null>(null)

  const searchDealer = async (text: string) => {
    const data: IFcvTable[] = []
    fcvTable
      .filter(fcv => {
        return fcv.DealerCode.indexOf(text) > -1
      })
      .map(tab => {
        return data.push(tab)
      })
    setSearchFcvTable(data)
    setSearchDealerText(text)
    setSearchDealerBool(true)
  }

  const [currentQuotaCodes, setCurrentQuotaCodes] = useState<ISelectOption[]>([])
  const [materials, setMaterials] = useState<ISelectOption[]>([])
  const [currentDagitimKanaliList, setcurrentDagitimKanaliList] = useState<ISelectOption[]>([])
  const [currentBayiCodes, setCurrentBayiCodes] = useState<ISelectOption[]>([])
  const [selectedBayiCodes, setSelectedBayiCodes] = useState<ISelectOption[]>([])
  const [currentSubTypes, setCurrentSubTypes] = useState<ISelectOption[]>([])
  const [currentCompany, setCurrentCompany] = useState<ISelectOption>()

  useEffect(() => {
    const companyData =
      fvcRuleData &&
      fvcRuleData.find(
        (elem: FCVRule) => currentCompany && currentCompany && elem?.CompanyNumber === currentCompany?.value
      )

    if (companyData) {
      setMaterials(
        companyData.FcvDataRule.MaterialList.map(material => {
          return { label: material.ProductName, value: material.MaterialNumber }
        })
      )
      setCurrentQuotaCodes(
        companyData.FcvDataRule.QuotaList.map(quota => {
          return { label: quota, value: quota }
        })
      )
      setcurrentDagitimKanaliList(
        companyData.FcvDataRule.DagitimKanaliList.map(dagitimKanali => {
          return { label: dagitimKanali, value: dagitimKanali }
        })
      )
      setCurrentBayiCodes(
        companyData.FcvDataRule.BayiCodes.map(bayiCode => {
          return { label: bayiCode, value: bayiCode }
        })
      )
      setCurrentSubTypes(
        companyData.FcvDataRule.SubTypes.map(subType => {
          return { label: subType, value: subType }
        })
      )
    }
  }, [currentCompany?.value, fvcRuleData])

  useEffect(() => {
    var arr: any[] = []
    arr.push(currentCompany?.value)
    setFieldValue('XCompanyCodes', arr)
  }, [currentCompany])

  useEffect(() => {
    getCompanies()
    getProductSubTypes()
  }, [])

  useEffect(() => {
    if (state.type?.value === 2) {
      props.showLoading()
      Promise.all([getBrandList(), getProductAndQuotaCodes()]).then((data: any) => {
        const [brandIdOptions, productAndQuotaCodes] = data

        const quotaCodeOptions = productAndQuotaCodes.quotaCodes
        const productCodeOptions = productAndQuotaCodes.productCodes

        setOptionList({
          brandIdOptions,
          quotaCodeOptions,
          productCodeOptions,
        })
        props.hideLoading()
      })
    }
  }, [state.type])

  useEffect(() => {
    if (searchDealerBool) {
      setSearchFcvTable([])
      setSearchDealerBool(false)
      setSearchDealerText('')
    }
  }, [fcvTable])

  const dateValid = () => {
    const result = new Date(values.EndDate).getTime() - new Date(values.StartDate).getTime()
    if (result < 60000) Swal.fire('Kampanya bitiş tarihi başlangıç tarihinden önce olamaz.')
  }
  const dateStartValid = () => {
    /*
    if (new Date(values.StartDate).getTime() < new Date().getTime()) {
      Swal.fire('Kampanya başlangıç tarihi şuan ki tarihten önce olamaz.')
      // setFieldValue("StartDate", "");
    }
    */
  }
  const dateEndValid = () => {
    /*
    if (new Date(values.EndDate).getTime() < new Date().getTime()) {
      Swal.fire('Kampanya bitiş tarihi şuan ki tarihten önce olamaz.')
      // setFieldValue("EndDate", "");
    }
    */
  }

  const setDealerCodes = dealer => {
    let data: IFcvTable[] = []
    let dataTable: IFcvTable[] = []
    let list: IFcvTable[] = []
    if (!state.cluesterType) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen bayi tipi seçiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (Number(state.cluesterType?.value) === 1 || Number(state.cluesterType?.value) === 2) {
      if (!values.Company) {
        Swal.fire({
          icon: 'error',
          title: 'Lütfen şirket seçiniz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }
    }
    const req: DealerControlReq = {
      ClusterType: state.cluesterType ? Number(state.cluesterType?.value) : 0,
      CompanyCode: String(values.Company),
      Data: dealer.map((i: any) => {
        if (Number(state.cluesterType?.value) === 0) return String(i.DealerCode)?.padStart(10, '0')
        else return String(i.DealerCode)
      }),
    }

    const dealerCon: DealerControl = new DealerControl({})
    dealerCon
      .dealerControl(req)
      .then(async (result: any) => {
        if (result.Data.length > 0) {
          dealer.forEach((d, index) => {
            if (fcvTable.length > 0) {
              let i = 0
              fcvTable.map(fcv => {
                if (Number(state.cluesterType?.value) === 0) {
                  if (fcv.DealerCode === String(d.DealerCode).padStart(10, '0')) {
                    return i++
                  }
                } else {
                  if (fcv.DealerCode === String(d.DealerCode)) {
                    return i++
                  }
                }
                return null
              })
              if (i > 0) {
                if (Number(state.cluesterType?.value) === 0) {
                  return dataTable.push({
                    Id: 0,
                    DealerCode: String(d.DealerCode).padStart(10, '0'),
                    Limit: d.Limit,
                    Title: 'Bu cari tabloda bulunmaktadır',
                    Bool: false,
                    StateId: uuidv4(),
                  })
                } else {
                  return dataTable.push({
                    Id: 0,
                    DealerCode: String(d.DealerCode),
                    Limit: d.Limit,
                    Title: 'Bu cari tabloda bulunmaktadır',
                    Bool: false,
                    StateId: uuidv4(),
                  })
                }
              }
            }
            if (Number(state.cluesterType?.value) === 0) {
              if (result.Data.indexOf(String(d.DealerCode).padStart(10, '0')) > -1) {
                return list.push({
                  Id: 0,
                  DealerCode: String(d.DealerCode).padStart(10, '0'),
                  Limit: d.Limit,
                  Title: 'Cari Bulunamadı',
                  Bool: false,
                  StateId: uuidv4(),
                })
              } else {
                return data.push({
                  Id: 0,
                  DealerCode: String(d.DealerCode).padStart(10, '0'),
                  Limit: d.Limit,
                  Title: 'Sorun Yok',
                  Bool: true,
                  StateId: uuidv4(),
                })
              }
            } else {
              if (result.Data.indexOf(String(d.DealerCode)) > -1) {
                return list.push({
                  Id: 0,
                  DealerCode: String(d.DealerCode),
                  Limit: d.Limit,
                  Title: 'Cari Bulunamadı',
                  Bool: false,
                  StateId: uuidv4(),
                })
              } else {
                return data.push({
                  Id: 0,
                  DealerCode: String(d.DealerCode),
                  Limit: d.Limit,
                  Title: 'Sorun Yok',
                  Bool: true,
                  StateId: uuidv4(),
                })
              }
            }
          })
          setFcvTable([...dataTable, ...list, ...fcvTable, ...data])
        } else {
          dealer.forEach((d, index) => {
            if (fcvTable.length > 0) {
              let i = 0
              fcvTable.map(fcv => {
                if (Number(state.cluesterType?.value) === 0) {
                  if (fcv.DealerCode === String(d.DealerCode).padStart(10, '0')) {
                    return i++
                  }
                } else {
                  if (fcv.DealerCode === String(d.DealerCode)) {
                    return i++
                  }
                }
                return null
              })
              if (i > 0) {
                if (Number(state.cluesterType?.value) === 0) {
                  return data.push({
                    Id: 0,
                    DealerCode: String(d.DealerCode).padStart(10, '0'),
                    Limit: d.Limit,
                    Title: 'Bu cari tabloda bulunmaktadır',
                    Bool: false,
                    StateId: uuidv4(),
                  })
                } else {
                  return data.push({
                    Id: 0,
                    DealerCode: String(d.DealerCode),
                    Limit: d.Limit,
                    Title: 'Bu cari tabloda bulunmaktadır',
                    Bool: false,
                    StateId: uuidv4(),
                  })
                }
              }
            }
            if (Number(state.cluesterType?.value) === 0)
              data.push({
                Id: 0,
                DealerCode: String(d.DealerCode).padStart(10, '0'),
                Limit: d.Limit,
                Title: 'Sorun Yok',
                Bool: true,
                StateId: uuidv4(),
              })
            else
              data.push({
                Id: 0,
                DealerCode: String(d.DealerCode),
                Limit: d.Limit,
                Title: 'Sorun Yok',
                Bool: true,
                StateId: uuidv4(),
              })
          })
          setFcvTable([...fcvTable, ...data])
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
    // if (Number(state.cluesterType.value) === 0) {
    //   dealer.forEach((item, index) => {

    //     // const code: any = state.dealerCodeOptions?.find((opti) => {
    //     //   if (item.DealerCode.length < 6) {
    //     //     if (opti === item.DealerCode) {
    //     //       return list = {
    //     //         Id: index + 1, DealerCode: item.DealerCode, Limit: item.Limit, Title: "Sorun yoktur", Bool: true
    //     //       }
    //     //     }
    //     //   } else {
    //     //     if (opti === "0000" + item.DealerCode) {
    //     //       return list = {
    //     //         Id: index + 1, DealerCode: "0000" + item.DealerCode, Limit: item.Limit, Title: "Sorun yoktur", Bool: true
    //     //       }
    //     //     }
    //     //   }
    //     // })
    //     // if (code === undefined) data.push({ Id: index + 1, DealerCode: item.DealerCode, Limit: item.Limit, Title: "Cari Bulunamadı", Bool: false })

    //     if (fcvTable.length > 0) {
    //       let i = 0;
    //       fcvTable.map((fcv) => {
    //         if (fcv.DealerCode === list.DealerCode) {
    //           return i++;
    //         }
    //         return;
    //       })
    //       if (i > 0) {
    //         return data.push({ Id: index + 1, DealerCode: item.DealerCode, Limit: item.Limit, Title: "Bu cari tabloda bulunmaktadır", Bool: false })
    //       }
    //     }
    //     if (data.length > 0) {
    //       let k = 0;
    //       data.map((dt) => {
    //         if (dt.DealerCode === list.DealerCode) {
    //           return k++;
    //         }
    //         return;
    //       })
    //       if (k > 0) {
    //         return data.push({ Id: index + 1, DealerCode: item.DealerCode, Limit: item.Limit, Title: "Bu cariyi excelden 2 defa yüklenmiştir", Bool: false })
    //       }
    //     }
    //     data.push(list)
    //   });
    // }
    // if (Number(state.cluesterType.value) === 1) {
    //   dealer.forEach((item, index) => {
    //     const code: any = currentSubTypes.find((opti) => {
    //       if (item.DealerCode.length < 6) {
    //         if (opti.value === item.DealerCode) {
    //           return list = {
    //             Id: index + 1, DealerCode: item.DealerCode, Limit: item.Limit, Title: "Sorun yoktur", Bool: true
    //           }
    //         }
    //       } else {
    //         if (opti.value === "0000" + item.DealerCode) {
    //           return list = {
    //             Id: index + 1, DealerCode: "0000" + item.DealerCode, Limit: item.Limit, Title: "Sorun yoktur", Bool: true
    //           }
    //         }
    //       }
    //     })
    //     if (code === undefined)
    //       data.push({ Id: index + 1, DealerCode: item.DealerCode, Limit: item.Limit, Title: "Cari Bulunamadı", Bool: false })
    //     if (fcvTable.length > 0) {
    //       let i = 0;
    //       fcvTable.map((fcv) => {
    //         if (fcv.DealerCode === list.DealerCode) {
    //           return i++;
    //         }
    //         return;
    //       })
    //       if (i > 0) {
    //         return data.push({ Id: index + 1, DealerCode: item.DealerCode, Limit: item.Limit, Title: "Bu cari tabloda bulunmaktadır", Bool: false })
    //       }
    //     }
    //     if (data.length > 0) {
    //       let k = 0;
    //       data.map((dt) => {
    //         if (dt.DealerCode === list.DealerCode) {
    //           return k++;
    //         }
    //         return;
    //       })
    //       if (k > 0) {
    //         return data.push({ Id: index + 1, DealerCode: item.DealerCode, Limit: item.Limit, Title: "Bu cariyi excelden 2 defa yüklenmiştir", Bool: false })
    //       }
    //     }
    //     data.push(list)
    //   });
    // }
    // if (Number(state.cluesterType.value) === 2) {
    //   dealer.forEach((item, index) => {
    //     const code: any = currentQuotaCodes.find((opti) => {
    //       if (item.DealerCode.length < 6) {
    //         if (opti.value === item.DealerCode) {
    //           return list = {
    //             Id: index + 1, DealerCode: item.DealerCode, Limit: item.Limit, Title: "Sorun yoktur", Bool: true
    //           }
    //         }
    //       } else {
    //         if (opti.value === "0000" + item.DealerCode) {
    //           return list = {
    //             Id: index + 1, DealerCode: "0000" + item.DealerCode, Limit: item.Limit, Title: "Sorun yoktur", Bool: true
    //           }
    //         }
    //       }
    //     })
    //     if (code === undefined) data.push({ Id: index + 1, DealerCode: item.DealerCode, Limit: item.Limit, Title: "Cari Bulunamadı", Bool: false })
    //     if (fcvTable.length > 0) {
    //       let i = 0;
    //       fcvTable.map((fcv) => {
    //         if (fcv.DealerCode === list.DealerCode) {
    //           return i++;
    //         }
    //         return;
    //       })
    //       if (i > 0) {
    //         return data.push({ Id: index + 1, DealerCode: item.DealerCode, Limit: item.Limit, Title: "Bu cari tabloda bulunmaktadır", Bool: false })
    //       }
    //     }
    //     if (data.length > 0) {
    //       let k = 0;
    //       data.map((dt) => {
    //         if (dt.DealerCode === list.DealerCode) {
    //           return k++;
    //         }
    //         return;
    //       })
    //       if (k > 0) {
    //         return data.push({ Id: index + 1, DealerCode: item.DealerCode, Limit: item.Limit, Title: "Bu cariyi excelden 2 defa yüklenmiştir", Bool: false })
    //       }
    //     }
    //     data.push(list)
    //   });
    // }
  }

  const handleFiles = async (files: File[]) => {
    const dealer: any = await excelToJSON(files[0])
    setDealerCodes(dealer[0])
  }

  const AllLimitUpdate = async () => {
    let newLimit = 0
    let spec = false //Limiti belirli bir limite eşitleme
    if (state.limit !== undefined && state.limit && state.limit > 0) {
      spec = true //Limiti belirli bir limite eşitleme
      newLimit = state.limit
    }

    if (state.artiLimit !== undefined) {
      newLimit += state.artiLimit
    }

    if (state.eksiLimit !== undefined) {
      newLimit -= state.eksiLimit
    }
    state.displayNewLimit = newLimit
    setState({ artiLimit: 0, eksiLimit: 0, limit: 0, displayNewLimit: 0 })
    let warn = false
    fcvTable.map(a => {
      let guncel = a.Limit
      let harcanan = a.UsedLimit ? a.UsedLimit : 0
      let newRemainderLimit = a.RemainderLimit ? a.RemainderLimit : 0

      if (!spec && guncel + newLimit < harcanan) {
        warn = true
      } else if (!spec && guncel + newLimit >= harcanan) {
        a.Limit += newLimit
        a.RemainderLimit = newRemainderLimit + newLimit
      } else if (spec && newLimit < harcanan) {
        warn = true
      } else if (spec && newLimit >= harcanan) {
        a.Limit = newLimit
        a.RemainderLimit = newLimit - harcanan
      }

      if (warn)
        Swal.fire({
          icon: 'error',
          title: 'Verilen limitin altında olan bayiler hariç bütün limitler güncellenmiştir',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      else {
        Swal.fire({
          icon: 'success',
          title: 'Bütün limitler güncellenmiştir.',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      }
    })
  }

  const dealerAndLimitTableAdd = async () => {
    let data: IFcvTable[] = []
    let list: IFcvTable[] = []
    if (!state.cluesterType) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen bayi tipi seçiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (Number(state.cluesterType?.value) === 1 || Number(state.cluesterType?.value) === 2) {
      if (!values.Company) {
        Swal.fire({
          icon: 'error',
          title: 'Lütfen şirket seçiniz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }
    }

    let newLimit = 0
    if (state.limit !== undefined) {
      newLimit += state.limit
    }

    if (state.artiLimit !== undefined) {
      newLimit += state.artiLimit
    }

    if (state.eksiLimit !== undefined) {
      newLimit -= state.eksiLimit
    }

    setState({ artiLimit: 0, eksiLimit: 0, displayNewLimit: 0 })

    if (state.cluster.length > 0 && newLimit && newLimit > 0) {
      let i = 0
      let j = 0
      if (fcvTable.length > 0 && state.clusterOld.length === 0) {
        fcvTable.map(fcv => {
          if (state.cluesterType?.value === 0) {
            if (fcv.DealerCode === String(state.cluster).padStart(10, '0')) {
              return i++
            }
          } else {
            if (fcv.DealerCode === String(state.cluster)) {
              return j++
            }
          }

          return
        })
        if (i > 0) {
          data.push({
            Id: 0,
            DealerCode: String(state.cluster).padStart(10, '0'),
            Limit: Number(newLimit),
            Title: 'Bu cari tabloda bulunmaktadır',
            Bool: false,
            UsedLimit: 0,
            RemainderLimit: 0,
            StateId: uuidv4(),
          })
          setState({ cluster: '', limit: 0, tableName: 'Tabloya Ekle', clusterOld: '' })
          setFcvTable([...data, ...fcvTable])
          return
        }
        if (j > 0) {
          data.push({
            Id: 0,
            DealerCode: String(state.cluster),
            Limit: Number(newLimit),
            Title: 'Bu cari tabloda bulunmaktadır',
            Bool: false,
            UsedLimit: 0,
            RemainderLimit: 0,
            StateId: uuidv4(),
          })
          setState({ cluster: '', limit: 0, tableName: 'Tabloya Ekle', clusterOld: '' })
          setFcvTable([...data, ...fcvTable])
          return
        }
        // else {
        //   data.push({ Id: fcvTable.length + 1, DealerCode: String(state.cluster).padStart(10, "0"), Limit: Number(state.limit), Title: "Sorun Yok", Bool: true, UsedLimit: 0 })
        // if (result.Data.indexOf(String(state.cluster).padStart(10, "0")) > -1) {
        //   list.push({ Id: fcvTable.length + 1, DealerCode: String(state.cluster).padStart(10, "0"), Limit: Number(state.limit), Title: "Cari Bulunamadı", Bool: false, UsedLimit: 0 })
        // } else {
        //   data.push({ Id: fcvTable.length + 1, DealerCode: String(state.cluster).padStart(10, "0"), Limit: Number(state.limit), Title: "Sorun Yok", Bool: true, UsedLimit: 0 })
        // }

        // }
      }
      // else {
      //   list.push({ Id: fcvTable.length + 1, DealerCode: String(state.cluster).padStart(10, "0"), Limit: Number(state.limit), Title: "Cari Bulunamadı", Bool: false, UsedLimit: 0 })
      //   setFcvTable([...data, ...list, ...fcvTable]);
      // }
      i = 0
      if (state.clusterOld.length > 0 && location.state) {
        fcvTable
          .filter(f => {
            return f.DealerCode === state.clusterOld
          })
          .forEach(fcv => {
            if (Number(fcv.UsedLimit) > Number(newLimit)) {
              return i++
            }
          })
      }
      if (i > 0) {
        Swal.fire({
          icon: 'error',
          title: 'Limit harcanan limitten az olamaz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }
      const clusterControl: string[] = []
      if (state.cluesterType?.value === 0) {
        clusterControl.push(String(state.cluster).padStart(10, '0'))
      } else {
        clusterControl.push(String(state.cluster))
      }
      const req: DealerControlReq = {
        ClusterType: state.cluesterType ? Number(state.cluesterType?.value) : 0,
        CompanyCode: String(values.Company),
        Data: clusterControl,
      }

      const dealerCon: DealerControl = new DealerControl({})
      dealerCon
        .dealerControl(req)
        .then(async (result: any) => {
          if (result.Data.length > 0) {
            const dt = fcvTable
              .filter(fcv => {
                return fcv.DealerCode === state.clusterOld
              })
              .pop()
            if (dt && dt.UsedLimit && dt.UsedLimit > Number(newLimit)) {
              return Swal.fire({
                icon: 'error',
                title: 'Harcanan limit girilen limitten fazla olamaz',
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            }
            if (state.clusterOld.length > 0) {
              setFcvTable(prevState => {
                return prevState.map((prev: any) => {
                  if (prev.DealerCode === state.clusterOld) {
                    prev = {
                      Id: state.dealerId,
                      DealerCode: String(state.cluster).padStart(10, '0'),
                      Limit: Number(newLimit),
                      UsedLimit: prev.UsedLimit,
                      Title: 'Cari Bulunamadı',
                      Bool: false,
                      RemainderLimit: prev.RemainderLimit,
                    }
                  }
                  return prev
                })
              })
            } else {
              if (Number(state.cluesterType?.value) === 0) {
                if (result.Data.indexOf(String(state.cluster).padStart(10, '0')) > -1) {
                  list.push({
                    Id: 0,
                    DealerCode: String(state.cluster).padStart(10, '0'),
                    Limit: Number(newLimit),
                    UsedLimit: 0,
                    Title: 'Cari Bulunamadı',
                    Bool: false,
                    RemainderLimit: 0,
                    StateId: uuidv4(),
                  })
                } else {
                  data.push({
                    Id: 0,
                    DealerCode: String(state.cluster).padStart(10, '0'),
                    Limit: Number(newLimit),
                    UsedLimit: 0,
                    Title: 'Sorun Yok',
                    Bool: true,
                    RemainderLimit: 0,
                    StateId: uuidv4(),
                  })
                }
                setFcvTable([...fcvTable, ...list, ...data])
              } else {
                if (result.Data.indexOf(String(state.cluster)) > -1) {
                  list.push({
                    Id: 0,
                    DealerCode: String(state.cluster),
                    Limit: Number(newLimit),
                    UsedLimit: 0,
                    Title: 'Cari Bulunamadı',
                    Bool: false,
                    RemainderLimit: 0,
                    StateId: uuidv4(),
                  })
                } else {
                  data.push({
                    Id: 0,
                    DealerCode: String(state.cluster),
                    Limit: Number(newLimit),
                    UsedLimit: 0,
                    Title: 'Sorun Yok',
                    Bool: true,
                    RemainderLimit: 0,
                    StateId: uuidv4(),
                  })
                }
                setFcvTable([...fcvTable, ...list, ...data])
              }
            }
            setState({ cluster: '', limit: 0, tableName: 'Tabloya Ekle', clusterOld: '' })
          } else {
            if (state.clusterOld.length > 0) {
              setFcvTable(prevState => {
                return prevState.map((prev: any) => {
                  if (prev.DealerCode === state.clusterOld) {
                    if (state.cluesterType?.value === 0) {
                      prev = {
                        Id: state.dealerId,
                        DealerCode: String(state.cluster).padStart(10, '0'),
                        Limit: Number(newLimit),
                        UsedLimit: prev.UsedLimit,
                        Title: 'Sorun Yok',
                        Bool: true,
                        RemainderLimit: Number(newLimit) - prev.UsedLimit,
                      }
                    } else {
                      prev = {
                        Id: state.dealerId,
                        DealerCode: String(state.cluster),
                        Limit: Number(newLimit),
                        UsedLimit: prev.UsedLimit,
                        Title: 'Sorun Yok',
                        Bool: true,
                        RemainderLimit: Number(newLimit) - prev.UsedLimit,
                      }
                    }
                  }
                  return prev
                })
              })
            } else {
              if (Number(state.cluesterType?.value) === 0) {
                if (result.Data.indexOf(String(state.cluster).padStart(10, '0')) > -1) {
                  list.push({
                    Id: 0,
                    DealerCode: String(state.cluster).padStart(10, '0'),
                    Limit: Number(newLimit),
                    UsedLimit: 0,
                    Title: 'Cari Bulunamadı',
                    Bool: false,
                    RemainderLimit: 0,
                    StateId: uuidv4(),
                  })
                } else {
                  data.push({
                    Id: 0,
                    DealerCode: String(state.cluster).padStart(10, '0'),
                    Limit: Number(newLimit),
                    UsedLimit: 0,
                    Title: 'Sorun Yok',
                    Bool: true,
                    RemainderLimit: 0,
                    StateId: uuidv4(),
                  })
                }
                setFcvTable([...fcvTable, ...list, ...data])
              } else {
                if (result.Data.indexOf(String(state.cluster)) > -1) {
                  list.push({
                    Id: 0,
                    DealerCode: String(state.cluster),
                    Limit: Number(newLimit),
                    UsedLimit: 0,
                    Title: 'Cari Bulunamadı',
                    Bool: false,
                    RemainderLimit: 0,
                    StateId: uuidv4(),
                  })
                } else {
                  data.push({
                    Id: 0,
                    DealerCode: String(state.cluster),
                    Limit: Number(newLimit),
                    UsedLimit: 0,
                    Title: 'Sorun Yok',
                    Bool: true,
                    StateId: uuidv4(),
                  })
                }
                setFcvTable([...fcvTable, ...list, ...data])
              }
              // if (Number(state.cluesterType?.value) === 0)
              //   if (result.Data.indexOf(String(state.cluster).padStart(10, "0")) > -1) {
              //     list.push({ Id: fcvTable.length + 1, DealerCode: String(state.cluster).padStart(10, "0"), Limit: Number(state.limit), Title: "Cari Bulunamadı", Bool: false })
              //   } else {
              //     data.push({ Id: fcvTable.length + 1, DealerCode: String(state.cluster).padStart(10, "0"), Limit: Number(state.limit), Title: "Sorun Yok", Bool: true, UsedLimit: 0 })
              //   }
              //   data.push({ Id: fcvTable.length + 1, DealerCode: String(state.cluster).padStart(10, "0"), Limit: Number(state.limit), Title: "Cari Bulunamadı", Bool: false })
              // else
              //   data.push({ Id: fcvTable.length + 1, DealerCode: String(state.cluster), Limit: Number(state.limit), Title: "Sorun Yok", Bool: true })
              setFcvTable([...fcvTable, ...list, ...data])
            }
            setState({ cluster: '', limit: 0, tableName: 'Tabloya Ekle', clusterOld: '' })
          }
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen bayi ve limit giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    // const data: IFcvTable[] = [];
    // let list: IFcvTable = {
    //   Id: 0,
    //   DealerCode: "",
    //   Limit: 0,
    //   Title: "",
    //   Bool: false
    // };
    // if (!state.cluesterType) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Lütfen bayi tipi seçiniz",
    //     showConfirmButton: true,
    //     allowOutsideClick: false,
    //   });
    //   return;
    // }
    // if (!state.limit) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Lütfen limit giriniz",
    //     showConfirmButton: true,
    //     allowOutsideClick: false,
    //   });
    //   return;
    // }
    // if (!state.cluster) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Lütfen bayi değeri seçiniz",
    //     showConfirmButton: true,
    //     allowOutsideClick: false,
    //   });
    //   return;
    // }
    // if (Number(state.cluesterType.value) === 0) {

    //   const code: any = state.dealerCodeOptions?.find((opti) => {
    //     if (opti === state.cluster) {
    //       return list = {
    //         Id: fcvTable.length + 1, DealerCode: String(state.cluster), Limit: Number(state.limit), Title: "Sorun yoktur", Bool: true
    //       }
    //     }
    //   })
    //   if (code === undefined)
    //     data.push({ Id: fcvTable.length + 1, DealerCode: String(state.cluster), Limit: Number(state.limit), Title: "Cari Bulunamadı", Bool: false })
    //   if (fcvTable.length > 0) {
    //     let i = 0;
    //     fcvTable.map((fcv) => {
    //       if (fcv.DealerCode === list.DealerCode) {
    //         return i++;
    //       }
    //       return;
    //     })
    //     if (i > 0) {
    //       return data.push({ Id: fcvTable.length + 1, DealerCode: String(state.cluster), Limit: Number(state.limit), Title: "Bu cari tabloda bulunmaktadır", Bool: false })
    //     }
    //   }
    //   data.push(list)
    // }
    // if (Number(state.cluesterType.value) === 1) {

    //   const code: any = currentSubTypes.find((opti) => {
    //     if (opti.value === state.cluster) {
    //       return list = {
    //         Id: fcvTable.length + 1, DealerCode: String(state.cluster), Limit: Number(state.limit), Title: "Sorun yoktur", Bool: true
    //       }
    //     }
    //   })
    //   if (code === undefined)
    //     data.push({ Id: fcvTable.length + 1, DealerCode: String(state.cluster), Limit: Number(state.limit), Title: "Cari Bulunamadı", Bool: false })
    //   if (fcvTable.length > 0) {
    //     let i = 0;
    //     fcvTable.map((fcv) => {
    //       if (fcv.DealerCode === list.DealerCode) {
    //         return i++;
    //       }
    //       return;
    //     })
    //     if (i > 0) {
    //       return data.push({ Id: fcvTable.length + 1, DealerCode: String(state.cluster), Limit: Number(state.limit), Title: "Bu cari tabloda bulunmaktadır", Bool: false })
    //     }
    //   }
    //   data.push(list)
    // }
    // if (Number(state.cluesterType.value) === 2) {

    //   const code: any = currentQuotaCodes?.find((opti) => {
    //     if (opti.value === state.cluster) {
    //       return list = {
    //         Id: fcvTable.length + 1, DealerCode: String(state.cluster), Limit: Number(state.limit), Title: "Sorun yoktur", Bool: true
    //       }
    //     }
    //   })
    //   if (code === undefined)
    //     data.push({ Id: fcvTable.length + 1, DealerCode: String(state.cluster), Limit: Number(state.limit), Title: "Cari Bulunamadı", Bool: false })
    //   if (fcvTable.length > 0) {
    //     let i = 0;
    //     fcvTable.map((fcv) => {
    //       if (fcv.DealerCode === list.DealerCode) {
    //         return i++;
    //       }
    //       return;
    //     })
    //     if (i > 0) {
    //       return data.push({ Id: fcvTable.length + 1, DealerCode: String(state.cluster), Limit: Number(state.limit), Title: "Bu cari tabloda bulunmaktadır", Bool: false })
    //     }
    //   }
    //   data.push(list)
    // }
    // setFcvTable([...fcvTable, ...data])
  }
  const typeOptions = [
    {
      value: 0,
      label: 'Avantaj POS',
    },
    {
      value: 1,
      label: 'Fırsat POS',
    },
    {
      value: 2,
      label: 'FÇV2',
    },
  ]

  const distributionChannelOptions = [
    {
      value: '10',
      label: '10',
    },
    {
      value: '30',
      label: '30',
    },
  ]

  const ClusterOptions = [
    {
      value: 0,
      label: 'Cari Kodu',
    },
    {
      value: 2,
      label: 'Cari Tipi',
    },
    {
      value: 1,
      label: 'Cari Alt Tipi',
    },
  ]

  const removeDealer = async (stateId, fcvId) => {
    if ((await checkUsedTotal(fcvId)) === true)
      setFcvTable((prevState: any) => prevState.filter(item => item.StateId !== stateId))
  }

  const getCompanies = async () => {
    const company: ICompany[] = await getCompanyApi()
    const data: ISelectOption[] = []
    company
      .filter(c => {
        return c.Name !== 'İndex Grup'
      })
      .map(com => {
        return data.push({ value: com.Code, label: com.Name })
      })
    setCompanies(data)
  }

  useEffect(() => {
    if ((location.state as any) && companies.length > 0) {
      const data = _.clone((location.state as any)?.paymentCam)
      setState(data)
      let list: IFcvTable[] = []
      data.ClusterAndLimit.map(clu => {
        list.push({
          Id: clu.Id,
          Limit: clu.Limit,
          DealerCode: clu.Cluster,
          Bool: true,
          Title: 'Sorun Yoktur',
          UsedLimit: clu.UsedLimit,
          RemainderLimit: clu.RemainderLimit,
          StateId: uuidv4(),
          History: clu.History,
        })
      })
      setFcvTable(list)
      setValues(data)
      let type =
        data.Type === 0
          ? { value: 0, label: 'Avantaj POS' }
          : data.Type === 1
          ? { value: 1, label: 'Fırsat POS' }
          : data.Type === 2
          ? { value: 2, label: 'FÇV2' }
          : { value: '', label: '' }
      setState({ type: type })
      setFieldValue('Type', type)
      let company = companies.filter(com => String(com.value) === String(data.Company)).pop()
      setCurrentCompany(company)
      setFieldValue('Company', data.Company)
      let ct =
        data.ClusterType === 0
          ? { value: 0, label: 'Cari Kodu' }
          : data.ClusterType === 1
          ? { value: 1, label: 'Cari Alt Tipi' }
          : data.ClusterType === 2
          ? { value: 2, label: 'Cari Tipi' }
          : { value: '', label: '' }
      setState({ cluesterType: ct, expiryDay: data.ExpiryDay })
      const distribution: ISelectOption[] = []
      distributionChannelOptions.map(dis => {
        return data.DistributionChannel.filter(cha => {
          return cha === dis.value
        }).map(channel => {
          return distribution.push(dis)
        })
      })
      setFieldValue('DistributionChannel', distribution)
      setFieldValue('ClusterType', ct)
      const invoice: ISelectOption[] = []
      data.InvoiceNumbers.map(invo => {
        return invoice.push({ value: invo, label: invo })
      })
      setFieldValue('InvoiceNumbers', invoice)
    }
  }, [location, companies])

  useEffect(() => {
    if (location.state) {
      const data: FCVObject = (location.state as any)?.paymentCam

      const brand = data.Brands.map(braId => optionList.brandIdOptions.find(bra => bra.label === braId))
      setBrandIdValue(brand)

      const quota = data.Quota.map(x => optionList.quotaCodeOptions.find(y => y.value === x))
      setQuotaCodesValue(quota)

      const productCodes = data.ProductCodes.map(x => optionList.productCodeOptions.find(y => y.value === x))
      setProductCodeValue(productCodes)

      const productSubTypes = data.ProductSubTypes.map(x => ({ value: x, label: x }))
      if (productSubTypes) setProductSubTypeValue(productSubTypes)
    }
  }, [location, optionList])

  //#region Excel'e aktarma
  const excelButton = async () => {
    const data: any[] = []
    try {
      if (searchDealerBool) {
        if (searchFcvTable && searchFcvTable.length > 0) {
          searchFcvTable.map(item => {
            return data.push({
              'Onay Durumu': item.Bool === true ? '✅' : '❌',
              Bayi: item.DealerCode.replace('0000', ''),
              Limit: parseFloat(item.Limit.toFixed(2)),
              Harcanan: location.state && item.UsedLimit ? parseFloat(item.UsedLimit.toFixed(2)) : 0,
              Kalan: location.state && item.RemainderLimit ? parseFloat(item.RemainderLimit.toFixed(2)) : 0,
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
      } else {
        if (fcvTable && fcvTable.length > 0) {
          fcvTable.map(item => {
            return data.push({
              'Onay Durumu': item.Bool === true ? '✅' : '❌',
              Bayi: item.DealerCode.replace('0000', ''),
              Limit: parseFloat(item.Limit.toFixed(2)),
              Harcanan: location.state && item.UsedLimit ? parseFloat(item.UsedLimit.toFixed(2)) : 0,
              Kalan: location.state && item.RemainderLimit ? parseFloat(item.RemainderLimit.toFixed(2)) : 0,
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
      }
      exportToXLSX(data, 'Bayi Limit ve Harcama Tablosu:')
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  //Tarihçeli excel
  const excelButtonWithDetail = async () => {
    const data: any[] = []

    try {
      if (searchDealerBool) {
        if (searchFcvTable && searchFcvTable.length > 0) {
          const combinedData: any[] = []

          for (let index = 0; index < searchFcvTable.length; index++) {
            const item = searchFcvTable[index]
            const modalData: IHistory[] = item.History || []

            combinedData.push({
              Sıra: index + 1,
              'Onay Durumu': item.Bool === true ? '✅' : '❌',
              Bayi: item.DealerCode.replace('0000', ''),
              Limit: parseFloat(item.Limit.toFixed(2)),
              Harcanan: location.state && item.UsedLimit ? parseFloat(item.UsedLimit.toFixed(2)) : 0,
              Kalan: location.state && item.RemainderLimit ? parseFloat(item.RemainderLimit.toFixed(2)) : 0,
            })

            if (modalData) {
              for (let i = 0; i < modalData.length; i++) {
                const modalRow = modalData[i]

                combinedData.push({
                  Sıra: index + 1,
                  'Onay Durumu': item.Bool === true ? '✅' : '❌',
                  Bayi: item.DealerCode.replace('0000', ''),
                  Limit: 0,
                  Harcanan: 0,
                  Kalan: 0,
                  'Detay Sıra': i + 1,
                  'Düzenleme Tarihi': modalRow.Date,
                  'Detay Limit': modalRow.Limit,
                  Kullanıcı: modalRow.UserName,
                  'Düzenleyen Kişi': modalRow.NameSurname,
                })
              }
            }
          }
          data.push(...combinedData)
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
      } else {
        if (fcvTable && fcvTable.length > 0) {
          const combinedData: any[] = []

          for (let index = 0; index < fcvTable.length; index++) {
            const item = fcvTable[index]
            const modalData: IHistory[] = item.History || []

            combinedData.push({
              Sıra: index + 1,
              'Onay Durumu': item.Bool === true ? '✅' : '❌',
              Bayi: item.DealerCode.replace('0000', ''),
              Limit: parseFloat(item.Limit.toFixed(2)),
              Harcanan: location.state && item.UsedLimit ? parseFloat(item.UsedLimit.toFixed(2)) : 0,
              Kalan: location.state && item.RemainderLimit ? parseFloat(item.RemainderLimit.toFixed(2)) : 0,
            })

            if (modalData) {
              for (let i = 0; i < modalData.length; i++) {
                const modalRow = modalData[i]

                combinedData.push({
                  Sıra: index + 1,
                  'Onay Durumu': item.Bool === true ? '✅' : '❌',
                  Bayi: item.DealerCode.replace('0000', ''),
                  Limit: 0,
                  Harcanan: 0,
                  Kalan: 0,
                  'Detay Sıra': i + 1,
                  'Düzenleme Tarihi': modalRow.Date,
                  'Detay Limit': modalRow.Limit,
                  Kullanıcı: modalRow.UserName,
                  'Düzenleyen Kişi': modalRow.NameSurname,
                })
              }
            }
          }
          data.push(...combinedData)
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
      }

      exportToXLSX(data, 'Bayi Limit ve Harcama Tablosu:')
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const paymentTermHistoryById = async (id: number) => {
    props.showLoading()
    const paymentTermHistoryByIdApi: PaymentTermHistoryByIdApi = new PaymentTermHistoryByIdApi({})
    try {
      await paymentTermHistoryByIdApi.paymentTermHistoryById(id).then(res => {
        setState({ showHistory: true, historyData: res })
      })
      props.hideLoading()
    } catch (err: any) {
      props.hideLoading()
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }
  //#endregion

  //#region DisplayNewLimit Live Calculation
  const handleLimitChange = (newLimit: number) => {
    calculateNewLimit({ ...state, limit: newLimit })
  }

  const handleArtiLimitChange = (newArtiLimit: number) => {
    calculateNewLimit({ ...state, artiLimit: newArtiLimit })
  }

  const handleEksiLimitChange = (newEksiLimit: number) => {
    calculateNewLimit({ ...state, eksiLimit: newEksiLimit })
  }

  const calculateNewLimit = newState => {
    let newLimit = 0

    const limit = newState.limit ?? 0 // Default to 0 if undefined or null
    const artiLimit = newState.artiLimit ?? 0 // Default to 0 if undefined or null
    const eksiLimit = newState.eksiLimit ?? 0 // Default to 0 if undefined or null

    if (limit > 0) {
      newLimit = limit
    }

    newLimit += artiLimit
    newLimit -= eksiLimit

    setState({
      ...newState,
      displayNewLimit: newLimit,
    })
  }

  const formatCurrency = value => {
    if (value === null || value === undefined) return '0,00 ₺'

    return (
      value
        .toFixed(2)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ₺'
    )
  }
  //#endregion

  return (
    <div className='fcvWrap'>
      <form onSubmit={handleSubmit} className='container'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex gap-1 align-items-center bg-white rounded-3'>
            <div className='arrow-left'></div>
            <IPButton
              onClick={() =>
                navigate(
                  '/admin/bankAndInstallementOperation/fcv/fcvCampaignList' +
                    (location.state?.url ? '?' + location.state?.url : '')
                )
              }
              text={'Listeye Dön'}
              className='listBtn'
            />
          </div>
          <IPButton
            type='submit'
            text={(location.state as any) ? 'Güncelle' : 'Kaydet'}
            className='saveBtn'
            icon='saveIcon.svg'
            // onClick={(e) => handleSubmit()}
          />
        </div>
        <div className='formTitle d-flex align-items-center gap-2 m-l-30'>
          <img src='/images/listIcon.svg' alt='' />
          <div>Cari Form</div>
        </div>
        <div className='formCard '>
          <div className='row'>
            <div className='col-md-6'>
              <label style={{ fontSize: '12px', color: 'red' }}>
                Şirket adı otomatik eklenecektir, sadece kampanya adı giriniz.!
              </label>
              <InputCampaign
                required
                id='PaymentTermsName'
                name='PaymentTermsName'
                label={'Adı'}
                value={values.PaymentTermsName}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={values.PaymentTermsName}
                errors={errors.PaymentTermsName}
                touched={touched.PaymentTermsName}
              />
            </div>
            {/* <label className="labeltext"></label> */}
            <div className='col-md-6 align-self-end'>
              <IPSelectBox
                isDisabled={(location.state as any) ? true : false}
                isClearable
                id='Type'
                name='Type'
                options={typeOptions}
                value={state.type}
                onChangeSingle={option => {
                  setState({ type: option })
                  setFieldValue('Type', option)
                }}
                placeholder={
                  <div
                    style={{
                      color: '#000',
                      fontSize: '11px',
                      fontWeight: '600',
                    }}
                  >
                    Tip
                  </div>
                }
              />
            </div>
          </div>
        </div>
        <div className='formTitle d-flex align-items-center gap-2 m-l-30'>
          <img src='/images/listIcon.svg' alt='' />
          <div>Kampanya Ekleme/Düzenleme</div>
        </div>
        <div className='formCard'>
          <div className='cariFilterContainer'>
            <div className='row'>
              <div className='col-md-4'>
                <InputCampaign
                  type='datetime-local'
                  id='StartDate'
                  name='StartDate'
                  label={'Yayın Başlangıç Tarihi'}
                  value={values.StartDate.toString()}
                  onChange={handleChange}
                  onBlur={e => {
                    handleBlur(e)
                    dateStartValid()
                  }}
                  isActive
                />
              </div>
              <div className='col-md-4'>
                <InputCampaign
                  type='datetime-local'
                  id='EndDate'
                  name='EndDate'
                  label={'Yayın Bitiş Tarihi'}
                  value={values.EndDate.toString()}
                  onChange={handleChange}
                  onBlur={e => {
                    handleBlur(e)
                    dateEndValid()
                    dateValid()
                  }}
                  isActive
                />
              </div>
              {state.type?.value === 2 && (
                <div className='col-md-4'>
                  <IPInput
                    type='text'
                    dataPattern={IPInputDataPattern.Numeric}
                    placeholder='Vade Günü'
                    value={state.expiryDay}
                    onChange={e => setState({ expiryDay: e.target.value })}
                  />
                </div>
              )}
            </div>

            {state.type?.value === 2 && (
              <>
                <div className='row'>
                  <div className='col-md-4'>
                    <IPSelectBox
                      isMulti
                      isCreatableSelect
                      name='InvoiceNumbers'
                      placeholder={placeholder('Fatura No')}
                      onChangeMulti={(options: ISelectOption[]) => {
                        setFieldValue('InvoiceNumbers', options)
                      }}
                      options={values.InvoiceNumbers}
                      value={values.InvoiceNumbers}
                      onCreateOption={option => onCreateOption(option, 'InvoiceNumbers')}
                    />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <IPSelectBox
                      placeholder={placeholder('Dağıtım Kanalı')}
                      options={distributionChannelOptions}
                      isMulti
                      isCreatableSelect
                      onChangeMulti={(options: ISelectOption[]) => {
                        setFieldValue('DistributionChannel', options)
                      }}
                      value={values.DistributionChannel}
                    />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <IPSelectBox
                      isMulti
                      options={optionList.brandIdOptions}
                      value={brandIdValue}
                      onChangeMulti={option => {
                        setBrandIdValue(option)
                      }}
                      placeholder={placeholder('Marka Listesi')}
                    />
                  </div>
                  <div className='col-md-4 mb-4'>
                    <IPSelectBox
                      isMulti
                      options={optionList.quotaCodeOptions}
                      value={quotaCodesValue}
                      onChangeMulti={option => setQuotaCodesValue(option)}
                      placeholder={placeholder('Quota')}
                    />
                  </div>
                  <div className='col-md-4'>
                    <IPSelectBox
                      isMulti
                      // isCreatableSelect
                      // onCreateOption={option => onCreateOption(option, 'ProductCode', setProductCodeValue)}
                      options={optionList.productCodeOptions}
                      value={productCodeValue}
                      onChangeMulti={option => setProductCodeValue(option)}
                      placeholder={placeholder('Ürün Kodu')}
                    />
                  </div>
                  <div className='col-md-4'>
                    <IPSelectBox
                      isMulti
                      // isCreatableSelect
                      // onCreateOption={option => onCreateOption(option, 'ProductSubType', setProductSubTypeValue)}
                      options={state.productSubTypeOptions}
                      value={productSubTypeValue}
                      onChangeMulti={option => setProductSubTypeValue(option)}
                      placeholder={placeholder('Ürün Alt Tipi')}
                    />
                  </div>
                </div>
              </>
            )}

            <div className='row'>
              <div className='col-6'>
                <IPSelectBox
                  isDisabled={(location.state as any) ? true : false}
                  className='listCariSelectBox'
                  name='Company'
                  options={companies}
                  value={currentCompany}
                  onChangeSingle={(option: ISelectOption) => {
                    setCurrentCompany(option)
                    setFieldValue('Company', option.value)
                  }}
                  placeholder={
                    <div
                      style={{
                        color: '#000',
                        fontSize: '11px',
                        fontWeight: '600',
                      }}
                    >
                      Grup Firma Listesi
                    </div>
                  }
                />
              </div>
              <div className='col-md-6'>
                <IPSelectBox
                  isClearable
                  isDisabled={(location.state as any) ? true : false}
                  id='ClusterType'
                  name='ClusterType'
                  options={ClusterOptions}
                  value={state.cluesterType}
                  onChangeSingle={option => {
                    if (fcvTable.length > 0) {
                      Swal.fire({
                        icon: 'question',
                        title:
                          'Bayi kod türünü değiştirirseniz tablodaki veriler silinecektir.Silmek istediğinize emin misiniz?',
                        showConfirmButton: true,
                        allowOutsideClick: false,
                        showDenyButton: true,
                        confirmButtonText: 'Evet',
                        denyButtonText: 'Hayır',
                      }).then(async result => {
                        if (result.isConfirmed) {
                          setState({
                            cluster: '',
                            limit: 0,
                            tableName: 'Tabloya Ekle',
                            dealerId: 0,
                            cluesterType: option,
                          })
                          setFcvTable([])
                          setFieldValue('ClusterType', option)
                        }
                        return
                      })
                    } else {
                      setState({ cluesterType: option })
                      setFieldValue('ClusterType', option)
                    }
                  }}
                  placeholder={
                    <div
                      style={{
                        color: '#000',
                        fontSize: '11px',
                        fontWeight: '600',
                      }}
                    >
                      Bayi Kod Türü
                    </div>
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className='formTitle d-flex align-items-center gap-2 m-l-30'>
          <img src='/images/listIcon.svg' alt='' />
          <div>Tablo Ekleme/Düzenleme</div>
        </div>

        <div className='formCard'>
          <div className='container mt-3'>
            <div className='row justify-content-end'>
              <div className='col-md-6'>
                <label style={{ color: 'red', fontSize: '12px' }}>
                  * Mevcut Limit'e eklemek istediğiniz değeri girebilirsiniz.
                </label>
              </div>
            </div>
            <div className='row justify-content-end'>
              <div className='form-group col-md-3 mb-3' style={{ backgroundColor: 'chartreuse' }}>
                <InputCampaign
                  required
                  id='LimitPlus'
                  name='LimitPlus'
                  label={'Limit Arttır'}
                  value={state.artiLimit}
                  onChange={e => {
                    const newArtiLimit = Number(e.target.value)
                    setState({ artiLimit: newArtiLimit })
                    handleArtiLimitChange(newArtiLimit ?? 0)
                  }}
                  onBlur={handleBlur}
                />
              </div>
              <div className='form-group col-md-3 mb-3'></div>
            </div>

            <div className='row justify-content-end'>
              <div className='col-md-6'>
                <label style={{ color: 'red', fontSize: '12px' }}>
                  * Mevcut Limit'i yeni bir değerle güncelleyebilirsiniz.
                </label>
              </div>
            </div>
            <div className='row '>
              <div className='col-md-6 mb-3'>
                <InputCampaign
                  disabled={state.tableName === 'Tabloya Ekle' ? false : true}
                  required
                  id='Cluster'
                  name='Cluster'
                  label={'Bayi Kod Değer'}
                  value={state.cluster}
                  onChange={e => setState({ cluster: e.target.value })}
                  onBlur={handleBlur}
                />
              </div>
              <div className='form-group col-md-3 mb-3' style={{ backgroundColor: 'lavender' }}>
                <InputCampaign
                  required
                  id='Limit'
                  name='Limit'
                  label={'Limit'}
                  value={state.limit}
                  onChange={e => {
                    setState({ limit: Number(e.target.value) })
                    handleLimitChange(Number(e.target.value) ?? state.limit)
                  }}
                  onBlur={handleBlur}
                />
              </div>
              <div className='form-group col-md-3 mb-3' style={{ backgroundColor: '#bbbbdb' }}>
                <InputCampaign
                  disabled
                  id='NewLimit'
                  name='NewLimit'
                  label={'Hesaplanan Yeni Limit'}
                  value={formatCurrency(state.displayNewLimit)}
                />
              </div>
            </div>

            <div className='row justify-content-end'>
              <div className='col-md-6'>
                <label style={{ color: 'red', fontSize: '12px' }}>
                  * Mevcut Limit'den çıkarmak istediğiniz değeri girebilirsiniz.
                </label>
              </div>
            </div>
            <div className='row justify-content-end'>
              <div className='form-group col-md-3 mb-3' style={{ backgroundColor: 'red' }}>
                <InputCampaign
                  required
                  id='LimitMinus'
                  name='LimitMinus'
                  label={'Limit Azalt'}
                  value={state.eksiLimit}
                  onChange={e => {
                    setState({ eksiLimit: Number(e.target.value) })
                    handleEksiLimitChange(Number(e.target.value) ?? 0)
                  }}
                  onBlur={handleBlur}
                />
              </div>
              <div className='form-group col-md-3 mb-3'></div>
            </div>
            <div className='d-flex gap-4 flex-wrap'>
              <a className='campaignExcelBtn' href='/assetWeb/docs/fcv-dealer-codes-example.xlsx' target='_blank'>
                Örnek Excel
              </a>

              <IPFile
                id='FileInputDealerCode'
                multiple={false}
                handleFiles={e => handleFiles(e)}
                removeAfterSelect={true}
                className='campaignFileInput'
                label="Excel'den Yükle"
              />

              <IPButton
                style={{
                  background: '#699bf7',
                  width: '80px',
                  borderRadius: '0.3rem',
                }}
                type='button'
                text={state.tableName}
                onClick={() => dealerAndLimitTableAdd()}
              />

              <IPInput
                type='text'
                placeholder='Bayi Ara...'
                value={searchDealerText}
                onChange={e => searchDealer(e.target.value)}
                style={{
                  width: '200px',
                  height: 44,
                }}
              />

              <IPButton
                style={{
                  background: '#699bf7',
                  width: '80px',
                  borderRadius: '0.3rem',
                }}
                type='button'
                text={'Tüm Limitleri Güncelle'}
                onClick={() => AllLimitUpdate()}
              />
              <span style={{ color: 'red', fontSize: '80%', alignSelf: 'center' }}>
                *Çok fazla kayıt olması durumunda sayfa çalışmayı durduracaktır.
              </span>

              <div className='d-flex align-items-center'>
                <span>Bayi Limit ve Harcama Tablosu: </span>
                <button
                  type='button'
                  style={{
                    border: '1px solid #2e7d32',
                    borderRadius: 0,
                    width: '29px',
                    height: '29px',
                    backgroundColor: 'white',
                    margin: '0 10px',
                  }}
                  onClick={excelButton}
                  className='btn text-capitalize background-white-important d-flex align-items-center'
                >
                  <i
                    className='fas fa-file-excel'
                    style={{
                      marginLeft: '-6px',
                      fontSize: '18px',
                      color: '#2e7d32',
                    }}
                  ></i>
                </button>
                <span>Tarihçeli Bayi Limit Excel: </span>
                <button
                  type='button'
                  style={{
                    border: '1px solid #2e7d32',
                    borderRadius: 0,
                    width: '29px',
                    height: '29px',
                    backgroundColor: 'white',
                    margin: '0 10px',
                  }}
                  onClick={excelButtonWithDetail}
                  className='btn text-capitalize background-white-important d-flex align-items-center'
                >
                  <i
                    className='fas fa-file-excel'
                    style={{
                      marginLeft: '-6px',
                      fontSize: '18px',
                      color: '#2e7d32',
                    }}
                  ></i>
                </button>
              </div>
            </div>
            <table className='table'>
              <thead>
                <tr role='row' className='odd'>
                  <th>Onay Durumu</th>
                  <th>Bayi</th>
                  <th>Limit</th>
                  {location.state && <th>Harcanan</th>}
                  {location.state && <th>Kalan</th>}
                  {location.state && <th>Tarihçe</th>}
                  <th>İşlemler</th>
                </tr>
              </thead>
              <tbody>
                {searchDealerBool
                  ? searchFcvTable.map(fcv => (
                      <tr key={fcv.Id}>
                        <td>
                          {fcv.Bool === true ? (
                            <div className='hasTooltip' style={{ width: '55px ' }}>
                              ✅<div className='otooltip'>{fcv.Title}</div>
                            </div>
                          ) : (
                            <div className='hasTooltip' style={{ width: '55px ' }}>
                              ❌<div className='otooltip'>{fcv.Title}</div>
                            </div>
                          )}
                        </td>
                        <td> {fcv.DealerCode.replace('0000', '')}</td>
                        <td>
                          {' '}
                          {fcv.Limit.toFixed(2)
                            .replace('.', ',')
                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ₺'}
                        </td>
                        {location.state && (
                          <td>
                            {fcv.UsedLimit?.toFixed(2)
                              .replace('.', ',')
                              .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ₺'}
                          </td>
                        )}
                        {location.state && (
                          <td>
                            {fcv.RemainderLimit?.toFixed(2)
                              .replace('.', ',')
                              .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ₺'}
                          </td>
                        )}
                        {location.state && (
                          <td>
                            <IPButton
                              onClick={() => {
                                paymentTermHistoryById(fcv.Id)
                              }}
                              text='Tarihçe'
                            ></IPButton>
                          </td>
                        )}
                        <td>
                          {(fcv.Id === 0 || fcv.UsedLimit === 0) && (
                            <a
                              className='ml-2'
                              data-toggle='tooltip'
                              style={{ cursor: 'pointer', color: 'red', fontSize: '35px' }}
                              onClick={() => removeDealer(fcv.StateId, fcv.Id)}
                            >
                              <i className='icon-trash-2'></i>
                            </a>
                          )}
                          <a
                            className='ml-2'
                            data-toggle='tooltip'
                            onClick={() => {
                              setState({
                                cluster: fcv.DealerCode.replace('0000', ''),
                                clusterOld: fcv.DealerCode,
                                limit: fcv.Limit,
                                tableName: 'Tabloyu Güncelle',
                                dealerId: fcv.Id,
                              })
                            }}
                            style={{
                              cursor: 'pointer',
                              fontSize: '35px',
                              color: 'green',
                            }}
                          >
                            <i className='icon-edit'></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  : fcvTable && fcvTable.length > 0
                  ? fcvTable.map(fcv => (
                      <tr key={fcv.Id}>
                        <td>
                          {fcv.Bool === true ? (
                            <div className='hasTooltip' style={{ width: '55px ' }}>
                              ✅<div className='otooltip'>{fcv.Title}</div>
                            </div>
                          ) : (
                            <div className='hasTooltip' style={{ width: '55px ' }}>
                              ❌<div className='otooltip'>{fcv.Title}</div>
                            </div>
                          )}
                        </td>
                        <td> {fcv.DealerCode?.replace('0000', '')}</td>
                        <td>
                          {fcv.Limit.toFixed(2)
                            .replace('.', ',')
                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ₺'}
                        </td>
                        {location.state && (
                          <td>
                            {fcv.UsedLimit?.toFixed(2)
                              .replace('.', ',')
                              .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ₺'}
                          </td>
                        )}
                        {location.state && (
                          <td>
                            {fcv.RemainderLimit?.toFixed(2)
                              .replace('.', ',')
                              .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ₺'}
                          </td>
                        )}
                        {location.state && (
                          <td>
                            <IPButton
                              onClick={() => {
                                paymentTermHistoryById(fcv.Id)
                              }}
                              text='Tarihçe'
                            ></IPButton>
                          </td>
                        )}
                        <td>
                          {(fcv.Id === 0 || fcv.UsedLimit === 0) && (
                            <a
                              className='ml-2'
                              data-toggle='tooltip'
                              style={{ cursor: 'pointer', color: 'red', fontSize: '35px' }}
                              onClick={() => removeDealer(fcv.StateId, fcv.Id)}
                            >
                              <i className='icon-trash-2'></i>
                            </a>
                          )}
                          <a
                            className='ml-2'
                            data-toggle='tooltip'
                            onClick={() => {
                              setState({
                                cluster: fcv.DealerCode.replace('0000', ''),
                                clusterOld: fcv.DealerCode,
                                limit: fcv.Limit,
                                tableName: 'Tabloyu Güncelle',
                                dealerId: fcv.Id,
                              })
                            }}
                            style={{
                              cursor: 'pointer',
                              fontSize: '35px',
                              color: 'green',
                            }}
                          >
                            <i className='icon-edit'></i>
                          </a>
                        </td>
                      </tr>
                    ))
                  : 'Veri bulunmamaktadır'}
              </tbody>
            </table>
          </div>
        </div>
      </form>

      <div className='d-flex justify-content-between mt-3 container'>
        <div className='d-flex gap-1 align-items-center bg-white rounded-3'>
          <div className='arrow-left'></div>
          <IPButton
            onClick={() =>
              navigate(
                '/admin/bankAndInstallementOperation/fcv/fcvCampaignList' +
                  (location.state?.url ? '?' + location.state?.url : '')
              )
            }
            text={'Listeye Dön'}
            className='listBtn'
          />
        </div>
        <IPButton
          type='submit'
          text={(location.state as any) ? 'Güncelle' : 'Kaydet'}
          className='saveBtn'
          icon='saveIcon.svg'
          onClick={() => handleSubmit()}
        />
      </div>
      <IPModal
        show={state.showHistory}
        onClose={() => setState({ showHistory: false, historyData: [] })}
        hideConfirm={true}
        width={1000}
        title='Tarihçe'
      >
        <table className='table'>
          <thead>
            <tr role='row' className='odd'>
              <th>Tarih</th>
              <th>Limit</th>
              <th>Kullanıcı</th>
              <th style={{ width: '400px' }}>Düzenleyen Kişi</th>
            </tr>
          </thead>

          <tbody>
            {state.historyData &&
              state.historyData.length > 0 &&
              state.historyData.map(his => (
                <tr role='row' className='odd' key={his.UserId}>
                  <td>{his.Date.toLocaleString()}</td>
                  <td>
                    {his.Limit.toFixed(2)
                      .replace('.', ',')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ₺'}
                  </td>
                  <td>{his.UserName}</td>
                  <td>{his.NameSurname}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </IPModal>
    </div>
  )
}

export default componentWithContext(FcvCampaignAdd)
