import { useEffect, useState, useRef } from 'react'
import { GetSubTypesApi } from '../../../HomeAndSiteOperation/DealerChannel/services/getSubTypesApi'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Swal from 'sweetalert2'

import IPButton from '../../../../../components/IPButton'
import IPSelectBox, { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import IPCheckbox from '../../../../../components/IPCheckBox'
import InputCampaign from './InputCampaign'
import CampaignSelect from './CampaignSelect'

import { useCustomReducer } from '../../../../../base/customHooks'
import { uuidv4 } from '../../../../../base/utils'

import { CampaignBundleModel, DealerType, EnableProductCodes } from '../models/CampaignBundleModel'
import { CampaignBundleService } from '../services/CampaignBundleService'
import { DealerService } from '../services/DealerService'

import './CampaignBundleList.scss'
import IPFile from '../../../../../components/IPFile'
import { excelToJSON, getUploadFileRequest, handleImageUpload } from '../../../../../base/utils/dataHelper'
import { useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import IPInput from '../../../../../components/IPInput'
import { GetPoductSubTypeListApi } from '../services/getPoductSubTypeListApi'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import IPImage from '../../../../../components/IPImage'
import { getCategoryList } from '../../../components/api'
import { ICategoryList } from '../../../components/models/models'

enum CariListType {
  CariCode,
  CariType,
  CariAltType,
}

interface ICampaignBasketConditions {
  Id: string
  CampaignBundleId: number
  XProductCodeList: string[]
  XQuotaCodeList: string[]
  XGroupList: string[]
  XBrandList: string[]
  XProductSubTypeList: string[]
  IsProductCodeList: boolean
  IsProductQuotaCodeList: boolean
  IsProductGroupList: boolean
  IsProductBrandList: boolean
  IsProductSubTypeList: boolean
  IsQuantity: boolean
  IsPrice: boolean
  MinQuantity: number
  MaxQuantity: number
  BasketOptions: number
  MinPrice: number
  MaxPrice: number
  CurrencyType: string
  TotalCondition: string
  Deleted: boolean
}

interface ICampaignProductCondition {
  Id: number | string
  CampaignBundleId: number
  ProductId: number
  ProductCode: ISelectOption
  ResultOperation: ISelectOption
  Quantity: number
  MaxQuantity: number
  AmountOrRatio: number
  CurrencyType: ISelectOption
  Deleted: boolean
}
export interface ICampaignState {
  showModal: boolean
  selectedDealerList?: CariListType
  isProductCodesInclude?: boolean
  isQuotaCodesInclude?: boolean
  isGroupCodesInclude?: boolean
  isBrandsInclude?: boolean
  isSubTypesInclude?: boolean
  productCodeList?: ISelectOption[]
  QuotaCodeList?: ISelectOption[]
  GroupList?: ISelectOption[]
  BrandList?: ISelectOption[]
  ProductSubTypeList?: ISelectOption[]
  minMaxType: boolean
  minQuantity: number
  maxQuantity: number
  totalCondition: ISelectOption
  minPrice: number
  maxPrice: number
  currencyType: ISelectOption
  //dealerCodeOptions: ISelectOption[]
  dealerTypeOptions: ISelectOption[]
  dealerSubTypeOptions: ISelectOption[]
  basketOptions: ISelectOption
  isNewRecord: boolean
  campaignBasketConditions: ICampaignBasketConditions[]
  selectedBasketConditionId: string
  resultOperation?: number
  productConditionQuantity?: number
  productConditionMaxQuantity?: number
  productContionAmountOrRatio?: number
  productConditionCurrencyType?: number
  productCodeOptions?: ISelectOption[]
  quotaCodeOptions?: ISelectOption[]
  brandListOptions?: string[]
  companyListOptions?: ISelectOption[]
  wareHouseList?: ISelectOption[]
  GiftCode: string
  updateBool: boolean
  EnableProductCodes: EnableProductCodes[]
  categoryList: ISelectOption[]
}

function CampaignBundleAdd(props: BaseComponentWithContextProps) {
  const [tmpBImage, setTmpBImage] = useState('')
  const [tmpLImage, setTmpLImage] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const inputElLImage = useRef<any>(null)
  const inputElBImage = useRef<any>(null)
  const [campaignBasketConditions, setCampaignBasketConditions] = useState<ICampaignBasketConditions[]>([])
  const [productSubType, setProductSubType] = useState<ISelectOption[]>([])
  const [campaignProductConditions, setCampaignProductConditions] = useState<ICampaignProductCondition[]>([
    {
      Id: 0,
      CampaignBundleId: 0,
      ProductId: 0,
      ProductCode: { label: '0', value: '0' },
      ResultOperation: { label: 'Sabit Fiyat Zorunlu', value: 4 },
      Quantity: 0,
      MaxQuantity: 0,
      AmountOrRatio: 0,
      CurrencyType: { label: 'TRY', value: 'TRY' },
      Deleted: false,
    },
  ])
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)

  const getInitialState = (): ICampaignState => {
    return {
      showModal: false,
      isNewRecord: false,
      selectedDealerList: CariListType.CariCode,
      isProductCodesInclude: true,
      isQuotaCodesInclude: true,
      isGroupCodesInclude: true,
      isBrandsInclude: true,
      isSubTypesInclude: true,
      productCodeList: [],
      BrandList: [],
      minMaxType: true,
      minQuantity: 0,
      maxQuantity: 0,
      minPrice: 0,
      maxPrice: 0,
      totalCondition: { label: 'Hayır', value: 'H' },
      currencyType: { label: 'TRY', value: 'TRY' },
      basketOptions: { label: 'Sepet', value: 1 },
      campaignBasketConditions: [],
      selectedBasketConditionId: '',
      wareHouseList: [],
      GiftCode: '',
      companyListOptions: [
        { label: 'Index Group', value: 5 },
        { label: 'Index', value: 1 },
        { label: 'Netex', value: 2 },
        { label: 'Despec', value: 3 },
        { label: 'Datagate', value: 4 },
      ],
      updateBool: false,
      //dealerCodeOptions: [],
      dealerTypeOptions: [],
      dealerSubTypeOptions: [],
      EnableProductCodes: [],
      categoryList: [],
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ICampaignState>(intialState)

  const enumFunction = (takenState, takenEnum) => {
    return takenState === takenEnum ? 'active' : ''
  }

  const validationSchema = yup.object().shape({
    BundleCode: yup.string().required('Lütfen kampanya kodunu girin!').max(50, 'Karakter sınırı 50 yi geçmemeli'),
    SapCampaignCode: yup
      .string()
      .max(30, 'Karakter sınırı 30 u geçmemeli')
      .required('Lütfen sap kampanya kodunu girin!'),
    ProductDetailDescription: yup.string().max(250, 'Karakter sınırı 250 yi geçmemeli'),
    ListLinkCode: yup.string().max(50, 'Karakter sınırı 50 yi geçmemeli'),
    CampaignDescription: yup.string().required('Lütfen açıklamayı girin!'),
    GiftCode: yup.string().max(50, 'Karakter sınırı 50 yi geçmemeli'),
  })

  const sweetAlert = (icon: 'error' | 'success', title: string, message: string) => {
    return Swal.fire({
      icon,
      title,
      html: `<div style='font-size: 17px'>${message}</div>`,
      showConfirmButton: true,
      allowOutsideClick: false,
    })
  }

  const { handleSubmit, handleChange, handleBlur, setFieldValue, values, errors, touched } = useFormik({
    initialValues: {
      Id: 0,
      BundleCode: '',
      CampaignDescription: '',
      SapCampaignCode: '',
      BundleImage: '',
      ProductDetailDescription: '',
      ListLinkCode: '',
      ListLinkImage: '',
      XDealerCodeList: [],
      XDealerTypeList: [],
      XDealerSubTypeList: [],
      IsDealerListInclude: true,
      IsDealerSubTypeInclude: true,
      IsDealerTypeInclude: true,
      XCompanyCodes: [],
      XQuotaCodeList: [],
      WarehouseCode: '1001',
      BasketOrder: 0,
      MaxCampaignCount: 0,
      TotalGiro: 0,
      IsIncludeCampaignProducts: false,
      IsSingleCampaign: false,
      IsBeforeCampaign: false,
      Enable: true,
      StartDate: new Date(),
      EndDate: new Date(),
      StartDate2: new Date(),
      EndDate2: new Date(),
      ProductCode: '',
      ProductName: '',
      Deleted: false,
      GiftCode: '',
      StartOadate2: 0,
      EndOadate2: 0,
      IsShow: false,
      LImage: {
        Label: '',
        Content: '',
        Extension: '',
        FileType: '',
      },
      BImage: {
        Label: '',
        Content: '',
        Extension: '',
        FileType: '',
      },
    },
    validationSchema,

    onSubmit: async () => {
      // if (values.IsBeforeCampaign && ((values.StartDate2 > values.StartDate) || (values.EndDate2 > values.StartDate) || (values.StartDate2 === values.StartDate) || (values.EndDate2 === values.StartDate))) {
      //   sweetAlert("error", "Hata", "Eski başlangıç ve bitiş tarihi Yayın başlangıç tarihinden önce olmalıdır");
      //   return
      // }
      if (values.BundleCode.length > 50 || values.SapCampaignCode.length > 50) {
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: 'Karakter sınırı 50 yi geçmemeli',
          showConfirmButton: true,
          allowOutsideClick: true,
          // timer: 1500
        })
        return
      }
      if (values.ProductDetailDescription.length > 250) {
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: 'Ürün detay açıklama karakter sınırı 250 yi geçmemeli',
          showConfirmButton: true,
          allowOutsideClick: true,
          // timer: 1500
        })
        return
      }
      const XProductCodeList = campaignBasketConditions.map(code => code.XProductCodeList)
      const XQuotaCodeList = campaignBasketConditions.map(code => code.XQuotaCodeList)
      const XGroupList = campaignBasketConditions.map(code => code.XGroupList)
      const XBrandList = campaignBasketConditions.map(code => code.XBrandList)
      const XProductSubTypeList = campaignBasketConditions.map(code => code.XProductSubTypeList)
      const minQuantity = campaignBasketConditions.map(code => code.MinQuantity)
      if (
        XProductCodeList.length === 0 &&
        XGroupList.length === 0 &&
        XQuotaCodeList.length === 0 &&
        XBrandList.length === 0 &&
        XProductSubTypeList.length === 0 &&
        minQuantity.length === 0
      ) {
        sweetAlert('error', 'Hata', 'En az bir listeyi doldurmalısınız.')
        return
      }

      const EnableProductCodes: EnableProductCodes[] = []
      state.EnableProductCodes.forEach(item => {
        if (item.Checked) {
          EnableProductCodes.push({
            Id: typeof item.Id === 'string' ? 0 : item.Id,
            ProductCode: item.ProductCode,
          })
        }
      })

      //update
      /* */
      if (state.updateBool) {
        props.showLoading()
        const reqUpdate: CampaignBundleModel = {
          CampaignBundle: {
            ...values,
            Id: values.Id,
            XCompanyCodes: values.XCompanyCodes.map((code: any) => code.value),
            XDealerCodeList: values.XDealerCodeList.map((code: any) => {
              if (code.label.length > 4) {
                return code.label.padStart(10, '0')
              }
              return code.label
            }),
            XDealerTypeList: values.XDealerTypeList.map((type: any) => type.value),
            XDealerSubTypeList: values.XDealerSubTypeList.map((subType: any) => subType.value),
            BundleCode: values.BundleCode,
            CampaignDescription: values.CampaignDescription,
            SapCampaignCode: values.SapCampaignCode,
            ProductDetailDescription: values.ProductDetailDescription,
            ListLinkCode: values.ListLinkCode,
            BundleImage:
              values.BImage.Content.length > 0
                ? tmpBImage.split(',')[0]
                : tmpBImage && tmpBImage.length > 0
                  ? tmpBImage.split('/')[5] + '/' + tmpBImage.split('/')[6]
                  : '',
            ListLinkImage:
              values.LImage.Content.length > 0
                ? tmpLImage.split(',')[0]
                : tmpLImage && tmpLImage.length > 0
                  ? tmpLImage.split('/')[5] + '/' + tmpLImage.split('/')[6]
                  : '',
            StartDate: values.StartDate,
            EndDate: values.EndDate,
            Enable: values.Enable,
            WarehouseCode: values.WarehouseCode,
            GiftCode: values.GiftCode,
            IsBeforeCampaign: values.IsBeforeCampaign,
            StartDate2: values.StartDate2,
            EndDate2: values.EndDate2,
            StartOadate2: values.StartOadate2,
            EndOadate2: values.EndOadate2,
            IsShow: values.IsShow,
            EnableProductCodes,
            LImage: values.LImage,
            BImage: values.BImage,
          },
          CampaignProductConditions: [],
          CampaignBasketConditions: [],
        }

        const campaignBundleUpdateService = new CampaignBundleService({})

        try {
          const res = await campaignBundleUpdateService.UpdateCampaign(reqUpdate)

          if (!res) {
            props.hideLoading()
            sweetAlert('error', 'Hata', 'Beklenmedik bir hata oluştu.')
          }
          props.hideLoading()
          sweetAlert('success', 'Başarılı', 'Başarıyla güncellendi').then(() =>
            navigate('/admin/ProductOperation/CampaignBundleList' + (location.state?.url ? "?" + location.state?.url : ''))
          )
        } catch (err: any) {
          props.hideLoading()
          sweetAlert('error', 'Hata', err.description)
        }
      } else {
        if (
          campaignProductConditions.filter(camp => {
            return camp.AmountOrRatio === 0
          }).length > 0
        ) {
          Swal.fire({
            icon: 'error',
            title: 'Lütfen fiyatları giriniz.',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          return
        }
        props.showLoading()

        const req: CampaignBundleModel = {
          CampaignBundle: {
            ...values,
            XCompanyCodes: values.XCompanyCodes.map((code: any) => code.value),
            WarehouseCode: values.WarehouseCode,
            GiftCode: state.GiftCode,
            XDealerCodeList: values.XDealerCodeList.map((code: any) => {
              if (code.label.length > 4) {
                return code.label.padStart(10, '0')
              }
              return code.label
            }),
            XDealerTypeList: values.XDealerTypeList.map((type: any) => type.value),
            XDealerSubTypeList: values.XDealerSubTypeList.map((subType: any) => subType.label),
            EnableProductCodes,
            BundleImage:
              values.BImage.Content.length > 0
                ? tmpBImage.split(',')[0]
                : tmpBImage && tmpBImage.length > 0
                  ? tmpBImage.split('/')[5] + '/' + tmpBImage.split('/')[6]
                  : '',
            ListLinkImage:
              values.LImage.Content.length > 0
                ? tmpLImage.split(',')[0]
                : tmpLImage && tmpLImage.length > 0
                  ? tmpLImage.split('/')[5] + '/' + tmpLImage.split('/')[6]
                  : '',
            LImage: values.LImage,
            BImage: values.BImage,
          },

          CampaignBasketConditions: campaignBasketConditions?.map(item => {
            return {
              ...item,
              Id: 0,
              XBrandList: item.XBrandList ? item.XBrandList : [],
              XProductCodeList: item.XProductCodeList ? item.XProductCodeList : [],
              XProductSubTypeList: item.XProductSubTypeList ? item.XProductSubTypeList : [],
              XGroupList: item.XGroupList ? item.XGroupList : [],
              XQuotaCodeList: item.XQuotaCodeList ? item.XQuotaCodeList : [],
            }
          }),

          CampaignProductConditions: campaignProductConditions?.map((item: ICampaignProductCondition) => {
            return {
              ...item,
              Id: 0,
              ProductCode: item.ProductCode ? (item.ProductCode.value as string) : '',
              ResultOperation: item.ResultOperation ? +item.ResultOperation.value : 4,
              CurrencyType: item.CurrencyType ? item.CurrencyType.label : '',
              Quantity: +item.Quantity,
              MaxQuantity: +item.MaxQuantity,
              AmountOrRatio: +item.AmountOrRatio,
              // Deleted: +item.Deleted,
            }
          }),
        }

        const campaignBundleService = new CampaignBundleService({})

        try {
          /*if (location.state as any) {
            const res = await campaignBundleService.UpdateCampaign(req)
    	
          }*/

          const res = await campaignBundleService.InsertCampaign(req)

          if (!res) {
            props.hideLoading()
            sweetAlert('error', 'Hata', 'Beklenmedik bir hata oluştu.')
          }
          props.hideLoading()
          sweetAlert('success', 'Başarılı', 'Başarıyla kayıt edildi.').then(() =>
            navigate('/admin/ProductOperation/CampaignBundleList' + (location.state?.url ? "?" + location.state?.url : ''))
          )
        } catch (err: any) {
          props.hideLoading()
          sweetAlert('error', 'Hata', err.description)
        }
      }
    },
  })

  const handleMinMax = type => {
    if (type) {
      setState({
        minMaxType: true,
        minPrice: 0,
        maxPrice: 0,
        currencyType: { label: 'TRY', value: 'TRY' },
      })
      return
    }
    setState({
      minMaxType: false,
      minQuantity: 0,
      maxQuantity: 0,
      totalCondition: { label: 'Hayır', value: 'H' },
    })
  }

  // const getDealerCodes = async () => {
  //   const dealerService = new DealerService({})
  //   const res: any = await dealerService.listAll()
  //   if (res) {
  //     const dealerCodeOptions: any = []
  //     res.forEach((item: any) => {
  //       item.SapCode && dealerCodeOptions.push({ value: item.SapCode, label: item.SapCode.replace('0000', '') })
  //     })
  //     setState({ dealerCodeOptions })
  //   }
  // }

  const getDealerTypes = async () => {
    const dealerService = new DealerService({})
    const res: any = await dealerService.getDealerTypes()
    if (res) {
      const dealerTypeOptions: ISelectOption[] = []
      res.forEach((item: DealerType) => {
        item && dealerTypeOptions.push({ value: item.MusteriGrubu, label: item.MusteriGrubuAciklama })
      })
      setState({ dealerTypeOptions })
    }
  }

  const getSubTypes = async () => {
    const getSubTypes: GetSubTypesApi = new GetSubTypesApi({})
    const res = await getSubTypes.getSubTypes()

    if (res) {
      const dealerSubTypeOptions: ISelectOption[] = []
      res.forEach((item: any) => {
        item && dealerSubTypeOptions.push({ value: item, label: item })
      })
      setState({ dealerSubTypeOptions })
    }
  }

  const getProductSubTypes = async () => {
    const getPoductSubTypeList: GetPoductSubTypeListApi = new GetPoductSubTypeListApi({})
    const res = await getPoductSubTypeList.getPoductSubTypeList()
    if (res) {
      const dealerSubTypeOptions: ISelectOption[] = []
      res.forEach((item: any) => {
        item && dealerSubTypeOptions.push({ value: item, label: item })
      })
      setProductSubType(dealerSubTypeOptions)
    }
  }

  const getProductCode = async () => {
    const campaignBundleService = new CampaignBundleService({})
    const res = await campaignBundleService.getProductCode()
    if (res) {
      let temp = res.Entity.ProductsInfo.map(a => {
        return {
          label: a.ProductCode + '       ' + a.ProductName,
          value: a.ProductCode,
        }
      })
      setState({
        productCodeOptions: temp,
        quotaCodeOptions: res.Entity.QuotaCode.map(item => {
          return { label: item, value: item }
        }),
      })
    }
  }

  const getBrandList = async () => {
    const campaignBundleService = new CampaignBundleService({})
    const res = await campaignBundleService.getBrandList()
    if (res) setState({ brandListOptions: res.Entity?.BrandCode })
  }

  const getCategory = async () => {
    const data: ICategoryList[] = await getCategoryList()
    const categoryList: ISelectOption[] = []
    if (data) {
      data.map(dt => {
        return categoryList.push({ value: dt.SapCode, label: dt.Name })
      })
    }
    setState({ categoryList })
  }
  /*
    const getCompany = async () => {
      const campaignBundleService = new CampaignBundleService({})
      const res = await campaignBundleService.getCompany()
      if (res) {
        setState({
          companyListOptions: res.map(item => {
            return {
              label: item.Name,
              value: item.Id.toString(),
            }
          }),
        })
      }
    }
  */

  const handleQuantity = (e: any, campaign: any) => {
    const val = e.target.value

    updateProductCondition(campaign.Id, 'AmountOrRatio', val.replace(',', '.'))
  }

  const handleQuantityOnBlur = (e: any, campaign: any) => {
    if (e.target.value?.length < 1 || isNaN(Number(e.target.value)) || Number(e.target.value) < 0) {
      updateProductCondition(campaign.Id, 'AmountOrRatio', 0.03)
    } else {
      if (Number(e.target.value) < 0.03) {
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: '0.03 e eşit veya büyük olmalıdır',
          showConfirmButton: true,
          allowOutsideClick: true,
        })
        updateProductCondition(campaign.Id, 'AmountOrRatio', 0.03)
        return
      }
      updateProductCondition(campaign.Id, 'AmountOrRatio', e.target.value)
    }
  }

  useEffect(() => {
    if ((location.state as any) && (location.state as any)?.flag === 'history') props.showLoading()
    if (
      (location.state as any) &&
      (location.state as any)?.flag === 'history' &&
      state.dealerTypeOptions &&
      state.dealerTypeOptions.length > 0
    ) {
      const data = _.clone((location.state as any)?.campaignData)

      setState({ updateBool: true })

      values.BundleCode = data.BundleCode
      values.CampaignDescription = data.CampaignDescription
      values.XCompanyCodes = data.CompanyCodes
      values.ProductDetailDescription = data.ProductDetailDescription
      values.BundleImage = data.BundleImage
      values.ListLinkCode = data.ListLinkCode
      values.ListLinkImage = data.ListLinkImage
      setTmpBImage(values.BundleImage)
      setTmpLImage(values.ListLinkImage)
      values.IsShow = data.IsShow

      const XDealerCodeList: any = []
      data.DealerCodeList.split(';').forEach(item => {
        if (item && item.length > 0) {
          XDealerCodeList.push({ value: item, label: item.replace('0000', '') })
        }
      })

      const XDealerTypeList: any = []
      if (data.DealerTypeList.length > 0) {
        data.DealerTypeList.split(';').forEach(item => {
          if (item && item.length > 0) {
            if (
              state.dealerTypeOptions.findIndex(dealer => {
                return dealer.value === item
              }) > -1
            ) {
              XDealerTypeList.push(
                state.dealerTypeOptions.find(dealer => {
                  return dealer.value === item
                })
              )
            }
          }
        })
      }

      const XDealerSubTypeList: any = []
      data.DealerSubTypeList.split(';').forEach(item => {
        if (item && item.length > 0) {
          XDealerSubTypeList.push({ value: item, label: item })
        }
      })

      values.XDealerCodeList = XDealerCodeList
      values.XDealerTypeList = XDealerTypeList
      values.XDealerSubTypeList = XDealerSubTypeList

      values.IsDealerListInclude = data.IsDealerListInclude
      values.IsDealerSubTypeInclude = data.IsDealerSubTypeInclude
      values.IsDealerTypeInclude = data.IsDealerTypeInclude

      let XCompanyCodes: ISelectOption[] = []
      for (const code of data.CompanyCodes.split(';')) {
        for (const option of state.companyListOptions!) {
          if (option.value === Number(code)) {
            XCompanyCodes.push(option)
          }
        }
      }

      values.XCompanyCodes = XCompanyCodes as any
      values.WarehouseCode = data.WarehouseCode
      values.BasketOrder = data.BasketOrder
      values.MaxCampaignCount = data.MaxCampaignCount
      values.TotalGiro = data.TotalGiro
      values.IsIncludeCampaignProducts = data.IsIncludeCampaignProducts
      values.IsSingleCampaign = data.IsSingleCampaign
      values.IsBeforeCampaign = data.IsBeforeCampaign
      values.Enable = data.Enable
      values.StartDate2 = data.StartDate2
      values.EndDate2 = data.EndDate2
      values.StartDate = data.StartDate
      values.EndDate = data.EndDate
      values.GiftCode = data.GiftCode
      values.StartOadate2 = data.StartOadate2
      values.EndOadate2 = data.EndOadate2
      values.SapCampaignCode = data.SapCampaignCode
      props.hideLoading()
    }
  }, [location, state.productCodeOptions])

  useEffect(() => {
    if (
      (location.state as any) &&
      state.productCodeOptions &&
      state.productCodeOptions.length > 0 &&
      (location.state as any)?.flag !== 'history'
    ) {
      const data = _.clone((location.state as any)?.campaignData)
      const campaignBundleConditions = data.CampaignProductConditions.map(item => {
        const ResultOperation = resultOperationSelect.find(option => option.value === item.ResultOperation)
        const CurrencyType = currencyTypeSelect.find(cur => cur.value === item.CurrencyType)
        const ProductCode = state.productCodeOptions
          ? state.productCodeOptions.find(option => option.value === item.ProductCode)
          : undefined
        return {
          ...item,
          ResultOperation,
          CurrencyType,
          ProductCode,
        }
      })
      props.hideLoading()
      setCampaignProductConditions(campaignBundleConditions)
    }
  }, [location, state.productCodeOptions])

  useEffect(() => {
    if (
      (location.state as any) &&
      state.dealerTypeOptions &&
      state.dealerTypeOptions.length > 0 &&
      (location.state as any)?.flag !== 'history'
    ) {
      const data = _.clone((location.state as any)?.campaignData)

      setCampaignBasketConditions(data.CampaignBasketConditions)

      const EnableProductCodes: EnableProductCodes[] = data.CampaignBundle.EnableProductCodes.map(x => ({
        ...x,
        Checked: true,
      }))
      const newProducts: EnableProductCodes[] = data.CampaignBasketConditions[0].XProductCodeList.filter(x => {
        return !data.CampaignBundle.EnableProductCodes.map(z => z.ProductCode).includes(x)
      }).map(p => {
        return { Id: uuidv4(), ProductCode: p, Checked: false }
      })

      setState({ ...data.CampaignBundle, EnableProductCodes: [...EnableProductCodes, ...newProducts] })

      if ((location.state as any)?.flag === 'update') {
        values.Id = data.CampaignBundle.Id
        setState({ updateBool: true })
      }

      values.BundleCode = data.CampaignBundle.BundleCode
      values.CampaignDescription = data.CampaignBundle.CampaignDescription
      values.XCompanyCodes = data.CampaignBundle.CompanyCodes
      values.ProductDetailDescription = data.CampaignBundle.ProductDetailDescription
      values.BundleImage = data.CampaignBundle.BundleImage
      values.ListLinkCode = data.CampaignBundle.ListLinkCode
      values.ListLinkImage = data.CampaignBundle.ListLinkImage
      setTmpBImage(values.BundleImage)
      setTmpLImage(values.ListLinkImage)
      values.IsShow = data.CampaignBundle.IsShow

      const XDealerCodeList: any = []
      data.CampaignBundle.XDealerCodeList.forEach(item => {
        if (item && item.length > 0) {
          XDealerCodeList.push({ value: item, label: item.replace('0000', '') })
        }
      })
      values.XDealerCodeList = XDealerCodeList

      const XDealerTypeList: any = []
      if (data.CampaignBundle.XDealerTypeList.length > 0) {
        data.CampaignBundle.XDealerTypeList.forEach(item => {
          if (item && item.length > 0) {
            if (
              state.dealerTypeOptions.findIndex(dealer => {
                return dealer.value === item
              }) > -1
            ) {
              XDealerTypeList.push(
                state.dealerTypeOptions.find(dealer => {
                  return dealer.value === item
                })
              )
            }
          }
        })
      }
      values.XDealerTypeList = XDealerTypeList

      const XDealerSubTypeList: any = []
      data.CampaignBundle.XDealerSubTypeList.forEach(item => {
        if (item && item.length > 0) {
          XDealerSubTypeList.push({ value: item, label: item })
        }
      })
      values.XDealerSubTypeList = XDealerSubTypeList

      values.IsDealerListInclude = data.CampaignBundle.IsDealerListInclude
      values.IsDealerSubTypeInclude = data.CampaignBundle.IsDealerSubTypeInclude
      values.IsDealerTypeInclude = data.CampaignBundle.IsDealerTypeInclude

      let XCompanyCodes: ISelectOption[] = []
      for (const code of data.CampaignBundle.XCompanyCodes) {
        for (const option of state.companyListOptions!) {
          if (option.value === code) {
            XCompanyCodes.push(option)
          }
        }
      }

      values.XCompanyCodes = XCompanyCodes as any
      values.WarehouseCode = data.CampaignBundle.WarehouseCode
      values.BasketOrder = data.CampaignBundle.BasketOrder
      values.MaxCampaignCount = data.CampaignBundle.MaxCampaignCount
      values.TotalGiro = data.CampaignBundle.TotalGiro
      values.IsIncludeCampaignProducts = data.CampaignBundle.IsIncludeCampaignProducts
      values.IsSingleCampaign = data.CampaignBundle.IsSingleCampaign
      values.IsBeforeCampaign = data.CampaignBundle.IsBeforeCampaign
      values.Enable = data.CampaignBundle.Enable
      values.StartDate2 = data.CampaignBundle.StartDate2
      values.EndDate2 = data.CampaignBundle.EndDate2
      values.StartDate = data.CampaignBundle.StartDate
      values.EndDate = data.CampaignBundle.EndDate
      values.GiftCode = data.CampaignBundle.GiftCode
      values.StartOadate2 = data.CampaignBundle.StartOadate2
      values.EndOadate2 = data.CampaignBundle.EndOadate2
      values.SapCampaignCode = data.CampaignBundle.SapCampaignCode
    }
  }, [location, state.dealerTypeOptions])

  useEffect(() => {
    getWareHouseList()
    //getDealerCodes()
    getProductCode()
    getBrandList()
    getSubTypes()
    getProductSubTypes()
    getCategory()
    getDealerTypes()
  }, [])

  const resultOperationSelect = [
    { label: 'Sepetteki Ürünlerden Sabit Fiyatlı Zorunlu', value: 1 },
    // { label: 'Sepetteki Tüm Ürünlere Yüzde İndirim Zorunlu *Sepet toplamına, tüm şirketlere indirim yapar.*', value: 2 }, //Sepet Toplamına
    {
      label: (
        <span>
          Sepetteki Tüm Ürünlere Yüzde İndirim Zorunlu
          <span style={{ color: 'red' }}> *Sepet toplamına, tüm şirketlere indirim yapar.*</span>
        </span>
      ),
      value: 2,
    },
    { label: 'Sepetteki Tüm Ürünlere Yüzde İndirimli', value: 3 },
    { label: 'Sabit Fiyat Zorunlu', value: 4 },
    { label: 'Yüzde İndrimli Zorunlu', value: 5 },
    { label: 'Sepetteki Ürünlerden Yüzde İndirimli Zorunlu', value: 6 },
    { label: 'Sepetteki Ürünlere Oranlı Tutar İndirimi', value: 9 },
    // { label: 'Sepetteki Ürünlerden Yüzde İndirimli Öneri', value: 7 },
    // { label: 'Sepetteki Ürünlerden Sabit Fiyatlı Öneri', value: 8 },
  ]
  const MinMaxSelect = [
    { label: 'Evet', value: 'E' },
    { label: 'Hayır', value: 'H' },
  ]
  const currencyTypeSelect = [
    { label: 'TRY', value: 'TRY' },
    { label: 'USD', value: 'USD' },
    { label: 'EUR', value: 'EUR' },
  ]
  const basketOptionSelect = [
    { label: 'Sepet', value: 1 },
    { label: 'Koşul', value: 2 },
  ]

  const getWareHouseList = async () => {
    const campaignBundleService = new CampaignBundleService({})
    const res = await campaignBundleService.getWareHouseCode()
    if (res) {
      const data: ISelectOption[] = []
      res.map((r, index) => {
        // if (r != 1920) {
        data.push({ value: r, label: r })
        // }
      })
      setState({ wareHouseList: data })
    }
  }

  const isBasketConditionRowSingle =
    campaignBasketConditions.length === 1 &&
    campaignBasketConditions[0].XProductCodeList &&
    campaignBasketConditions[0].XProductCodeList.length > 0 &&
    campaignBasketConditions[0].XQuotaCodeList &&
    campaignBasketConditions[0].XQuotaCodeList.length === 0 &&
    campaignBasketConditions[0].XGroupList &&
    campaignBasketConditions[0].XGroupList.length === 0 &&
    campaignBasketConditions[0].XBrandList &&
    campaignBasketConditions[0].XBrandList.length === 0 &&
    campaignBasketConditions[0].XProductSubTypeList &&
    campaignBasketConditions[0].XProductSubTypeList.length === 0 &&
    campaignBasketConditions[0].IsProductCodeList

  const handleRecord = (id?: string) => {
    const XProductCodeList = state.productCodeList?.map(code => code.value)
    const XQuotaCodeList = state.QuotaCodeList?.map(quota => quota.label)
    const XGroupList = state.GroupList?.map(group => group.value)
    const XBrandList = state.BrandList?.map(brand => brand.label)
    const XProductSubTypeList = state.ProductSubTypeList?.map(item => item.label)

    if (
      state.productCodeList?.length === 0 &&
      state.GroupList?.length === 0 &&
      state.QuotaCodeList?.length === 0 &&
      state.BrandList?.length === 0 &&
      state.ProductSubTypeList?.length === 0
    ) {
      sweetAlert('error', 'Hata', 'En az bir listeyi doldurmalısınız.')
      return
    }

    if (state.minMaxType && state.maxQuantity === 0) {
      sweetAlert('error', 'Hata', 'Maksimum miktar girmediniz.')
      return
    }

    if (!state.minMaxType && state.maxPrice === 0) {
      sweetAlert('error', 'Hata', 'Maksimum fiyat girmediniz.')
      return
    }

    if (id) {
      setCampaignBasketConditions((prevState: any) =>
        prevState.map(pState => {
          if (pState.Id === id) {
            pState.XProductCodeList = XProductCodeList && XProductCodeList.length > 0 ? XProductCodeList : []
            pState.XQuotaCodeList = XQuotaCodeList && XQuotaCodeList.length > 0 ? XQuotaCodeList : []
            pState.XGroupList = XGroupList && XGroupList.length > 0 ? XGroupList : []
            pState.XBrandList = XBrandList && XBrandList.length > 0 ? XBrandList : []
            pState.XProductSubTypeList =
              XProductSubTypeList && XProductSubTypeList.length > 0 ? XProductSubTypeList : []
            pState.IsProductCodeList = state.isProductCodesInclude
            pState.IsProductQuotaCodeList = state.isQuotaCodesInclude
            pState.IsProductBrandList = state.isBrandsInclude
            pState.IsProductGroupList = state.isGroupCodesInclude
            pState.IsProductSubTypeList = state.isSubTypesInclude
            pState.IsQuantity = state.minMaxType
            pState.IsPrice = !state.minMaxType
            pState.MinQuantity = state.minQuantity
            pState.MaxQuantity = state.maxQuantity
            pState.BasketOptions = state.basketOptions.value
            pState.MinPrice = state.minPrice
            pState.MaxPrice = state.maxPrice
            pState.CurrencyType = state.currencyType?.value
            pState.TotalCondition = state.totalCondition?.value
          }
          return pState
        })
      )

      if (isBasketConditionRowSingle && XProductCodeList) {
        const EnableProductCodes: EnableProductCodes[] = XProductCodeList.map(x => {
          let item: EnableProductCodes
          const a = state.EnableProductCodes.find(code => code.ProductCode === x.toString())
          if (!a) {
            item = { Id: uuidv4(), ProductCode: x.toString(), Checked: false }
            return item
          }
          item = a
          return item
        })

        setState({ EnableProductCodes })
      }

      return
    }

    if (XProductCodeList && XProductCodeList.length > 0) {
      const EnableProductCodes: EnableProductCodes[] = XProductCodeList.map(x => {
        let item: EnableProductCodes
        item = { Id: uuidv4(), ProductCode: x.toString(), Checked: false }
        return item
      })

      setState({
        EnableProductCodes,
      })
    }

    setCampaignBasketConditions((prevState: any) => {
      const newRecord = {
        Id: uuidv4(),
        XProductCodeList,
        XQuotaCodeList,
        XGroupList,
        XBrandList,
        XProductSubTypeList,
        IsProductCodeList: state.isProductCodesInclude,
        IsProductQuotaCodeList: state.isQuotaCodesInclude,
        IsProductGroupList: state.isGroupCodesInclude,
        IsProductBrandList: state.isBrandsInclude,
        IsProductSubTypeList: state.isSubTypesInclude,
        IsQuantity: state.minMaxType,
        IsPrice: !state.minMaxType,
        MinQuantity: state.minQuantity,
        MaxQuantity: state.maxQuantity,
        BasketOptions: state.basketOptions.value,
        MinPrice: state.minPrice,
        MaxPrice: state.maxPrice,
        CurrencyType: state.currencyType.value,
        TotalCondition: state.totalCondition.value,
      }

      if (prevState && prevState.length > 0) {
        return [...prevState, newRecord]
      }

      return [newRecord]
    })
  }

  useEffect(() => {
    if (isBasketConditionRowSingle && campaignBasketConditions[0].XProductCodeList) {
      const EnableProductCodes: EnableProductCodes[] = campaignBasketConditions[0].XProductCodeList.map(x => {
        let item: EnableProductCodes
        const a = state.EnableProductCodes.find(code => code.ProductCode === x.toString())
        if (!a) {
          item = { Id: uuidv4(), ProductCode: x.toString(), Checked: false }
          return item
        }
        item = a
        return item
      })

      setState({ EnableProductCodes })
    }
  }, [isBasketConditionRowSingle])

  const removeBasketCondition = id => {
    const basketBasketConditions = campaignBasketConditions.filter(item => item.Id !== id)
    setCampaignBasketConditions(basketBasketConditions)
    if (isBasketConditionRowSingle) {
      const EnableProductCodes: EnableProductCodes[] = campaignBasketConditions[0].XProductCodeList.map(x => {
        let item: EnableProductCodes
        item = { Id: uuidv4(), ProductCode: x.toString(), Checked: false }
        return item
      })

      setState({
        EnableProductCodes,
      })
    }
  }

  const checkGiftCode = async () => {
    const campaignBundleService = new CampaignBundleService({})
    const reqmodel = {
      //GiftCode: values.GiftCode,
      GiftCode: state.GiftCode,
    }

    try {
      const res = await campaignBundleService.checkGiftCode(reqmodel)

      if (!res) {
        sweetAlert('error', 'Hata', 'Bu kupon kullanılamaz olabilir galiba')
      }
      sweetAlert('success', 'Başarılı', 'Kontrol edildi. Kupon kullanılabilir.')
    } catch (err: any) {
      sweetAlert('error', 'Hata', err.description)
    }
  }

  const addProductCondition = () => {
    setCampaignProductConditions((prevState: any) => {
      const newRecord = {
        Id: uuidv4(),
        CampaignBundleId: 0,
        ProductId: 0,
        ResultOperation: { value: 4, label: 'Sabit Fiyat Zorunlu' },
        Quantity: 0,
        MaxQuantity: 0,
        AmountOrRatio: 0,
        CurrencyType: { value: 'TRY', label: 'TRY' },
        ProductCode: '',
      }
      if (prevState && prevState.length > 0) {
        return [...prevState, newRecord]
      }
      return [newRecord]
    })
  }

  const updateProductCondition = (id, key, target) => {
    setCampaignProductConditions((prevState: any) =>
      prevState.map(item => {
        if (item.Id === id) {
          item[key] = target
        }
        return item
      })
    )
  }

  const removeProductCondition = id => {
    setCampaignProductConditions((prevState: any) => prevState.filter(item => item.Id !== id))
  }

  const setRecord = (campaign: any) => {
    let productCodeList: any = []
    if (campaign.XProductCodeList && campaign.XProductCodeList.length > 0 && campaign.XProductCodeList[0].length > 0) {
      productCodeList = campaign.XProductCodeList.map(item => {
        return {
          label: item,
          value: item,
        }
      })
    }

    let QuotaCodeList: any = []
    if (campaign.XQuotaCodeList && campaign.XQuotaCodeList.length > 0 && campaign.XQuotaCodeList[0].length > 0) {
      QuotaCodeList = campaign.XQuotaCodeList.map(item => {
        return {
          label: item,
          value: item,
        }
      })
    }
    let GroupList: any = []
    if (campaign.XGroupList && campaign.XGroupList.length > 0 && campaign.XGroupList[0].length > 0) {
      state.categoryList.map(cat => {
        if (
          campaign.XGroupList.findIndex(grup => {
            return grup === cat.value
          }) > -1
        ) {
          return GroupList.push(cat)
        }
        return
      })
    }
    let BrandList: any = []
    if (campaign.XBrandList && campaign.XBrandList.length > 0 && campaign.XBrandList[0].length > 0) {
      BrandList = campaign.XBrandList.map(item => {
        return {
          label: item,
          value: item,
        }
      })
    }
    let ProductSubTypeList: any = []
    if (
      campaign.XProductSubTypeList &&
      campaign.XProductSubTypeList.length > 0 &&
      campaign.XProductSubTypeList[0].length > 0
    ) {
      ProductSubTypeList = campaign.XProductSubTypeList.map(item => {
        return {
          label: item,
          value: item,
        }
      })
    }

    setState({
      showModal: true,
      selectedBasketConditionId: campaign.Id,
      productCodeList,
      QuotaCodeList,
      BrandList,
      GroupList,
      ProductSubTypeList,
      minQuantity: campaign.MinQuantity,
      maxQuantity: campaign.MaxQuantity,
      minPrice: campaign.MinPrice,
      maxPrice: campaign.MaxPrice,
      isProductCodesInclude: campaign.IsProductCodeList,
      isQuotaCodesInclude: campaign.IsProductQuotaCodeList,
      isGroupCodesInclude: campaign.IsProductGroupList,
      isBrandsInclude: campaign.IsProductBrandList,
      isSubTypesInclude: campaign.IsProductSubTypeList,
      minMaxType: campaign.IsQuantity,
      currencyType: { value: campaign.CurrencyType, label: campaign.CurrencyType },
      basketOptions: { value: campaign.BasketOptions, label: campaign.BasketOptions === 1 ? 'Sepet' : 'Koşul' },
    })
  }

  const dateValid = () => {
    const result = new Date(values.EndDate).getTime() - new Date(values.StartDate).getTime()
    if (result < 60000) Swal.fire('Kampanya bitiş tarihi başlangıç tarihinden önce olamaz.')
  }
  const dateStartValid = () => {
    /*
    if (new Date(values.StartDate).getTime() < new Date().getTime()) {
      Swal.fire('Kampanya başlangıç tarihi şuan ki tarihten önce olamaz.')
      // setFieldValue("StartDate", "");
    }
    */
  }
  const dateEndValid = () => {
    /*
    if (new Date(values.EndDate).getTime() < new Date().getTime()) {
      Swal.fire('Kampanya bitiş tarihi şuan ki tarihten önce olamaz.')
      // setFieldValue("EndDate", "");
    }
    */
  }

  const setABS = (e, name) => {
    const value = Math.abs(+e.target.value)
    setFieldValue(name, value)
  }

  const setDealerCodes = (dealerCodes, optionArr, fieldValue) => {
    if (fieldValue === 'XDealerCodeList') {
      const codeData = dealerCodes.map(item => ({
        label: String(item.DealerCode),
        value: String(item.DealerCode),
      }))

      let list: { label: string; value: string }[] = []

      codeData.forEach(codeItem => {
        if (!values.XDealerCodeList.some((item: any) => item.label === String(codeItem.label))) {
          list.push(codeItem)
        }
      })

      setFieldValue(fieldValue, [
        ...values[fieldValue],
        ...list.filter((item, index, self) => {
          return index === self.findIndex(i => i.label === item.label)
        }),
      ])
    } else {
      let list: any = []
      let codeData: any = []
      dealerCodes.forEach(item => {
        const codes: any = state[optionArr]?.find((code: any) => {
          if (item.DealerCode < 6) {
            return code.value === item.DealerCode
          } else {
            return code.value === '0000' + item.DealerCode
          }
        })
        if (codes === undefined) codeData.push(item.DealerCode)
        else list.push(codes)
      })
      if (codeData.length === 0) return setFieldValue(fieldValue, list)

      Swal.fire({
        icon: 'question',
        title: `${codeData.map(dt => {
          return dt
        })} bu cariler bulunamadı. Yeniden yüklemek ister misiniz?`,
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(async result => {
        if (result.isConfirmed) {
          return
        }
        return setFieldValue(fieldValue, list)
      })
    }
  }

  const handleFiles = async (files: File[], type: 'DealerCode' | 'DealerType' | 'DealerSubType') => {
    const dealerCodes: any = await excelToJSON(files[0])

    if (type === 'DealerCode') {
      setDealerCodes(dealerCodes[0], 'dealerCodeOptions', 'XDealerCodeList')
      return
    }
    if (type === 'DealerType') {
      setDealerCodes(dealerCodes[0], 'dealerTypeOptions', 'XDealerTypeList')
      return
    }
    if (type === 'DealerSubType') {
      setDealerCodes(dealerCodes[0], 'dealerSubTypeOptions', 'XDealerSubTypeList')
      return
    }
  }

  const deleteImage = async data => {
    if (data === 'BImage') setTmpBImage('')
    if (data === 'LImage') setTmpLImage('')
  }
  const clearFileInput = (type: 'BImage' | 'LImage') => {
    if (inputElBImage.current) {
      inputElBImage.current.value = ''
    }
  }
  const handleBImageUpload = async e => {
    if (
      !(
        e.target.files[0].type === 'image/jpeg' ||
        e.target.files[0].type === 'image/JPEG' ||
        e.target.files[0].type === 'image/png' ||
        e.target.files[0].type === 'image/PNG' ||
        e.target.files[0].type === 'image/GIF' ||
        e.target.files[0].type === 'image/gif' ||
        e.target.files[0].type === 'video/mp4' ||
        e.target.files[0].type === 'video/MP4'
      )
    ) {
      clearFileInput('BImage')
      return Swal.fire('Hata', 'Resim jpeg,png, gif veya mp4 olmalıdır!', 'error')
    }
    const fileList: any[] = await getUploadFileRequest(e)
    if (e && e.target && e.target.files && e.target.files[0]) {
      const base64Str = await handleImageUpload(e.target.files[0])
      setTmpBImage(base64Str)
    }

    if (fileList && fileList.length > 0) {
      setFieldValue('BImage', fileList[0])
    }
  }

  const handleLImageUpload = async (e: any) => {
    if (
      !(
        e.target.files[0].type === 'image/jpeg' ||
        e.target.files[0].type === 'image/JPEG' ||
        e.target.files[0].type === 'image/png' ||
        e.target.files[0].type === 'image/PNG' ||
        e.target.files[0].type === 'image/GIF' ||
        e.target.files[0].type === 'image/gif' ||
        e.target.files[0].type === 'video/mp4' ||
        e.target.files[0].type === 'video/MP4'
      )
    ) {
      clearFileInput('LImage')
      return Swal.fire('Hata', 'Resim jpeg,png, gif veya mp4 olmalıdır!', 'error')
    }
    const fileList: any[] = await getUploadFileRequest(e)
    if (e && e.target && e.target.files && e.target.files[0]) {
      const base64Str = await handleImageUpload(e.target.files[0])
      setTmpLImage(base64Str)
    }

    if (fileList && fileList.length > 0) {
      setFieldValue('LImage', fileList[0])
    }
  }

  const handleFilesProductCode = async (files: File[]) => {
    const productCode: any = await excelToJSON(files[0])
    const data: ISelectOption[] = []
    productCode.forEach(code => {
      code.forEach(c => {
        const label: string = String(c.ProductCode)
        if (data.findIndex(d => d.label === label) === -1) {
          const newOption: ISelectOption = { label, value: label }
          data.push(newOption)
        }
      })
    })
    const newData = state.productCodeList ? [...state.productCodeList, ...data] : data
    const uniqueList = newData.filter((item, index, self) => {
      return index === self.findIndex(i => i.label === item.label)
    })
    setState({ productCodeList: uniqueList })
  }

  const handleFilesPCode = async (files: File[]) => {
    const productCodes: any = await excelToJSON(files[0])
    const dt: ISelectOption[] = []
    productCodes.map(code => {
      code.map(c => {
        if (dt.findIndex(d => d.label === String(c.ProductCode)) > -1) return
        dt.push({ value: String(c.ProductCode), label: String(c.ProductCode) })
        if (
          campaignProductConditions.length > 0 &&
          campaignProductConditions.findIndex(camp => {
            return camp.ProductCode === c.ProductCode
          }) > -1
        )
          return
        setCampaignProductConditions((prevState: any) => {
          const newRecord = {
            Id: uuidv4(),
            CampaignBundleId: 0,
            ProductId: 0,
            ResultOperation: { value: 4, label: 'Sabit Fiyat Zorunlu' },
            Quantity: 0,
            MaxQuantity: 0,
            AmountOrRatio: 0,
            CurrencyType: { value: 'TRY', label: 'TRY' },
            ProductCode: { value: String(c.ProductCode), label: String(c.ProductCode) },
          }
          if (prevState && prevState.length > 0) {
            if (prevState.length === 1 && +prevState[0].ProductCode.value === 0) return [newRecord]
            return [...prevState, newRecord]
          }
          return [newRecord]
        })
      })
    })
  }

  const [inputValue, setInputValue] = useState('')
  const [inputValueCond, setInputValueCond] = useState('')

  const handleInputCondChange = event => {
    setInputValueCond(event.target.value)
  }

  const handleInputKeyPressCond = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAddValueCond()
    }
  }

  const handleAddValueCond = () => {
    if (inputValueCond.trim() !== '') {
      let prodList: { label: string; value: string }[] = []

      if (state.productCodeList) {
        prodList = state.productCodeList.map(item => ({
          label: item.label,
          value: String(item.value),
        }))
      }
      if (!state.productCodeList?.some(item => item.label === inputValueCond)) {
        const newItem = { label: inputValueCond, value: inputValueCond }
        prodList.push(newItem)
      }
      setState({ productCodeList: prodList })
      setInputValueCond('')
    }
  }

  const handleInputChange = event => {
    setInputValue(event.target.value)
  }

  const handleInputKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAddValue()
    }
  }

  const handleAddValue = () => {
    if (inputValue.trim() !== '') {
      const words = inputValue.split(' ')
      let dealerList: { label: string; value: string }[] = []
      words.forEach((word: any) => {
        if (!values.XDealerCodeList.some((item: any) => item.label === word)) {
          const newItem = { label: word, value: word }
          dealerList.push(newItem)
        }
      })
      setFieldValue('XDealerCodeList', [...values.XDealerCodeList, ...dealerList])
      setInputValue('')
    }
  }

  const handleInputProductChange = (campaignID, ProductCode, event) => {
    let product: { label: string; value: string }[] = []
    product.push({ label: event.target.value, value: event.target.value })
    updateProductCondition(campaignID, ProductCode, product[0])
  }

  return (
    <div className='campaignWrap'>
      <form onSubmit={handleSubmit} className='container'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex gap-1 align-items-center bg-white rounded-3'>
            <div className='arrow-left'></div>
            <IPButton
              onClick={() => navigate('/admin/ProductOperation/CampaignBundleList' + (location.state?.url ? "?" + location.state?.url : ''))}
              text={'Listeye Dön'}
              className='listBtn'
            />
          </div>
          <IPButton
            style={{ display: (location.state as any)?.flag === 'history' ? 'none' : '' }}
            disabled={(location.state as any)?.flag === 'history' && true}
            type='submit'
            text={state.updateBool ? 'Güncelle' : 'Kaydet'}
            className='saveBtn'
            icon='saveIcon.svg'
          />
        </div>
        <div className='formTitle d-flex align-items-center gap-2 m-l-30'>
          <img src='/images/listIcon.svg' alt='' />
          <div>CARİ FORMS</div>
        </div>
        <div className='formCard'>
          <div className='cariForms'>
            <InputCampaign
              required
              id='BundleCode'
              name='BundleCode'
              label={'Kampanya Kodu/Adı'}
              value={values.BundleCode}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={values.BundleCode}
              errors={errors.BundleCode}
              touched={touched.BundleCode}
              disabled={(location.state as any)?.flag === 'history' && true}
            />
            <InputCampaign
              required
              id='CampaignDescription'
              name='CampaignDescription'
              label={'Sepet Ve Kampanyalar Açıklama'}
              value={values.CampaignDescription}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.CampaignDescription}
              touched={touched.CampaignDescription}
              disabled={(location.state as any)?.flag === 'history' && true}
            />
            <InputCampaign
              required
              id='SapCampaignCode'
              name='SapCampaignCode'
              label={'SAP Kampanya Kodu'}
              value={values.SapCampaignCode}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.SapCampaignCode}
              touched={touched.SapCampaignCode}
              disabled={(location.state as any)?.flag === 'history' && true}
            />
            <InputCampaign
              required
              id='ProductDetailDescription'
              name='ProductDetailDescription'
              label={'Ürün Detay Sayfası Açıklama'}
              value={values.ProductDetailDescription}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.ProductDetailDescription}
              touched={touched.ProductDetailDescription}
              disabled={(location.state as any)?.flag === 'history' && true}
            />
            {/* <InputCampaign
            id='BundleImage'
            name='BundleImage'
            label={'Bundle Görseli'}
            value={values.BundleImage}
            onChange={handleChange}
            onBlur={handleBlur}
          /> */}
            <InputCampaign
              required
              id='ListLinkCode'
              name='ListLinkCode'
              label={'Liste Linki Açıklama'}
              value={values.ListLinkCode}
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.ListLinkCode}
              touched={touched.ListLinkCode}
              disabled={(location.state as any)?.flag === 'history' && true}
            />
            {/* <InputCampaign
            id='ListLinkImage'
            name='ListLinkImage'
            label={'Liste Linki Görseli'}
            value={values.ListLinkImage}
            onChange={handleChange}
            onBlur={handleBlur}
          /> */}
          </div>
          <div className='form--files'>
            {tmpLImage ? (
              <div className='col-lg-4 mt-sm-4 mt-lg-0 card-mobile-resize'>
                <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                  <div className='room '>
                    <div className='room-image text-center '>
                      <IPImage src={tmpLImage} alt='' className='RecentVisits' />
                    </div>
                  </div>
                  <div className='product-description'>
                    <div className='product-title text-left'></div>
                    <div className='product-title text-left' style={{ padding: '10px' }}>
                      Adı:Kapak Görseli
                    </div>
                  </div>
                  <a
                    className='ml-2'
                    data-toggle='tooltip'
                    onClick={() => deleteImage('LImage')}
                    style={{
                      cursor: 'pointer',
                      color: 'red',
                      fontSize: '35px',
                      display: (location.state as any)?.flag === 'history' ? 'none' : '',
                    }}
                  >
                    <i className='icon-trash-2'></i>
                  </a>
                </div>
              </div>
            ) : (
              <div className='col-lg-4 mt-sm-4 mt-lg-0 card-mobile-resize'>
                <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                  <i
                    className='icon-plus'
                    style={{ fontSize: '150px', color: '#adb5bd' }}
                    onClick={() => inputElLImage.current.click()}
                  ></i>
                  <input
                    disabled={(location.state as any)?.flag === 'history' && true}
                    ref={inputElLImage}
                    type='file'
                    name='bannerImage'
                    className='form-control'
                    id='bannerImage'
                    style={{ display: 'none' }}
                    onInput={event => {
                      handleLImageUpload(event)
                    }}
                  />
                  <br />
                  <h5 style={{ color: '#adb5bd' }}>Kapak Görseli Ekle</h5>
                </div>
              </div>
            )}
            {tmpBImage ? (
              <div className='col-lg-4 mt-sm-4 mt-lg-0 card-mobile-resize'>
                <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                  <div className='room '>
                    <div className='room-image text-center '>
                      <IPImage src={tmpBImage} alt='' className='RecentVisits' />
                    </div>
                  </div>
                  <div className='product-description'>
                    <div className='product-title text-left'></div>
                    <div className='product-title text-left' style={{ padding: '10px' }}>
                      Adı:Detay Görseli
                    </div>
                  </div>
                  <a
                    className='ml-2'
                    data-toggle='tooltip'
                    onClick={() => deleteImage('BImage')}
                    style={{
                      display: (location.state as any)?.flag === 'history' ? 'none' : '',
                      cursor: 'pointer',
                      color: 'red',
                      fontSize: '35px',
                    }}
                  >
                    <i className='icon-trash-2'></i>
                  </a>
                </div>
              </div>
            ) : (
              <div className='col-lg-4 mt-sm-4 mt-lg-0 card-mobile-resize'>
                <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                  <i
                    className='icon-plus'
                    style={{ fontSize: '150px', color: '#adb5bd' }}
                    onClick={() => inputElBImage.current.click()}
                  ></i>
                  <input
                    disabled={(location.state as any)?.flag === 'history' && true}
                    ref={inputElBImage}
                    type='file'
                    name='bannerImage'
                    className='form-control'
                    id='bannerImage'
                    style={{ display: 'none' }}
                    onInput={event => {
                      handleBImageUpload(event)
                    }}
                  />
                  <br />
                  <h5 style={{ color: '#adb5bd' }}>Detay Görseli Ekle</h5>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='formTitle d-flex align-items-center gap-2 m-l-30'>
          <img src='/images/listIcon.svg' alt='' />
          <div>CARİ FİLTRE</div>
        </div>
        <div className='formCard'>
          <div className='cariFilterContainer'>
            <div className='d-flex gap-4'>
              <div className='leftContainer w-50'>
                <div>
                  <a className='campaignExcelBtn' href='/assetWeb/docs/dealer-codes-example.xlsx' target='_blank'>
                    Örnek Excel
                  </a>

                  <div className='listBtnGroup'>
                    <IPButton
                      className={`listCariBtn ${enumFunction(state.selectedDealerList, CariListType.CariCode)}`}
                      text={`Cari Kodu Listesi ${values.XDealerCodeList?.length > 0 ? '✅' : ''}`}
                      onClick={() => setState({ selectedDealerList: CariListType.CariCode })}
                    />
                    <IPButton
                      className={`listCariBtn ${enumFunction(state.selectedDealerList, CariListType.CariType)}`}
                      text={`Cari Tipi Listesi ${values.XDealerTypeList?.length > 0 ? '✅' : ''}`}
                      onClick={() => setState({ selectedDealerList: CariListType.CariType })}
                    />
                    <IPButton
                      className={`listCariBtn ${enumFunction(state.selectedDealerList, CariListType.CariAltType)}`}
                      text={`Cari Alt Tipi Listesi ${values.XDealerSubTypeList?.length > 0 ? '✅' : ''}`}
                      onClick={() =>
                        setState({
                          selectedDealerList: CariListType.CariAltType,
                        })
                      }
                    />
                  </div>
                </div>

                <div className='p-0 m-0'>
                  <div
                    className={`cariFilterTextAreaWrap ${enumFunction(
                      state.selectedDealerList,
                      CariListType.CariCode
                    )}`}
                  >
                    <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                      <IPFile
                        disabled={(location.state as any)?.flag === 'history' && true}
                        id='FileInputDealerCode'
                        multiple={false}
                        handleFiles={e => handleFiles(e, 'DealerCode')}
                        removeAfterSelect={true}
                        // removeAfterSelectdealerCodeOptions
                        className='campaignFileInput'
                        label="Excel'den Yükle"
                      />

                      <IPButton
                        disabled={(location.state as any)?.flag === 'history' && true}
                        text={'Dahil'}
                        className={`btnIncluded ${values.IsDealerListInclude ? 'active' : ''}`}
                        onClick={() => setFieldValue('IsDealerListInclude', true)}
                      />
                      <IPButton
                        disabled={(location.state as any)?.flag === 'history' && true}
                        text={'Hariç'}
                        className={`btnExcept ${!values.IsDealerListInclude ? 'active' : ''}`}
                        onClick={() => setFieldValue('IsDealerListInclude', false)}
                      />
                    </div>
                    <CampaignSelect
                      placeholder={(location.state as any) ? values.XDealerCodeList : 'Cari Kod Listesi'}
                      height={180}
                      //options={state.dealerCodeOptions}
                      onChangeMulti={(options: ISelectOption[]) => {
                        if ((location.state as any)?.flag != 'history') setFieldValue('XDealerCodeList', options)
                      }}
                      value={values.XDealerCodeList}
                    />
                    <input
                      disabled={(location.state as any)?.flag === 'history' && true}
                      type='text'
                      placeholder='Cari kodu yazın'
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyPress={handleInputKeyPress}
                    />
                  </div>

                  <div
                    className={`cariFilterTextAreaWrap ${enumFunction(
                      state.selectedDealerList,
                      CariListType.CariType
                    )}`}
                  >
                    <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                      <div className='btns'>
                        <IPFile
                          id='FileInputDealerType'
                          multiple={false}
                          handleFiles={e => handleFiles(e, 'DealerType')}
                          className='campaignFileInput'
                          removeAfterSelect={true}
                          label="Excel'den Yükle"
                        />
                      </div>
                      <IPButton
                        disabled={(location.state as any)?.flag === 'history' && true}
                        text={'Dahil'}
                        className={`btnIncluded ${values.IsDealerTypeInclude ? 'active' : ''}`}
                        onClick={() => setFieldValue('IsDealerTypeInclude', true)}
                      />
                      <IPButton
                        disabled={(location.state as any)?.flag === 'history' && true}
                        text={'Hariç'}
                        className={`btnExcept ${!values.IsDealerTypeInclude ? 'active' : ''}`}
                        onClick={() => setFieldValue('IsDealerTypeInclude', false)}
                      />
                    </div>
                    <CampaignSelect
                      placeholder={'Cari Tipi Listesi'}
                      height={180}
                      options={state.dealerTypeOptions}
                      onChangeMulti={(options: ISelectOption[]) => {
                        if ((location.state as any)?.flag != 'history') setFieldValue('XDealerTypeList', options)
                      }}
                      value={values.XDealerTypeList}
                    />
                  </div>

                  <div
                    className={`cariFilterTextAreaWrap ${enumFunction(
                      state.selectedDealerList,
                      CariListType.CariAltType
                    )}`}
                  >
                    <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                      {/* <div className='btns'>
												<IPFile id='FileInputDealerSubType' multiple={false} handleFiles={e => handleFiles(e, 'DealerSubType')} removeAfterSelect className='campaignFileInput' label="Excel'den Yükle" />
											</div> */}
                      <IPButton
                        disabled={(location.state as any)?.flag === 'history' && true}
                        text={'Dahil'}
                        className={`btnIncluded ${values.IsDealerSubTypeInclude ? 'active' : ''}`}
                        onClick={() => setFieldValue('IsDealerSubTypeInclude', true)}
                      />
                      <IPButton
                        disabled={(location.state as any)?.flag === 'history' && true}
                        text={'Hariç'}
                        className={`btnExcept ${!values.IsDealerSubTypeInclude ? 'active' : ''}`}
                        onClick={() => setFieldValue('IsDealerSubTypeInclude', false)}
                      />
                    </div>
                    <CampaignSelect
                      placeholder={'Cari Alt Tipi Listesi'}
                      height={180}
                      options={state.dealerSubTypeOptions}
                      onChangeMulti={(options: ISelectOption[]) => {
                        if ((location.state as any)?.flag != 'history') setFieldValue('XDealerSubTypeList', options)
                      }}
                      value={values.XDealerSubTypeList}
                    />
                  </div>
                </div>
                <div></div>
                <div className='pt-3'>
                  <IPSelectBox
                    // isDisabled={(location.state as any) ? true : false}
                    isDisabled={(location.state as any)?.flag === 'history' && true}
                    className='listCariSelectBox'
                    isMulti
                    options={state.companyListOptions}
                    value={values.XCompanyCodes}
                    onChangeMulti={option => setFieldValue('XCompanyCodes', option)}
                    placeholder={
                      <div
                        style={{
                          color: '#000',
                          fontSize: '11px',
                          fontWeight: '600',
                        }}
                      >
                        Grup Firma Listesi
                      </div>
                    }
                  />
                </div>
              </div>
              <div className='rightContainer'>
                <InputCampaign
                  disabled={(location.state as any)?.flag === 'history' && true}
                  type='datetime-local'
                  id='StartDate'
                  name='StartDate'
                  label={'Yayın Başlangıç Tarihi'}
                  value={values.StartDate.toString()}
                  onChange={handleChange}
                  onBlur={e => {
                    handleBlur(e)
                    dateStartValid()
                  }}
                  isActive
                />
                <InputCampaign
                  disabled={(location.state as any)?.flag === 'history' && true}
                  type='datetime-local'
                  id='EndDate'
                  name='EndDate'
                  label={'Yayın Bitiş Tarihi'}
                  value={values.EndDate.toString()}
                  onChange={handleChange}
                  onBlur={e => {
                    handleBlur(e)
                    dateEndValid()
                    dateValid()
                  }}
                  isActive
                />
                {values.IsBeforeCampaign && (
                  <InputCampaign
                    //style={location.state as any ? { visibility: 'visible' } : { visibility: !values.IsBeforeCampaign ? 'hidden' : 'visible' }}
                    disabled={state.updateBool}
                    type='datetime-local'
                    id='StartDate2'
                    name='StartDate2'
                    label={'Eski Yayın Başlangıç Tarihi'}
                    value={values.StartDate2.toString()}
                    //placeholder={values.StartDate2.toString()}
                    //placeholder={location.state as any ? values.StartDate2.toString() : "Tarih seçimi"}
                    onChange={handleChange}
                    onBlur={e => {
                      handleBlur(e)
                      dateStartValid()
                    }}
                    isActive
                  />
                )}
                {values.IsBeforeCampaign && (
                  <InputCampaign
                    //style={location.state as any ? { visibility: 'visible' } : { visibility: !values.IsBeforeCampaign ? 'hidden' : 'visible' }}
                    disabled={state.updateBool}
                    type='datetime-local'
                    id='EndDate2'
                    name='EndDate2'
                    label={'Eski Yayın Bitiş Tarihi'}
                    value={values.EndDate2.toString()}
                    //placeholder={location.state as any ? values.EndDate2.toString() : "Tarih seçimi"}
                    onChange={handleChange}
                    onBlur={e => {
                      handleBlur(e)
                      dateEndValid()
                      dateValid()
                    }}
                    isActive
                  />
                )}

                <IPSelectBox
                  isDisabled={state.updateBool}
                  placeholder={
                    <div
                      style={{
                        color: '#000',
                        fontSize: '11px',
                        fontWeight: '600',
                      }}
                    >
                      {values.WarehouseCode}
                    </div>
                  }
                  options={state.wareHouseList} // TODO: Real data will be fetched when it's ready.
                  //value={values.WarehouseCode}
                  onChangeSingle={option => setFieldValue('WarehouseCode', option.label)}
                />
                <InputCampaign
                  type='number'
                  id='MaxCampaignCount'
                  disabled={state.updateBool}
                  name='MaxCampaignCount'
                  label={'Kampanya Yararlanma'}
                  min={0}
                  value={values.MaxCampaignCount.toString()}
                  onChange={e => setABS(e, 'MaxCampaignCount')}
                  onBlur={handleBlur}
                />
                <InputCampaign
                  disabled={(location.state as any)?.flag === 'history' && true}
                  type='number'
                  id='BasketOrder'
                  name='BasketOrder'
                  label={'Sıra No'}
                  min={0}
                  value={values.BasketOrder.toString()}
                  onChange={e => setABS(e, 'BasketOrder')}
                  onBlur={handleBlur}
                />
                <InputCampaign
                  disabled={state.updateBool}
                  type='number'
                  id='TotalGiro'
                  name='TotalGiro'
                  label={'Toplam Ciro'}
                  min={0}
                  value={values.TotalGiro.toString()}
                  onChange={e => setABS(e, 'TotalGiro')}
                  onBlur={handleBlur}
                />
                <InputCampaign
                  disabled={state.updateBool}
                  type='text'
                  id='GiftCode'
                  label={'Hediye Kuponu'}
                  //onChange={(e) => setFieldValue("GiftCode", e.target.value)}
                  onChange={e => setState({ GiftCode: e.target.value })}
                  onBlur={e => setState({ GiftCode: e.target.value })}
                  // setFieldValue("GiftCode", e.target.value)
                  //setState({ GiftCode: e.target.value })
                  value={state.GiftCode}
                // placeholder={state.GiftCode}
                //placeholder={location.state as any ? state.giftCode : 'Hediye kuponu'}
                //labelClassName='campaignLabel'
                //checked={values.IsFcv2}
                //onCheckedChange={isChecked => setFieldValue('IsFcv2', isChecked)}
                />
                <IPButton
                  onClick={checkGiftCode}
                  disabled={state.updateBool}
                  text='Kontrol et'
                  className='w-25'
                  style={{
                    visibility: state.updateBool ? 'hidden' : 'visible',
                  }}
                />

                {/* <IPCheckbox
                  disabled={state.updateBool}
                  label='Sepet tutarına kampanya ürünleri dahil olsun'
                  labelClassName='campaignLabel'
                  checked={values.IsIncludeCampaignProducts}
                  onCheckedChange={isChecked => setFieldValue('IsIncludeCampaignProducts', isChecked)}
                /> */}
                <IPCheckbox
                  disabled={(location.state as any)?.flag === 'history' && true}
                  label='Etkin'
                  labelClassName='campaignLabel'
                  checked={values.Enable}
                  onCheckedChange={isChecked => setFieldValue('Enable', isChecked)}
                />
                <IPCheckbox
                  disabled={(location.state as any)?.flag === 'history' && true}
                  label='Kampanyalar Ekranında Göster'
                  labelClassName='campaignLabel'
                  checked={values.IsShow}
                  onCheckedChange={isChecked => setFieldValue('IsShow', isChecked)}
                />
                <IPCheckbox
                  disabled={state.updateBool}
                  label='Tek seçimli kampanya'
                  labelClassName='campaignLabel'
                  checked={values.IsSingleCampaign}
                  onCheckedChange={isChecked => setFieldValue('IsSingleCampaign', isChecked)}
                />
                <IPCheckbox
                  disabled={state.updateBool}
                  label='Sipariş geçmişine kampanya'
                  labelClassName='campaignLabel'
                  checked={values.IsBeforeCampaign}
                  onCheckedChange={isChecked => setFieldValue('IsBeforeCampaign', isChecked)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='formCard mt-5 d-flex flex-column gap-4'>
          <div className='topArea'>
            <div className='table-responsive' style={{ height: 'auto' }}>
              <table className='campaignTable table table-hover'>
                <thead>
                  <tr>
                    <th scope='col'>
                      <div className='d-flex align-items-center gap-2'>Ürün Kodu Listesi</div>
                    </th>
                    <th scope='col'>
                      <div className='d-flex align-items-center gap-2'>Quota Kodu Listesi</div>
                    </th>
                    <th scope='col'>
                      <div className='d-flex align-items-center gap-2'>Grup Kodu Listesi</div>
                    </th>
                    <th scope='col'>
                      <div className='d-flex align-items-center gap-2'>Marka Listesi</div>
                    </th>
                    <th scope='col'>
                      <div className='d-flex align-items-center gap-2'>Alt Tip Listesi</div>
                    </th>
                    <th scope='col'>İŞLEMLER</th>
                  </tr>
                </thead>

                <tbody>
                  {campaignBasketConditions &&
                    campaignBasketConditions.map(campaign => (
                      <tr key={campaign.Id} onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
                        {/* data-toggle='tooltip' data-placement='top' title='Ürün Adı' */}
                        <td>
                          {campaign.XProductCodeList && campaign.XProductCodeList.length > 1
                            ? campaign.XProductCodeList[0] + ' ...'
                            : campaign.XProductCodeList}
                        </td>
                        <td>
                          {campaign.XQuotaCodeList && campaign.XQuotaCodeList.length > 1
                            ? campaign.XQuotaCodeList[0] + ' ...'
                            : campaign.XQuotaCodeList}
                        </td>
                        <td>
                          {campaign.XGroupList && campaign.XGroupList.length > 1
                            ? state.categoryList.find(category => {
                              return category.value === campaign.XGroupList[0]
                            })?.label + ' ...'
                            : state.categoryList.find(category => {
                              return category.value === campaign.XGroupList[0]
                            })?.label}
                        </td>
                        <td>
                          {campaign.XBrandList && campaign.XBrandList.length > 1
                            ? campaign.XBrandList[0] + ' ...'
                            : campaign.XBrandList}
                        </td>
                        <td>
                          {campaign.XProductSubTypeList && campaign.XProductSubTypeList.length > 1
                            ? campaign.XProductSubTypeList[0] + ' ...'
                            : campaign.XProductSubTypeList}
                        </td>
                        <td className='actionCol'>
                          <div
                            className='tableIcon'
                            onClick={() => {
                              setRecord(campaign)
                            }}
                          >
                            <i className='icon-edit'></i>
                          </div>
                          <div
                            className='tableIcon'
                            onClick={() => {
                              if (state.updateBool) {
                                return
                              }
                              Swal.fire({
                                icon: 'error',
                                title: 'Satır silinecek emin misiniz?',
                                showCloseButton: true,
                                showDenyButton: true,
                                confirmButtonText: 'Sil!',
                                confirmButtonAriaLabel: 'Sil',
                                confirmButtonColor: '#ec4c4c',
                                denyButtonText: 'İptal',
                                denyButtonAriaLabel: 'İptal',
                                denyButtonColor: '#AAA',
                              }).then(result => {
                                if (result.isConfirmed) {
                                  removeBasketCondition(campaign.Id)
                                }
                              })
                            }}
                          >
                            <i className='icon-delete'></i>
                          </div>
                          {isBasketConditionRowSingle && (
                            <div className={isAccordionOpen ? 'arrow-down' : 'arrow-up'}></div>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              {isBasketConditionRowSingle && (
                <div className={`product-acc ${isAccordionOpen ? 'product-acc-opened' : ''}`}>
                  {state.EnableProductCodes.length > 0 &&
                    state.EnableProductCodes.map(campaign => (
                      <div key={campaign.Id} className='w-25'>
                        <IPCheckbox
                          disabled={(location.state as any)?.flag === 'history' && true}
                          label={campaign.ProductCode}
                          labelClassName='campaignLabel'
                          checked={campaign.Checked}
                          onCheckedChange={isChecked => {
                            const updatedState = state.EnableProductCodes.map(x => {
                              if (x.Id === campaign.Id) {
                                x.Checked = isChecked
                              }
                              return x
                            })
                            setState({
                              EnableProductCodes: updatedState,
                            })
                          }}
                        />
                      </div>
                    ))}
                </div>
              )}

              <div
                className='newRecordButton'
                onClick={() => {
                  if (state.updateBool) {
                    return
                  }
                  if (campaignBasketConditions.length === 1) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Ekranda göstermek istediğiniz ürünler silinecektir. Silmek istediğinize emin misiniz?',
                      showCloseButton: true,
                      showDenyButton: true,
                      confirmButtonText: 'Evet',
                      confirmButtonAriaLabel: 'Evet',
                      confirmButtonColor: '#ec4c4c',
                      denyButtonText: 'Hayır',
                      denyButtonAriaLabel: 'Hayır',
                      denyButtonColor: '#AAA',
                    }).then(result => {
                      if (result.isDenied) {
                        return
                      }
                      setState({
                        showModal: true,
                        isNewRecord: true,
                        productCodeList: [],
                        QuotaCodeList: [],
                        GroupList: [],
                        BrandList: [],
                        ProductSubTypeList: [],
                        minQuantity: 0,
                        maxQuantity: 0,
                        minPrice: 0,
                        maxPrice: 0,
                        selectedBasketConditionId: '',
                      })
                      state.productCodeList = []
                      state.QuotaCodeList = []
                      state.GroupList = []
                      state.BrandList = []
                      state.ProductSubTypeList = []
                      state.minQuantity = 0
                      state.maxQuantity = 0
                      state.minPrice = 0
                      state.maxPrice = 0
                      state.selectedBasketConditionId = ''
                    })
                  } else {
                    setState({
                      showModal: true,
                      isNewRecord: true,
                      productCodeList: [],
                      QuotaCodeList: [],
                      GroupList: [],
                      BrandList: [],
                      ProductSubTypeList: [],
                      minQuantity: 0,
                      maxQuantity: 0,
                      minPrice: 0,
                      maxPrice: 0,
                      selectedBasketConditionId: '',
                    })
                    state.productCodeList = []
                    state.QuotaCodeList = []
                    state.GroupList = []
                    state.BrandList = []
                    state.ProductSubTypeList = []
                    state.minQuantity = 0
                    state.maxQuantity = 0
                    state.minPrice = 0
                    state.maxPrice = 0
                    state.selectedBasketConditionId = ''
                  }
                }}
              >
                <td colSpan={6} style={{ color: '#699bf7' }}>
                  Yeni Kayıt Ekle...
                </td>
              </div>
            </div>
          </div>
          <div className='row' style={{ paddingTop: '35px' }}>
            <div className='col-md-2'>
              <a className='campaignExcelBtn' href='/assetWeb/docs/productCode.xlsx' target='_blank'>
                Örnek Excel
              </a>
            </div>
            <div className='col-md-2'>
              <IPFile
                id='FileInputPCode'
                multiple={false}
                handleFiles={e => handleFilesPCode(e)}
                removeAfterSelect={true}
                className='campaignFileInput'
                label="Excel'den Yükle"
              />
            </div>
          </div>
          <div className={window.innerWidth < 1200 ? 'table-responsive' : ''}>
            <table className='campaignTable table table-hover'>
              <thead>
                <tr>
                  <th scope='col' style={{ width: '260px' }}>
                    <div className='d-flex align-items-center gap-2'>Ürün Kodu</div>
                  </th>
                  <th scope='col' style={{ width: '400px' }}>
                    <div className='d-flex align-items-center gap-2'>Sonuç İşlemi</div>
                  </th>
                  <th scope='col'>
                    <div className='d-flex align-items-center gap-2'>Adet</div>
                  </th>
                  <th scope='col'>
                    <div className='d-flex align-items-center gap-2'>Max Adet</div>
                  </th>
                  <th scope='col'>
                    <div className='d-flex align-items-center gap-2'>Tutar/Oran</div>
                  </th>
                  <th scope='col' style={{ width: '150px' }}>
                    <div className='d-flex align-items-center gap-2'>Döviz</div>
                  </th>
                  <th scope='col'>İŞLEMLER</th>
                </tr>
              </thead>

              <tbody>
                {campaignProductConditions &&
                  campaignProductConditions.map(campaign => (
                    <tr key={campaign.Id}>
                      <td data-toggle='tooltip' data-placement='top' title='Ürün Adı'>
                        <IPInput
                          type='text'
                          disabled={state.updateBool}
                          //options={state.productCodeOptions}
                          value={campaign.ProductCode && campaign.ProductCode.value}
                          placeholder={campaign.ProductCode && campaign.ProductCode.label}
                          onChange={e => {
                            handleInputProductChange(campaign.Id, 'ProductCode', e)
                          }}
                        />
                      </td>
                      <td>
                        <div style={{ zIndex: 5 }}>
                          <IPSelectBox
                            isDisabled={state.updateBool}
                            options={
                              state.updateBool
                                ? resultOperationSelect
                                : resultOperationSelect.filter(res => {
                                  return res.value !== 3
                                })
                            }
                            value={campaign.ResultOperation}
                            placeholder={resultOperationSelect.map((a: ISelectOption) => {
                              if (Number(campaign.ResultOperation) === +a.value) {
                                return a.label
                              }
                            })}
                            onChangeSingle={option => updateProductCondition(campaign.Id, 'ResultOperation', option)}
                          />
                        </div>
                      </td>
                      <td>
                        <input
                          disabled={state.updateBool}
                          type='number'
                          min={0}
                          value={campaign.Quantity}
                          onChange={e => updateProductCondition(campaign.Id, 'Quantity', e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          disabled={state.updateBool}
                          type='number'
                          min={0}
                          value={campaign.MaxQuantity}
                          onChange={e => {
                            updateProductCondition(campaign.Id, 'MaxQuantity', e.target.value)
                          }}
                        />
                      </td>
                      <td>
                        <IPInput
                          disabled={state.updateBool}
                          type='text'
                          min={0.03}
                          value={campaign.AmountOrRatio}
                          onChange={e => {
                            handleQuantity(e, campaign)
                          }}
                          onBlur={e => handleQuantityOnBlur(e, campaign)}
                        />
                      </td>
                      <td>
                        <IPSelectBox
                          isDisabled={state.updateBool}
                          options={[
                            { label: 'TRY', value: 'TRY' },
                            { label: 'USD', value: 'USD' },
                            { label: 'EUR', value: 'EUR' },
                          ]}
                          value={campaign.CurrencyType}
                          placeholder={campaign.CurrencyType}
                          onChangeSingle={option => updateProductCondition(campaign.Id, 'CurrencyType', option)}
                        />
                      </td>
                      <td className='actionCol'>
                        <div
                          className='tableIcon'
                          onClick={() => {
                            if (state.updateBool) {
                              return
                            }
                            Swal.fire({
                              icon: 'error',
                              title: 'Satır silinecek emin misiniz?',
                              showCloseButton: true,
                              showDenyButton: true,
                              confirmButtonText: 'Sil!',
                              confirmButtonAriaLabel: 'Sil',
                              confirmButtonColor: '#ec4c4c',
                              denyButtonText: 'İptal',
                              denyButtonAriaLabel: 'İptal',
                              denyButtonColor: '#AAA',
                            }).then(result => {
                              if (result.isConfirmed) {
                                removeProductCondition(campaign.Id)
                              }
                            })
                          }}
                        >
                          <i className='icon-delete'></i>
                        </div>
                      </td>
                    </tr>
                  ))}
                <tr
                  onClick={() => {
                    if (state.updateBool) {
                      return
                    }
                    addProductCondition()
                  }}
                >
                  <td colSpan={7} style={{ color: '#699bf7' }}>
                    Yeni Kayıt Ekle...
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className='d-flex justify-content-between m-t-10'>
          <div className='d-flex gap-1 align-items-center bg-white rounded-3'>
            <div className='arrow-left'></div>
            <IPButton
              onClick={() => navigate('/admin/ProductOperation/CampaignBundleList' + (location.state?.url ? "?" + location.state?.url : ''))}
              text={'Listeye Dön'}
              className='listBtn'
            />
          </div>
          <IPButton
            style={{ display: (location.state as any)?.flag === 'history' ? 'none' : '' }}
            disabled={(location.state as any)?.flag === 'history' && true}
            type='submit'
            text={state.updateBool ? 'Güncelle' : 'Kaydet'}
            className='saveBtn'
            icon='saveIcon.svg'
          />
        </div>
      </form>

      <div
        className={`campaignModalWrapper ${state.showModal ? 'active' : ''}`}
        onClick={() => setState({ showModal: false, isNewRecord: false })}
      >
        <div className='campaignModal' onClick={e => e.stopPropagation()}>
          <form
            onSubmit={e => {
              e.preventDefault()
              state.isNewRecord ? handleRecord() : handleRecord(state.selectedBasketConditionId)
            }}
          >
            <div className='topAreaListGroup'>
              <div>
                <div className='w-100 text-center mb-4'>Ürün Kodu Listesi</div>
                <div className='topAreaBtnGroup w-100 d-flex justify-content-center align-items-center mb-2'>
                  <IPButton
                    disabled={state.updateBool}
                    text={'Dahil'}
                    className={`btnTopArea ${enumFunction(state.isProductCodesInclude, true)}`}
                    onClick={() =>
                      setState({
                        isProductCodesInclude: true,
                      })
                    }
                  />
                  <IPButton
                    disabled={state.updateBool}
                    text={'Hariç'}
                    className={`btnTopArea ${enumFunction(state.isProductCodesInclude, false)}`}
                    onClick={() =>
                      setState({
                        isProductCodesInclude: false,
                      })
                    }
                  />
                </div>
                <div
                  className='topAreaInputGroup position-relative'
                  data-toggle='tooltip'
                  data-placement='top'
                  title='Ürün Adı'
                >
                  <CampaignSelect
                    disabled={state.updateBool}
                    isDisabled={state.updateBool}
                    //options={state.productCodeOptions}
                    value={state.productCodeList}
                    onChangeMulti={(options: ISelectOption[]) => setState({ productCodeList: options })}
                    placeholder={'Ürün Kodu'}
                    menuStyle={true}
                  />
                  <input
                    type='text'
                    disabled={state.updateBool}
                    placeholder='Ürün kodu yazın'
                    value={inputValueCond}
                    onChange={handleInputCondChange}
                    onKeyPress={handleInputKeyPressCond}
                  />
                </div>
              </div>
              <div>
                <div className='w-100 text-center mb-4'>Quota Kodu Listesi</div>
                <div className='topAreaBtnGroup w-100 d-flex justify-content-center align-items-center mb-2'>
                  <IPButton
                    disabled={state.updateBool}
                    text={'Dahil'}
                    className={`btnTopArea ${enumFunction(state.isQuotaCodesInclude, true)}`}
                    onClick={() => setState({ isQuotaCodesInclude: true })}
                  />
                  <IPButton
                    disabled={state.updateBool}
                    text={'Hariç'}
                    className={`btnTopArea ${enumFunction(state.isQuotaCodesInclude, false)}`}
                    onClick={() => setState({ isQuotaCodesInclude: false })}
                  />
                </div>
                <div className='topAreaInputGroup position-relative'>
                  <CampaignSelect
                    disabled={state.updateBool}
                    options={state.updateBool ? undefined : state.quotaCodeOptions}
                    placeholder={'Quota Kodu'}
                    value={state.QuotaCodeList}
                    onChangeMulti={options => setState({ QuotaCodeList: options })}
                  />
                </div>
              </div>
              <div>
                <div className='w-100 text-center mb-4'>Grup Kodu Listesi</div>
                <div className='topAreaBtnGroup w-100 d-flex justify-content-center align-items-center mb-2'>
                  <IPButton
                    disabled={state.updateBool}
                    text={'Dahil'}
                    className={`btnTopArea ${enumFunction(state.isGroupCodesInclude, true)}`}
                    onClick={() => setState({ isGroupCodesInclude: true })}
                  />
                  <IPButton
                    disabled={state.updateBool}
                    text={'Hariç'}
                    className={`btnTopArea ${enumFunction(state.isGroupCodesInclude, false)}`}
                    onClick={() => setState({ isGroupCodesInclude: false })}
                  />
                </div>
                <div className='topAreaInputGroup position-relative'>
                  <CampaignSelect
                    disabled={state.updateBool}
                    placeholder={'Grup Kodu'}
                    value={state.GroupList}
                    options={state.categoryList}
                    onChangeMulti={options => setState({ GroupList: options })}
                  />
                </div>
              </div>
              <div>
                <div className='w-100 text-center mb-4'>Marka Listesi</div>
                <div className='topAreaBtnGroup w-100 d-flex justify-content-center align-items-center mb-2'>
                  <IPButton
                    disabled={state.updateBool}
                    text={'Dahil'}
                    className={`btnTopArea ${enumFunction(state.isBrandsInclude, true)}`}
                    onClick={() => setState({ isBrandsInclude: true })}
                  />
                  <IPButton
                    disabled={state.updateBool}
                    text={'Hariç'}
                    className={`btnTopArea ${enumFunction(state.isBrandsInclude, false)}`}
                    onClick={() => setState({ isBrandsInclude: false })}
                  />
                </div>
                <div className='topAreaInputGroup position-relative'>
                  <CampaignSelect
                    disabled={state.updateBool}
                    placeholder={'Marka Kodu'}
                    options={
                      state.updateBool
                        ? undefined
                        : state.brandListOptions?.map(item => {
                          return { label: item, value: item }
                        })
                    }
                    // placeholder={location.state ? campaignBasketConditions.map(a => a.XBrandList) : 'Marka Kodu'}
                    value={state.BrandList}
                    onChangeMulti={options => setState({ BrandList: options })}
                  />
                </div>
              </div>
              <div>
                <div className='w-100 text-center mb-4'>Alt Tip Listesi</div>
                <div className='topAreaBtnGroup w-100 d-flex justify-content-center align-items-center mb-2'>
                  <IPButton
                    disabled={state.updateBool}
                    text={'Dahil'}
                    className={`btnTopArea ${enumFunction(state.isSubTypesInclude, true)}`}
                    onClick={() => setState({ isSubTypesInclude: true })}
                  />
                  <IPButton
                    disabled={state.updateBool}
                    text={'Hariç'}
                    className={`btnTopArea ${enumFunction(state.isSubTypesInclude, false)}`}
                    onClick={() => setState({ isSubTypesInclude: false })}
                  />
                </div>
                <div className='topAreaInputGroup position-relative'>
                  <CampaignSelect
                    disabled={state.updateBool}
                    options={state.updateBool ? undefined : productSubType}
                    placeholder={'Alt Tip Kodu'}
                    // placeholder={
                    //   location.state ? campaignBasketConditions.map(a => a.XProductSubTypeList) : 'Alt Tip Kodu'
                    // }
                    value={state.ProductSubTypeList}
                    onChangeMulti={state.updateBool ? undefined : options => setState({ ProductSubTypeList: options })}
                  />
                </div>
              </div>
            </div>
            <div className='row' style={{ paddingTop: '35px' }}>
              <div className='col-md-2'>
                <a className='campaignExcelBtn' href='/assetWeb/docs/productCode.xlsx' target='_blank'>
                  Örnek Excel
                </a>
              </div>
              <div className='col-md-2'>
                <IPFile
                  id='FileInputProductCode'
                  multiple={false}
                  handleFiles={e => handleFilesProductCode(e)}
                  removeAfterSelect={true}
                  className='campaignFileInput'
                  label="Excel'den Yükle"
                />
              </div>
            </div>
            <div className='topAreaQuantityGroup d-flex flex-column justify-content-around align-items-center'>
              <div className='contentArea w-100'>
                <div className='contentCard'>
                  <div className={`title ${enumFunction(state.minMaxType, true)}`} onClick={() => handleMinMax(true)}>
                    Min/Max Adet
                  </div>
                  <br></br>
                  <div
                    className='d-flex flex-column justify-content-end'
                    style={{ display: 'contents', paddingLeft: '15rem' }}
                  >
                    Koşul Toplam Adetine Bak
                  </div>
                  <div className='contentCardInputArea'>
                    <input
                      id='specinput'
                      type='number'
                      disabled={state.updateBool ? true : !state.minMaxType}
                      value={state.minQuantity}
                      onChange={e => {
                        const value = Math.abs(+e.target.value)
                        setState({ minQuantity: value })
                      }}
                      onBlur={e => {
                        const val = e.target.value
                        if (+val > state.maxQuantity && state.maxQuantity > 0) {
                          setState({ minQuantity: state.maxQuantity })
                          return Swal.fire('Hata', 'Girilen değer max değerden eşit veya küçük olmalıdır.', 'error')
                        }
                      }}
                    />
                    <input
                      type='number'
                      disabled={state.updateBool ? true : !state.minMaxType}
                      value={state.maxQuantity}
                      onChange={e => {
                        const value = Math.abs(+e.target.value)
                        setState({ maxQuantity: value })
                      }}
                      onBlur={e => {
                        const val = e.target.value
                        if (+val < state.minQuantity) {
                          setState({ maxQuantity: state.minQuantity })
                          return Swal.fire('Hata', 'Girilen değer minimum değere eşit veya büyük olmalıdır.', 'error')
                        }
                      }}
                    />

                    <div className='w-100'>
                      <IPSelectBox
                        isDisabled={state.updateBool ? true : !state.minMaxType}
                        //value={state.totalCondition}
                        placeholder={campaignBasketConditions
                          .map(a => {
                            if (a.TotalCondition === 'E') {
                              return 'Evet'
                            } else {
                              return 'Hayır'
                            }
                          })
                          .slice(0, 1)}
                        options={MinMaxSelect}
                        onChangeSingle={option => setState({ totalCondition: option })}
                      />
                    </div>
                  </div>
                </div>
                <div className='contentCard'>
                  <div className={`title ${enumFunction(state.minMaxType, false)}`} onClick={() => handleMinMax(false)}>
                    Min/Max Toplam Fiyat
                  </div>
                  <br></br>
                  <div
                    className='d-flex flex-column justify-content-end'
                    style={{ display: 'contents', paddingLeft: '15rem' }}
                  >
                    Para Birimini Seçin
                  </div>
                  <div className='contentCardInputArea'>
                    <input
                      type='number'
                      disabled={state.updateBool ? true : state.minMaxType} //state.minMaxType
                      value={state.minPrice}
                      placeholder={state.minPrice.toString()}
                      onChange={e => {
                        const value = Math.abs(+e.target.value)
                        setState({ minPrice: value })
                      }}
                      onBlur={e => {
                        const val = e.target.value
                        if (+val > state.maxPrice && state.maxPrice > 0) {
                          setState({ minPrice: state.maxPrice })
                          return Swal.fire('Hata', 'Girilen değer max değerden eşit veya küçük olmalıdır.', 'error')
                        }
                      }}
                    />
                    <input
                      type='number'
                      disabled={state.updateBool ? true : state.minMaxType} //state.minMaxType
                      value={state.maxPrice}
                      placeholder={state.maxPrice.toString()}
                      onChange={e => {
                        const value = Math.abs(+e.target.value)
                        setState({ maxPrice: value })
                      }}
                      onBlur={e => {
                        const val = e.target.value
                        if (+val < state.minPrice) {
                          setState({ maxPrice: state.minPrice })
                          return Swal.fire('Hata', 'Girilen değer minimum değere eşit veya büyük olmalıdır.', 'error')
                        }
                      }}
                    />

                    <div className='w-100'>
                      <IPSelectBox
                        isDisabled={state.updateBool ? true : state.minMaxType}
                        //value={state.currencyType}
                        placeholder={campaignBasketConditions
                          .map(a => {
                            if (a.CurrencyType === 'TRY') {
                              return 'TRY'
                            }
                            if (a.CurrencyType === 'USD') {
                              return 'USD'
                            }
                            if (a.CurrencyType === 'EUR') {
                              return 'EUR'
                            }
                          })
                          .slice(0, 1)}
                        options={currencyTypeSelect}
                        onChangeSingle={option => setState({ currencyType: option })}
                      />
                    </div>
                  </div>
                </div>
                <div className='contentCard'>
                  <IPSelectBox
                    isDisabled={state.updateBool}
                    value={state.basketOptions}
                    // placeholder={campaignBasketConditions
                    //   .map((a) => {
                    //     if (a.BasketOptions == 1) {
                    //       return "Sepet";
                    //     }
                    //     if (a.BasketOptions == 2) {
                    //       return "Koşul";
                    //     }
                    //   })
                    //   .slice(0, 1)}
                    options={basketOptionSelect}
                    onChangeSingle={option => setState({ basketOptions: option })}
                  />
                </div>
              </div>
            </div>
            <IPButton
              style={{ display: (location.state as any)?.flag === 'history' ? 'none' : '' }}
              disabled={state.updateBool}
              type='submit'
              text={state.isNewRecord ? 'Yeni Kayıt' : 'Kaydet'}
              className='modalBtn'
              onClick={() => setState({ showModal: false, isNewRecord: false })}
            />
            {!state.isNewRecord && (
              <IPButton
                disabled={state.updateBool}
                text={'Sil'}
                className='modalBtn'
                onClick={() =>
                  Swal.fire({
                    icon: 'error',
                    title: 'Satır silinecek emin misiniz?',
                    showCloseButton: true,
                    showDenyButton: true,
                    confirmButtonText: 'Sil!',
                    confirmButtonAriaLabel: 'Sil',
                    confirmButtonColor: '#ec4c4c',
                    denyButtonText: 'İptal',
                    denyButtonAriaLabel: 'İptal',
                    denyButtonColor: '#AAA',
                  }).then(result => {
                    if (result.isConfirmed) {
                      removeBasketCondition(state.selectedBasketConditionId)
                    }
                    setState({ showModal: false, isNewRecord: false })
                  })
                }
              />
            )}
          </form>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(CampaignBundleAdd)
