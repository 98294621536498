import React, { useState, useMemo } from 'react'
import IPButton from '../../../../../components/IPButton'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { useCustomReducer } from '../../../../../base/customHooks'
import InputCampaign from '../../../../../components/InputCampaign'
import Table from '../../../../../components/Table'
import Swal from 'sweetalert2'
import { ISapRequest, ISapResponse } from '../models/models'
import { getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'
import { FilterListApi } from '../services/filterListApi'
import IPRadio from '../../../../../components/IPRadio'
import { CreateReceiptApi } from '../services/createReceiptApi'
import { ISelectOption } from '../../../HomeAndSiteOperation/BulkAdding/components/BulkAdding'
import IPSelectBox from '../../../../../components/IPSelectBox'

interface ISapState {
  radioSelect: number
  isDone: ISelectOption[]
}


function SAP(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const [sapData, setSapData] = useState<ISapResponse[]>([])
  const [success, setSuccess] = useState<number>(2)

  const getInitialState = (): ISapState => {
    return {
      radioSelect: 0,
      isDone: [
        { label: 'Hepsi', value: 2 },
        { label: 'Başarılı', value: 1 },
        { label: 'Başarısız', value: 0 },
      ],
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ISapState>(intialState)

  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Seçim',
        accessor: 'col1',
      },
      {
        Header: 'İşlem Tipi',
        accessor: 'col2',
      },
      {
        Header: 'İşlem Anı',
        accessor: 'col3',
      },
      {
        Header: 'Şirket',
        accessor: 'col13',
      },
      {
        Header: 'Hesap Kodu',
        accessor: 'col4',
      },
      {
        Header: 'Banka',
        accessor: 'col5',
      },
      {
        Header: 'Kart No',
        accessor: 'col6',
      },
      {
        Header: 'Kart İsmi',
        accessor: 'col7',
      },
      {
        Header: 'Çekilen Tutar',
        accessor: 'col8',
      },
      {
        Header: 'Taksit',
        accessor: 'col9',
      },
      {
        Header: 'Evrak No',
        accessor: 'col10',
      },
      {
        Header: 'Vade Farkı, Satış Siparişi Açıklama',
        accessor: 'col11',
      },
      {
        Header: 'Hata Açıklama',
        accessor: 'col12',
      },
    ],
    []
  )
  const tableData = useMemo(
    () => sapData.map((item, index) => {
      const data = {
        col1: item.IsDone ?
          (<span style={{ color: 'green' }}>Kayıt Oluşturuldu</span>) : item.ProcessType == 'Satış Siparişi' ? (<IPButton text={`Sipariş Oluştur`} disabled={true} onClick={() => makbuzOlustur(item.Id)} style={{ width: "15%", backgroundColor: 'grey', cursor: 'default' }} />)
            :
            (<IPButton text={`Makbuz Oluştur`} onClick={() => makbuzOlustur(item.Id)} style={{ width: "15%" }} />),
        col2: item.ProcessType,
        col3: item.ProcessDate.toLocaleString(),
        col13: item.Company == "1000" ? "Index" : item.Company == "1100" ? "Datagate" : item.Company == "1200" ? "Despec" : item.Company == "2300" ? "Netex" : "",
        col4: item.DealerSapCode && item.DealerSapCode.replace("0000", ""),
        col5: item.BankName,
        col6: item.CardNumber,
        col7: item.CardNameSurname,
        col8: item.TotalPrice.toFixed(2),
        col9: item.Installment,
        col10: item.DocumenNumber,
        col11: item.Description.indexOf("Finansal İşlem") ? <p style={{ color: "green" }}>{item.Description}</p> : item.Description.indexOf("Satış Siparişi") ? <p style={{ color: "red" }}>{item.Description}</p> : <p style={{ color: "orange" }}>{item.Description}</p>,
        col12: item.ErrorMessage !== "[[[]]]" && <div
          dangerouslySetInnerHTML={{
            __html: item.ErrorMessage,
          }}
        ></div>
      }
      return data
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [sapData])

  const getSap = async () => {
    if (!startDate || !endDate) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen tarih giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const req: ISapRequest = {
      Company: "",
      StartDate: startDate && getDateByTimezoneOffset(startDate) as any,
      EndDate: endDate && getDateByTimezoneOffset(endDate) as any,
      IsDone: success
    }
    props.showLoading();
    const filterListApi: FilterListApi = new FilterListApi({})
    await filterListApi
      .filterList(req)
      .then(result => {
        if (result) {
          setSapData(result)
        }
        props.hideLoading();
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading();
      })
  }

  const makbuzOlustur = async (id: number) => {
    Swal.fire({
      icon: 'question',
      title: "Makbuz/Sipariş kaydı oluşturulacaktır. Devam etmek istediğinize emin misiniz?",
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        createReceipt(id)
      }
    })
  }

  const createReceipt = async (id: number) => {
    props.showLoading();
    const createReceiptApi: CreateReceiptApi = new CreateReceiptApi({})
    await createReceiptApi
      .createReceipt(id)
      .then(result => {
        if (result) {
          setSapData((prevState: any) => prevState.filter(item => item.Id !== id))
          Swal.fire({
            icon: 'success',
            title: result,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
        props.hideLoading();
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading();
      })
  }

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>{t('pages.admin.CreditCardOperation.SAP.header')}</span>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row mb-3'>
                <div className='col-12 col-md-3 mb-3'>
                  <InputCampaign
                    type='date'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, "Start")}
                    isActive
                  />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <InputCampaign
                    type='date'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, "End")}
                    isActive
                  />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <IPSelectBox
                    placeholder='Durum'
                    options={state.isDone}
                    value={state.isDone.filter(a => a.value == success)}
                    onChangeSingle={option => {
                      setSuccess(Number(option.value))
                    }}
                  />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <IPButton text='Göster' onClick={getSap} />
                </div>
              </div>
            </form>
            <div className='m-4'>
              <Table columns={tableColumns} data={tableData} currentPage={0} headerName={`İşlem Tarihi Aralığı: ${new Date(startDate).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()}`} columnCount={13} backgroundColor="red" color="white" textAlign={true} />
            </div>
            {/* <IPButton text={`<< SSP ve Makbuz Oluştur >>`} onClick={getSap} style={{ width: "15%" }} /> */}
            {/* {
              sapData && sapData.length > 0 &&
              <div className='m-10'>

            <IPButton text={`<< Makbuz Oluştur >>`} onClick={createReceipt} style={{ width: "15%" }} />
          </div>
            } */}
          </div>
        </div>
      </div>
    </div >
  )
}

export default componentWithContext(SAP)
