import React, { useEffect, useMemo } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPModal from '../../../../../components/IPModal'
import Table from '../../../../../components/Table'
import { GetNetChannelContentListApi } from '../services/getNetChannelContentList'
import { INetChannelContentData } from '../models/model'
import IPButton from '../../../../../components/IPButton'
import IPInput from '../../../../../components/IPInput'
import IPCheckbox from '../../../../../components/IPCheckBox'
import classes from '../../../../../components/IPFile/IPFile.module.scss'
import { getUploadFileRequest, uuidv4 } from '../../../../../base/utils/dataHelper'
import { SaveNetChannelContentApi } from '../services/saveNetChannelContentApi'

interface INetChannelState {
  netChannelContentData: INetChannelContentData[]
}

function NetChannelContentAdd(props: BaseComponentWithContextProps) {
  const getInitialState = (): INetChannelState => {
    return {
      netChannelContentData: [],
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<INetChannelState>(intialState)
  const routeLocation = useLocation()


  useEffect(() => {
    if (routeLocation.state)
      getNetChannelContentList(routeLocation.state.pageID)
  }, [])

  const getNetChannelContentList = async (id: number) => {
    props.showLoading();
    const netChannelContent: GetNetChannelContentListApi = new GetNetChannelContentListApi({})

    netChannelContent
      .getNetChannelContentList(id)
      .then((res: INetChannelContentData[]) => {
        props.hideLoading()
        res.forEach(element => {
          element.StateId = element.Id.toString()
        });
        setState({ netChannelContentData: res })
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading();
      })
  }

  const locations = [
    { label: 'Banner', value: 1 },
    { label: 'Ürünler', value: 2 },
    { label: 'Eğitim Dokümanları', value: 3 },
    { label: 'Görseller', value: 4 },
    { label: 'Dokümanlar', value: 5 },
  ]

  const navigate: any = useNavigate()


  const getNet = async (datas: INetChannelContentData, type: string, value: any) => {
    const dataDpp: INetChannelContentData[] = []
    state.netChannelContentData.map((dpp) => {
      if (dpp.StateId === datas.StateId) {
        return dataDpp.push({
          Deleted: type === "Deleted" ? value : datas.Deleted,
          Id: datas.Id,
          Image: datas.Image,
          StateId: datas.StateId,
          IsTitle: type === "IsTitle" ? value : datas.IsTitle,
          File: datas.File,
          Link: type === "Link" ? value : datas.Link,
          Location: routeLocation.state.pageID,
          Name: type === "Name" ? value : datas.Name,
          OrderNo: type === "OrderNo" ? value : datas.OrderNo,
          IsLink: type === "IsLink" ? value : datas.IsLink,
        })
      }
      dataDpp.push(dpp)
    })
    setState({ netChannelContentData: dataDpp })
  }

  const handleImageFileUpload = async (datas: INetChannelContentData, type: string, e: any) => {
    const fileList: any[] = await getUploadFileRequest(e)

    for (const fileData of fileList) {
      if (fileData.size > 20000000) {
        return Swal.fire('Hata', 'Her dosya 20 MB\'den küçük olmalıdır!', 'error');
      }

      const allowedTypes = ['.jpeg', '.png', '.gif', '.jpg', '.vnd.openxmlformats-officedocument.wordprocessingml.document', '.xlsx', '.pdf'];
      if (!allowedTypes.includes(fileData.Extension)) {
        return Swal.fire('Hata', 'Hatalı dosya formatı! Sayfayı yeniledikten sonra tekrar deneyiniz.', 'error');
      }
    }

    // Create the Files array from the fileList array
    const data = {
      Files: fileList.map((fileData, index) => ({
        Id: index,
        Label: '',
        Description: '',
        File: fileData,
      })),
    };

    const dataDpp: INetChannelContentData[] = []
    state.netChannelContentData.map((dpp) => {
      if (dpp.StateId === datas.StateId) {
        return dataDpp.push({
          Deleted: datas.Deleted,
          Id: datas.Id,
          Image: datas.Image,
          StateId: datas.StateId,
          File: type === "Image" ? data.Files[0]?.File : datas.File,
          IsTitle: datas.IsTitle,
          Link: datas.Link,
          Location: routeLocation.state.pageID,
          Name: datas.Name,
          OrderNo: datas.OrderNo,
          IsLink: datas.IsLink,
        })
      }
      dataDpp.push(dpp)
    })
    setState({ netChannelContentData: dataDpp })
  }

  const addNetChannelContent = async () => {
    const req = state.netChannelContentData
    const saveNetChannelContent: SaveNetChannelContentApi = new SaveNetChannelContentApi({})

    saveNetChannelContent
      .saveNetChannelContent(req)
      .then((res: any) => {
        props.hideLoading();
        if (res) {
          Swal.fire({
            icon: 'success',
            title: `${routeLocation.state?.pageName} güncellenmiştir.`,
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async () => {
            navigate('/admin/homeAndSiteOperation/netChannelContentList')
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const addContentItem = async (id: string) => {
    const dt: INetChannelContentData[] = state.netChannelContentData;
    dt.push({
      Deleted: false,
      Id: 0,
      StateId: uuidv4(),
      IsLink: false,
      Image: "",
      IsTitle: false,
      Link: "",
      Location: routeLocation.state.pageID,
      Name: "",
      OrderNo: 0,
    })
    setState({ netChannelContentData: dt })
  }


  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>NetChannel Sayfası / {routeLocation?.state?.pageName}</span>
            <Link to='/admin/homeAndSiteOperation/netChannelContentList'>
              <IPButton
                type='button'
                className='btn btn-primary m-l-10'
                text='Listeye Dön'
                style={{ width: '25px', marginRight: '10px' }}
              />
            </Link>
          </div>
          <div className='card-body'>
            {
              state.netChannelContentData.map((item, index) => (
                <>
                  <form id='id1' className='formform' noValidate>
                    <div className='form-row'>
                      <div className='form-group col-md-3'>
                        <label className='labeltext'>Metin</label>
                        <IPInput
                          value={item.Name}
                          type='text'
                          onChange={(e) => getNet(item, "Name", e.target.value)}
                        />
                      </div>
                      <div className='form-group col-md-3'>
                        <label className='labeltext'>Link</label>
                        <IPInput
                          value={item.Link}
                          type='text'
                          onChange={(e) => getNet(item, "Link", e.target.value)}
                        />
                      </div>
                      <div className='form-group col-md-1'>
                        <label className='labeltext'>Sıra</label>
                        <IPInput
                          value={item.OrderNo}
                          type='number'
                          onChange={(e) => getNet(item, "OrderNo", e.target.value)}
                        />
                      </div>
                      <div className='form-group col-md-1 mt-5 ml-5'>
                        <IPCheckbox
                          name={`isTitle${index}`}
                          label='Başlık'
                          onChange={(e) => getNet(item, "IsTitle", !item.IsTitle)}
                          checked={item.IsTitle}
                        />
                      </div>
                      <div className='col-md-2 mt-5'>
                        <div className={classes.file}>
                          <input
                            type='file'
                            onInput={(e) => {
                              handleImageFileUpload(item, "Image", e)
                            }}
                            multiple={false}
                            id={`ImageFile${index}`}
                          />
                          <label htmlFor={`ImageFile${index}`}>
                            Dosya Seç
                          </label>
                        </div>
                        {
                          item.File?.Label?.length > 0 ? <p>{item.File?.Label}</p> : <p>{item.Image}</p>
                        }
                      </div>
                      <div className='form-group col-md-1 mt-5 ml-5'>
                        <IPCheckbox
                          name={`isTitle${index}`}
                          label='Sil'
                          onChange={(e) => getNet(item, "Deleted", !item.Deleted)}
                          checked={item.Deleted}
                        />
                      </div>
                      <div className='col-lg-12 d-flex'>
                        <div>
                          <IPButton
                            onClick={() => addContentItem("")}
                            type='button'
                            className='btn btn-success m-t-5'
                            text='Satır Ekle'
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                  <br></br>
                  <hr />
                  <br></br>
                </>
              ))

            }
            {
              state.netChannelContentData && state.netChannelContentData.length === 0 &&
              <div className='col-lg-12 d-flex'>
                <div>
                  <IPButton
                    onClick={() => addContentItem("")}
                    type='button'
                    className='btn btn-success m-t-5'
                    text='Satır Ekle'
                  />
                </div>
              </div>
            }
            <div className='col-lg-2 d-flex justify-content-end float-right mb-5'>
              <IPButton
                text="Güncelle"
                type='button'
                className='btn btn-success m-t-5'
                onClick={addNetChannelContent}
              />
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
export default componentWithContext(NetChannelContentAdd)