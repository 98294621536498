import React, { useEffect, useMemo, useState } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPModal from '../../../../../components/IPModal'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { ListNoticeApi } from '../services/ListNoticeApi'
import IPButton from '../../../../../components/IPButton'
import { NoticeData } from '../models/models'
import { SaveNoticeApi } from '../services/SaveNoticeApi'


function HomePageNotice(props: BaseComponentWithContextProps) {

  useEffect(() => {
    getNotice()
  }, [])


  const [notice, setNotice] = useState<any>()

  const getNotice = async () => {
    props.showLoading();
    const listNotice: ListNoticeApi = new ListNoticeApi({})

    listNotice
      .listNoticeApi()
      .then((res: any) => {
        props.hideLoading()
        setNotice(res[0].Notice)
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading();
      })
  }

  const editNotice = async () => {
    const req: NoticeData = {
      Notice: notice
    }
    const saveNotice: SaveNoticeApi = new SaveNoticeApi({})
    saveNotice.saveNoticeApi(req).then((res: any) => {
      props.hideLoading()
      if (res) {
        Swal.fire({
          icon: 'success',
          title: `Duyuru Güncellenmiştir.`,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      }
    })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Anasayfa Duyuru</span>
          </div>
          <div className='card-body'>
            <CKEditor
              editor={ClassicEditor}
              data={notice}
              onReady={editor => {
                // You can store the "editor" and use when it is needed.
              }}
              onChange={(event, editor) => {
                const data = editor.getData()
                setNotice(data)
              }}
              onBlur={(event, editor) => { }}
              onFocus={(event, editor) => { }}
            />
          </div>
          <div className='etkin d-flex justify-content-end'>
            <div className='col-md-2'>
              <IPButton
                type='button'
                onClick={() => editNotice()}
                className='btn btn-primary'
                text={'Kaydet'}
              />
            </div>
          </div>
        </div>
      </div>

    </div >
  )
}
export default componentWithContext(HomePageNotice)