import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { translate } from '../../base/localization'
import Image from '../../components/IPImage'
import IPPriceDisplay from '../../components/IPPriceDisplay'
import { useAppSelector } from '../../redux/app/hooks'
import { selectedTheme } from '../../redux/slices/theme/themeSlice'
import Translations from '../../translations'
import { ProductListSpecialFilterType } from '../ProductList/models/commonModels'
import { IBanner, ILocationEnum } from './commonModels/models'
import './css/HomePageRedBanner.scss'
import { GetBannersApi } from './services/getBannersApi'

function HomePageNightMarket(props: any) {
  const [banners, setBanners] = useState<IBanner[]>([])
  const theme = useAppSelector(selectedTheme)
  // useEffect(() => {
  //   getBanners()
  // }, [theme])

  const getBanners = async () => {
    const getBanners: GetBannersApi = new GetBannersApi({})
    const banners = await getBanners.getBanners()
    setBanners(
      banners
        .filter((banner: IBanner) => {
          return banner.Location === ILocationEnum.OrtaBanner
        })
        .sort((a, b) => a.Order - b.Order)
    )
  }
  const t = translate(Translations)
  return (
    <React.Fragment>
      {props.product && (
        <section style={{
          backgroundImage: `url('images/gecepazari.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center'
        }}>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-9'>
                <div className='row m-t-10'>
                  {(props.product as []).slice(0, 4).map((item: any, index) => (
                    <div className='col-12 col-sm-6 col-md-3 products-mobile-resize mb-3' key={item?.Id}>
                      <div className='first-products-banner text-center'>
                        <div className='row' style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div style={{ width: '25%' }}>
                            {' '}
                            <Image src={item.Product.BrandDetail.ImageUrl} alt='#' className='' />
                          </div>
                          <div style={{ width: '25%' }}>
                            {' '}
                            <Image src={item.Product.Company.Image} alt='#' className='' />
                          </div>
                        </div>

                        <div className='room'>
                          <div className='room-image text-center'>
                            <Link to={`/opportunit/detail/${item.Product.OpportunityId}?from=opportunity`}>
                              {item.Product.Image ? (
                                <Image src={item.Product.Image} alt='#' className='' />
                              ) : (
                                <Image src='./images/products/Resim_yok-01.jpg' alt='' />
                              )}
                            </Link>
                          </div>
                          {/* Start Product Description */}
                          <div className='product-description '>
                            <Link to={`/opportunit/detail/${item.Product.OpportunityId}?from=opportunity`}>
                              <div className='product-title text-left'>{item.Product?.Name}</div>
                            </Link>
                            <div className='product-title text-left'>Ürün Kodu: {item?.ProductStockCode}</div>
                          </div>
                          {/* End Product Description */}
                        </div>
                        <br></br>
                        <div className='product'>
                          <div className='product-detail m-b-30 p-0 float-left'>
                            <div className='d-flex product-price mt-0 w-100'>
                              {/* <ul className="list list-legend d-flex align-items-center">
                                  <li className="m-0">
                                    {" "}
                                    <del className="mt-0 mr-2">
                                      {item.Product?.Prices?.Normal?.Price}
                                      <span className="price">{item.Product?.Prices?.Normal?.Price}</span>
                                    </del>
                                  </li>
                                  {item?.discount > 0 && (
                                    <li className="mb-0 mr-0">
                                      <span
                                        className="product-sale-off"
                                        style={{
                                          backgroundColor:
                                            props.theme.colors.backgroundColor,
                                        }}
                                      >
                                        {item?.discount}%
                                      </span>
                                    </li>
                                  )}
                                </ul> */}
                              {/* <ins>{formatPrice(item.Price)} </ins> */}
                              Size Özel:
                              <span className='price ml-3' style={{ color: 'blue' }}>
                                {' '}
                                {/* {item.PriceDisplay} */}
                                <IPPriceDisplay price={item.PriceDisplay ?? '0,00 $'} />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='p-progress-bar-container m-0 title-up small extra-small'>
                          <div
                            className='p-progress-bar'
                            style={{
                              backgroundColor: 'blue',
                            }}
                            data-percent='80'
                          ></div>
                        </div>
                        <div className='product-bottom-desc text-left'>
                          <span
                            style={{
                              color: props.theme.colors.backgroundColor,
                            }}
                          >
                            {/* {item?.Stock} */}
                          </span>{' '}
                          <span>{item.Product.OpportunityShortDescription}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className='col-lg-3 text-lg-right text-sm-center text-light'>
                <h2 className='red-banner-title'>
                  {'Gece'} &nbsp;{t('pages.homePage.market')}
                </h2>
                <img src='/images/gecepazaripaket.png' className='img-fluid mb-4' alt='' width={'70%'} />
                {props.count > 4 && (
                  <div className='see-more'>
                    {/* <Link to='/opportunitMarket'> */}
                    <Link to={`/products?specialFilter=${ProductListSpecialFilterType.OpportunityMarket}`}>
                      <div className='btn '>
                        Tümünü Göster
                        <i className='fas fa-chevron-right'></i>
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
      <br></br>
    </React.Fragment>
  )
}

export default HomePageNightMarket
