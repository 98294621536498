import React, { useEffect, useRef, useState } from 'react'
import IPButton from '../../../../../components/IPButton'
import IPInput from '../../../../../components/IPInput'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPCheckbox from '../../../../../components/IPCheckBox'
import { useFormik } from 'formik'
import _ from 'lodash'
import { UpdateBrandApi } from '../services/updateBrandApi'
import Modal from '../../../../../components/IPModal/IPModal'
import {
  BrandModul,
  getDateByTimezoneOffset,
  getUploadFileRequest,
  handleImageUpload,
  uuidv4,
} from '../../../../../base/utils/dataHelper'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { postBrandAddApi } from '../services/postBrandAddApi'
import IPTextarea from '../../../../../components/IPTextarea'
import Image from '../../../../../components/IPImage/IPImage'
import { CommonPostResponse, IBrand, IDocuments, IFiles, ILink } from '../../../components/models/models'
import { getBrandApi } from '../../../components/api'
import InputCampaign from '../../../../../components/InputCampaign'

interface IMarkaState {
  brandList: IBrand[]
  showModal: boolean
  linkList: ILink[]
  button: string
  logo: boolean
  banner: boolean
  imageName: string
  link: ILink
  image: boolean
  linkBool: boolean
  documentLogo: boolean
  documentBanner: boolean
  linkDetail: string
  brand: boolean
  modul: boolean
  videoId: boolean
  videoType: boolean
  documentBannerCount: number
  documentLogoCount: number
}
function BrandUpdate() {
  const routeLocation = useLocation()
  const [images, setImages] = useState<any[]>([])
  const [documents, setDocuments] = useState<IDocuments[]>([])
  const [link, setLink] = useState<ILink[]>([])

  const deleteLink = async (data, string) => {
    let linkListUpdate: ILink[] = []
    if (string === 'list') {
      linkListUpdate = link
        .filter(list => {
          return list.Id != data
        })
        .map(l => {
          return l
        })
      setLink(linkListUpdate)
    } else {
      linkListUpdate = state.linkList
        .filter(list => {
          return list.Order != data
        })
        .map(l => {
          return l
        })
      setState({ linkList: linkListUpdate })
    }
  }
  const inputElBanner: any = useRef(null)
  const inputElLogo: any = useRef(null)
  const inputEl: any = useRef(null)
  const deleteImage = async data => {
    const imageUpdate = images.filter(img => {
      return img.Id !== data
    })
    images
      .filter(doc => {
        return doc.Id === data
      })
      .map(doc => {
        if (doc.Label === 'Banner') setState({ documentBanner: false, banner: false })
        if (doc.Label === 'Logo') setState({ documentLogo: false, logo: false })
      })
    setImages(imageUpdate)
  }

  const deleteDocument = async data => {
    const documentUpdate = documents.filter(doc => {
      return doc.Id !== data
    })
    documents
      .filter(doc => {
        return doc.Id === data
      })
      .map(doc => {
        if (doc.Name === 'Banner') setState({ documentBanner: false, banner: false })
        if (doc.Name === 'Logo') setState({ documentLogo: false, logo: false })
      })
    setDocuments(documentUpdate)
  }

  const handleFileUpload = async (data, type, e: any) => {
    if ((type === 1 && state.banner) || (type === 2 && state.logo)) {
      Swal.fire({
        icon: 'question',
        title: `${data} daha önce eklenmiştir değiştirmek istediğinize emin misiniz?`,
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(async result => {
        if (result.isConfirmed) {
          handleAdd(data, type, e, true)
        }
      })
    } else handleAdd(data, type, e)
    if (type === 1) setState({ banner: true })
    else setState({ logo: true })
  }
  const handleFile = async e => {
    let targetFiles = e.target.files
    targetFiles = await getUploadFileRequest(e)
    targetFiles[0].Id = uuidv4()
    targetFiles[0].base64Str = await handleImageUpload(e.target.files[0])
    setImages([...images, ...targetFiles])
  }
  const handleAdd = async (data, type, e: any, update?: any) => {
    const fileList: any[] = await getUploadFileRequest(e)
    if (fileList && fileList.length > 0) {
      const datas = _.clone(fileList[0])
      datas.Id = uuidv4()
      datas.Type = type
      datas.Label = data
      datas.base64Str = await handleImageUpload(e.target.files[0])
      if (update) {
        if (type === 1 || type === 2) {
          if (type === 1) setState({ documentBanner: true })
          if (type === 2) setState({ documentLogo: true })
          setDocuments(prevState => {
            return prevState
              .filter(prev => {
                return prev.Name != data
              })
              .map(p => {
                return p
              })
          })
          setImages([...images, datas])
        } else
          setImages(prevState => {
            return prevState.map(prev => {
              if (prev.Type === type) {
                prev = datas
              }
              return prev
            })
          })
      } else {
        setImages([...images, datas])
      }
    }
    setState({ image: true })
  }

  const initialValues = Object.assign({
    Type: null,
    BrandId: null,
    Description: '',
    Files: [
      {
        Label: '',
        Content: '',
        Extension: '',
        FileType: '',
      },
    ],
    Name: '',
    Link: [
      {
        Name: '',
        Link: '',
      },
    ],
    StartDate: new Date(),
    EndDate: new Date(),
    Enabled: false,
    flag: 'Ekle',
    VideoId: '',
    VideoType: null,
  })
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    validateField,
    setFieldValue,
    setFieldTouched,
    resetForm,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: async () => {},
  })

  const navigate: any = useNavigate()

  const editBrand = async () => {
    const data: IFiles[] = []
    const dataLink: ILink[] = []
    images &&
      images.map(img =>
        data.push({ Label: img.Label, Content: img.Content, Extension: img.Extension, FileType: img.Extension })
      )
    link && link.map(list => dataLink.push({ Link: list.Link, Name: list.Name }))
    state.linkList && state.linkList.map(list => dataLink.push({ Link: list.Link, Name: list.Name }))
    const clnValues = _.clone(values)
    delete clnValues.flag
    clnValues.Files = data
    clnValues.Link = dataLink
    clnValues.Type = clnValues.Type ? Number(clnValues.Type.value) : 0
    clnValues.VideoType = clnValues.VideoType ? Number(clnValues.VideoType.value) : 0
    clnValues.BrandId = clnValues.BrandId ? Number(clnValues.BrandId.value) : 0
    clnValues.StartDate = getDateByTimezoneOffset(startDate) as any
    clnValues.EndDate = getDateByTimezoneOffset(endDate) as any
    if (values.Documents) clnValues.Documents = documents
    if (clnValues) {
      if (values.flag === 'Güncelle') {
        const updateBrand: UpdateBrandApi = new UpdateBrandApi({})
        updateBrand
          .putBrand(clnValues)
          .then(async result => {
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'Marka Güncellendi',
                showConfirmButton: true,
                allowOutsideClick: false,
                confirmButtonText: 'Tamam',
              }).then(async result => {
                navigate('/admin/BrandPageOperation/BrandInformation')
              })
            }
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: true,
            })
          })
      } else {
        const postBrand: postBrandAddApi = new postBrandAddApi({})
        postBrand
          .postBrand(clnValues)
          .then((result: CommonPostResponse) => {
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'Marka Eklendi',
                showConfirmButton: true,
                allowOutsideClick: false,
                confirmButtonText: 'Tamam',
              }).then(async result => {
                navigate('/admin/BrandPageOperation/BrandInformation')
              })
            }
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: true,
              confirmButtonText: 'Evet',
              denyButtonText: 'Hayır',
            })
          })
      }
    }
  }
  const getInitialState = (): IMarkaState => {
    return {
      brandList: [],
      showModal: false,
      linkList: [],
      button: 'Ekle',
      logo: false,
      banner: false,
      imageName: '',
      link: {
        Id: '',
        Name: '',
        Link: '',
        Order: 0,
      },
      image: false,
      linkBool: false,
      documentLogo: false,
      documentBanner: false,
      linkDetail: '',
      brand: false,
      modul: false,
      videoId: true,
      videoType: true,
      documentBannerCount: 0,
      documentLogoCount: 0,
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IMarkaState>(intialState)

  const addLink = async () => {
    if (state.button === 'Ekle') {
      const clnValues = _.clone(state.link)
      clnValues.Id = ''
      clnValues.Order = state.linkList.length + 1
      setState({ linkList: [...state.linkList, clnValues], showModal: false, linkBool: true })
    } else {
      if (state.linkDetail === 'link') {
        setLink(prevState => {
          return prevState.map(prev => {
            if (prev.Id === state.link.Id) {
              prev = state.link
            }
            return prev
          })
        })
        setState({ showModal: false, linkBool: true, linkDetail: '' })
      } else {
        const linkListUpdate = state.linkList
          .filter(list => {
            return list.Order != state.link.Order
          })
          .map(l => {
            return l
          })
        setState({ linkList: [...linkListUpdate, state.link], showModal: false, linkBool: true, linkDetail: '' })
      }
    }
  }
  useEffect(() => {
    getBrand()
  }, [])

  const getBrand = async () => {
    const data: any[] = await getBrandApi()
    setState({ brandList: data })
  }
  const t = translate(Translations)
  const TypeChange = async (data: ISelectOption) => {
    if (data.value === '2' || data.value === '3') return setState({ videoId: false })
    return setState({ videoId: true })
  }
  const onModulChange = (data: ISelectOption) => {
    if (data.value === '16' || data.value === '17') {
      if (state.image || state.linkBool || (state.image && state.linkBool) || values.BrandId) {
        Swal.fire({
          icon: 'question',
          title: `Bu modül de marka geçersiz olacaktır. Modül değiştirmeniz durumunda daha önce girmiş olduğunuz veriler silinecektir kabul ediyor musunuz?`,
          showConfirmButton: true,
          allowOutsideClick: false,
          showDenyButton: true,
          confirmButtonText: 'Evet',
          denyButtonText: 'Hayır',
        }).then(async result => {
          if (result.isConfirmed) {
            const image = _.clone(images)
            const link = _.clone(state.linkList)
            link.splice(0)
            image.splice(0)
            setImages(image)
            setState({
              linkList: link,
              logo: false,
              banner: false,
              linkBool: false,
              image: false,
              brand: true,
            })
            setFieldValue('BrandId', null)
            setFieldValue('Type', data)
          }
        })
      } else {
        setState({
          brand: true,
          videoType: true,
          videoId: true,
        })
        setFieldValue('Type', data)
      }
    } else {
      if (state.image || state.linkBool || (state.image && state.linkBool) || values.BrandId) {
        Swal.fire({
          icon: 'question',
          title: `Modül değiştirmeniz durumunda daha önce girmiş olduğunuz veriler silinecektir kabul ediyor musunuz?`,
          showConfirmButton: true,
          allowOutsideClick: false,
          showDenyButton: true,
          confirmButtonText: 'Evet',
          denyButtonText: 'Hayır',
        }).then(async result => {
          if (result.isConfirmed) {
            const image = _.clone(images)
            const link = _.clone(state.linkList)
            link.splice(0)
            image.splice(0)
            setImages(image)
            setState({
              linkList: link,
              logo: false,
              banner: false,
              linkBool: false,
              image: false,
              brand: false,
            })
            if (data.value == '2') setState({ videoType: false, videoId: true })
            else {
              setState({ videoType: true, videoId: true })
              setFieldValue('VideoType', null)
              setFieldValue('VideoId', '')
            }
            setFieldValue('Type', data)
          }
        })
      } else {
        if (data.value == '2') setState({ videoType: false, videoId: true, brand: false })
        else {
          setState({ videoType: true, videoId: true, brand: false })
          setFieldValue('VideoType', null)
          setFieldValue('VideoId', '')
        }
        setFieldValue('Type', data)
      }
    }
  }
  const IBrandOption =
    state.brandList &&
    state.brandList.map(brand => {
      return { label: brand.Name, value: brand.Id }
    })
  const IVideoTypeOption: ISelectOption[] = [
    {
      label: 'MP4 Video',
      value: '1',
    },
    {
      label: 'Youtube Video',
      value: '2',
    },

    {
      label: 'Vimeo Video',
      value: '3',
    },
  ]

  useEffect(() => {
    if (routeLocation.state) {
      if ((routeLocation.state as any)?.data === 'data') {
        const brand = (routeLocation.state as any)?.brand
        const modul = (routeLocation.state as any)?.modul
        setFieldValue('BrandId', brand)
        setFieldValue('Type', modul)
        if (modul && (modul.value === '16' || modul.value === '17')) setState({ brand: true })
      } else {
        const data = (routeLocation.state as any)?.brandDetailData
        setValues(data)
        setFieldValue(
          'Type',
          IModulOption.find((elem: any) => Number(elem.value) === data.Type)
        )
        setFieldValue(
          'BrandId',
          IBrandOption?.find((elem: any) => Number(elem.value) === data.BrandId)
        )
        if (data.VideoType) {
          setFieldValue(
            'VideoType',
            IVideoTypeOption?.find((elem: any) => Number(elem.value) === data.VideoType)
          )
          setState({ videoType: false })
          if (data.VideoType === 2 || data.VideoType === 3) setState({ videoId: false })
        }

        data.Documents.map(document => {
          if (document.Name === 'Logo') setState({ logo: true, documentLogo: true })
          if (document.Name === 'Banner') setState({ banner: true, documentBanner: true })
        })
        setDocuments(data.Documents)
        setLink(data.Link)
        //setDateRange([new Date(data.StartDate), new Date(data.EndDate)])
        setState({ brand: true, modul: true })

        setStartDate(new Date(data.StartDate))
        setEndDate(new Date(data.EndDate))
      }
    }
  }, [state.brandList])
  useEffect(() => {
    if (!state.showModal) setState({ link: { Name: '', Link: '', Order: 0 } })
  }, [state.showModal])

  const IModulOption: ISelectOption[] = BrandModul()

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>{t('pages.admin.brandPageOperation.brandInformation.brandUpdate.header')}</span>
            <Link to='/admin/BrandPageOperation/BrandInformation'>
              <IPButton type='button' className='btn btn-primary' text='Listeye Dön' />
            </Link>
          </div>
          <div className='card-body'>
            <form id='form1' className='form-validate' noValidate>
              <div className='etkin'>
                <label className='p-switch switch-color-success'>
                  <label className='float-left mr-4'>
                    {t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.publish')}
                  </label>
                  <IPCheckbox name='Enabled' onChange={handleChange} checked={values.Enabled} />
                </label>
              </div>
              <div className='form-row'>
                <div className='col-lg-4'>
                  <label className='labeltext'>
                    {t('pages.admin.brandPageOperation.brandInformation.brandUpdate.brand')}
                  </label>
                  <IPSelectBox
                    isDisabled={state.brand}
                    isClearable
                    id='BrandId'
                    name='BrandId'
                    options={
                      state.brandList &&
                      state.brandList.map(brand => {
                        return { label: brand.Name, value: brand.Id }
                      })
                    }
                    onChangeSingle={option => setFieldValue('BrandId', option)}
                    value={values.BrandId}
                  />
                </div>
                <div className='form-group col-md-4'>
                  <label className='labeltext'>
                    {/* {t('pages.admin.brandPageOperation.brandInformation.brandUpdate.module1')} */}
                    Modül
                  </label>
                  <IPSelectBox
                    isDisabled={state.modul}
                    id='Type'
                    name='Type'
                    options={IModulOption}
                    onChangeSingle={option => {
                      onModulChange(option)
                    }}
                    value={values.Type}
                  />
                </div>

                <div className='form-group col-md-2 align-self-end'>
                  <InputCampaign
                    type='date'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, 'Start')}
                    isActive
                  />
                </div>
                <div className='form-group col-md-2 align-self-end'>
                  <InputCampaign
                    type='date'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, 'End')}
                    isActive
                  />
                </div>
              </div>
              <div className='form-row'>
                <div className='form-group col-md-4'>
                  <label className='labeltext'>Video Tipi</label>
                  <IPSelectBox
                    isDisabled={state.videoType}
                    isClearable
                    id='VideoType'
                    name='VideoType'
                    options={IVideoTypeOption}
                    onChangeSingle={option => {
                      TypeChange(option)
                      setFieldValue('VideoType', option)
                    }}
                    value={values.VideoType}
                  />
                </div>
                <div className='form-group col-md-4'>
                  <label className='labeltext'>Video Id</label>
                  <IPInput
                    disabled={state.videoId}
                    type='text'
                    className='form-control'
                    name='VideoId'
                    placeholder='Video Id'
                    onChange={handleChange}
                    value={values.VideoId}
                  />
                </div>
                <div className='form-group col-md-4'>
                  <label className='labeltext'>
                    {t('pages.admin.brandPageOperation.brandInformation.brandUpdate.description')}
                  </label>
                  <IPTextarea
                    id='Description'
                    className='form-control'
                    name='Description'
                    value={values.Description}
                    placeholder='Açıklama'
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='form-row'>
                <label className='labeltext' style={{ margin: '10px' }}>
                  Dosyalar
                </label>
              </div>
              <div className='form-row'>
                {documents &&
                  documents.length > 0 &&
                  documents.map(image =>
                    !state.videoType ? (
                      <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize'>
                        <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                          <h5>Video</h5>
                        </div>
                      </div>
                    ) : (
                      <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize' key={image.Id}>
                        <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                          <div className='room '>
                            <div className='room-image text-center '>
                              <Image src={image.FileUrl} alt='' className='RecentVisits' />
                            </div>
                          </div>
                          <div className='product-description '>
                            <div className='product-title text-left'></div>
                            <div className='product-title text-left'>Adı:{image.Name}</div>
                          </div>
                          <a
                            className='ml-2'
                            data-toggle='tooltip'
                            onClick={() => deleteDocument(image.Id)}
                            style={{ cursor: 'pointer', color: 'red', fontSize: '35px' }}
                          >
                            <i className='icon-trash-2'></i>
                          </a>
                        </div>
                      </div>
                    )
                  )}
                {images &&
                  images.length > 0 &&
                  images.map(image =>
                    !state.videoType ? (
                      <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize'>
                        <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                          <h5>Video</h5>
                        </div>
                      </div>
                    ) : (
                      <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize' key={image.Order}>
                        <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                          <div className='room '>
                            <div className='room-image text-center '>
                              <Image src={image.base64Str} alt='' className='RecentVisits' />
                            </div>
                          </div>
                          <div className='product-description'>
                            <div className='product-title text-left'></div>
                            <div className='product-title text-left' style={{ padding: '10px' }}>
                              Adı:{image.Label}
                            </div>
                          </div>
                          <a
                            className='ml-2'
                            data-toggle='tooltip'
                            onClick={() => deleteImage(image.Id)}
                            style={{ cursor: 'pointer', color: 'red', fontSize: '35px' }}
                          >
                            <i className='icon-trash-2'></i>
                          </a>
                        </div>
                      </div>
                    )
                  )}
                {((images && images.length > 0) || (documents && documents.length > 0)) &&
                values.Type &&
                (values.Type.value === '16' || (values.Type && values.Type.value === '17')) ? (
                  ''
                ) : values.Type && values.Type.value === '1' ? (
                  <>
                    {!state.documentBanner && !state.banner ? (
                      <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize'>
                        <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                          <i
                            className='icon-plus'
                            style={{ fontSize: '150px' }}
                            onClick={() => inputElBanner.current.click()}
                          ></i>
                          <input
                            ref={inputElBanner}
                            type='file'
                            name='Image'
                            className='form-control'
                            id='customFile'
                            style={{ display: 'none' }}
                            onChange={event => {
                              handleFileUpload('Banner', 1, event)
                            }}
                          />
                          <br />
                          <h5>Banner Ekle</h5>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {!state.documentLogo && !state.logo ? (
                      <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize'>
                        <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                          <i
                            className='icon-plus'
                            style={{ fontSize: '150px' }}
                            onClick={() => inputElLogo.current.click()}
                          ></i>
                          <input
                            ref={inputElLogo}
                            type='file'
                            name='Image'
                            className='form-control'
                            id='customFile'
                            style={{ display: 'none' }}
                            onChange={event => {
                              handleFileUpload('Logo', 2, event)
                            }}
                          />
                          <br />
                          <h5>Logo Ekle</h5>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize'>
                    <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                      <i
                        className='icon-plus'
                        style={{ fontSize: '150px' }}
                        onClick={() => inputEl.current.click()}
                      ></i>
                      <input
                        ref={inputEl}
                        type='file'
                        name='Image'
                        className='form-control'
                        id='customFile'
                        style={{ display: 'none' }}
                        multiple
                        onChange={handleFile}
                      />
                      <br />
                      <h5>Dosya Ekle</h5>
                    </div>
                  </div>
                )}
              </div>
              {values.Type && values.Type.value != '16' && values.Type.value != '17' && (
                <>
                  <div className='form-row' style={{ marginTop: '10px' }}>
                    <div className='form-group col-md-6'>
                      <label className='labeltext'>Linkler</label>
                      <div className='row' style={{ justifyContent: 'end', marginRight: '10px' }}>
                        <IPButton
                          type='button'
                          className='btn btn-success'
                          onClick={() => setState({ showModal: true })}
                          style={{ width: '25%' }}
                          text='Link Ekle'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className='form-group col-md-6'>
                      <table className='table'>
                        <thead className='table-light '>
                          <tr>
                            <th>Başlık</th>
                            <th>Link</th>
                            <th>İşlemler</th>
                          </tr>
                        </thead>
                        <tbody>
                          {link &&
                            link.map(list => (
                              <tr role='row' className='odd' key={list.Id}>
                                <td>{list.Name}</td>
                                <td>
                                  <a href=''>{list.Link}</a>
                                </td>
                                <td>
                                  <a
                                    className='ml-2'
                                    data-toggle='tooltip'
                                    onClick={() => {
                                      setState({ showModal: true, button: 'Güncelle', link: list, linkDetail: 'link' })
                                    }}
                                    style={{ cursor: 'pointer', color: 'green' }}
                                  >
                                    <i className='icon-edit'></i>
                                  </a>
                                  <a
                                    className='ml-2'
                                    data-toggle='tooltip'
                                    onClick={() => deleteLink(list.Id, 'list')}
                                    style={{ cursor: 'pointer', color: 'red' }}
                                  >
                                    <i className='icon-trash-2'></i>
                                  </a>
                                </td>
                              </tr>
                            ))}
                          {state.linkList &&
                            state.linkList.map(list => (
                              <tr role='row' className='odd' key={list.Order}>
                                <td>{list.Name}</td>
                                <td>
                                  <a href=''>{list.Link}</a>
                                </td>
                                <td>
                                  <a
                                    className='ml-2'
                                    data-toggle='tooltip'
                                    onClick={() => {
                                      setState({
                                        showModal: true,
                                        button: 'Güncelle',
                                        link: list,
                                        linkDetail: 'linkList',
                                      })
                                    }}
                                    style={{ cursor: 'pointer', color: 'green' }}
                                  >
                                    <i className='icon-edit'></i>
                                  </a>
                                  <a
                                    className='ml-2'
                                    data-toggle='tooltip'
                                    onClick={() => deleteLink(list.Order, 'linkList')}
                                    style={{ cursor: 'pointer', color: 'red' }}
                                  >
                                    <i className='icon-trash-2'></i>
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
              <br />
              <div className='row'>
                <div className='siparisKodu col-2 '>
                  <IPButton type='button' className='btn btn-success' onClick={() => editBrand()} text={values.flag} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        title={`Link ${state.button}`}
        show={state.showModal ? state.showModal : false}
        onClose={() => setState({ showModal: false })}
        confirmText={state.button}
        onConfirm={addLink}
      >
        <>
          <div className='form-row'>
            <div className='form-group align-items-center row col-12'>
              <label className='col-form-label col-lg-12 text-sm-left'>Adı</label>
              <br />
              <div className='col-lg-12'>
                <IPInput
                  type='text'
                  className='form-control'
                  name='Name'
                  value={state.link.Name}
                  required
                  onChange={event =>
                    setState({
                      link: {
                        Name: event.target.value,
                        Link: state.link.Link,
                        Order: state.link.Order,
                        Id: state.link.Id,
                      },
                    })
                  }
                />
              </div>
            </div>
            <div className='form-group align-items-center row col-12'>
              <label className='col-form-label col-lg-12 text-sm-left text-sm-left'>Link</label>
              <div className='col-lg-12'>
                <IPInput
                  type='text'
                  className='form-control'
                  name='Link'
                  required
                  value={state.link.Link}
                  onChange={event =>
                    setState({
                      link: {
                        Name: state.link.Name,
                        Link: event.target.value,
                        Order: state.link.Order,
                        Id: state.link.Id,
                      },
                    })
                  }
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  )
}

export default BrandUpdate
