import { useFormik } from 'formik'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useCustomReducer } from '../../../../base/customHooks'
import { IExchangeRate } from '../../../../layouts/components/client/models'
import { GetExchangeRatesApi } from '../../../../layouts/components/client/services/getExchangeRatesApi'
import moment from 'moment'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import Swal from 'sweetalert2'
import { ListCurrencyLogHistoryResponse, ListCurrencyLogHistoryService } from './services/listCurrencyLogHistoryApi'
import { exportToXLSX, getDateByTimezoneOffset } from '../../../../base/utils/dataHelper'
import InputCampaign from '../../../../components/InputCampaign'
import IPButton from '../../../../components/IPButton'
import Table from '../../../../components/Table'
import { css } from '@emotion/css'
import { LastTwoCurrencyInfoService } from './services/lastTwoCurrencyInfoApi'
export interface ExchangeRateState {
  firstExchangeRate?: IExchangeRate
  previousUsd?: number
  previousEur?: number
  previousGbp?: number
  currentUsd?: number
  currentEur?: number
  currentGbp?: number
  whenUpdated?: Date
  previousWhenUpdated?: Date
}
function ExchangeRateInformation(props: BaseComponentWithContextProps) {
  const getInitialState = (): ExchangeRateState => {
    return {
      previousUsd: 0,
      previousEur: 0,
      previousGbp: 0,
      currentUsd: 0,
      currentEur: 0,
      currentGbp: 0,
      whenUpdated: new Date(),
      previousWhenUpdated: new Date(),
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ExchangeRateState>(intialState)
  const [tableData, setTableData] = useState<ListCurrencyLogHistoryResponse[]>([])

  //#region Start-End Date Selectbox
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [StartDate, setStartDate] = useState(inititalStartDate)
  const [EndDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }
  //#endregion

  const initialValues = Object.assign({
    Usd: 0,
    Eur: 0,
    Gbp: 0,
  })
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    validateField,
    setFieldValue,
    setFieldTouched,
    resetForm,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: initialValues,

    onSubmit: async values => {},
  })

  useEffect(() => {
    //getExchangeRates()
    getLastTwoCurrencyInfo()
  }, [])

  // const getExchangeRates = async () => {
  //   try {
  //     props.showLoading()
  //     const api = new GetExchangeRatesApi({})
  //     const res = await api.getExhangeRates()
  //     props.hideLoading()

  //     const formatCurrency = value => {
  //       if (value === null || value === undefined) {
  //         return ''
  //       }
  //       const parts = value.split(/[.,]/)
  //       const integerPart = parts[0]
  //       const decimalPart = parts[1] || ''
  //       const precision = 4

  //       const paddedDecimalPart = decimalPart.padEnd(precision, '0').substring(0, precision)
  //       return `${integerPart},${paddedDecimalPart}`
  //     }

  //     const formattedUsd = formatCurrency(res.Usd)
  //     const formattedEur = formatCurrency(res.Eur)
  //     const formattedGbp = formatCurrency(res.Gbp)
  //     const formattedPreviousEur = formatCurrency(res.PreviousEur)
  //     const formattedPreviousUsd = formatCurrency(res.PreviousUsd)
  //     const formattedPreviousGbp = formatCurrency(res.PreviousGbp)

  //     setState({
  //       firstExchangeRate: {
  //         ...res,
  //         Usd: formattedUsd,
  //         Eur: formattedEur,
  //         Gbp: formattedGbp,
  //         PreviousEur: formattedPreviousEur,
  //         PreviousUsd: formattedPreviousUsd,
  //         PreviousGbp: formattedPreviousGbp,
  //       },
  //     })

  //     setValues({
  //       Usd: Number(formattedUsd),
  //       Eur: Number(formattedEur),
  //       Gbp: Number(formattedGbp),
  //       PreviousEur: Number(formattedPreviousEur),
  //       PreviousUsd: Number(formattedPreviousUsd),
  //       PreviousGbp: Number(formattedPreviousGbp),
  //     })
  //   } catch (err: any) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: err.description,
  //       showConfirmButton: true,
  //       allowOutsideClick: false,
  //     })
  //     props.hideLoading()
  //   }
  // }

  const getLastTwoCurrencyInfo = async () => {
    try {
      const res = await new LastTwoCurrencyInfoService({}).getLastTwoCurrInfo()
      if (res) {
        setState({
          previousUsd: res[0] && res[0].Usd,
          previousEur: res[0] && res[0].Euro,
          previousGbp: res[0] && res[0].Gbp,
          currentUsd: res[1] && res[1].Usd,
          currentEur: res[1] && res[1].Euro,
          currentGbp: res[1] && res[1].Gbp,
          whenUpdated: res[1] && res[1].Date,
          previousWhenUpdated: res[0] && res[0].Date,
        })
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      props.hideLoading()
    }
  }

  //#region DÖVİZ TARİHÇE
  const getCurrencyLogHistory = async () => {
    const req = {
      StartDate: getDateByTimezoneOffset(StartDate),
      EndDate: getDateByTimezoneOffset(EndDate),
    }
    props.showLoading()
    try {
      const res = await new ListCurrencyLogHistoryService({}).Post(req)
      if (res) {
        res.sort((a, b) => {
          return new Date(b.Date).getTime() - new Date(a.Date).getTime()
        })
        setTableData(res)
        props.hideLoading()
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      props.hideLoading()
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Tarih',
        accessor: 'Date',
      },
      {
        Header: 'Dolar',
        accessor: 'Usd',
      },
      {
        Header: 'Euro',
        accessor: 'Euro',
      },
      {
        Header: 'Gbp',
        accessor: 'Gbp',
      },
    ],
    []
  )

  const data = useMemo(
    () =>
      tableData.map(p => ({
        Date: moment(p.Date).format('DD.MM.YYYY HH:mm:ss'),
        Usd: p.Usd,
        Euro: p.Euro,
        Gbp: p.Gbp,
      })),
    [tableData]
  )

  const excelButton = async () => {
    try {
      if (!tableData || tableData.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Excele atılacak veri bulunmamaktadır.',
          allowOutsideClick: true,
          backdrop: true,
        })
        return
      }

      const data = tableData.map(p => {
        const rowData = {
          Tarih: moment(p.Date).format('DD.MM.YYYY HH:mm:ss'),
          USD: p.Usd,
          EURO: p.Euro,
          GBP: p.Gbp,
        }
        return rowData
      })

      exportToXLSX(data, 'Döviz Kurları Geçmişi')
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }
  //#endregion

  const containerStyles = {
    display: 'flex',
  }

  const textStyles = {
    color: 'black',
    margin: '0px 0px 35px 0px',
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <section
            style={{
              padding: '25px 0',
            }}
          >
            <div
              style={{
                margin: '0px 0px 40px 0px',
                display: 'block',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <h2
                style={{
                  margin: '0px 32px 0px 0px',
                  fontFamily: 'normal 700 42px/1.2 "Source Sans Pro", sans-serif',
                  color: 'black',
                  textAlign: 'center',
                }}
              >
                Döviz Kur Bilgisi
              </h2>
            </div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gridGap: '32px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={containerStyles}>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    padding: '32px 0px 64px 0px',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      color: 'black',
                      margin: '0px 0px 35px 0px',
                      fontWeight: 'bold',
                    }}
                  >
                    Kurlar
                  </div>
                  <div style={textStyles}>USD</div>
                  <div style={textStyles}>EUR</div>
                  <div style={textStyles}>GBP</div>
                  <div style={textStyles}>Değiştirilme Tarihi</div>
                </div>
              </div>

              <div style={containerStyles}>
                <div
                  style={{
                    display: 'flex',
                    width: '250px',
                    background: '#ccdbf5',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderRadius: '16px',
                    padding: '32px 0px 64px 0px',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      color: 'black',
                      margin: '0px 0px 35px 0px',
                      fontWeight: 'bold',
                    }}
                  >
                    Güncel Kur Bilgisi
                  </div>
                  {/* <div style={textStyles}>{`${'$'}${state.firstExchangeRate?.Usd}`}</div>
                  <div style={textStyles}>{`${'€'}${state.firstExchangeRate?.Eur}`}</div>
                  <div style={textStyles}>{`${'£'}${state.firstExchangeRate?.Gbp}`}</div>
                  <div style={textStyles}>
                    {moment(state.firstExchangeRate?.WhenUpdated).format('DD.MM.YYYY HH:mm:ss')}
                  </div> */}
                  <div style={textStyles}>{`${'$'}${state.currentUsd}`}</div>
                  <div style={textStyles}>{`${'€'}${state.currentEur}`}</div>
                  <div style={textStyles}>{`${'£'}${state.currentGbp}`}</div>
                  <div style={textStyles}>{moment(state.whenUpdated).format('DD.MM.YYYY HH:mm:ss')}</div>
                </div>
              </div>

              <div style={containerStyles}>
                <div
                  style={{
                    display: 'flex',
                    width: '250px',
                    background: 'rgb(172 175 181)',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderRadius: '16px',
                    padding: '32px 0px 64px 0px',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      color: 'black',
                      margin: '0px 0px 35px 0px',
                      fontWeight: 'bold',
                    }}
                  >
                    Önceki Kur Bilgisi
                  </div>
                  {/* <div style={textStyles}>{`${'$'}${state.firstExchangeRate?.PreviousUsd}`}</div>
                  <div style={textStyles}>{`${'€'}${state.firstExchangeRate?.PreviousEur}`}</div>
                  <div style={textStyles}>{`${'£'}${state.firstExchangeRate?.PreviousGbp}`}</div>
                  <div style={textStyles}>
                    {' '}
                    {moment(state.firstExchangeRate?.PreviousWhenUpdated).format('DD.MM.YYYY HH:mm:ss')}
                  </div> */}
                  <div style={textStyles}>{`${'$'}${state.previousUsd}`}</div>
                  <div style={textStyles}>{`${'€'}${state.previousEur}`}</div>
                  <div style={textStyles}>{`${'£'}${state.previousGbp}`}</div>
                  <div style={textStyles}>{moment(state.previousWhenUpdated).format('DD.MM.YYYY HH:mm:ss')}</div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className='content col-lg-12 mt-5'>
        <div className='card'>
          <section
            style={{
              padding: '25px 0',
            }}
          >
            <div
              style={{
                margin: '0px 0px 40px 0px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <h2
                style={{
                  margin: '0px 32px 0px 0px',
                  fontFamily: 'normal 700 42px/1.2 "Source Sans Pro", sans-serif',
                  color: 'black',
                  textAlign: 'center',
                }}
              >
                Detaylı Döviz Tarihçesi
              </h2>
              <div className='d-flex align-items-center'>
                <span>Excel: </span>
                <button
                  type='button'
                  style={{
                    border: '1px solid #2e7d32',
                    borderRadius: 0,
                    width: '29px',
                    height: '29px',
                    backgroundColor: 'white',
                    margin: '0 10px',
                  }}
                  onClick={excelButton}
                  className='btn text-capitalize background-white-important d-flex align-items-center'
                >
                  <i
                    className='fas fa-file-excel'
                    style={{
                      marginLeft: '-6px',
                      fontSize: '18px',
                      color: '#2e7d32',
                    }}
                  ></i>
                </button>
              </div>
            </div>
            <div className='col-lg-12' style={{ padding: '0 25%', textAlign: 'center' }}>
              <div className='d-flex flex-column flex-sm-row gap-2 mb-3 justify-content-center'>
                <InputCampaign
                  type='datetime-local'
                  id='StartDate'
                  name='StartDate'
                  label={'Başlangıç Tarihi'}
                  value={toDateTimeLocal(StartDate)}
                  onChange={e => getDate(e.target.value, 'Start')}
                  isActive
                />
                <InputCampaign
                  type='datetime-local'
                  id='EndDate'
                  name='EndDate'
                  label={'Bitiş Tarihi'}
                  value={toDateTimeLocal(EndDate)}
                  onChange={e => getDate(e.target.value, 'End')}
                  isActive
                />
                <IPButton
                  type='button'
                  className={`btn btn-danger ${css`
                    width: 150px;
                    height: 50px;
                    @media (max-width: 575px) {
                      width: 100%;
                    }
                  `}`}
                  text='Listele'
                  onClick={() => getCurrencyLogHistory()}
                />
              </div>
              <Table columns={columns} data={data} />
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(ExchangeRateInformation)

// const editCurrency = async () => {
//   if (values) {
//     const updateCurrency: CurrencyUpdateApi = new CurrencyUpdateApi({});
//     updateCurrency
//       .updateCurrency({

//         Eur: Number(values.Eur),
//         Usd: Number(values.Usd),
//         Gbp: Number(values.Gbp),

//       })
//       .then(async (result) => {
//         if (result) {
//           setState({ firstExchangeRate: result });
//           Swal.fire({
//             icon: "success",
//             title: "Güncellendi",
//             showConfirmButton: true,
//             allowOutsideClick: false,
//           }).then(async (result) => {
//             navigate("/admin/settings/exchangeRateInformation");

//           });
//         }
//       })
//       .catch((err: any) => {
//         Swal.fire({
//           icon: "error",
//           title: err.description,
//           showConfirmButton: true,
//           allowOutsideClick: false,
//         });
//       });
//   }
// }

// <div className='container'>
//   <div className='content col-lg-12 m-auto'>
//     <div className='card'>
//       <div className='card-header'>
//         <span className='h4'>Döviz Kur Bilgisi</span>
//       </div>
//       <div className='card-body'>
//         <div className='row'>
//           <div className='col-md-4'>
//             <div style={{ fontSize: '25px' }}>
//               <label>USD</label>
//             </div>
//             <br></br>
//             <span style={{ fontSize: '25px' }}>{`${'$'}${state.firstExchangeRate?.Usd}`}</span>
//             {/* <div className='mt-4'>
//               <label>Yeni Değer</label>
//               <IPInput
//                 type='number'
//                 skipCustomValidation
//                 name={"Usd"}
//                 onChange={handleChange}
//                 value={values.Usd} />
//             </div> */}
//           </div>
//           <div className='col-md-4'>
//             <div style={{ fontSize: '25px' }}>
//               {' '}
//               <label>EURO</label>
//             </div>
//             <br></br>
//             <span style={{ fontSize: '25px' }}>{`${'€'}${state.firstExchangeRate?.Eur}`}</span>
//             {/* <div className='mt-4'>
//               <label>Yeni Değer</label>
//               <IPInput
//                 type='number'
//                 name={"Eur"}
//                 skipCustomValidation
//                 onChange={handleChange}
//                 value={values.Eur} />
//             </div> */}
//           </div>
//           <div className='col-md-4'>
//             <div style={{ fontSize: '25px' }}>
//               {' '}
//               <label>GBP</label>
//             </div>
//             <br></br>
//             <span style={{ fontSize: '25px' }}>{`${'£'}${state.firstExchangeRate?.Gbp}`}</span>
//             {/* <div className='mt-4'>
//               <label>Yeni Değer</label>
//               <IPInput
//                 type='number'
//                 name={"Gbp"}
//                 skipCustomValidation
//                 onChange={handleChange}
//                 value={values.Gbp}
//               />
//             </div> */}
//           </div>
//         </div>

//         {/* <div
//           className=" col-md-2  mt-3"
//         >
//           <IPButton
//             type="button"
//             text='Güncelle'
//             onClick={() => editCurrency()}>

//           </IPButton>
//         </div> */}
//       </div>
//     </div>
//   </div>
// </div>
