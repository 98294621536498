import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { CreditCardModel } from '../models/models'
import moment from 'moment'
import IPSelectBox, { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { useCustomReducer } from '../../../../../base/customHooks'
import InputCampaign from '../../../../../components/InputCampaign'
import IPInput from '../../../../../components/IPInput'
import IPButton from '../../../../../components/IPButton'
import Table from '../../../../../components/Table'
import IPCheckbox from '../../../../../components/IPCheckBox'
import { GetDealerCreditCardsApi } from '../services/getDealerCreditCardsApi'
import { ChangeStatusCreditCardApi } from '../services/changeStatusCreditCardApi'

interface ICreditCardState {
  selectedStatus?: ISelectOption
  dealerCode?: string
  dealerName?: string
  cardNumber?: string
  cardName?: string
  isActive?: boolean
}

function Index(props: BaseComponentWithContextProps) {

  const getInitialState = (): ICreditCardState => {
    return {
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ICreditCardState>(intialState)
  const [cardList, setCardList] = useState<CreditCardModel[]>([])


  const filterStatus = [
    { label: 'Onaylandı', value: 2 },
    { label: 'Reddedildi', value: 1 },
    { label: 'Beklemede', value: 0 },
  ]

  const getList = async () => {
    props.showLoading()

    const reqModel: any = {
      DealerCode: state.dealerCode,
      DealerName: state.dealerName,
      CardName: state.cardName,
      CardNumber: state.cardNumber,
      Status: state.selectedStatus?.value,
      Active: state.isActive
    }

    const netapi: GetDealerCreditCardsApi = new GetDealerCreditCardsApi({})
    try {
      await netapi.getDealerCreditCards(reqModel).then(result => {
        if (result) {
          setCardList(result)
        }
        props.hideLoading()
      })
        .catch((err: any) => {
          props.hideLoading()
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    }
    catch (err: any) {
      props.hideLoading()
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  useEffect(() => {
    getList()
  }, [])

  const changeStatus = async (id: number, status: number, active: boolean) => {
    props.showLoading()

    const reqModel: any = {
      Id: id,
      Status: status,
      Active: active
    }

    const netapi: ChangeStatusCreditCardApi = new ChangeStatusCreditCardApi({})
    try {
      await netapi.changeStatusCreditCard(reqModel).then(result => {
        if (result) {
          getList()
        }
        props.hideLoading()
      })
        .catch((err: any) => {
          props.hideLoading()
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    }
    catch (err: any) {
      props.hideLoading()
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }


  const columnDefinitions = [
    { Header: '#', accessor: 'order' },
    { Header: 'Bayi Kodu', accessor: 'bayiKodu' },
    { Header: 'Bayi Adı', accessor: 'bayiAdı' },
    { Header: 'Kart Numarası', accessor: 'cardNumber' },
    { Header: 'Kart Sahibi', accessor: 'cardName' },
    { Header: 'Kart Resmi', accessor: 'image' },
    { Header: 'Oluşturulma Tarihi', accessor: 'create' },
    { Header: 'Aktiflik', accessor: 'enabled' },
    { Header: 'Silindi', accessor: 'deleted' },
    { Header: 'Kullanılabilir', accessor: 'active' },
    { Header: 'Durum', accessor: 'status' },
    { Header: 'İşlemler', accessor: 'changes' },
    { Header: 'Güncellenme Tarihi', accessor: 'update' },
    { Header: 'Güncelleyen', accessor: 'acceptingUser' },
  ]

  const tableColumns = useMemo(() => columnDefinitions, [])

  const tableData = useMemo(() => {
    const tableData = cardList?.map((item, index) => {
      const data = {
        order: index + 1,
        bayiKodu: item.DealerCode && item.DealerCode.replace('0000', ''),
        bayiAdı: item.DealerName,
        cardNumber: item.CardNumber,
        cardName: item.CardName,
        image: item.Image && <Link to={item.Image} target='_blank'>{
          (<img
            style={{ cursor: 'pointer' }}
            src='/images/document-icon.svg'
            alt=''
          />)
        }</Link>,
        create: item.CreateDate && moment(item.CreateDate).format('DD.MM.YYYY HH:mm:ss'),
        enabled: item.Enabled ? <span style={{ color: 'green' }}>Evet</span> : <span style={{ color: 'red' }}>Hayır</span>,
        deleted: item.Deleted ? <span style={{ color: 'red' }}>Evet</span> : <span style={{ color: 'green' }}>Hayır</span>,
        active: item.Active ? <span style={{ color: 'green' }}>Evet</span> : <span style={{ color: 'red' }}>Hayır</span>,
        status: <span style={{ color: item.Status === 0 ? 'orange' : item.Status === 1 ? 'red' : item.Status === 2 ? 'green' : '' }}>{filterStatus.find(a => a.value === item.Status)?.label}</span>,
        update: item.AcceptingTime && moment(item.AcceptingTime).format('DD.MM.YYYY HH:mm:ss'),
        acceptingUser: item.AcceptingUserDealer && item.AcceptingUserName && "(" + (item.AcceptingUserDealer.replace('0000', '') + ") " + item.AcceptingUserName),
        changes: <><div>
          <a
            data-action-type='edit'
            role='button'
            style={{ cursor: 'pointer' }}
            data-toggle='tooltip'
            data-original-title="Onayla"
            onClick={() => changeStatus(item.Id, 2, item.Active)}
            className='CellWithComment ml-auto'
          >
            <i className="fa fa-thumbs-up" style={{ color: "#29db7f", width: "20px" }}></i>
            <span className='CellComment'>Onayla</span>
          </a>
          <a
            data-action-type='edit'
            role='button'
            style={{ cursor: 'pointer', marginLeft: '10px' }}
            data-toggle='tooltip'
            data-original-title="Red"
            onClick={() => changeStatus(item.Id, 1, item.Active)}
            className='CellWithComment'
          >
            <i className="fa fa-thumbs-down" style={{ color: "red", width: "20px" }}></i>
            <span className='CellComment'>Reddet</span>
          </a>
          <a
            data-action-type='edit'
            role='button'
            style={{ cursor: 'pointer', marginLeft: '10px' }}
            data-toggle='tooltip'
            data-original-title="Kapat"
            onClick={() => changeStatus(item.Id, item.Status, !item.Active)}
            className='CellWithComment'
          >
            {
              item.Active ? (
                <>
                  <i className="fa fa-x" style={{ color: "red", width: "20px" }}></i>
                  <span className='CellComment'>Kartı Kapat</span>
                </>
              ) :
                (
                  <>
                    <i className="fa fa-check" style={{ color: "green", width: "20px" }}></i>
                    <span className='CellComment'>Kartı Aç</span>
                  </>
                )

            }
          </a>
        </div></>,
      }
      return data
    })
    return {
      tableData,
    }
  }, [cardList])

  return (
    <>
      <div className='card'>
        <div className='content col-lg-12'>
          <div className='card-header px-4'>
            <span className='h4'>
              Bayi Kredi Kartı Listesi
            </span>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row'>
                <div className='col-lg-2'>
                  <IPInput type='text'
                    className='form-control'
                    id='bayiAdi'
                    placeholder={'Bayi Adı'}
                    value={state.dealerName}
                    onChange={e => {
                      setState({ dealerName: e.target.value })
                    }}
                  />
                </div>
                <div className='col-lg-2'>
                  <IPInput type='text'
                    className='form-control'
                    id='bayiKodu'
                    placeholder={'Bayi Kodu'}
                    value={state.dealerCode}
                    onChange={e => {
                      setState({ dealerCode: e.target.value })
                    }}
                  />
                </div>
                <div className='col-lg-2'>
                  <IPInput type='text'
                    className='form-control'
                    id='cardName'
                    placeholder={'Kart Sahibi'}
                    value={state.cardName}
                    onChange={e => {
                      setState({ cardName: e.target.value })
                    }}
                  />
                </div>
                <div className='col-lg-2'>
                  <IPInput type='text'
                    className='form-control'
                    id='cardNumber'
                    placeholder={'Kart Numarası'}
                    value={state.cardNumber}
                    onChange={e => {
                      setState({ cardNumber: e.target.value })
                    }}
                  />
                </div>
                <div className='col-lg-2'>
                  <IPSelectBox
                    isClearable
                    id='Status'
                    name='Status'
                    options={filterStatus}
                    placeholder={'Başvuru Durumu'}
                    value={state.selectedStatus}
                    onChangeSingle={(option) => {
                      setState({ selectedStatus: option })
                    }
                    }
                  />
                </div>
                <div className='col-lg-1'>
                  <IPCheckbox
                    id='Active'
                    name='Active'
                    label={'Aktif'}
                    checked={state.isActive}
                    onCheckedChange={() => {
                      setState({ isActive: !state.isActive })
                    }
                    }
                  />
                </div>
                <div className='col-lg-1'>
                  <IPButton text='Listele' className='button' onClick={() => getList()} />
                </div>
              </div>
            </form>
          </div>
          <div>
            <Table
              columns={tableColumns}
              data={tableData.tableData}
              currentPage={0}
              columnCount={16}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default componentWithContext(Index)
