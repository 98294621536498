import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import Swal from 'sweetalert2'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import { exportToXLSX } from '../../../../base/utils/dataHelper'
import ExcelButton from '../../../../components/IPExcelButton/IPExcelButton'
import Table from '../../../../components/Table'
import { ILicenseTracking } from '../models/license'
import { GetLicenseApi } from '../services/getLicenseApi'

function Microsoft(props: BaseComponentWithContextProps) {
  const [myLicense, setMyLicense] = useState<ILicenseTracking[]>([])

  const getLicenseTracking = async () => {
    props.showLoading()
    const getLicenseTracking: GetLicenseApi = new GetLicenseApi({})
    getLicenseTracking
      .getLicense()
      .then(result => {
        if (result) {
          setMyLicense(result.sort((a, b) => Number(b.SIP_NO) - Number(a.SIP_NO)))
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        if (!err.description || err.description === 'OK') {
          Swal.fire({
            icon: 'error',
            title: 'Veri bulunamadı',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }

        props.hideLoading()
      })
    return
  }

  useEffect(() => {
    getLicenseTracking()
  }, [])

  const columnDefinitions = [
    { Header: 'Müşteri Adı', accessor: 'col1' },
    { Header: 'Ürün Kodu', accessor: 'col2' },
    { Header: 'Açıklama', accessor: 'col3' },
    { Header: 'Ürün Anahtarı', accessor: 'col4' },
    { Header: 'Sipariş No', accessor: 'col5' },
    { Header: 'Satın Alma Tarihi', accessor: 'col6' },
  ]

  const tableColumns = useMemo(() => columnDefinitions, [])

  const tableData = useMemo(
    () =>
      myLicense.map(item => {
        const data = {
          col1: item.MUSTERI_ADI,
          col2: item.URUN_KODU,
          col3: item.ACIKLAMA,
          col4: item.URUN_ANAHTARI,
          col5: item.SIP_NO,
          col6: moment(item.SATIN_ALMA_TARIHI, 'DD.MM.YYYY').format('DD.MM.YYYY'),
        }
        return data
      }),
    [myLicense]
  )

  //#region Excel'e aktarma
  const excelButton = async () => {
    try {
      if (myLicense) {
        const data: any[] = []
        if (myLicense.length > 0) {
          myLicense.map(item => {
            return data.push({
              'Müşteri Adı': item.MUSTERI_ADI,
              'Ürün Kodu': item.URUN_KODU,
              Açıklama: item.ACIKLAMA,
              'Ürün Anahtarı': item.URUN_ANAHTARI,
              'Sipariş No': item.SIP_NO,
              'Satın Alma Tarihi': moment(item.SATIN_ALMA_TARIHI, 'DD.MM.YYYY').format('DD.MM.YYYY'),
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Microsoft ESD Ürünleri')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }
  //#endregion

  return (
    <div className='row'>
      <div className='p-4 d-flex justify-content-between align-items-center'>
        <span className='h4 mb-0'>Microsoft ESD Ürünleri</span>
        <div className='d-flex align-items-center'>
          <ExcelButton onClick={excelButton} />
        </div>
      </div>
      <div className='col-lg-12 text-nowrap'>
        <div>
          <div className='row'>
            <div className='m-4'>
              <Table
                columns={tableColumns}
                data={tableData}
                currentPage={0}
                columnCount={6}
                backgroundColor='red'
                color='white'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(Microsoft)
