import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import _ from 'lodash'
import IPImage from '../../../components/IPImage'
import { IDocument, INews } from '../models/models'
import { GetByIdApi } from '../services/getByIdApi'

export default function NewsAnnouncements() {
  const routeLocation = useLocation()
  const [brand, setBrand] = useState<IDocument>()
  const [news, setNews] = useState<INews>()
  const [description, setDescription] = useState([])
  const [link, setLink] = useState()

  useEffect(() => {
    const data = _.clone((routeLocation.state as any)?.brand)
    const id = _.clone((routeLocation.state as any)?.id)
    const search = _.clone((routeLocation.state as any)?.search)
    setLink(search)
    data &&
      data.map(dt => {
        if (dt.Name === 'Logo') {
          return setBrand(dt)
        }
      })
    getNews(id)
  }, [routeLocation])
  const getNews = async (data: number) => {
    const getByIdApi: GetByIdApi = new GetByIdApi({})
    const newsData: INews = await getByIdApi.getById(data)

    const datas = newsData.Description.split('<br>')
    const dat = datas.map(dt => {
      return dt.replace('<p>', '').replace('</p>', '').replace('&nbsp;', '')
    })
    setNews(newsData as any)
    setDescription(dat as any)
  }

  return (
    <div className='container'>
      <div className='pageTitle my-4'>
        <span style={{ fontSize: '1.3vw' }}>Haberler/Duyurular</span>
        {brand && (
          <Link to={`/brandDetail${link}`}>
            <IPImage src={brand?.FileUrl} className={brand?.Name} alt='' style={{ width: '50px', float: 'right' }} />
          </Link>
        )}
      </div>
      <div className='row'>
        {news && <h2>{news.Topic}</h2>}
        {description &&
          description.map((desc: string) => {
            return (
              <div
                dangerouslySetInnerHTML={{
                  __html: desc ? desc.replaceAll('&lt;', '<').replaceAll('&gt;', '>') : '',
                }}
              ></div>
            )
          })}
      </div>
    </div>
  )
}
